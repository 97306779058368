import { persistAndSyncStore } from '@/utils/mobx'
import { autorun, toJS, makeAutoObservable } from 'mobx'
import { KnowledgeChecksums } from '@roolz/types/api/knowledge'
import { Country, UserCountry } from '@roolz/types/custom'

class KnowledgeStore {
  checksums: KnowledgeChecksums = {}
  countries: Country[] = []
  userCountry?: UserCountry

  constructor() {
    makeAutoObservable(this)
  }

  setUserCountry(country: UserCountry): void {
    this.userCountry = country
  }

  reset() {
    const instance = new KnowledgeStore()
    // @ts-ignore
    for(const [key, value] in Object.entries(instance)) {
      // @ts-ignore
      this[key] = value
    }
  }
}

const knowledgeStore = persistAndSyncStore(new KnowledgeStore(), 'knowledge')

export {
  KnowledgeStore,
  knowledgeStore
}
