import { useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

interface Props {
  element: HTMLElement
  onResize: (entries: ResizeObserverEntry[]) => void
}


export function useResizeObserver({
  element,
  onResize
}: Props, deps: Array<unknown> = []) {
  useEffect(() => {
    if(!element) {
      return
    }

    // TODO make polyfill for old safari (Mutation Observer)
    const obs = new ResizeObserver(onResize)

    obs.observe(element)

    return () => {
      element && obs.unobserve(element)
      obs.disconnect()
    }
  }, [element, ...deps])
}
