import { ChatModel, isSystemMessage, MessageModel } from '@/types/models/chat'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { ChatType, MessageState, MessageType, OwnPcp, PcpStatus, PcpType } from '@roolz/types/api/chats'
import { OnlineStatus } from '@roolz/types/custom'
import { groupBy } from 'lodash'
import { Profile } from '@roolz/types/api/profiles'

export function divideMessagesToGroups({
    messages,
    isOwnMessage,
    chat,
    lastReadMessageIndex
  }: {
    messages: MessageModel[],
    isOwnMessage: (message: MessageModel) => boolean
    chat: ChatModel,
    lastReadMessageIndex: OwnPcp["last_read_message_index"] | null
  }
): MessageModel[][] {
  const MAX_MESSAGES_MINUTES_INTERVAL = 10

  const groups: MessageModel[][] = []

  const isMessageStartsNewGroup = (i: number) => {
    const message = messages[i]
    const prevMessage = messages[i - 1]

    if(i === 0) return true

    if(lastReadMessageIndex === message.number - 1) {
      return true
    }

    // No need to divide own messages to groups
    // if((!prevMessage ||  isOwnMessage(prevMessage)) && isOwnMessage(message)) return false

    return (
      !prevMessage
      || message?.sender_id !== prevMessage?.sender_id
      || dayjs(message.created_at).diff(prevMessage.created_at, 'minutes') > MAX_MESSAGES_MINUTES_INTERVAL
      || [MessageType.STICKER].includes(prevMessage?.type)
      || [MessageType.STICKER].includes(message?.type)
      || isSystemMessage(prevMessage)
      || isSystemMessage(message)
      || !dayjs(message.created_at).isSame(prevMessage.created_at, 'day')
    )
  }

  messages.forEach((message, i) => {
    if(isMessageStartsNewGroup(i)) {
      groups.push([])
    }

    (groups.at(-1) as Array<MessageModel>).push(message)
  })

  return groups
}

export function isMessageVisibleForMe(message: MessageModel) {
  if(!message.chat || !message.chat?.own_pcp) {
    return false
  }
  // const { chat } = message

  if(!message || message?.state === MessageState.NOT_DISPLAYED) return false

  return true

  // if (
  //   [ChatType.CHANNEL, ChatType.GROUP_CHAT].includes(chat?.type)
  //   &&
  // )
}

// const isMessageStartsNewMessages = (message: MessageModel) => {
//   return message.cha .own_pcp.last_read_message_index === message.number
// }


export function getMessageDate(message: MessageModel): string {
  return dayjs(message.created_at).format('MM/DD/YYYY')
}


export function groupMessagesByDates(messages: MessageModel[]) {
  return groupBy(messages, (message) => {
    return getMessageDate(message)
  })
}

export enum RenderItemType {
  Date = 'date',
  Message = 'message',
  MessageGroup = 'message-group'
}
export type MessagesRenderItem = {
  type: RenderItemType.Date
  date: string
} | {
  type: RenderItemType.Message
  message: MessageModel
} | {
  type: RenderItemType.MessageGroup
  messages: MessageModel[]
}

export function buildMessagesRenderArray(messages: MessageModel[], params: {
  isOwnMessage: (message: MessageModel) => boolean
  chat: ChatModel
}): {
  renderArray: MessagesRenderItem[],
  stickyIndices: number[]
} {
  const res: MessagesRenderItem[]  = []
  const dateIndices: number[] = []

  let currentDateMessages: MessageModel[] = []

  messages.forEach((message, i) => {
    const prevMessage = messages[i - 1]
    if(i === 0 || getMessageDate(prevMessage) !== getMessageDate(message)) {
      if(currentDateMessages.length) {
        divideMessagesToGroups({
          isOwnMessage: params.isOwnMessage,
          messages: currentDateMessages,
          chat: params.chat,
          lastReadMessageIndex: params.chat.own_pcp.last_read_message_index
        })
          .forEach(messages => {
            res.push({
              type: RenderItemType.MessageGroup,
              messages
            })
          })

        currentDateMessages = []
      }

      res.push({
        type: RenderItemType.Date,
        date: getMessageDate(message)
      })

      dateIndices.push(res.length - 1)
    }

    currentDateMessages.push(message)
  })

  if(currentDateMessages.length) {
    divideMessagesToGroups({
      isOwnMessage: params.isOwnMessage,
      messages: currentDateMessages,
      chat: params.chat,
      lastReadMessageIndex: params.chat.own_pcp.last_read_message_index
    })
      .forEach(messages => {
        res.push({
          type: RenderItemType.MessageGroup,
          messages
        })
      })
  }

  return {
    renderArray: res,
    stickyIndices: dateIndices
  }
}

export function getChatName(chat: ChatModel, t: any): string {
  const ns = 'chat/common:'

  if(chat.type === ChatType.SELF_CHAT) {
    return t(ns + 'name.bookmarks')
  }

  if(chat.type === ChatType.DIALOG) {
    if(!chat.companion) {
      console.error('COMPANION OF CHAT ' + chat.id + ' NOT LOADED')
      return ''
    }
    if(!chat.companion.isActive) {
      return t(ns + 'name.deleted_profile')
    }

    return [chat.companion?.first_name, chat.companion?.last_name].join(' ')
  }

  if(chat.type === ChatType.CHANNEL) {
    return chat.name ?? ''
  }

  // TODO replace literal name
  if(chat.type === ChatType.GROUP_CHAT) {
    return chat.name ?? ''
  }

  return t('chat/list:name.unsupported_chat_type')
}

export function getOnlineStatusText(onlineStatus: OnlineStatus, lastOnlineDate: string, t: any) {
  switch(onlineStatus) {
    case OnlineStatus.Online:
      return t('chat/common:status.online')
    case OnlineStatus.Recently:
      return t('chat/common:status.online_recently')
  }

  const dayjsDate = dayjs(lastOnlineDate)

  if(dayjsDate.isToday()) {

    return t('chat/common:status.online_at', {
      datetime: dayjsDate.format('HH:mm')
    })
  }
  if(dayjsDate.isYesterday()) {
    return t('chat/common:status.online_yesterday')
  }

  return t('chat/common:status.online_at', {
    datetime: dayjsDate.format('HH:mm, DD.MM')
  })
}

export function checkIfCanReply(message: MessageModel) {
  // TODO check if can write to the chat
  return message.state === MessageState.ACTIVE
}

export function checkIfProfileCanWriteToChat(profile: Profile, chat: ChatModel) {
  switch(chat.type) {
    case ChatType.GROUP_CHAT:
      return chat.own_pcp?.status === PcpStatus.ACTIVE
    case ChatType.CHANNEL: {
      const isAdmin = [PcpType.ADMIN, PcpType.OWNER].includes(chat.own_pcp.type)

      return isAdmin
    }
    case ChatType.DIALOG:
      return chat.companion?.is_active
    case ChatType.SELF_CHAT:
      return true
  }
  return false
}
