interface Props {
  className?: string
}

export function ArrowRightAlt({ className = '' }: Props) {
  return (
    <svg
      className={className}
      width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.94995 0.350391L6.99995 1.42539L3.17495 5.25039L15.7 5.25039L15.7 6.75039L3.17495 6.75039L6.99995 10.5754L5.94995 11.6504L0.299951 6.00039L5.94995 0.350391Z'
        fill='#8E8E93'
      />
    </svg>
  )
}
