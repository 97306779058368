import { DONT_CLOSE_MENU_FLAG } from '../const'
import cn from 'classnames'
import { ElementType, MouseEvent, ReactNode } from 'react'
import styles from './MenuDescription.module.scss'

interface Props {
  Component?: ElementType
  prepend?: ReactNode
  label?: ReactNode
  className?: string
  labelClassName?: string

  [key: string]: any
}

export const MenuDescription = ({
  Component = 'div',
  prepend,
  label,
  className = '',
  labelClassName = '',
  ...rest
}: Props) => {
  function handleClick(event: MouseEvent) {
    // @ts-ignore
    event.target.flags ??= []
    // @ts-ignore
    event.target.flags.push(DONT_CLOSE_MENU_FLAG)
  }

  return (
    <Component
      className={cn(styles.root, className)}
      onClick={handleClick}
      {...rest}
    >
      {prepend && (
        <span className={styles.icon}>
          {prepend}
        </span>
      )}

      <div className={cn(styles.label, labelClassName)}>
        {label}
      </div>
    </Component>
  )
}
