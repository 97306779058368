import { AuthStore, authStore } from '@/store/auth/auth.store'
import { AuthFormStore, authFormStore } from '@/store/forms/auth-form.store'
import { ExchangeKnowledgeStore, exchangeKnowledgeStore } from '@/store/knowledge/stores/exchange-knowledge.store'
import { UiStore, uiStore } from '@/store/ui/ui.store'
import { configure } from 'mobx'

// TODO

configure({
  enforceActions: 'never'
})

export interface StoreInterface {
  ui: UiStore,
  options: ExchangeKnowledgeStore,
  auth: AuthStore,

  forms: {
    auth: AuthFormStore
  }
}

export const store: StoreInterface = {
  ui: uiStore,
  options: exchangeKnowledgeStore,
  auth: authStore,

  forms: {
    auth: authFormStore
  }
}

