import { useId } from 'react'

interface Props {
  color?: string
  size?: number
}

export const Volume = ({
  color = '#272A36',
  size = 24
}: Props) => {
  const id = useId()


  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='volume_chat'>
        <mask
          id={id}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='24'
          height='24'
        >
          <rect id='Bounding box' width='24' height='24' fill='#D9D9D9'/>
        </mask>
        <g mask={`url(#${id})`}>
          <path
            id='volume_up'
            d='M15.05 19.775C14.8 19.875 14.571 19.8416 14.363 19.675C14.1544 19.5083 14.05 19.2833 14.05 19C14.05 18.8666 14.0877 18.75 14.163 18.65C14.2377 18.55 14.3417 18.4666 14.475 18.4C15.7917 17.8666 16.85 17.021 17.65 15.863C18.45 14.7043 18.85 13.4083 18.85 11.975C18.85 10.5416 18.45 9.24563 17.65 8.08696C16.85 6.92896 15.7917 6.08329 14.475 5.54996C14.325 5.48329 14.2167 5.39563 14.15 5.28696C14.0834 5.17896 14.05 5.05829 14.05 4.92496C14.05 4.64163 14.1544 4.42063 14.363 4.26196C14.571 4.10396 14.8 4.07496 15.05 4.17496C16.65 4.84163 17.9334 5.87496 18.9 7.27496C19.8667 8.67496 20.35 10.2416 20.35 11.975C20.35 13.7083 19.8667 15.275 18.9 16.675C17.9334 18.075 16.65 19.1083 15.05 19.775ZM4.55002 14.5C4.30002 14.5 4.08769 14.4126 3.91302 14.238C3.73769 14.0626 3.65002 13.85 3.65002 13.6V10.4C3.65002 10.15 3.73769 9.93729 3.91302 9.76196C4.08769 9.58729 4.30002 9.49996 4.55002 9.49996H7.37502L10.125 6.74996C10.4084 6.46663 10.7334 6.40396 11.1 6.56196C11.4667 6.72063 11.65 6.99996 11.65 7.39996V16.6C11.65 17 11.4667 17.2793 11.1 17.438C10.7334 17.596 10.4084 17.5333 10.125 17.25L7.37502 14.5H4.55002ZM14.05 15.65V8.29996C14.6834 8.64996 15.1917 9.15829 15.575 9.82496C15.9584 10.4916 16.15 11.2166 16.15 12C16.15 12.7833 15.9584 13.5 15.575 14.15C15.1917 14.8 14.6834 15.3 14.05 15.65Z'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
}
