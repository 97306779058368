import { wsService } from '@/store/ws/ws.service'
import { wsStore } from '@/store/ws/ws.store'
import { getNow } from '@/utils/date'
import { persistAndSyncStore } from '@/utils/mobx'
import { autorun, makeAutoObservable, runInAction } from 'mobx'

class AuthStore {
  loading = false

  access_token?: string
  expiration_at?: number
  refresh_token?: string

  constructor() {
    makeAutoObservable(this)
  }

  get isAuthed(): boolean {
    return !!(this.access_token && this.expiration_at && new Date(this.expiration_at * 1000).getTime() > getNow().getTime())
  }

  setCredentials({
    access_token,
    expiration_at,
    refresh_token
  }: {
    access_token: string,
    expiration_at: number,
    refresh_token: string
  }) {
    this.access_token = access_token
    this.expiration_at = expiration_at
    this.refresh_token = refresh_token

    if(access_token) {
      wsService.init()
    }
  }

  clean() {
    runInAction(() => {
      this.access_token = ''
      this.expiration_at = 0
      this.refresh_token = ''
    })
  }
}

const authStore = persistAndSyncStore(new AuthStore(), 'auth')

autorun(() => {
  console.log('authStore', authStore.access_token)
})

export {
  AuthStore,
  authStore
}
