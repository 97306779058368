interface Props {
  size?: number,
  color?: string
}

export const Time = ({ size = 14, color = '#8E8E93' }: Props) => {
  return (
    <svg width={size} height={size} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.98366 9.68334C9.07255 9.77223 9.18366 9.81667 9.31699 9.81667C9.45033 9.81667 9.56699 9.76667 9.66699 9.66667C9.76699 9.56667 9.81699 9.45 9.81699 9.31667C9.81699 9.18334 9.76699 9.06667 9.66699 8.96667L7.50033 6.8V4.15C7.50033 4.01667 7.45321 3.90289 7.35899 3.80867C7.26433 3.714 7.14477 3.66667 7.00033 3.66667C6.85588 3.66667 6.73655 3.714 6.64233 3.80867C6.54766 3.90289 6.50033 4.02223 6.50033 4.16667V6.95001C6.50033 7.02778 6.5141 7.10267 6.54166 7.17467C6.56966 7.24712 6.61699 7.31667 6.68366 7.38334L8.98366 9.68334ZM7.00033 13.3333C6.12255 13.3333 5.29766 13.1667 4.52566 12.8333C3.75321 12.5 3.08366 12.05 2.51699 11.4833C1.95033 10.9167 1.50033 10.2471 1.16699 9.47467C0.833659 8.70267 0.666992 7.87778 0.666992 7C0.666992 6.12223 0.833659 5.29712 1.16699 4.52467C1.50033 3.75267 1.95033 3.08334 2.51699 2.51667C3.08366 1.95001 3.75321 1.50001 4.52566 1.16667C5.29766 0.833338 6.12255 0.666672 7.00033 0.666672C7.8781 0.666672 8.70321 0.833338 9.47566 1.16667C10.2477 1.50001 10.917 1.95001 11.4837 2.51667C12.0503 3.08334 12.5003 3.75267 12.8337 4.52467C13.167 5.29712 13.3337 6.12223 13.3337 7C13.3337 7.87778 13.167 8.70267 12.8337 9.47467C12.5003 10.2471 12.0503 10.9167 11.4837 11.4833C10.917 12.05 10.2477 12.5 9.47566 12.8333C8.70321 13.1667 7.8781 13.3333 7.00033 13.3333ZM7.00033 12.3333C8.4781 12.3333 9.73655 11.814 10.7757 10.7753C11.8143 9.73623 12.3337 8.47778 12.3337 7C12.3337 5.52223 11.8143 4.26378 10.7757 3.22467C9.73655 2.186 8.4781 1.66667 7.00033 1.66667C5.52255 1.66667 4.26433 2.186 3.22566 3.22467C2.18655 4.26378 1.66699 5.52223 1.66699 7C1.66699 8.47778 2.18655 9.73623 3.22566 10.7753C4.26433 11.814 5.52255 12.3333 7.00033 12.3333Z'
        fill={color}
      />
    </svg>
  )
}
