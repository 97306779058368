import { useId } from 'react'

interface Props {
  color?: string
  size?: number
}
export const UnreadChatBubble = ({
  color = '#1C1B1F',
  size = 24
}: Props) => {
  const id = useId()

  return (
   <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g id="mark_chat_unread">
       <mask id={id} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
         <rect id="Bounding box" width="24" height="24" fill="#D9D9D9"/>
       </mask>
       <g mask={`url(#${id})`}>
         <path id="mark_chat_unread_2" d="M19 6.23078C18.2372 6.23078 17.5881 5.96316 17.0529 5.42791C16.5176 4.89266 16.25 4.24363 16.25 3.48081C16.25 2.71799 16.5176 2.06896 17.0529 1.53371C17.5881 0.99846 18.2372 0.730835 19 0.730835C19.7628 0.730835 20.4118 0.99846 20.9471 1.53371C21.4823 2.06896 21.75 2.71799 21.75 3.48081C21.75 4.24363 21.4823 4.89266 20.9471 5.42791C20.4118 5.96316 19.7628 6.23078 19 6.23078ZM6.03845 17.5L4.03655 19.5019C3.75193 19.7865 3.42469 19.8509 3.05483 19.6951C2.68494 19.5394 2.5 19.2589 2.5 18.8538V4.30773C2.5 3.8026 2.675 3.37504 3.025 3.02504C3.375 2.67504 3.80257 2.50004 4.3077 2.50004H14.35C14.2962 2.75004 14.2676 2.99843 14.2644 3.24521C14.2612 3.49201 14.2769 3.74361 14.3115 4.00001C14.4551 5.22694 14.9724 6.23881 15.8635 7.03561C16.7545 7.83239 17.8 8.23078 19 8.23078C19.4371 8.23078 19.8708 8.16829 20.3009 8.04331C20.731 7.91831 21.1307 7.73081 21.5 7.48081V15.6923C21.5 16.1974 21.325 16.625 20.975 16.975C20.625 17.325 20.1974 17.5 19.6923 17.5H6.03845Z"
               fill={color}/>
       </g>
     </g>
   </svg>
 )
}
