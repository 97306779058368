interface Props {
  color?: string
}

export const Delivered = ({
  color = '#4778EE'
}: Props) => {
  return (

    <svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.46706 7.53333C3.38928 7.53333 3.31439 7.51667 3.24239 7.48333C3.16995 7.45 3.10595 7.40556 3.05039 7.35L0.300391 4.6C0.200391 4.5 0.150391 4.38045 0.150391 4.24133C0.150391 4.10267 0.200391 3.98333 0.300391 3.88333C0.38928 3.78333 0.50328 3.73333 0.642391 3.73333C0.781057 3.73333 0.900391 3.78333 1.00039 3.88333L3.48372 6.36667L3.70039 6.13333L4.41706 6.83333L3.88372 7.35C3.82817 7.40556 3.76417 7.45 3.69172 7.48333C3.61972 7.51667 3.54484 7.53333 3.46706 7.53333ZM7.23372 7.51667C7.15595 7.51667 7.08106 7.50267 7.00906 7.47467C6.93661 7.44711 6.87261 7.40556 6.81706 7.35L4.05039 4.58333C3.95039 4.48333 3.90039 4.36378 3.90039 4.22467C3.90039 4.086 3.95039 3.96667 4.05039 3.86667C4.15039 3.77778 4.26706 3.73333 4.40039 3.73333C4.53372 3.73333 4.65039 3.77778 4.75039 3.86667L7.23372 6.35L13.0171 0.566668C13.1171 0.477779 13.2337 0.433334 13.3671 0.433334C13.5004 0.433334 13.6171 0.477779 13.7171 0.566668C13.8171 0.666668 13.8671 0.786001 13.8671 0.924668C13.8671 1.06378 13.8171 1.18333 13.7171 1.28333L7.65039 7.35C7.59484 7.40556 7.53106 7.44711 7.45906 7.47467C7.38661 7.50267 7.3115 7.51667 7.23372 7.51667ZM7.00039 4.23333L6.28372 3.53333L9.23372 0.583334C9.33372 0.483334 9.45306 0.433334 9.59172 0.433334C9.73083 0.433334 9.85039 0.483334 9.95039 0.583334C10.0504 0.683334 10.1004 0.800001 10.1004 0.933334C10.1004 1.06667 10.0504 1.18333 9.95039 1.28333L7.00039 4.23333Z'
        fill={color}
      />
    </svg>

  )
}
