import { observable } from 'mobx'
import { ReactNode } from 'react'

interface ConfirmParams {
  title: string | ReactNode
  content?: ReactNode
  actions: ReactNode

  maxWidth?: number

  onClose?: () => void
}

interface ConfirmationContext {
  open: boolean
  params: ConfirmParams | null
}


const getDefaultValue = () => ({
  open: false,
  params: null
})

export const confirmationContext: ConfirmationContext = observable(getDefaultValue())

export function useConfirmation() {
  const ctx = confirmationContext

  function confirm(params: ConfirmParams) {
    ctx.open = true
    ctx.params = params
  }

  function close() {
    ctx.open = false

    // wait for animation end
    setTimeout(() => {
      if(!ctx.open) {
        ctx.params = null
      }
    }, 250)
  }

  return {
    confirm,
    close
  }
}
