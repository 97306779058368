import { BaseApiClient } from '@/api'
import { identityHttp } from '@/api/transport/http/identity.http'
import { ContactDepartmentsData, GetCompanyContacts } from '@roolz/types/api/companies'
import { GetList, GetSearch, Profile, ContactInfo } from '@roolz/types/api/profiles'

class ContactsClient extends BaseApiClient {
  getContactsBySearch(params: GetSearch) {
    return this.get<ContactInfo[]>('/v1/device/contact/search', { params })
  }

  getList(params: GetList) {
    return this.get<ContactInfo[]>('/v1/device/contact/list', { params })
  }

  getContactDepartmentsData(company_id: string) {
    return this.get<ContactDepartmentsData[]>(`/v1/device/company/${company_id}/contact_departments_data`, {})
  }

  getCompanyContacts(company_id: string, params: GetCompanyContacts) {
    return this.get<ContactInfo[]>(`/v1/device/company/${company_id}/contact/list`, { params })
  }

  addPersonalContact(profile_id: string) {
    return this.post<Profile>(`/v1/profile/personal_contacts/add/${profile_id}`)
  }

  unbanAndAddPersonalContact(profile_id: string) {
    return this.post<Profile>(`/v1/profile/personal_contacts/unban_and_add/${profile_id}`)
  }

  deletePersonalContact(profile_id: string) {
    return this.post<Profile>(`/v1/profile/personal_contacts/delete/${profile_id}`)
  }

  banContact(profile_id: string) {
    return this.post<Profile>(`/v1/profile/personal_contacts/ban/${profile_id}`)
  }

  unbanContact(profile_id: string) {
    return this.post<Profile>(`/v1/profile/personal_contacts/unban/${profile_id}`)
  }
}

export const contactsClient = new ContactsClient(identityHttp, '')
