import { CreateCompanyAppeal } from '@/components/modals/CreateCompanyAppeal/CreateCompanyAppeal'
import {
  CreateOffersAppeal,
  DONT_SHOW_CREATE_OFFERS_APPEAL_STORAGE_KEY
} from '@/components/modals/CreateOffersAppeal/CreateOffersAppeal'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { getNow } from '@/utils/date'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { useEffect, useState } from 'react'

interface Props {

}

enum MODAL_NAMES {
  createCompanyAppeal = 'create-company-appeal',
  createOffersAppeal = 'create-offers-appeal'
}

export const DONT_SHOW_CREATE_COMPANY_APPEAL_STORAGE_KEY = 'create-company-appeal'
export const DONT_SHOW_CREATE_COMPANY_APPEAL_STORAGE_VALUE = '1'
export const JUST_SIGNED_UP_STORAGE_VALUE = '1'
export const getStorageItemNameByProfileId = (id?: string) => id ? `just_signed_up-${id}` : ''

// TODO rename when global-modals would be renamed
export const GlobalRequestModalsContainer = (props: Props) => {
  const [activeModal, setActiveModal] = useState<MODAL_NAMES | null>()
  const [open, setOpen] = useState<boolean>(false)

  // Give modal time to show destroy animation before unmount it
  const closeDelayed = () => {
    setOpen(false)
    setTimeout(() => {
      setActiveModal(null)
    }, 1000)
  }

  useEffect(() => {
    const justSignedUp =
      localStorage.getItem(getStorageItemNameByProfileId(profilesStore.my_profile?.id)) === JUST_SIGNED_UP_STORAGE_VALUE

    if(
      profilesStore.isMyProfileFilled
      && !myCompaniesStore.companies?.length
      && dayjs(profilesStore.my_profile?.created_at).diff(getNow(), 'minutes') < 20
      && localStorage.getItem(DONT_SHOW_CREATE_COMPANY_APPEAL_STORAGE_KEY) !== DONT_SHOW_CREATE_COMPANY_APPEAL_STORAGE_VALUE
      && !justSignedUp
    ) {
      setActiveModal(MODAL_NAMES.createCompanyAppeal)
      setOpen(true)
    }

    // if(profilesStore.isMyProfileFilled && myCompaniesStore.companies?.length && !localStorage.getItem(DONT_SHOW_CREATE_OFFERS_APPEAL_STORAGE_KEY)) {
    //   setActiveModal(MODAL_NAMES.createOffersAppeal)
    //   setOpen(true)
    // }
  }, [profilesStore.my_profile, profilesStore.isMyProfileFilled, myCompaniesStore.companies])

  if(!open) return null

  switch(activeModal) {
    case MODAL_NAMES.createCompanyAppeal: {
      const handleToggleOpen = (open: boolean): void => {
        if(!open) {
          localStorage.setItem(DONT_SHOW_CREATE_COMPANY_APPEAL_STORAGE_KEY, DONT_SHOW_CREATE_COMPANY_APPEAL_STORAGE_VALUE)
          setOpen(false)
          return
        }
        setOpen(true)
      }

      return (
        <CreateCompanyAppeal
          open={open}
          setOpen={handleToggleOpen}
        />
      )
    }
    case MODAL_NAMES.createOffersAppeal: {
      return <CreateOffersAppeal open={open} onClose={() => setOpen(false)}/>
    }
    default:
      return null
  }
}
