import CompanyLayout from '@/components/layouts/CompanyLayout/CompanyLayout'
import { PageLoading } from '@/components/layouts/PageLoading/PageLoading'
import { Suspense } from 'react'
import { Outlet } from 'react-router'

export function CompanyPage() {
  return (
    <Suspense fallback={<PageLoading/>}>
      <CompanyLayout>
        <Suspense fallback={<PageLoading/>}>
          <Outlet/>
        </Suspense>
      </CompanyLayout>
    </Suspense>
  )
}
