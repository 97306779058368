function filepathToNamespace(filepath) {
  return filepath
    .replaceAll(/\.\//g, '') // remove ./
    .replace(/\..*$/, '') // remove extension .json
    // .replaceAll(/\/(\w)/g, '.$1')
}

function requireAll(r) {
  return Object.fromEntries(
    r.keys().map(filename => ([
      filepathToNamespace(filename),
      r(filename)
    ]))
  )
}

export default {
  ...requireAll(require.context('./', true, /\.json$/))
}
