import data from '@roolz/icons/lottie/data/rocket.json'
import Lottie, { LottieComponentProps } from 'lottie-react'
import { useLayoutEffect, useRef } from 'react'

type Props = Omit<LottieComponentProps, 'animationData'>

export const Rocket = (props: Props) => {
  const ref = useRef<any>()

  useLayoutEffect(() => {
    if(ref.current) {
      ref.current.setSpeed(0.6)
    }
  }, [ref.current])

  return (
    <Lottie
      lottieRef={ref}
      animationData={data}
      {...props}
    />
  )
}
