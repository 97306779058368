import React from 'react'
import { ToastContainer, cssTransition, Zoom } from 'react-toastify'
import styles from './snackbars.module.scss'
import cn from 'classnames'

// const Zoom = cssTransition({
//   // zoomIn will become zoomIn--top-right or zoomIn--top-left and so on
//   enter: 'alert',
//   // zoomIn will become zoomOut--top-right or zoomOut--top-left and so on
//   exit: 'alert',
//   // default to false
//   // appendPosition: true
//   collapseDuration: 600
// });

export const SnackbarsContainer = () => {
  const transition = cssTransition({
    enter: cn(styles.animate__animated, styles.animate__fadeIn),
    exit:  cn(styles.animate__animated, styles.animate__fadeOut),
  })

  return (
    <ToastContainer
      newestOnTop={true}
      className={styles.container}
      autoClose={4000}
      position='bottom-right'
      closeButton={false}
      draggable={false}
      pauseOnFocusLoss={false}
      transition={transition}

      hideProgressBar={true}
      limit={5}
    />
  )
}
