import { contactsService } from '@/store/profiles/contacts.service'
import { ChatModel } from '@/types/models/chat'
import { NoAccounts } from '@roolz/icons/NoAccounts'
import { PersonAdd } from '@roolz/icons/PersonAdd'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './DialogCompanionActionsPanel.module.scss'
import { ChatType } from '@roolz/types/api/chats'
import { toastError } from '@roolz/sdk/components/snackbars'
import { Loadable } from '@/components/ui/Loadable/Loadable'

interface Props {
  chat: ChatModel
}

export const DialogCompanionActionsPanel = observer(function DialogCompanionActionsPanel({
  chat
}: Props) {
  const { t } = useTranslation('chat/common')
  const { t: errorsT } = useTranslation('errors')

  const [isBanning, setIsBanning] = useState<boolean>(false)
  const [isAddingToContacts, setIsAddingToContacts] = useState<boolean>(false)

  if(chat.type !== ChatType.DIALOG
    || chat.company_id
    || !chat.companion
    || chat.companion?.relationships?.is_banned === true
    || chat.companion?.relationships?.is_personal_contact === true
  ) {
    return null
  }

  function handleBan() {
    if(!chat.companion?.id) return

    setIsBanning(true)
    return contactsService.banProfile(chat.companion?.id)
      .catch(({ response }) => {
        toastError(response?.data?.detail ?? errorsT('insufficient_request'))
      })
      .finally(() => {
        setIsBanning(false)
      })
  }

  function handleAddToContacts() {
    if(!chat.companion?.id) return

    setIsAddingToContacts(true)
    contactsService.addContact(chat.companion.id)
      .catch(({ response }) => {
        toastError(response?.data?.detail ?? errorsT('insufficient_request'))
      })
      .finally(() => {
        setIsAddingToContacts(false)
      })
  }

  return (
    <div className={styles.root}>

      <button
        className={cn(styles.button, styles.button__danger)}
        onClick={handleBan}
      >
        {!isBanning ? (<>
          <NoAccounts
            size={20}
            color='#E23834'
            className={styles.button__icon}
          />

          {t('chat_dialog_actions.ban')}
        </>) : (
          <Loadable loading={isBanning}/>
        )}
      </button>

      <button
        className={cn(styles.button, styles.button__default)}
        onClick={handleAddToContacts}
      >
        {!isAddingToContacts ? (<>
          <PersonAdd
            color='#4778EE'
            className={styles.button__icon}
            size={20}
          />
          {t('chat_dialog_actions.add_to_contacts')}
        </>) : (
          <Loadable loading={isAddingToContacts}/>
        )}
      </button>
    </div>
  )
})
