import { forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'src/components/chats/shared/message/FloatingMessagesDate/FloatingMessagesDate.module.scss'
import dayjs from '@roolz/sdk/plugins/dayjs'
import cn from 'classnames'

type DateString = string

interface Props {
  date: DateString
  isScrolling: boolean

  [key: string]: any
}

const monthNumberToName = {
  0: "january",
  1: "february",
  2: "march",
  3: "april",
  4: "may",
  5: "june",
  6: "july",
  7: "august",
  8: "september",
  9: "october",
  10: "november",
  11: "december",
}

export const FloatingMessagesDate = forwardRef(({
  date,
  isScrolling,
  ...rest
}: Props, ref: any) => {
  const elRef = useRef<any>()

  const { t } = useTranslation('chat/common')
  const [isSticky, setIsSticky] = useState(false)

  useLayoutEffect(() => {
    if(elRef.current) {
      setIsSticky(elRef.current.offsetTop > elRef.current.offsetHeight)
    }
  }, [elRef, isScrolling])

  const formattedDate = useMemo(() => {
    const dateParsed = dayjs(date)
    if(dateParsed.isToday()) {
      return t('ui:datetime.today')
    }
    if(dateParsed.isYesterday()) {
      return t('ui:datetime.yesterday')
    }

    // @ts-ignore
    let result = t('date.months.' + monthNumberToName[dateParsed.month()], {
      day: dateParsed.date()
    })

    if(!dateParsed.isSame(dayjs(), 'year')) {
      result += ', ' + dateParsed.format('YYYY')
    }

    return result
  }, [date])


  return (
    <div
      className={cn(styles.root, {
        [styles.rootInvisible]: isSticky && !isScrolling
      })}
      ref={elRef}
      {...rest}
    >
      <div className={styles.item}>
        <div className={styles.text}>
          {formattedDate}
        </div>
      </div>
    </div>
  )
})
