import { db, DEXIE_STORES } from '@/database'
import { profilesService } from '@/store/profiles/profiles.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { ProfileModel } from '@/types/models/profile'
import { Profile } from '@roolz/types/api/profiles'

export async function addOrUpdateProfiles(profiles: Profile[]) {
  profiles.forEach(profile => {
    profilesStore.addOrUpdateProfile(profile)
    db[DEXIE_STORES.PROFILES].put(profile)
  })
}

export async function patchProfile(id: Profile["id"], data: Partial<Profile>) {
  profilesStore.patchProfile(id, data)
  return db[DEXIE_STORES.PROFILES].update(id, data)
}

export async function getProfileById(id: Profile["id"]): Promise<ProfileModel | undefined> {
  // check if already exist in memory
  if(!profilesStore.findProfile(id)) {
    try {
      const profile = await db[DEXIE_STORES.PROFILES].get(id)

      if(profile) {
        profilesStore.addOrUpdateProfile(profile)
      }
    } catch(e) {
      console.log(e)
      await profilesService.loadProfile(id)
    }
  }

  return profilesStore.findProfile(id)
}
