import { db, DEXIE_STORES } from '@/database'
import * as ownPcpQueries from '@/database/queries/own_pcps.queries'
import { chatsStore } from '@/store/chats/chats.store'
import { OwnPcp, PcpStatus } from '@roolz/types/api/chats'

export async function addOrUpdateOwnPcps(ownPcps: OwnPcp[]) {
  chatsStore.addOrUpdateOwnPcps(ownPcps)
  await ownPcpQueries.addOrUpdateOwnPcps(ownPcps)
}

export function updateOwnPcp(chatId: OwnPcp["chat_id"], data: Partial<OwnPcp>) {
  chatsStore.addOrUpdateChatOwnPcp(chatId, data)
  db[DEXIE_STORES.OWN_PCPS].update(chatId, data)
}

export async function getOwnPcp(chatId: OwnPcp["chat_id"]): Promise<OwnPcp |undefined> {
  let ownPcp = chatsStore.getOwnPcp(chatId)

  if(!ownPcp) {
    ownPcp = await db[DEXIE_STORES.OWN_PCPS].get(chatId)
  }

  return ownPcp
}
