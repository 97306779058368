import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import getBoundingClientRect from '@popperjs/core/lib/dom-utils/getBoundingClientRect'
import { useEffect, useLayoutEffect, useState } from 'react'

interface Props {
  element: any
}

export function useElementScrollPosition({
  element,

}: Props) {
  const [isInEnd, setIsInEnd] = useState<boolean>(checkIsInEnd())

  function getDistanceToEnd() {
    if(element.current) {
      const el = element.current

      return el.scrollHeight - el.scrollTop - el.clientHeight
    }
    return 0
  }

  function checkIsInEnd() {
    return getDistanceToEnd() < 1
  }

  function handleScroll() {
    setIsInEnd(checkIsInEnd())
  }

  useLayoutEffect(() => {
    setIsInEnd(checkIsInEnd())
  })

  useEffect(() => {
    if(element.current) {
      element.current.addEventListener('scroll', handleScroll, { passive: true })
    }

    return () => {
      if(element.current) {
        element.current.removeEventListener('scroll', handleScroll, { passive: true })
      }
    }
  }, [element])

  return {
    isInEnd,
    getDistanceToEnd
  }
}
