import { Company, Department, Role } from './companies'
import { Country } from '../custom'
import { OS } from './index'
import { DateString, ID, Url } from '../scalars'

export const SUCCESS_RESPONSE = 'OK'

export enum AuthenticableSocialNetwork {
  Facebook = 'facebook',
  Google = 'google',
  Apple = 'apple'
}

export interface Device {
  "id": string
  "is_push_on": boolean,
  "is_chats_wt_on": boolean
  "is_gps_on": boolean
  "system_version": string
  "is_ios_att": boolean
  "vendor_id": string
  "advertiser_id": string
  "android_id": string
  "open_anonymous_device_id": string
  "imei": string
  "appsflyer_id": string
  "installation_id": string
  "phone_model": string
  "app_version": string
  "os_type": 'windows' | 'android' | 'ios' | 'macos' | 'unix'
}

export interface authToken {
  grant_type: 'refresh_token' | 'access_token',
  username?: string
  password?: string
  scope?: string
  client_id?: string
  client_secret?: string
  refresh_token?: string
}

// TODO no signature in swagger
export interface testToken {
  token: string
}

export interface checkTokenExpiration {
  token: string
}

export interface loginByEmail {
  email: string,
  password: string,
  device: {
    installation_id: string,
    phone_model: string,
    system_version: string,
    app_version: string,
    os_type: OS
  }
}

export interface loginByMagicLinkToken {
  device: {
    installation_id: string,
    phone_model: string,
    system_version: string,
    app_version: string,
    os_type: OS
  },
  invite_id?: string,
  device_fingerprint?: string,
  token: string
}

export interface loginBySms {
  code: string,
  sms_id: string,
  device: {
    installation_id: string,
    phone_model: string,
    system_version: string,
    app_version: string,
    os_type: OS
  },
  fcm_token?: string,
  active_company_id?: string,
  invite_id?: string,
  device_fingerprint?: string
}

export interface getResetPasswordLink {
  email: string
}

export interface getMagicLink {
  email: string
}

export interface loginBySocialNetwork {
  social_network: AuthenticableSocialNetwork,
  user_token: string,
  device: {
    installation_id: string,
    phone_model: string,
    system_version: string,
    app_version: string,
    os_type: OS
  }
  invite_id?: string,
  device_fingerprint?: string,
  email?: string,
}

export interface loginByMagicLink {
  device: {
    installation_id: string,
    phone_model: string,
    system_version: string,
    app_version: string,
    os_type: OS
  }
  invite_id?: string,
  device_fingerprint?: string,
  token: string,
}

export interface checkIfEmailBusyRequest {
  email: string
}

export interface checkIfEmailBusyResponse {
  is_busy: boolean
}

export interface registerProfileRequest {
  email: string
  password: string
  device: {
    installation_id: string,
    phone_model: string,
    system_version: string,
    app_version: string,
    os_type: OS
  }
  invite_id?: string,
  device_fingerprint?: string,
}

export interface Avatar {

}

export interface chatByNickname {
  nickname: string
}

export interface SendSms {
  phone: string
  imei: string
  hash: string
}

export interface GetSearch {
  query: string
  exclude_banned?: boolean
  kinds?: string
  is_registered_contact?: boolean
  offset?: number
  limit?: number
}

export interface GetList {
  kinds?: string
  is_ban?: boolean
  is_registered_contact?: boolean
  enable_duplication_rule?: boolean
  ordering?: string
  offset?: number
  limit?: number
}

export interface SendSmsToChangePhoneRequest {
  phone: string
  imei: string
  hash: string
}

type CompanyInvitation = {
  id: string
  company_id: string
  created_at: string
  created_by: string
  email: string
  invited_user_id: string
  is_active: boolean
  role: string
  status: string
  updated_at: string
  magic_hash: string

  company: {
    id: string
    name: string
    color: string
    logo_path: string
  }
}

type ProfileRegMethod = 'EMAIL' | 'PHONE' | 'CHANNEL'

export type Profile = {
  id: ID
  avatar?: Url
  email?: string | null
  phone?: string | null
  first_name?: string | null
  last_name?: string | null
  middle_name?: string | null
  nickname?: string | null
  country?: Country | null
  about?: string | null
  skype?: string | null
  site?: Url | null
  is_active?: boolean
  is_filled?: boolean
  color?: string | null
  deletion_at?: DateString | null
  lang_code?: string
  active_space_company_id?: Company['id'] | null
  personal_companies?: string | null
  // readonly reg_method?: ProfileRegMethod
  readonly personal_key: string
  readonly is_system_user: boolean

  relationships?: {
    is_personal_contact: boolean
    is_banned: boolean
    is_banned_me: boolean
  }

  first_email?: string | null
  second_email?: string | null
  first_phone?: string | null
  second_phone?: string | null
  is_superuser?: boolean
  is_set_password?: boolean

  last_action_time?: string
  companies?: UserCompany[]
  company_invitations?: CompanyInvitation[]

  created_at?: string
}

export type updateMyProfileRequest = Partial<Omit<Profile, 'id'>>

export interface deleteOwnProfileRequest {
  deletion_reason?: string
}

export interface checkNicknameBusynessRequest {
  nickname: string
}

export interface checkNicknameBusynessResponse {
  is_busy: boolean
}

export interface checkOwnPasswordRequest {
  password: string
}

export interface checkOwnPasswordResponse {
  password_is_correct: boolean
}

export interface createVerifiedPasswordTokenRequest {
  password: string
}

export interface createVerifiedPasswordTokenResponse {
  token: string
}

export interface changePasswordRequest {
  token?: string
  new_password: string
}

export type changePasswordResponse = Profile

export interface deleteOwnEmailRequest {
  token: string
}

export interface deleteOwnPasswordRequest {
  token: string
}

export type deleteOwnEmailResponse = Profile

export type updateOwnEmailRequest = {
  email: Profile['email']
  token: string
}
export type updateOwnEmailResponse = Profile

export type updateOwnPhoneRequest = {
  code: string
  sms_id: string
}
export type updateOwnPhoneResponse = Profile
export type deleteOwnPhoneResponse = Profile
export type deleteOwnPasswordResponse = Profile

export type UserCompany = Pick<Company,
  'id' |
  'name' |
  'my_role' |
  'color' |
  'logo_path' |
  'is_profile_public'
> & {
  user_departments: Department[]
  user_roles: Role[]
  is_verified?: boolean
  is_active?: boolean
}

export enum ContactKind {
  phone = 'phone',
  personal = 'personal',
  company = 'company',
  global = 'global'
}

export interface ContactInfo {
  profile: Profile
  phone?: string
  kind?: ContactKind
  is_ban: boolean
  company_id?: string
}

export interface ClientConfigResponse {
  rabbit_socket: {
    host: string
    port: number
    virtual_host: string
  },
  rabbit_queue: string
  server_time: number
  upload_url: string
  static_url: string
  fcm_token: string
}

export interface untieSocialNetworkRequest {
  social_network: AuthenticableSocialNetwork
}

export interface TiedSocialNetworks {
  google_id: string | null
  facebook_id: string | null
  apple_id: string | null
}

export interface LoadProfilesByIdsRequest {
  profile_ids: Profile["id"][]
  offset?: number
  limit?: number
}
