import { AppIsUnderConstruction } from '@/components/errors/AppIsUnderConstruction/AppIsUnderConstruction'
import React, { ErrorInfo } from 'react'
import * as Sentry from "@sentry/react"

const DefaultErrorBoundary = ({ children }: any) => (
  <Sentry.ErrorBoundary fallback={<AppIsUnderConstruction/>}>
    {children}
  </Sentry.ErrorBoundary>
)

export {
  DefaultErrorBoundary
}

// interface State {
//   errorInfo: ErrorInfo | null
//   error: Error | null
// }
//
// export class DefaultErrorBoundary extends React.Component<any, State> {
//   constructor(props: any) {
//     super(props)
//     this.state = {
//       error: null,
//       errorInfo: null
//     }
//   }
//
//   componentDidCatch(error: Error, errorInfo: ErrorInfo) {
//     console.log('CATCHED', error, errorInfo)
//     this.setState({
//       error: error,
//       errorInfo: errorInfo
//     })
//   }
//
//   // This will render this component wherever called
//   render() {
//     if(this.state.errorInfo) {
//       return (
//         <AppIsUnderConstruction/>
//       )
//     }
//
//     return this.props.children
//   }
// }
