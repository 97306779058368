import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'src/components/chats/shared/chat/SelfChatPlaceholder/SelfChatPlaceholder.module.scss'
import { SelfChatPlaceholder as Icon } from '@roolz/icons/chats/SelfChatPlaceholder'
import { Attachment } from '@roolz/icons/chats/Attachment'
import { Forward } from '@roolz/icons/chats/Forward'
import { Clarify } from '@roolz/icons/Clarify'
import { Profile } from '@roolz/types/api/profiles'

interface Props {
  profile: Profile
}
export const SelfChatPlaceholder = ({
  profile
}: Props) => {
  const { t } = useTranslation('chat/message')

  const items: Array<{
    icon: ReactNode
    text: string
  }> = [
    {
      icon: <Clarify size={22}/>,
      text: t('self_chat_placeholder.save_ideas')
    },
    {
      icon: <Forward/>,
      text: t('self_chat_placeholder.forward')
    },
    // {
    //   icon: <Attachment/>,
    //   text: t('self_chat_placeholder.save_files')
    // },
  ]

 return (
  <div className={styles.root}>
    <div className={styles.content}>
      <Icon/>

      <h2 className={styles.title}>
        {t('self_chat_placeholder.title', {
          name: profile.first_name + ' ' + profile.last_name
        })}
      </h2>

      <div className={styles.list}>
        {items.map(({ icon, text }) => (
          <p
            key={text}
            className={styles.list__item}
          >
            <span className={styles.list__item__icon}>
              {icon}
            </span>
            <span className={styles.list__item__text}>
              {text}
            </span>
          </p>
        ))}
      </div>
    </div>
  </div>
 )
}
