import { countriesClient } from '@/api'
import { knowledgeClient } from '@/api/clients/knowledge.client'
import { companyKnowledgeStore } from '@/store/knowledge/stores/company-knowledge.store'
import { exchangeKnowledgeStore } from '@/store/knowledge/stores/exchange-knowledge.store'
import { knowledgeStore } from '@/store/knowledge/stores/knowledge.store'
import { UserCountry } from '@roolz/types/custom'
import { runInAction } from 'mobx'

export class KnowledgeService {
  async detectUserCountry(force = false) {
    if(force || knowledgeStore.userCountry === undefined) {
      return countriesClient.detectMine()
        .then(({ data }: { data: UserCountry }) => {
          knowledgeStore.setUserCountry(data)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  async loadChecksums() {
    return knowledgeClient.getAllChecksums()
      .then(({ data }) => {
        knowledgeStore.checksums = data

        return data
      })
  }

  async loadRequiredDictionariesList() {
    const dictPathSetterMap = {
      '/company/associations': (data: any) => companyKnowledgeStore.allCompanyAssociations = data,
      '/company/cargo_type': (data: any) => companyKnowledgeStore.allCompanyCargoTypes = data,
      '/company/company_form': (data: any) => companyKnowledgeStore.allCompanyForms = data,
      '/company/customs_service': (data: any) => companyKnowledgeStore.allCustomsServices = data,
      '/company/employee_amount': (data: any) => companyKnowledgeStore.allCompanyEmployeeAmounts = data,
      '/company/exchange_role': (data: any) => companyKnowledgeStore.allExchangeRoles = data,
      '/company/fleet_amount': (data: any) => companyKnowledgeStore.allFleetAmounts = data,
      '/company/goal': (data: any) => companyKnowledgeStore.allCompanyGoals = data,
      '/company/logistics_service': (data: any) => companyKnowledgeStore.allLogisticsServices = data,
      '/company/order_amount': (data: any) => companyKnowledgeStore.allOrderAmounts = data,
      '/company/transportation_type': (data: any) => companyKnowledgeStore.allTransportationTypes = data,

      '/exchange/adr_classes': (data: any) => exchangeKnowledgeStore.allAdrClasses = data,
      '/exchange/cargo_types': (data: any) => exchangeKnowledgeStore.allCargoTypes = data,
      '/exchange/extra_equipment': (data: any) => exchangeKnowledgeStore.allExtraEquipment = data,
      '/exchange/loading_types': (data: any) => exchangeKnowledgeStore.allLoadingTypes = data,
      '/exchange/payment_cond': (data: any) => exchangeKnowledgeStore.allPaymentConditions = data,
      '/exchange/payment_methods': (data: any) => exchangeKnowledgeStore.allPaymentMethods = data,
      '/exchange/point_types': (data: any) => exchangeKnowledgeStore.allPointTypes = data,
      '/exchange/publ_autocancel_reasons': (data: any) => exchangeKnowledgeStore.allPublicationAutocancelReasons = data,
      '/exchange/shipping_modes': (data: any) => exchangeKnowledgeStore.allShippingModes = data,
      '/exchange/transport_types': (data: any) => exchangeKnowledgeStore.allTransportTypes = data,
      '/exchange/truck_bodies': (data: any) => exchangeKnowledgeStore.allTruckBodies = data,
      '/global/curr': (data: any) => exchangeKnowledgeStore.allCurrencies = data,

      '/global/countries': (data: any) => knowledgeStore.countries = data
    }

    const { data } = await knowledgeClient.getDictionariesList(Object.keys(dictPathSetterMap))

    const isDev = () => window.location.origin === 'https://business-roolz.dev.roolz.fg13.net'
      || window.location.origin === 'https://business-roolz.stage.roolz.fg13.net'

    if(isDev()) {
      let string = ''

      Object.entries(dictPathSetterMap).forEach(([key, setter]) => {
        if(!data?.[key]?.length) {
          string += `<br/>
              по ключу: ${key} <br/>
              не пришли данные: ${JSON.stringify(data?.[key])}<br/><br/>`

          string += `полный ответ с бекенда: <br/>${JSON.stringify(data)}`

          document.write(string)
          return
        }
      })
    }

    Object.entries(dictPathSetterMap).forEach(([key, setter]) => {
      if(data?.[key]) {
        setter(data?.[key])
      }
    })

  }

  async loadRequiredDictionaries(retryTillLoadAll = true) {
    let hasNotLoaded = false

    const { data: checksums } = await knowledgeClient.getAllChecksums()

    const dictPathSetterMap = {
      '/knowledge/company/associations.json': (data: any) => companyKnowledgeStore.allCompanyAssociations = data,
      '/knowledge/company/cargo_type.json': (data: any) => companyKnowledgeStore.allCompanyCargoTypes = data,
      '/knowledge/company/company_form.json': (data: any) => companyKnowledgeStore.allCompanyForms = data,
      '/knowledge/company/customs_service.json': (data: any) => companyKnowledgeStore.allCustomsServices = data,
      '/knowledge/company/employee_amount.json': (data: any) => companyKnowledgeStore.allCompanyEmployeeAmounts = data,
      '/knowledge/company/exchange_role.json': (data: any) => companyKnowledgeStore.allExchangeRoles = data,
      '/knowledge/company/fleet_amount.json': (data: any) => companyKnowledgeStore.allFleetAmounts = data,
      '/knowledge/company/goal.json': (data: any) => companyKnowledgeStore.allCompanyGoals = data,
      '/knowledge/company/logistics_service.json': (data: any) => companyKnowledgeStore.allLogisticsServices = data,
      '/knowledge/company/order_amount.json': (data: any) => companyKnowledgeStore.allOrderAmounts = data,
      '/knowledge/company/transportation_type.json': (data: any) => companyKnowledgeStore.allTransportationTypes = data,

      '/knowledge/exchange/adr_classes.json': (data: any) => exchangeKnowledgeStore.allAdrClasses = data,
      '/knowledge/exchange/cargo_types.json': (data: any) => exchangeKnowledgeStore.allCargoTypes = data,
      '/knowledge/exchange/extra_equipment.json': (data: any) => exchangeKnowledgeStore.allExtraEquipment = data,
      '/knowledge/exchange/loading_types.json': (data: any) => exchangeKnowledgeStore.allLoadingTypes = data,
      '/knowledge/exchange/payment_cond.json': (data: any) => exchangeKnowledgeStore.allPaymentConditions = data,
      '/knowledge/exchange/payment_methods.json': (data: any) => exchangeKnowledgeStore.allPaymentMethods = data,
      '/knowledge/exchange/point_types.json': (data: any) => exchangeKnowledgeStore.allPointTypes = data,
      '/knowledge/exchange/publ_autocancel_reasons.json': (data: any) => exchangeKnowledgeStore.allPublicationAutocancelReasons = data,
      '/knowledge/exchange/shipping_modes.json': (data: any) => exchangeKnowledgeStore.allShippingModes = data,
      '/knowledge/exchange/transport_types.json': (data: any) => exchangeKnowledgeStore.allTransportTypes = data,
      '/knowledge/exchange/truck_bodies.json': (data: any) => exchangeKnowledgeStore.allTruckBodies = data,
      '/knowledge/global/curr.json': (data: any) => exchangeKnowledgeStore.allCurrencies = data,

      '/knowledge/global/countries.json': (data: any) => knowledgeStore.countries = data
    }

    const requests = []

    for(const [key, setter] of Object.entries(dictPathSetterMap)) {
      if(checksums?.[key] !== knowledgeStore.checksums?.[key]) {
        const path = key.replace(/^\/knowledge/, '').replace(/\.json$/, '')
        const request = knowledgeClient.getDictionaryByPath(path)
          .then(({ data }) => setter(data))
          .catch(() => {
            hasNotLoaded = true
            checksums[key] = ''
          })

        requests.push(request)
      }
    }

    return Promise.all(requests)
      .then(() => {
        if(retryTillLoadAll && hasNotLoaded) {
          setTimeout(
            () => this.loadRequiredDictionaries(retryTillLoadAll)
            , 5000
          )
        }

        knowledgeStore.checksums = checksums
      })
  }

  cleanAllDictionaries() {
    runInAction(() => {
      knowledgeStore.reset()
      exchangeKnowledgeStore.reset()
      companyKnowledgeStore.reset()
    })
  }
}

const knowledgeService = new KnowledgeService()

export {
  knowledgeService
}
