// import * as Cookies from '@roolz/sdk/utils/cookie'
import { GlobalModalsManager } from '@/global-modals/GlobalModalsManager'
import { persistStore } from '@/utils/mobx'
import { makeAutoObservable } from 'mobx'

class UiStore {
  isSidebarCollapsed = false
  isSidebarMobileOpen = false

  closeAllGlobalModals?: () => void

  constructor() {
    makeAutoObservable(this)
  }

  toggleSidebarMobileOpen(): void {
    this.isSidebarMobileOpen = !this.isSidebarMobileOpen
  }

  toggleSidebarCollapse() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed
  }
}

const uiStore = persistStore(new UiStore(), 'ui')

export {
  UiStore,
  uiStore
}
