import * as React from 'react'

interface Props {
  size?: number
  color?: string
}

export const Copy = ({
  size,
  color = '#4778EE'
}: Props) => {
  return (
    <svg width={size} height={size * 1.15} viewBox='0 0 19 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.7 17.5H6.3C5.8 17.5 5.375 17.325 5.025 16.975C4.675 16.625 4.5 16.2 4.5 15.7V2.3C4.5 1.8 4.675 1.375 5.025 1.025C5.375 0.675 5.8 0.5 6.3 0.5H13.25L18.5 5.75V15.7C18.5 16.2 18.325 16.625 17.975 16.975C17.625 17.325 17.2 17.5 16.7 17.5ZM12.5 6.5V2H6.3C6.23333 2 6.16667 2.03333 6.1 2.1C6.03333 2.16667 6 2.23333 6 2.3V15.7C6 15.7667 6.03333 15.8333 6.1 15.9C6.16667 15.9667 6.23333 16 6.3 16H16.7C16.7667 16 16.8333 15.9667 16.9 15.9C16.9667 15.8333 17 15.7667 17 15.7V6.5H12.5ZM2.3 21.5C1.8 21.5 1.375 21.325 1.025 20.975C0.675 20.625 0.5 20.2 0.5 19.7V6.5H2V19.7C2 19.7667 2.03333 19.8333 2.1 19.9C2.16667 19.9667 2.23333 20 2.3 20H12.5V21.5H2.3Z'
        fill={color}
      />
    </svg>
  )
}
