interface Props {
 color?: string
}
export const SendingError = ({
  color = '#E23834'
}: Props) => {
 return (
   <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path
       d="M6.99935 10.15C7.1549 10.15 7.28268 10.1 7.38268 10C7.48268 9.9 7.53268 9.77223 7.53268 9.61667C7.53268 9.46112 7.48268 9.33334 7.38268 9.23334C7.28268 9.13334 7.1549 9.08334 6.99935 9.08334C6.84379 9.08334 6.71602 9.13334 6.61602 9.23334C6.51602 9.33334 6.46602 9.46112 6.46602 9.61667C6.46602 9.77223 6.51602 9.9 6.61602 10C6.71602 10.1 6.84379 10.15 6.99935 10.15ZM6.99935 13.3333C6.12157 13.3333 5.29668 13.1667 4.52468 12.8333C3.75224 12.5 3.08268 12.05 2.51602 11.4833C1.94935 10.9167 1.49935 10.2471 1.16602 9.47467C0.832682 8.70267 0.666016 7.87778 0.666016 7C0.666016 6.12223 0.832682 5.29712 1.16602 4.52467C1.49935 3.75267 1.94935 3.08334 2.51602 2.51667C3.08268 1.95001 3.75224 1.50001 4.52468 1.16667C5.29668 0.833338 6.12157 0.666672 6.99935 0.666672C7.87713 0.666672 8.70224 0.833338 9.47468 1.16667C10.2467 1.50001 10.916 1.95001 11.4827 2.51667C12.0493 3.08334 12.4993 3.75267 12.8327 4.52467C13.166 5.29712 13.3327 6.12223 13.3327 7C13.3327 7.87778 13.166 8.70267 12.8327 9.47467C12.4993 10.2471 12.0493 10.9167 11.4827 11.4833C10.916 12.05 10.2467 12.5 9.47468 12.8333C8.70224 13.1667 7.87713 13.3333 6.99935 13.3333ZM6.99935 7.71667C7.14379 7.71667 7.26335 7.66934 7.35802 7.57467C7.45224 7.48045 7.49935 7.36112 7.49935 7.21667V4.21667C7.49935 4.07223 7.45224 3.95267 7.35802 3.85801C7.26335 3.76378 7.14379 3.71667 6.99935 3.71667C6.8549 3.71667 6.73557 3.76378 6.64135 3.85801C6.54668 3.95267 6.49935 4.07223 6.49935 4.21667V7.21667C6.49935 7.36112 6.54668 7.48045 6.64135 7.57467C6.73557 7.66934 6.8549 7.71667 6.99935 7.71667Z"
       fill={color}
     />
   </svg>
 )
}
