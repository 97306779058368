import { Pcp } from '@roolz/types/api/chats'
import { db } from '@/database'
import { groupBy, merge } from 'lodash'

export async function addOrUpdatePcps(pcps: Array<Pcp>) {
  const byChatIds = groupBy(pcps, 'chat_id')

  return db.transaction('rw', db.pcps, async () => {
    const existing = await db.pcps.bulkGet(Object.keys(byChatIds))

    existing.forEach(existing => {
      if(existing) merge(existing, byChatIds[existing.chat_id][0])
    })

    await db.pcps.bulkPut(Object.values(byChatIds).map(c => c[0]))
  })
}

