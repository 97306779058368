interface Props {

}
export const SelfChatPlaceholder = (props: Props) => {
 return (
   <svg width="104" height="96" viewBox="0 0 104 96" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M82.4987 47.0545L55.7441 0.799999L35.3323 36.0948H0V95.7H52.4414V51.8488H68.8312C63.9421 55.7989 60.8123 61.838 60.8123 68.6073C60.8123 80.5129 70.4795 90.1631 82.4061 90.1631C94.3328 90.1631 104 80.5129 104 68.6073C104 56.7016 94.3821 47.1038 82.4987 47.0545Z" fill="url(#paint0_linear_14489_143390)"/>
     <path d="M97.0647 30.9123H48.7779V25.9608H97.0647V30.9123ZM98.2993 23.4959H47.5432C46.8611 23.4959 46.3086 24.0474 46.3086 24.7284V32.1447C46.3086 32.8257 46.8611 33.3772 47.5432 33.3772H98.2993C98.9815 33.3772 99.534 32.8257 99.534 32.1447V24.7284C99.534 24.0474 98.9815 23.4959 98.2993 23.4959Z" fill="url(#paint1_linear_14489_143390)"/>
     <path d="M48.7779 38.8124H79.9803V43.7607H48.7779V38.8124ZM47.5432 46.2257H81.215C81.8971 46.2257 82.4496 45.6741 82.4496 44.9932V37.5799C82.4496 36.899 81.8971 36.3474 81.215 36.3474H47.5432C46.8611 36.3474 46.3086 36.899 46.3086 37.5799V44.9932C46.3086 45.6741 46.8611 46.2257 47.5432 46.2257Z" fill="url(#paint2_linear_14489_143390)"/>
     <path d="M97.0647 73.1736H48.7779V68.2252H97.0647V73.1736ZM98.2993 65.7603H47.5432C46.8611 65.7603 46.3086 66.3118 46.3086 66.9928V74.406C46.3086 75.087 46.8611 75.6385 47.5432 75.6385H98.2993C98.9815 75.6385 99.534 75.087 99.534 74.406V66.9928C99.534 66.3118 98.9815 65.7603 98.2993 65.7603Z" fill="url(#paint3_linear_14489_143390)"/>
     <path d="M79.9803 86.0251H48.7779V81.0768H79.9803V86.0251ZM81.215 78.6118H47.5432C46.8611 78.6118 46.3086 79.1634 46.3086 79.8443V87.2576C46.3086 87.9385 46.8611 88.4901 47.5432 88.4901H81.215C81.8971 88.4901 82.4496 87.9385 82.4496 87.2576V79.8443C82.4496 79.1634 81.8971 78.6118 81.215 78.6118Z" fill="url(#paint4_linear_14489_143390)"/>
     <path d="M32.2789 24.79L18.9047 39.1698L7.44103 26.1395L12.2283 21.943L14.0896 24.0567L18.1299 28.6507C18.3614 28.9126 18.6917 29.0666 19.0405 29.0697H19.059C19.096 29.0697 19.13 29.0605 19.167 29.0574C19.2133 29.0543 19.2596 29.0512 19.3059 29.042C19.3522 29.0328 19.3924 29.0143 19.4387 29.0019C19.4757 28.9896 19.5127 28.9834 19.5467 28.968C19.6084 28.9403 19.6671 28.9064 19.7257 28.8694C19.7411 28.8602 19.7566 28.854 19.7689 28.8448C19.8399 28.7955 19.9047 28.74 19.9634 28.6753L37.344 9.99421L42.0141 14.3232L32.2758 24.79H32.2789ZM31.9487 44.7498H11.6388V34.6466L17.9509 41.8196C18.1824 42.0815 18.5127 42.2325 18.8615 42.2386H18.8769C18.8954 42.2386 18.9139 42.2325 18.9325 42.2325C19.167 42.2263 19.3954 42.1493 19.5899 42.0137C19.6578 41.9644 19.7226 41.909 19.7813 41.8443L31.9456 28.7647V44.7467L31.9487 44.7498ZM17.7441 24.4788H20.5004L19.0837 26.0009L17.7441 24.4788ZM38.1219 7.35056C37.8811 7.12872 37.5724 7.01164 37.236 7.02088C36.9088 7.0332 36.6002 7.17494 36.3779 7.41527L22.7969 22.0138H15.5773L13.2716 19.3918C13.0556 19.1453 12.75 18.9974 12.4228 18.9758C12.0925 18.9542 11.7746 19.0621 11.5277 19.2809L4.88223 25.1043C4.63531 25.3199 4.48715 25.625 4.46554 25.9516C4.44394 26.2782 4.55506 26.5986 4.77112 26.8451L9.16953 31.8428V45.9853C9.16953 46.6663 9.72203 47.2178 10.4042 47.2178H33.1802C33.8624 47.2178 34.4149 46.6663 34.4149 45.9853V26.1149L44.6624 15.0966C45.1254 14.5975 45.0976 13.8179 44.5976 13.3558L38.1188 7.35056H38.1219Z" fill="url(#paint5_linear_14489_143390)"/>
     <path d="M48.7773 25.9608V30.9123H97.0642V25.9608H48.7773Z" fill="white"/>
     <path d="M79.9798 38.8124H48.7773V43.7638H79.9798V38.8124Z" fill="white"/>
     <path d="M48.7773 73.1736H97.0642V68.2252H48.7773V73.1736Z" fill="white"/>
     <path d="M48.7773 86.0251H79.9798V81.0768H48.7773V86.0251Z" fill="white"/>
     <path d="M20.5055 24.4788H17.7461L19.0888 26.0009L20.5055 24.4788Z" fill="white"/>
     <path d="M19.9677 28.6784C19.909 28.7431 19.8411 28.7986 19.7732 28.8479C19.7609 28.8571 19.7423 28.8633 19.73 28.8725C19.6713 28.9095 19.6127 28.9434 19.551 28.9711C19.517 28.9865 19.4769 28.9927 19.4429 29.005C19.3997 29.0204 19.3565 29.0358 19.3102 29.0451C19.2639 29.0543 19.2176 29.0543 19.1713 29.0605C19.1343 29.0605 19.1003 29.0728 19.0633 29.0728H19.0448C18.696 29.0666 18.3657 28.9157 18.1342 28.6538L14.0939 24.0597L12.2326 21.9461L7.44531 26.1426L18.9059 39.1729L32.2801 24.7961L42.0184 14.3263L37.3483 9.99729L19.9677 28.6815V28.6784Z" fill="#F2F2F2"/>
     <path d="M19.5898 42.0168C19.3984 42.1524 19.1669 42.2294 18.9323 42.2356C18.9138 42.2356 18.8953 42.2417 18.8768 42.2417H18.8613C18.5125 42.2356 18.1823 42.0846 17.9508 41.8227L11.6387 34.6497V44.7529H31.9454V28.7709L19.7811 41.8504C19.7225 41.912 19.6577 41.9706 19.5898 42.0199V42.0168Z" fill="white"/>
     <defs>
       <linearGradient id="paint0_linear_14489_143390" x1="52" y1="0.799999" x2="52" y2="95.7" gradientUnits="userSpaceOnUse">
         <stop stop-color="#E7EAED"/>
         <stop offset="1" stop-color="#DDDEE5" stop-opacity="0"/>
       </linearGradient>
       <linearGradient id="paint1_linear_14489_143390" x1="72.9213" y1="23.4959" x2="72.9213" y2="33.3772" gradientUnits="userSpaceOnUse">
         <stop stop-color="#AFB2BB"/>
         <stop offset="1" stop-color="#C6C8CF"/>
       </linearGradient>
       <linearGradient id="paint2_linear_14489_143390" x1="64.3791" y1="36.3474" x2="64.3791" y2="46.2257" gradientUnits="userSpaceOnUse">
         <stop stop-color="#AFB2BB"/>
         <stop offset="1" stop-color="#C6C8CF"/>
       </linearGradient>
       <linearGradient id="paint3_linear_14489_143390" x1="72.9213" y1="65.7603" x2="72.9213" y2="75.6385" gradientUnits="userSpaceOnUse">
         <stop stop-color="#AFB2BB"/>
         <stop offset="1" stop-color="#C6C8CF"/>
       </linearGradient>
       <linearGradient id="paint4_linear_14489_143390" x1="64.3791" y1="78.6118" x2="64.3791" y2="88.4901" gradientUnits="userSpaceOnUse">
         <stop stop-color="#AFB2BB"/>
         <stop offset="1" stop-color="#C6C8CF"/>
       </linearGradient>
       <linearGradient id="paint5_linear_14489_143390" x1="24.7274" y1="7.02037" x2="24.7274" y2="47.2178" gradientUnits="userSpaceOnUse">
         <stop stop-color="#AFB2BB"/>
         <stop offset="1" stop-color="#C6C8CF"/>
       </linearGradient>
     </defs>
   </svg>

 )
}
