import { ProfileModel } from '@/types/models/profile'
import { Chat, Message, MessageType, OwnPcp, Pcp, SystemMessageEvent, SystemMessageType } from '@roolz/types/api/chats'
import { Profile } from '@roolz/types/api/profiles'

export interface ChatModel extends Chat {
  messages: MessageModel[]
  totalMessagesCount: number
  sentMessages: MessageModel[]
  nonSentMessages: MessageModel[]
  visibleMessages: MessageModel[]
  last_message: MessageModel | undefined
  pcps: PcpModel[]
  own_pcp: OwnPcpModel
  companionId?: ProfileModel["id"]
  companion?: ProfileModel | undefined
  onlineUsersCount: number

  draft: string

  // nonSendMessagesCount: number
  isPinned: boolean
  unreadMessagesCount: number
}

export interface PcpModel extends Pcp {
  chat: ChatModel | undefined
  profile: ProfileModel | undefined
}

export interface OwnPcpModel extends OwnPcp {
  chat: ChatModel | undefined
}

/**
 * Message types
 */

export interface MessageModel extends Message {
  chat: ChatModel | undefined

  owner: ProfileModel | undefined
  decodedContent: unknown

  isOwnMessage: boolean
  // totalMessagesCount: number
}

export interface TextMessageModel extends MessageModel {
  type: MessageType.TEXT
  decodedContent: TextContent
}
/*
  System messages
 */
export interface SystemMessageModel extends MessageModel {
  type: MessageType.SYSTEM
  decodedContent: SystemContent
}
export interface AddedPcpsMessageModel extends SystemMessageModel {
  decodedContent: SystemAddedPcpsContent
}
export interface AddedPcpMessageModel extends SystemMessageModel {
  decodedContent: SystemAddedPcpContent
}
export interface JoinedPcpMessageModel extends SystemMessageModel {
  decodedContent: SystemJoinedPcpContent
}
export interface JoinedByInviteMessageModel extends SystemMessageModel {
  decodedContent: SystemJoinedByInviteContent
}
/**
 * End system messages
 */

export interface AudioMessageModel extends MessageModel {
  type: MessageType.AUDIO
  decodedContent: AudioContent
}
export interface ImageMessageModel extends MessageModel {
  type: MessageType.IMAGE
  decodedContent: ImageContent
}
export interface VideoMessageModel extends MessageModel {
  type: MessageType.IMAGE
  decodedContent: VideoContent
}
export interface FileMessageModel extends MessageModel {
  type: MessageType.IMAGE
  decodedContent: FileContent
}
export interface ContactMessageModel extends MessageModel {
  type: MessageType.IMAGE
  decodedContent: ContactContent
}
export interface StickerMessageModel extends MessageModel {
  type: MessageType.IMAGE
  decodedContent: StickerContent
}
export interface PollMessageModel extends MessageModel {
  type: MessageType.IMAGE
  decodedContent: PollContent
}
export interface GeolocationMessageModel extends MessageModel {
  type: MessageType.IMAGE
  decodedContent: GeolocationContent
}

export interface ReplyMessage extends MessageModel {
  reply_to: Message
  reply_to_id: Message["id"]
}

export interface ForwardMessage extends MessageModel {
  forward_from: Message
  forward_from_id: Message["id"]
}

/**
 * END Message types
 */


/**
 * Message content types
 */
export interface TextContent {
  content: {
    text: string
  }
}

/**
 * System message contents
 */
export interface SystemContent {
  content: unknown // TODO replace
  event: SystemMessageEvent
}
export interface SystemAddedPcpsContent extends SystemContent {
  event: SystemMessageEvent.ADDED_PCPS
  content: { added_profiles: Profile[] }
}
export interface SystemAddedPcpContent extends SystemContent {
  event: SystemMessageEvent.ADDED_PCP
  content: { added_profile: Profile }
}
export interface SystemJoinedPcpContent extends SystemContent {
  event: SystemMessageEvent.JOINED_PCP
  // content: { joined_profile: Profile }
}
export interface SystemJoinedByInviteContent extends SystemContent {
  event: SystemMessageEvent.JOINED_BY_INVITE
  content: {
    invited_profile: Profile
    profile_who_invited: Profile
  }
}

/**
 * End system message contents
 */

export interface AudioContent { // TODO think(ask) about nulls
  content: {
    id: string
    duration: number
    height: number
    name: string
    size: number
    thumbnail: string | null
    url: string
    width: number
  }
}
export interface ImageContent {
  content: {
    id: string
    duration: number
    height: number
    name: string
    size: number
    thumbnail: string
    url: string
    width: number
  }
}
export interface VideoContent {

}
export interface FileContent {

}
export interface ContactContent {

}
export interface StickerContent {

}
export interface PollContent {

}
export interface GeolocationContent {

}
/**
 * END Message content types
 */


/**
 * TYPE GUARDS
 */

export function isTextMessage(message: MessageModel): message is TextMessageModel {
  return message?.type === MessageType.TEXT
}

export function isSystemMessage(message: MessageModel): message is SystemMessageModel {
  return message?.type === MessageType.SYSTEM
}

export function isImageMessage(message: MessageModel): message is ImageMessageModel {
  return message?.type === MessageType.IMAGE
}

export function isAudioMessage(message: MessageModel): message is AudioMessageModel {
  return message?.type === MessageType.AUDIO
}

export function isVideoMessage(message: MessageModel): message is VideoMessageModel {
  return message?.type === MessageType.VIDEO
}

export function isFileMessage(message: MessageModel): message is FileMessageModel {
  return message?.type === MessageType.FILE
}

export function isContactMessage(message: MessageModel): message is ContactMessageModel {
  return message?.type === MessageType.CONTACT
}

export function isStickerMessage(message: MessageModel): message is StickerMessageModel {
  return message?.type === MessageType.STICKER
}

export function isPollMessage(message: MessageModel): message is PollMessageModel {
  return message?.type === MessageType.POLL
}

export function isGeolocationMessage(message: MessageModel): message is GeolocationMessageModel {
  return message?.type === MessageType.GEOLOCATION
}

export function isAddedPcpsMessage(message: SystemMessageModel): message is AddedPcpsMessageModel {
  return message.decodedContent?.event === SystemMessageEvent.ADDED_PCPS
}

export function isAddedPcpMessage(message: SystemMessageModel): message is AddedPcpMessageModel {
  return message.decodedContent?.event === SystemMessageEvent.ADDED_PCP
}

export function isJoinedPcpMessage(message: SystemMessageModel): message is JoinedPcpMessageModel {
  return message.decodedContent?.event === SystemMessageEvent.JOINED_PCP
}

export function isJoinedByInvitePcpMessage(message: SystemMessageModel): message is JoinedByInviteMessageModel {
  return message.decodedContent?.event === SystemMessageEvent.JOINED_BY_INVITE
}

export function isReplyMessage(message: Message): message is ReplyMessage {
  return !!message.reply_to_id && !!message.reply_to
}

export function isForwardMessage(message: Message): message is ForwardMessage {
  return !!message.forward_from_id && !!message.forward_from
}
