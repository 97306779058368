import { BaseApiClient } from '@/api/clients/_base.client'
import { hereProxyHttp } from '@/api/transport/http/here-proxy.http'
import { identityHttp } from '@/api/transport/http/identity.http'
import { SendSms, ClientConfigResponse, SendSmsToChangePhoneRequest } from '@roolz/types/api/profiles'
import { PointsTimezonesResponse, SendEventRequest } from '@roolz/types/api/system'

class SystemClient extends BaseApiClient {
  sendSms(body: SendSms) {
    return this.post('/v1/system/sms/send', { body })
  }
  sendSmsToChangePhone(body: SendSmsToChangePhoneRequest) {
    return this.post('/v1/system/sms/send-for-update-phone', { body })
  }
  getClientConfig() {
    return this.get<ClientConfigResponse>('/v1/system/client_config')
  }
  getGeoPointsTimezone(params: {
    point: string[]
  }) {
    return hereProxyHttp.get<PointsTimezonesResponse>('/mapgeo/v1/geo/timezone', {
      params,
    })
  }
  sendEvent(body: SendEventRequest) {
    return this.post('/v1/event/send', { body })
  }
}

export const systemClient = new SystemClient(identityHttp, '')
