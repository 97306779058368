import { chatsStore } from '@/store/chats/chats.store'
import { OwnPcp } from '@roolz/types/api/chats'
import { OwnPcpModel } from '@/types/models/chat'
import { clone } from 'lodash'

export function OwnPcpModelFactory(ownPcp: OwnPcp): OwnPcpModel {
  return  ({
    ...clone(ownPcp),

    get chat() {
      return chatsStore.chats[this.chat_id]
    }
  })
}
