// import { AsideCompanyPage } from '@/components/companies/Page/AsideCompanyPage'
// import { PopoverCreateCompany } from '@/components/companies/PopoverCreateCompany'
// import { ContactList } from '@/components/contactList/ContactList'
// import { OfferViewAside } from '@/components/offers/view/OfferViewAside/OfferViewAside'
const ContactList = lazyWithSuspense(() => import('@/components/contactList/ContactList'), null)
const OfferViewAside = lazyWithSuspense(() => import('@/components/offers/view/OfferViewAside/OfferViewAside'), null)
const AsideCompanyPage = lazyWithSuspense(() => import('@/components/companies/Page/AsideCompanyPage'), null)
// const PopoverCreateCompany = lazyWithSuspense(() => import('@/components/companies/PopoverCreateCompany'), null)
const PopoverCreateCompanyNew = lazyWithSuspense(() => import('@/components/companies/PopoverCreateCompanyNew'), null)
const CargoFormAside = lazyWithSuspense(() => import('@/components/offers/form/OfferFormAside/CargoFormAside'), null)
const TransportFormAside = lazyWithSuspense(() => import('@/components/offers/form/OfferFormAside/TransportFormAside'), null)
const ProfileViewAside = lazyWithSuspense(() => import('@/components/profile/ProfileViewAside/ProfileViewAside'), null)
const OfferMatchesViewAside = lazyWithSuspense(() => import('@/components/offers/view/MatchesViewAside/MatchesViewAside'), null)
import { lazyWithSuspense } from '@/components/utils/LazyWithSuspense'
import { GlobalModalContainer } from '@/global-modals/components/GlobalModalContainer'
import { GlobalModalsContext } from '@/global-modals/GlobalModalsContext'
import { GlobalModalsManager } from '@/global-modals/GlobalModalsManager'
import { uiStore } from '@/store/ui/ui.store'
import { ComponentType, memo, useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ModalType } from './const'

interface PopupProviderProps {
  manager?: GlobalModalsManager,
}

/**
 * Modal names
 */
export enum GLOBAL_MODALS_NAMES {
  CREATE_CARGO = 'createCargo',
  CREATE_TRANSPORT = 'createTransport',

  CONTACT_LIST = 'contactList',
  CREATE_COMPANY = 'createCompany',
  CREATE_COMPANY_NEW = 'createCompanyNew',
  OFFER_VIEW_ASIDE = 'offerView',
  OFFER_MATCHES_VIEW_ASIDE = 'offerMatches',
  PROFILE_VIEW = 'profileView',
  COMPANY_VIEW = 'companyViewAside'
}

export const GlobalModalsProvider = memo((
  {manager, children}: React.PropsWithChildren<PopupProviderProps>
) => {
  const navigate = useNavigate()
  const location = useLocation()

  const _manager = useMemo(
    () => manager || new GlobalModalsManager(),
    [manager]
  )

  function registerOverlay(name: string, Component: ComponentType<any>) {
    _manager.register(name, Component, { meta: { type: ModalType.OVERLAY } })
  }
  function registerPopup(name: string, Component: ComponentType<any>) {
    _manager.register(name, Component, { meta: { type: ModalType.POPUP } })
  }

  useLayoutEffect(() => {
    registerOverlay(GLOBAL_MODALS_NAMES.CREATE_CARGO, CargoFormAside)
    registerOverlay(GLOBAL_MODALS_NAMES.CREATE_TRANSPORT, TransportFormAside)
    // registerOverlay(GLOBAL_MODALS_NAMES.CREATE_COMPANY, PopoverCreateCompany)
    registerOverlay(GLOBAL_MODALS_NAMES.CREATE_COMPANY_NEW, PopoverCreateCompanyNew)
    registerOverlay(GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE, OfferViewAside)
    registerOverlay(GLOBAL_MODALS_NAMES.OFFER_MATCHES_VIEW_ASIDE, OfferMatchesViewAside)
    registerOverlay(GLOBAL_MODALS_NAMES.COMPANY_VIEW, AsideCompanyPage)

    registerPopup(GLOBAL_MODALS_NAMES.CONTACT_LIST, ContactList)
    registerPopup(GLOBAL_MODALS_NAMES.PROFILE_VIEW, ProfileViewAside)
  }, [_manager])

  const closeAll = useCallback(() => {
    if(_manager) {
      _manager.closeAll()

      if(location.state?.backgroundLocation) {
        navigate(location.state?.backgroundLocation)
      }
    }
  }, [_manager, location])

  useEffect(() => {
    uiStore.closeAllGlobalModals = closeAll
  }, [_manager, location])

  return (
    <GlobalModalsContext.Provider value={{ modalManager: _manager}}>
      <GlobalModalContainer/>
      {children}
    </GlobalModalsContext.Provider>
  )
})
