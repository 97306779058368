import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { PopupModalPage } from '@/global-modals/HOC/PopupModalPage'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { useEffect } from 'react'

interface Props {

}

const ContactsViewAside = PopupModalPage<Props>(({ onClose }) => {
  const globalModals = useGlobalModals()

  useEffect(() => {
    globalModals.open(GLOBAL_MODALS_NAMES.CONTACT_LIST, {
      props: {

      },
      config: {
        onClose
      }
    })

  }, [])

  return null
})

export default ContactsViewAside
