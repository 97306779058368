import { debounce } from 'lodash'
import throttle from 'lodash/throttle'
import after from 'lodash/after'
import { MutableRefObject, useCallback, useEffect, useState } from 'react'

const THROTTLE_MS = 50

export function useIsElementScrolling(el: MutableRefObject<any>) {
  const [isScrolling, setIsScrolling] = useState<boolean>(false)

  const subscriber = useCallback((isScrolling: boolean) => {
    setIsScrolling(isScrolling)
  }, [])

  const setScrollingStopped = useCallback(debounce(() => {
    setIsScrolling(false)
  }, 250), [])

  const setScrollingTrue = useCallback(after(3, () => {
    setIsScrolling(true)

    setScrollingStopped()
  }), [isScrolling])

  const scrollHandler = useCallback(throttle((e: any) => {
    setScrollingTrue()
  }, THROTTLE_MS), [])

  useEffect(() => {
    if(el.current) {
      const node = el.current

      node.addEventListener('scroll', scrollHandler)

      return () => {
        if(node) {
          node.removeEventListener('scroll', scrollHandler)
        }
      }
    }
  }, [el])

  return isScrolling
}
