interface Props {
  size?: number
  color?: string
  className?: string
}

export function Edit2({ size = 14, color = '#8E8E93', className }: Props) {
  return (
    <svg
      className={className}
      width={size} height={size} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.43359 10.6668H2.26693L8.43359 4.50013L7.61693 3.6668L1.43359 9.85013V10.6668ZM10.5669 3.7668L8.31693 1.53346L9.05026 0.80013C9.28359 0.577908 9.56982 0.466797 9.90893 0.466797C10.2476 0.466797 10.528 0.577908 10.7503 0.80013L11.4336 1.48346C11.6225 1.68346 11.7225 1.91946 11.7336 2.19146C11.7447 2.46391 11.6503 2.69457 11.4503 2.88346L10.5669 3.7668ZM9.85026 4.50013L2.68359 11.6668H0.433594V9.4168L7.60026 2.25013L9.85026 4.50013ZM8.01693 4.08346L7.61693 3.6668L8.43359 4.50013L8.01693 4.08346Z'
        fill={color}
      />
    </svg>
  )
}
