// @flow 
import * as React from 'react'

interface Props {
  
}
export const HourglassEmpty = (props: Props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_10223_86865" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
        <rect width="14" height="14" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_10223_86865)">
        <path d="M4.75443 11.6654H9.24609V9.91537C9.24609 9.26398 9.02987 8.70009 8.59743 8.2237C8.16459 7.74731 7.63221 7.50912 7.00026 7.50912C6.36832 7.50912 5.83593 7.74731 5.40309 8.2237C4.97065 8.70009 4.75443 9.26398 4.75443 9.91537V11.6654ZM7.00026 6.48828C7.63221 6.48828 8.16459 6.25009 8.59743 5.7737C9.02987 5.29731 9.24609 4.73342 9.24609 4.08203V2.33203H4.75443V4.08203C4.75443 4.73342 4.97065 5.29731 5.40309 5.7737C5.83593 6.25009 6.36832 6.48828 7.00026 6.48828ZM10.8794 12.5404H3.12109C2.9947 12.5404 2.89009 12.4991 2.80726 12.4167C2.72482 12.3339 2.68359 12.2293 2.68359 12.1029C2.68359 11.9765 2.72482 11.8719 2.80726 11.789C2.89009 11.7066 2.9947 11.6654 3.12109 11.6654H3.87943V9.91537C3.87943 9.28342 4.04237 8.70009 4.36826 8.16537C4.69376 7.63064 5.13845 7.24175 5.70234 6.9987C5.13845 6.75564 4.69376 6.36675 4.36826 5.83203C4.04237 5.29731 3.87943 4.71398 3.87943 4.08203V2.33203H3.12109C2.9947 2.33203 2.89009 2.29061 2.80726 2.20778C2.72482 2.12534 2.68359 2.02092 2.68359 1.89453C2.68359 1.76814 2.72482 1.66353 2.80726 1.5807C2.89009 1.49825 2.9947 1.45703 3.12109 1.45703H10.8794C11.0058 1.45703 11.1104 1.49825 11.1933 1.5807C11.2757 1.66353 11.3169 1.76814 11.3169 1.89453C11.3169 2.02092 11.2757 2.12534 11.1933 2.20778C11.1104 2.29061 11.0058 2.33203 10.8794 2.33203H10.1211V4.08203C10.1211 4.71398 9.95834 5.29731 9.63284 5.83203C9.30696 6.36675 8.86207 6.75564 8.29818 6.9987C8.86207 7.24175 9.30696 7.63064 9.63284 8.16537C9.95834 8.70009 10.1211 9.28342 10.1211 9.91537V11.6654H10.8794C11.0058 11.6654 11.1104 11.7066 11.1933 11.789C11.2757 11.8719 11.3169 11.9765 11.3169 12.1029C11.3169 12.2293 11.2757 12.3339 11.1933 12.4167C11.1104 12.4991 11.0058 12.5404 10.8794 12.5404Z" fill="#E59F42"/>
      </g>
    </svg>
  )
}