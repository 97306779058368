import { fireViewOfferStrange, fireViewOwnOffer } from '@/config/events'
import { useNavigateToModal } from '@/config/routes'
import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { OverlayModalPage } from '@/global-modals/HOC/OverlayModalPage'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { allOffersStore } from '@/store/exchange/all_offers.store'
import { exchangeService } from '@/store/exchange/exchange.service'
import { exchangeStore } from '@/store/exchange/exchange.store'
import { myOffersStore } from '@/store/exchange/my_offers.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { isCargoOffer, isTransportOffer, Offer } from '@roolz/types/api/exchange'
import { useLayoutEffect } from 'react'
import { useParams } from 'react-router'

interface Props {

}

const OfferView = OverlayModalPage<Props>(({ onClose }) => {
  const { offer_id } = useParams()

  const globalModals = useGlobalModals()

  async function loadIfNeeded(offer_id: string) {
    const highestVisibleOverlay = globalModals.visibleModals
      .filter(modal => modal?.modal?.meta?.type === 'overlay').at(-1)

    if(highestVisibleOverlay?.modal?.id === GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE
      && highestVisibleOverlay?.key === offer_id
    ) {
      return
    }

    // if(globalModals.isVisible(GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE)) {
    //   const modal = globalModals.getMountedModal(GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE)
    //   let offer = modal?.props?.offer
    //   if(isPromise(offer)) {
    //     offer = await offer
    //   }
    //
    //   if(offer?._id === offer_id) {
    //     load = false
    //   }
    // }

    globalModals.open(GLOBAL_MODALS_NAMES.OFFER_VIEW_ASIDE, {
      key: offer_id,

      props: {
        offer: exchangeService.loadOffer({
          id: offer_id,
          active_space_company_id: profilesStore.activeCompanyId ?? null
        })
          .then(res => {
            let offer_type: any = ''
            if(isCargoOffer(res)) offer_type = 'cargo'
            if(isTransportOffer(res)) offer_type = 'transport'

            if(exchangeStore.isOwnOffer(res)) {
              fireViewOwnOffer({ offer_type })
            } else {
              fireViewOfferStrange({
                is_spa_app: true,
                offer_type: offer_type,
                offer_id: res._id
              })
            }

            return exchangeStore.findOffer(res._id)
          })
      },
      config: { onClose }
    })
  }

  useLayoutEffect(() => {
    if(offer_id) {
      loadIfNeeded(offer_id)
    }
  }, [offer_id])

  return null
})

export default OfferView
