import { authService } from '@/store/auth/auth.service'
import { AxiosRequestConfig } from 'axios'

export async function refreshTokensInterceptor(config: AxiosRequestConfig) {
  if(config.url?.includes('token')
    || config.url?.includes('refresh')
  ) {
    return config
  }

  try {
    await authService.refreshTokenIfNecessary()
  } catch(e) {
    console.log(e)
  }

  return config
}
