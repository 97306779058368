import { wsProxyClient } from '@/api/clients/ws-proxy.client'
import { db, DEXIE_STORES } from '@/database'
import { ProfileModelFactory } from '@/models/Profile.model'
import { addOrUpdateProfiles, getProfileById } from '@/repositories/profiles.repository'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesService } from '@/store/profiles/profiles.service'
import { getNow } from '@/utils/date'
import { Profile } from '@roolz/types/api/profiles'
import { Company } from '@roolz/types/api/companies'
import { liveQuery } from 'dexie'
import { merge, mergeWith } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { persist, retrieveClientside } from '@roolz/sdk/utils/auth'
import { OnlineStatus } from '@roolz/types/custom'
import { ProfileModel } from '@/types/models/profile'
import { RecursivePartial } from '@roolz/types'
import { Device } from '@roolz/types/api/profiles'
import { Offer } from '@roolz/types/api/exchange'
import dayjs from '@roolz/sdk/plugins/dayjs'

class ProfilesStore {
  my_profile?: ProfileModel
  my_device?: Device

  profiles: ProfileModel[] = []
  presenceSubscriptionQueue: Array<Profile["id"]> = []

  constructor() {
    makeAutoObservable(this)
  }

  get activeCompanyId(): Company['id'] | null {
    const company = myCompaniesStore.companies.find(({ id }) => id === this.my_profile?.active_space_company_id)

    return company?.id ?? null
  }

  get bannedProfiles() {
    return this.profiles.filter(profile => profile.relationships?.is_banned)
  }

  /**
   * If required fields of profile filled, or show the Profile Fill page to fill them
   */
  get isMyProfileFilled(): boolean {
    return !!this.my_profile?.is_filled
      //!!(this.my_profile?.first_name && this.my_profile?.last_name)
  }

  addOrUpdateProfile = (profile: Profile) => {
    if(this.updateProfileIfExists(profile)) {
      return
    }

    this.presenceSubscriptionQueue.push(profile.id)
    profilesService.bindToProfilesOnlineChanges()
    liveQuery(() => db[DEXIE_STORES.PROFILES].get(profile.id))
      .subscribe(profile => {
        profile && this.updateProfileIfExists(profile)
      })

    const model = ProfileModelFactory(profile)
    this.profiles.push(model)

    return model
  }

  updateProfileIfExists = (profile: Profile) => {
    const existing = this.profiles.find(({ id }) => id === profile?.id)

    if(existing) {
      mergeWith(existing, profile, (a: unknown, b: unknown) => {
        if(Array.isArray(b)) return b
      })

      return true
    }

    return false
  }

  setMyProfile(profile: Profile) {
    retrieveClientside().then((credentials: any) => {
      if(credentials && profile.lang_code && credentials?.lang_code !== profile.lang_code) {
        persist({ ...credentials, lang_code: profile.lang_code })
      }
    }).catch(console.log)

    this.addOrUpdateProfile(profile)

    this.my_profile = ProfileModelFactory(profile)
  }

  setMyDevice(device: Device) {
    this.my_device = device
  }

  findProfile = (id: Profile['id']): ProfileModel | undefined => {
    if(id == this.my_profile?.id) {
      return this.my_profile
    }

    return this.profiles.find(profile => profile.id === id)
  }

  patchProfile(id: Profile["id"], data: RecursivePartial<Profile>) {
    const profile = this.findProfile(id)

    if(profile) {
      merge(profile, data)
    }
  }

  hasAccessToOffer(offer: Offer): boolean {
    if (this.my_profile?.is_superuser) return true

    if (!offer.company_id) {
      return this.activeCompanyId ? false : this.my_profile?.id === offer.creation_profile_id
    } else {
      return this.activeCompanyId === offer.company_id
    }
  }
}

const profilesStore = new ProfilesStore()

// setInterval(() => {
//   profilesStore.server_time = getNow()
// }, 10000)

export {
  ProfilesStore,
  profilesStore
}
