import { MessagingControlsProvider } from '@/components/chats/MessagingControlsProvider'
import { GlobalRequestModalsContainer } from '@/components/GlobalRequestModalsContainer'
import HomeLayout from '@/components/layouts/HomeLayout/HomeLayout'
import { PageLoading } from '@/components/layouts/PageLoading/PageLoading'
 import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import React, { Suspense, useMemo, memo } from 'react'
import { Outlet, useLocation } from 'react-router'

export const Home = memo(() => {
  const location = useLocation()

  const showSidebar: boolean = useMemo(() => {
    return location.pathname !== resolvePathByName(ROUTE_NAMES.FILL_PROFILE)
  }, [location.pathname])

  return (
    <Suspense fallback={<PageLoading/>}>
      <GlobalRequestModalsContainer/>

      <HomeLayout showSidebar={showSidebar}>
        <Suspense fallback={<PageLoading/>}>
          <Outlet/>
        </Suspense>

      </HomeLayout>

      {!IS_MOBILE && (
        <MessagingControlsProvider/>
      )}
    </Suspense>
  )
})

