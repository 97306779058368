import { getChatName } from '@/components/chats/utils'
import { ChatModel } from '@/types/models/chat'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { ChatType, MessageType, SystemMessageEvent } from '@roolz/types/api/chats'
import { useTranslation } from 'react-i18next'

interface Props {
  chat: ChatModel
}

export const ChatName = observer(function ChatName({
  chat
}: Props) {
  const { t } = useTranslation('chat/common')

  return <>{getChatName(chat, t)}</>
})
