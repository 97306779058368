import { authService } from '@/store/auth/auth.service'
import { authStore } from '@/store/auth/auth.store'
import { profilesService } from '@/store/profiles/profiles.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { systemService } from '@/store/system/system.service'
import { useInterval } from 'ahooks'
import { observer } from 'mobx-react-lite'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { LOGOUT_BACK_URL_PARAM, BUSINESS_EXTERNAL_ACTION_PARAM, BUSINESS_EXTERNAL_ACTIONS } from '@roolz/sdk/config/const'

interface Props {
  children: ReactNode
}

export const Authed = observer(({ children }: Props) => {
  const [isError, setIsError] = useState(false)
  const [params] = useSearchParams()
  const { i18n: { language } } = useTranslation()

  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    if(profilesStore.isMyProfileFilled) {
      profilesService.retrieveMyDevice()
    }
  }, [profilesStore.isMyProfileFilled])

  async function init() {
    // if(authStore.isAuthed && profilesStore.my_profile) {
    //   return
    // }

    try {
      await authService.restoreAuth()
    } catch(e) {
      logout()
      throw new Error('Can`t restore auth credentials')
    }
    await authService.refreshTokenIfNecessary()
    if(!authStore.isAuthed) {
      await logout()
      throw new Error('User is not authed after restoring and refreshing credentials')
    }

    await Promise.all([
      profilesService.retrieveMyProfile(),
      systemService.syncClientConfig(),
    ]).catch(e => {
      if(e?.response?.status === 401) {
        return logout()
      }
      // TODO network errors should be handled different way
      setIsError(true)
    })

    await authService.syncCredentialsWithIdb()
    setReady(true)
  }
  useEffect(() => {
    const action = params.get(BUSINESS_EXTERNAL_ACTION_PARAM)
    if(action === BUSINESS_EXTERNAL_ACTIONS.LOGOUT) {
      logout()
      return
    }

    init()
  }, [authStore.isAuthed, !!profilesStore.my_profile])

  useInterval(() => authService.refreshTokenIfNecessary(), 60 * 1000)

  async function logout() {
    let result = false
    try {
      await authService.clientsideLogout()
      result = true
    } catch(e) {
      console.log(e)
    }
    const backUrl = params.get(LOGOUT_BACK_URL_PARAM)
    let redirectUrl = backUrl || process.env.REACT_APP_ROOLZ_STATIC_URL + '/' + language + location.pathname

    if(backUrl) {
      const resultParam = 'loggedOut=' + Number(result)

      if(redirectUrl.includes('?')) {
        redirectUrl += '&' + resultParam
      } else {
        redirectUrl += '?' + resultParam
      }
    }

    window.location.href = redirectUrl
  }

  if(isError) {
    throw new Error('Auth restoration error')
  }

  if(!profilesStore.my_profile || !ready) {
    return null
  }

  return <>{children}</>
})
