import { Dialog } from '@/components/ui/Dialog/Dialog'
import { fireCompanyCreateButton } from '@/config/events'
import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { DialogContent, DialogTitle } from '@mui/material'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './CreateCompanyAppeal.module.scss'
import CompanyCreateImage from '@/assets/images/create-company.png'
import { Close } from '@roolz/icons/Close'

interface Props {
  open: boolean
  setOpen: (open: boolean) => any
}

export const CreateCompanyAppeal = ({
  open,
  setOpen
}: Props) => {
  const { t } = useTranslation('modals')

  const globalModals = useGlobalModals()

  function openCreateCompany() {
    fireCompanyCreateButton()

    setOpen(false)
    // uiStore.isSidebarMobileOpen = false
    globalModals.open(GLOBAL_MODALS_NAMES.CREATE_COMPANY_NEW)
  }

  function handlePerson() {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth='xs'
      classes={{ paper: styles.paper }}
      scroll='body'
    >
      <div className={styles.header}>
        <img
          className={styles.image}
          src={CompanyCreateImage}
          alt=''
        />

        <CloseButton onClick={() => setOpen(false)}/>
      </div>

      <DialogTitle className={styles.title}>
        {t('create_company_appeal.title')}
      </DialogTitle>
      {/*<DialogContent style={{ maxWidth: '100%' }}>*/}
      <div className={styles.content}>
        <div className={styles.description}>
          {t('create_company_appeal.description')}
        </div>
      </div>
      {/*</DialogContent>*/}

      <div className={styles.actions}>
        <Button
          onClick={openCreateCompany}
        >{t('create_company_appeal.create_company')}</Button>
        {/*<Button*/}
        {/*  onClick={handlePerson}*/}
        {/*>{t('create_company_appeal.act_as_person')}</Button>*/}
      </div>

      <div className={styles.content}>
        <div className={styles.note}>
          {t('create_company_appeal.note')}
        </div>
      </div>
    </Dialog>
  )
}


function CloseButton({
  onClick
}: {
  onClick: () => void
}) {
  return (
    <button
      className={styles.close}
      onClick={onClick}
    >
      <Close
        className={styles.close__icon}
        color='#8E8E93'
      />
    </button>
  )
}
