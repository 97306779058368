import { refreshTokensInterceptor } from '@/api/refreshTokensInterceptor'
import i18n from '@/plugins/i18n'
import { authStore } from '@/store/auth/auth.store'
import axios from 'axios'

/**
 * Http client for requests to Roolz Identity Server
 */
export const identityHttp = axios.create({
  baseURL: process.env.REACT_APP_OWN_SERVER_BASE_URL + '/identity',
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    "Roolz-Product": "business"
  }
})
identityHttp.interceptors.request.use(refreshTokensInterceptor)

// Change request data/error here
identityHttp.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authStore.access_token}`,
      'Accept-Language': i18n.language
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
