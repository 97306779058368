import { MessageDate } from '@/components/chats/shared/message/MessageDate/MessageDate'
import { MessageDeliverStatus } from '@/components/chats/shared/message/MessageDeliverStatus/MessageDeliverStatus'
import { MessageModel } from '@/types/models/chat'
import { Zoom } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import styles from '@/components/chats/entities/MessageGroup/MessageAttributes/MessageAttributes.module.scss'

interface Props {
  message: MessageModel

  showDate?: boolean
  showStatus?: boolean
}

export const MessageAttributes = observer(function MessageAttributes({
  message,

  showDate = true,
  showStatus = true
}: Props) {
  const { t } = useTranslation('chat/message')

  return (
    <div className={styles.attributes}>
      {message.version > 0 && (
        <span className={styles.editLabel}>
          {t('message_state.edited')}
        </span>
      )}

      {showDate && (
        <MessageDate message={message}/>
      )}

      {showStatus && (
        // <Zoom
        //   in={message.status === MessageStatus.SENT}
        //   appear={true}
        // >
          <div className={styles.status}>
            <MessageDeliverStatus status={message.status}/>
          </div>
        // </Zoom>
      )}
    </div>
  )
})
