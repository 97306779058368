import { mergeWith } from 'lodash'

interface WithIndex {
  index: number
}

export function sortByIndex<T extends WithIndex>(list: T[]): T[] {
  return list.sort((a, b) => {
    return a.index - b.index
  })
}

export function mergeModels(a: any, b: any) {
  return mergeWith(a, b, (a: any, b: any) => {
    if(Array.isArray(a) && Array.isArray(b) && a.length !== b.length) {
      return b
    }
  })
}

export function pickField<T extends object>(arr: Array<T>, field: keyof T): Array<unknown | undefined> {
  return arr.map((item: T) => item[field])
}
