import ru from './ru/index.js'
import en from './en/index.js'
import fr_FR from './fr_FR/index.js'
import de from './de/index.js'
import zh_CN from './zh_CN/index.js'
import es_ES from './es_ES/index.js'
import hi_IN from './hi_IN/index.js'
import pl_PL from './pl_PL/index.js'
import tr from './tr/index.js'
import uk from './uk/index.js'

const locales = {
  de,
  en,
  'es-ES': es_ES,
  'fr-FR': fr_FR,
  'hi-IN': hi_IN,
  'pl-PL': pl_PL,
  tr,
  uk,
  'zh-CN': zh_CN,
  ru,
}

export default locales
