import { ClientSettings } from '@/store/client-settings/client-settings'
import { Chat, Message, Pcp, OwnPcp } from '@roolz/types/api/chats'
import Dexie from 'dexie'
import { Profile } from '@roolz/types/api/profiles'
import { OutgoingSocketPackage } from '@roolz/types/ws'
import { AuthCredentials, NotificationTask, DisplayedNotification, InteractedClient } from '@roolz/types/database'

const DB_NAME = 'roolz_dexie3'
const DB_VERSION = 8

export enum DEXIE_STORES {
  MESSAGES = 'messages',
  CHATS = 'chats',
  PCPS = 'pcps',
  OWN_PCPS = 'own_pcps',
  PROFILES = 'profiles',

  SOCKET_QUEUE = 'socket-queue',
  CLIENT_SETTINGS = 'CLIENT_SETTINGS',

  AUTH = 'auth',
  NOTIFICATIONS_QUEUE = 'notifications-queue',
  DISPLAYED_NOTIFICATIONS = 'displayed-notifications',
  INTERACTED_CLIENT_IDS = 'interacted-client-ids',
}

export class RoolzDatabase extends Dexie {
  [DEXIE_STORES.MESSAGES]: Dexie.Table<Message, string>
  [DEXIE_STORES.CHATS]: Dexie.Table<Chat, string>
  [DEXIE_STORES.PCPS]: Dexie.Table<Pcp, string>
  [DEXIE_STORES.OWN_PCPS]: Dexie.Table<OwnPcp, string>
  [DEXIE_STORES.PROFILES]: Dexie.Table<Profile, string>

  [DEXIE_STORES.SOCKET_QUEUE]: Dexie.Table<OutgoingSocketPackage, string>
  [DEXIE_STORES.AUTH]: Dexie.Table<AuthCredentials, string>
  [DEXIE_STORES.NOTIFICATIONS_QUEUE]: Dexie.Table<NotificationTask, string>
  [DEXIE_STORES.DISPLAYED_NOTIFICATIONS]: Dexie.Table<DisplayedNotification, string>
  [DEXIE_STORES.INTERACTED_CLIENT_IDS]: Dexie.Table<InteractedClient, string>

  [DEXIE_STORES.CLIENT_SETTINGS]: Dexie.Table<ClientSettings, number>

  constructor () {
    super(DB_NAME)

    this.version(DB_VERSION).stores({
      [DEXIE_STORES.CHATS]: 'id',
      [DEXIE_STORES.MESSAGES]: 'id, chat_id, [chat_id+number], [chat_id+client_message_id], created_at',
      [DEXIE_STORES.PCPS]: 'chat_id',
      [DEXIE_STORES.OWN_PCPS]: 'chat_id',
      [DEXIE_STORES.PROFILES]: 'id',

      [DEXIE_STORES.SOCKET_QUEUE]: 'id, last_sent_at, created_at',
      [DEXIE_STORES.AUTH]: 'id',
      [DEXIE_STORES.NOTIFICATIONS_QUEUE]: 'id',
      [DEXIE_STORES.DISPLAYED_NOTIFICATIONS]: 'id',
      [DEXIE_STORES.INTERACTED_CLIENT_IDS]: 'id',

      [DEXIE_STORES.CLIENT_SETTINGS]: 'id'
    })
  }
}
const db = new RoolzDatabase()

async function cleanDatabase() {
  try {
    if(!db.isOpen()) {
      await db.open()
    }

    return Promise.all(
      db.tables.map(table => table.clear())
    )
  } catch(e) {
    console.error('CLEANING DB ERROR: ', e)
  }
}


export {
  db,
  cleanDatabase
}
