import { exchangeStore } from '@/store/exchange/exchange.store'
import { Offer, OfferStatus } from '@roolz/types/api/exchange'
import { isNotUndefined } from '@/utils/types'
import { makeAutoObservable } from 'mobx'

export const ITEMS_PER_PAGE = 20

interface Filter {
  status: OfferStatus[]
}

const DEFAULT_FILTER = (): Filter => ({
  status: [OfferStatus.NOT_PUBLISHED, OfferStatus.PUBLISHED]
})
const DEFAULT_LOADING = false
const DEFAULT_PAGE = 1
const DEFAULT_OFFER_IDS = () => []
const DEFAULT_TOTAL = ITEMS_PER_PAGE + 1

class MyOffersStore {
  loading: boolean = DEFAULT_LOADING
  page: number = DEFAULT_PAGE
  offerIds: Array<Offer['_id']> = DEFAULT_OFFER_IDS()
  total: number = DEFAULT_TOTAL
  status_counts: { [key in OfferStatus]: number } | null
  constructor() {
    makeAutoObservable(this)

    this.resetState()
  }
  private _filter: Filter = DEFAULT_FILTER()
  get filter(): Filter {
    return this._filter
  }
  set filter(filter: Filter) {
    // merge(this._filter, filter)

    this._filter = filter

    this.resetLoadedOffers()
  }
  get offers(): Offer[] {
    return this.offerIds
      .map(_id => exchangeStore.findOffer(_id))
      .filter(isNotUndefined)
      .filter(offer => this.filter.status?.includes(offer.status))
      .filter(offer => offer.deleted_at === null)
      .sort(
        (a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      )
  }

  get is_any_status_non_empty(): boolean {
    return this.status_counts !== null && (
           !!this.status_counts?.[OfferStatus.PUBLISHED]
        || !!this.status_counts?.[OfferStatus.NOT_PUBLISHED]
        || !!this.status_counts?.[OfferStatus.CLOSED]
      )
  }

  resetState() {
    this._filter = DEFAULT_FILTER()
    this.loading = DEFAULT_LOADING
    this.page = DEFAULT_PAGE
    this.offerIds = DEFAULT_OFFER_IDS()
    this.total = ITEMS_PER_PAGE + 1
  }
  resetLoadedOffers() {
    this.page = 1
    this.offerIds = []
    this.total = ITEMS_PER_PAGE + 1
  }
  // method for realtime updating list of offers on creating new and changing offers
  handleOfferUpdated(offer: Offer) {
    if(this.offerIds.includes(offer._id)) {
      if(!this.filter.status.includes(offer.status)) {
        this.offerIds = this.offerIds.filter(_id => _id !== offer._id)
        this.total -= 1
      }
    } else {
      if(this.filter.status.includes(offer.status)) {
        this.offerIds.unshift(offer._id)
        this.total += 1
      }
    }
  }

  // Update counters of offers in statuses
  handleStatusChange(newStatus: OfferStatus, oldStatus?: OfferStatus) {
    if(this.status_counts) {
      this.status_counts[newStatus] += 1

      if(oldStatus) {
        this.status_counts[oldStatus] -= 1
      }
    }
  }
}

const myOffersStore = new MyOffersStore()

export {
  MyOffersStore,
  myOffersStore
}

