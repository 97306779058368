export const SEND_ONLINE_INTERVAL = 15 * 1000

// from ws to clients
export enum WORKER_MESSAGES {
  PONG = 'pong',

  WS_ESTABLISHED = 'WS_ESTABLISHED',
  NEW_WS_MESSAGE = 'NEW_WS_MESSAGE',
  NOTIFY_SW_ABOUT_NEW_WORKER = 'NOTIFY_SW_ABOUT_NEW_WORKER'
}

// from tabs to service worker
export enum CLIENT_MESSAGES {
  PING = 'ping',

  SEND_WS_MESSAGE = 'SEND_WS_MESSAGE',
  SEND_ONLINE = 'SEND_ONLINE',

  ADD_TO_INTERACTED = 'ADD_TO_INTERACTED', // set tab as user interacted (able to play audio notifications)
  TAB_FOCUSED = 'TAB_FOCUSED', // set tab as active

  BROADCAST = 'BROADCAST'
}

// from ws to clients
export enum SW_MESSAGES {
  ACK = 'ack',

  SHOW_NOTIFICATIONS = 'SHOW_NOTIFICATIONS',
  ROOLZ_TAB_FOCUSED = 'ROOLZ_TAB_FOCUSED',
}

export enum BROADCAST_MESSAGES {
  NEW_NOTIFICATION = 'NEW_NOTIFICATION',
  CLIENT_SETTINGS_CHANGED = 'CLIENT_SETTINGS_CHANGED'
}
