import { chatsClient } from '@/api'
import { db, DEXIE_STORES } from '@/database'
import { addOrUpdateMessages } from '@/repositories/messages.repository'
import * as chatQueries from '@/database/queries/chats.queries'
import { addOrUpdateOwnPcps } from '@/repositories/own_pcp.repository'
import { addOrUpdatePcps } from '@/repositories/pcp.repository'
import { addOrUpdateProfiles } from '@/repositories/profiles.repository'
import { chatsStore } from '@/store/chats/chats.store'
import { settings } from '@/store/client-settings/client-settings'
import { systemStore } from '@/store/system/system.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { getNow } from '@/utils/date'
import { GetChatsDataRequest, GetChatsDataResponse, OwnPcp } from '@roolz/types/api/chats'
import { Profile } from '@roolz/types/api/profiles'
import { Message } from '@roolz/types/api/chats'
import { Chat } from '@roolz/types/api/chats'

export async function loadAllChats(params: GetChatsDataRequest = {}): Promise<any> {
  const fillToStore = (data: GetChatsDataResponse) => {
    // runInAction(() => {
      addOrUpdateProfiles(data.profile_list)

      addOrUpdateChats(data.chat_list)
      addOrUpdatePcps(data.pcp_list)
      addOrUpdateOwnPcps(data.own_pcp_list)
      addOrUpdateMessages(data.message_list)
    // })
  }

  if(!params.chat_updated_at_gt) {
    try {
      await db.transaction('r',
        DEXIE_STORES.CHATS,
        DEXIE_STORES.PCPS,
        DEXIE_STORES.OWN_PCPS,
        DEXIE_STORES.MESSAGES,
        async () => {
        const chat_list = await db[DEXIE_STORES.CHATS].toArray()
        const message_list = Promise.all(chat_list.map(chat => {
          return db[DEXIE_STORES.MESSAGES]
            .where('chat_id')
            .equals(chat.id)
            .reverse()
            .sortBy('created_at')?.[0]
        })) ?? []

        const own_pcp_list = db[DEXIE_STORES.OWN_PCPS].toArray()
        const pcp_list = db[DEXIE_STORES.PCPS].toArray()
        // TODO Load profiles
        const profile_list: Profile[] = []

        return {
          chat_list,
          message_list: await message_list,
          own_pcp_list: await own_pcp_list,
          pcp_list: await pcp_list,
          profile_list
        }
      })
        .then(data => {
          // console.log('loaded chats from idb', data)

          fillToStore(data)
        })
    } catch(e) {
      console.error(e)
    }
  }

  try {
    let offset = params.offset ?? 0
    const limit = params.limit ?? 50

    let chat_list = []

    do {
      const { data } = await chatsClient.loadChats({
        ...params,
        offset, limit
      })
      fillToStore(data)

      offset += limit
      chat_list = data.chat_list
    } while(chat_list?.length >= limit)

    settings.lastChatsLoadTime = getNow().toISOString()
  } catch(e) {
    console.error(e)
  }
}


export async function getChat(chatId: OwnPcp["chat_id"]): Promise<Chat |undefined> {
  let res: Chat | undefined = chatsStore.getChat(chatId)

  if(!res) {
    res = await db[DEXIE_STORES.CHATS].get(chatId)
  }

  return res
}

export async function addOrUpdateChats(chats: Chat[]) {
  chatsStore.addOrUpdateChats(chats)
  await chatQueries.addOrUpdateChats(chats)
}

export function patchChat(chatId: Chat["id"], data: Partial<Chat>) {
  chatsStore.updateChat(chatId, data)
  db[DEXIE_STORES.CHATS].update(chatId, data)
}
