import { getFlagUrl } from '@/utils/cdns'

interface Props {
  alpha2: string
  width?: number
}

export const CountryFlag = ({
  alpha2,
  width = 40
}: Props) => {
  return (
    <img
      style={{ width }}
      src={getFlagUrl(alpha2)} alt={alpha2}
    />
  )
}
