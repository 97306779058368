import { checkIfProfileCanWriteToChat } from '@/components/chats/utils'
import { Loadable } from '@/components/ui/Loadable/Loadable'
import { useConfirmation } from '@/confirmation/ConfirmationContext'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { isForwardMessage, isTextMessage, MessageModel } from '@/types/models/chat'
import { getNow } from '@/utils/date'
import { toast, toastError, toastSuccess, toastWarning } from '@roolz/sdk/components/snackbars'
import Button, { Color } from '@roolz/sdk/components/ui/buttons/Button/Button'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { getSelectedText } from '@roolz/sdk/utils/selection'
import { ChatType, PcpType } from '@roolz/types/api/chats'
import copy from 'copy-to-clipboard'
import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'


export const useReplyMessage = (message: MessageModel): [boolean, () => any] => {
  const canReply = (() => {
    const chat = message?.chat

    return chat
      && profilesStore.my_profile
      && checkIfProfileCanWriteToChat(profilesStore.my_profile, chat)
  })()

  const handleReply = useCallback(() => {
    if(!message || !chatsStore.activeChatId) {
      return
    }

    chatsStore.setReplyingMessage(chatsStore.activeChatId, message)
  }, [message])

  return [canReply, handleReply]
}

export const useForwardMessage = (message: MessageModel): () => any => {
  const handleForward = useCallback(() => {
    if(!message) return

    chatsStore.forwardingMessage = message
  }, [message])

  return handleForward
}

export const useCopyMessage = (message: MessageModel): () => any => {
  const { t } = useTranslation('chat/message')

  return useCallback(() => {
    if(!message) {
      return
    }

    if(isTextMessage(message)) {
      const selection = getSelectedText()

      if(selection?.length) {
        copy(selection)
      } else {
        copy(message.decodedContent.content.text)
      }

      toastSuccess(t('action_toasts.copied'))
    }
  }, [message])
}

export const useEditMessage = (message: MessageModel): [boolean, () => any] => {
  const canEdit = useMemo(() => {
    const chat = message?.chat

    if(!chat || isForwardMessage(message)) return false

    const minutesFromSendLeft = Math.abs(dayjs(message.created_at).diff(getNow(), 'minutes'))


    switch(chat.type) {
      case ChatType.CHANNEL:
        return [PcpType.ADMIN, PcpType.OWNER].includes(chat.own_pcp.type)
          && !isForwardMessage(message)
      case ChatType.SELF_CHAT:
        return true
      case ChatType.GROUP_CHAT:
        return message?.isOwnMessage && minutesFromSendLeft < 15
    }

    return message?.isOwnMessage && minutesFromSendLeft < 15
  }, [message])

  const handleEdit = useCallback(() => {
    if(!message || !chatsStore.activeChatId) {
      return
    }

    chatsStore.setEditingMessage(chatsStore.activeChatId, message)
  }, [message])

  return [canEdit, handleEdit]
}

export const useDeleteMessage = (message: MessageModel): [boolean, () => any] => {
  const { confirm, close } = useConfirmation()
  const { t } = useTranslation('chat/message')

  const canDelete = (() => {
    const chat = message?.chat

    if(!chat) return false

    switch(chat.type) {
      case ChatType.CHANNEL:
        return [PcpType.ADMIN, PcpType.OWNER].includes(chat.own_pcp.type)
      case ChatType.SELF_CHAT:
        return true
      case ChatType.GROUP_CHAT:
        if([PcpType.ADMIN, PcpType.OWNER].includes(chat.own_pcp.type)) {
          return true
        }
    }

    const minutesFromSendLeft = Math.abs(dayjs(message.created_at).diff(getNow(), 'minutes'))

    return message?.isOwnMessage && minutesFromSendLeft < 15
  })()

  const handleDelete = useCallback(() => {
    if(!message?.id) {
      return
    }

    confirm({
      title: t('delete_message_confirmation.title'),
      content: t('delete_message_confirmation.default_description'),
      actions: (
        <>
          <DeleteMessageButton
            message={message}
            onFinish={close}
          />

          <Button
            color={Color.primary}
            onClick={close}
          >
            {t('delete_message_confirmation.cancel')}
          </Button>
        </>
      )
    })
  }, [message])

  return [canDelete, handleDelete]
}

function DeleteMessageButton({
  message,
  onFinish
}: {
  message: MessageModel
  onFinish: () => void
}) {
  const { t } = useTranslation('chat/message')

  const [isDeleting, setIsDeleting]=  useState(false)

  function handleClick() {
    if(!message) {
      onFinish()
    }

    setIsDeleting(true)

    chatsService.deleteMessage(message)
      .then(() => toast({
        message: t('action_toasts.deleted'),
        icon: 'trashed'
      }))
      .catch(({ response }) => {
        if(response?.data?.detail) {
          return toastError(response?.data?.detail)
        }

        toastWarning(t('errors:insufficient_request'))
      })
      .finally(onFinish)
  }

  return (
    <Loadable loading={isDeleting}>
      <Button
        color={Color.muted}
        onClick={handleClick}
      >
        {t('delete_message_confirmation.confirm')}
      </Button>
    </Loadable>
  )
}
