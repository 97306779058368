import Autolinker from 'autolinker'

export function formattedTextToHtml(text: string): string {
  text = Autolinker.link(text, { newWindow: true, sanitizeHtml: true })

  text = text.replaceAll(/\n/g, '<br/>')
  text = text.replaceAll(/\r/g, '<br/>')

  return text
}

export function UCFirst(text: string): string {
  return text.slice(0, 1).toUpperCase() + text.slice(1)
}

export function commaSeparatedNumber(x: number): string {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function omitLeadingZeros(v: string): string {
  return v.replaceAll(/^0+/g, '')
}

export function omitTrailingZeros(v: string): string {
  return v.replaceAll(/0+$/g, '')
}

export function numberToShortHumanReadable(number: number): string {
  const THOUSAND = 1000
  const MILLION = THOUSAND * THOUSAND

  let leftNum = number
  let letter = ''

  if(number > MILLION) {
    leftNum /= MILLION
    letter = 'm'
  } else if(number > THOUSAND) {
    leftNum /= THOUSAND
    letter = 'k'
  }

  const totalNum = Number(leftNum.toFixed(1))
    .toLocaleString('en')

  return totalNum
    .replaceAll(',', '.')
    + letter
}
