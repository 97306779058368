export function getFlagUrl(alpha2: string ) {
  if(!alpha2?.length) {
    alpha2 = 'plsh'
  }

  return getStaticCdnFilePath(`/flags/${alpha2.toLowerCase()}.png`)
}

export function getStaticCdnFilePath(path: string) {
  return process.env.REACT_APP_CDN_URL + '/' + path.replace(/^\//, '')
}
