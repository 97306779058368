import { BROADCAST_MESSAGES, CLIENT_MESSAGES } from '@/config/workers'
import { workersStore } from '@/store/workers/workers.store'
import { SharedWorkerSupported } from '@/workers/app-worker/app-worker.client'
import { appWorker } from '@/workers/app-worker/useAppWorker'

export async function broadcastTabsMessage(type: BROADCAST_MESSAGES, body: unknown) {
  return broadcastTabsMessageViaSharedWorker(type, body)
    || broadcastTabsMessageViaSW(type, body)
}

export async function broadcastTabsMessageViaSharedWorker(type: BROADCAST_MESSAGES, body: unknown): Promise<boolean> {
  if(!appWorker || !SharedWorkerSupported) {
   return false
  }

  appWorker?.postMessage({
    type: CLIENT_MESSAGES.BROADCAST,
    body: { type, body }
  })

  return true
}

export async function broadcastTabsMessageViaSW(type: BROADCAST_MESSAGES, body: unknown): Promise<boolean> {
  const reg = await navigator.serviceWorker.getRegistration()

  if(!reg || !reg.active) {
    return false
  }

  workersStore.swQueue.addTask({
    type: CLIENT_MESSAGES.BROADCAST,
    body: { type, body }
  })

  // reg.active.postMessage({
  //   type: CLIENT_MESSAGES.BROADCAST,
  //   body: { type, body }
  // })

  return true
}
