interface Props {
  size?: number
  color?: string
}

export const Inventory = ({ size = 16, color = '#8E8E93' }: Props) => {
  return (
    <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.33317 13.1333V5.85C2.15539 5.76111 1.99984 5.62489 1.8665 5.44133C1.73317 5.25822 1.6665 5.04444 1.6665 4.8V3.2C1.6665 2.86667 1.78317 2.58333 2.0165 2.35C2.24984 2.11667 2.53317 2 2.8665 2H13.1332C13.4665 2 13.7498 2.11667 13.9832 2.35C14.2165 2.58333 14.3332 2.86667 14.3332 3.2V4.8C14.3332 5.04444 14.2665 5.25822 14.1332 5.44133C13.9998 5.62489 13.8443 5.76111 13.6665 5.85V13.1333C13.6665 13.4667 13.5498 13.75 13.3165 13.9833C13.0832 14.2167 12.7998 14.3333 12.4665 14.3333H3.53317C3.19984 14.3333 2.9165 14.2167 2.68317 13.9833C2.44984 13.75 2.33317 13.4667 2.33317 13.1333ZM3.33317 6V13.1333C3.33317 13.1889 3.35273 13.2362 3.39184 13.2753C3.4305 13.314 3.47761 13.3333 3.53317 13.3333H12.4665C12.5221 13.3333 12.5694 13.314 12.6085 13.2753C12.6472 13.2362 12.6665 13.1889 12.6665 13.1333V6H3.33317ZM13.1332 5C13.1887 5 13.2361 4.98044 13.2752 4.94133C13.3138 4.90267 13.3332 4.85556 13.3332 4.8V3.2C13.3332 3.14444 13.3138 3.09711 13.2752 3.058C13.2361 3.01933 13.1887 3 13.1332 3H2.8665C2.81095 3 2.76384 3.01933 2.72517 3.058C2.68606 3.09711 2.6665 3.14444 2.6665 3.2V4.8C2.6665 4.85556 2.68606 4.90267 2.72517 4.94133C2.76384 4.98044 2.81095 5 2.8665 5H13.1332ZM6.13317 9.13333H9.8665V8.13333H6.13317V9.13333Z'
        fill={color}
      />
    </svg>
  )
}
