import { chatsService } from '@/store/chats/chats.service'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { lazy } from 'react'
import { RouteObject, useLocation, useNavigate } from 'react-router'
import ContactsViewAside from '@/components/contactList/ContactsViewAside'
import Fallback from '@/pages/Fallback'
import { CompanyPage } from '@/pages/home/companies/View/CompanyPage'
import { Home } from '@/pages/home/Home'
import CompanyView from '@/pages/modals/CompanyView'
import OfferMatches from '@/pages/modals/OfferMatches'
import OfferView from '@/pages/modals/OfferView'
import ProfileView from '@/pages/modals/ProfileView'
import Logout from '@/pages/Logout'

const CompanyPageUsers = lazy(() => import('@/pages/home/companies/View/users/CompanyPageUsers'))
const CompanyList = lazy(() => import('@/pages/home/companies/AllCompanies/AllCompanies'))
const CompanyPageMain = lazy(() => import('@/pages/home/companies/View/main/CompanyPageMain'))
const CompanyPageProfile = lazy(() => import('@/pages/home/companies/View/profile/CompanyPageProfile'))

const MyOffers = lazy(() => import('@/pages/home/offers/MyOffers/MyOffers'))
const PublicExchange = lazy(() => import('@/pages/home/offers/PublicExchange/PublicExchange'))

const AuthMethods = lazy(() => import('@/pages/home/profile/AuthMethods/AuthMethods'))
const BlackList = lazy(() => import('@/pages/home/profile/BlackList/BlackList'))
const EditProfile = lazy(() => import('@/pages/home/profile/EditProfile/EditProfile'))
const FillProfile = lazy(() => import('@/pages/home/profile/FillProfile/FillProfile'))
const MyProfile = lazy(() => import('@/pages/home/profile/MyProfile/MyProfile'))

import Test from '@/pages/home/Test'
import { generatePath } from 'react-router-dom'
// const Test = lazy(() => import('@/pages/home/Test'))


/**
 * Regular page routes declarations
 */

export interface ExtendedRoute extends RouteObject {
  name?: string,

  children?: ExtendedRoute[]
}

export const ROUTE_NAMES = {
  HOME: 'home',

  LOGOUT: 'logout',

  FILL_PROFILE: 'fill_profile',
  EDIT_PROFILE: 'edit_profile',
  MY_PROFILE: 'my_profile',
  MY_PROFILE_AUTH_METHODS: 'my_profile.auth_methods',
  PROFILE_VIEW: 'profile_view',
  CONTACT_LIST: 'contact_list',

  BLACK_LIST: 'black_list',
  PUBLIC_EXCHANGE: 'exchange',
  MY_OFFERS: 'offers.my',
  OFFER_VIEW: 'offers.view',
  OFFER_MATCHES: 'offer.matches',

  COMPANIES: 'companies',

  AUTH: 'auth',

  NOT_FOUND: 'not_found',

  COMPANY_PAGE: 'company',
  COMPANY_PAGE_MAIN: 'company.main',
  COMPANY_PAGE_PROFILE: 'company.profile',
  COMPANY_PAGE_USERS: 'company.users',

  COMPANY_VIEW: 'company.view'
}

export const ROUTE_MODAL_PATHS = {
  [ROUTE_NAMES.OFFER_VIEW]: 'offers/:id'
}

// export function resolveRouteModalPath(name: string) {
//   const map = {
//     [ROUTE_NAMES.OFFER_VIEW]: 'offers/:id'
//   }
// }

// export const modalRoutes: {[key: string]: string} = {
//   [ROUTE_NAMES.OFFER_VIEW]: '/offers/:offer_id'
// }

export const routes: ExtendedRoute[] = [
  {
    path: '/',
    name: ROUTE_NAMES.HOME,
    element: <Home/>,
    children: [
      {
        path: '/me/fill',
        name: ROUTE_NAMES.FILL_PROFILE,
        element: <FillProfile/>
      },
      {
        path: '/me/profile/edit',
        name: ROUTE_NAMES.EDIT_PROFILE,
        element: <EditProfile/>
      },
      {
        path: '/me/profile',
        name: ROUTE_NAMES.MY_PROFILE,
        element: <MyProfile/>
      },
      {
        path: '/me/profile/black_list',
        name: ROUTE_NAMES.BLACK_LIST,
        element: <BlackList/>
      },
      {
        path: '/me/profile/auth',
        name: ROUTE_NAMES.MY_PROFILE_AUTH_METHODS,
        element: <AuthMethods/>
      },

      {
        path: '/exchange',
        name: ROUTE_NAMES.PUBLIC_EXCHANGE,
        element: <PublicExchange/>
      },
      {
        path: '/me/offers',
        name: ROUTE_NAMES.MY_OFFERS,
        element: <MyOffers/>
      },
      {
        path: '/logistics-companies',
        name: ROUTE_NAMES.COMPANIES,
        element: <CompanyList/>
      },
/*      {
        path: '/company_invitation/accept/:hash',
        name: ROUTE_NAMES.COMPANY_INVITATION_ACCEPT,
        element: <Hash/>
      },
      {
        path: '/company_invitation/decline/:hash',
        name: ROUTE_NAMES.COMPANY_INVITATION_DECLINE,
        element: <Hash/>
      },*/
      {
        name: ROUTE_NAMES.NOT_FOUND,
        path: '*',
        element: <Fallback/>
      }
    ]
  },
  {
    path: '/company-admin/:company_id',
    name: ROUTE_NAMES.COMPANY_PAGE,
    element: <CompanyPage/>,
    children: [
      {
        path: 'main',
        name: ROUTE_NAMES.COMPANY_PAGE_MAIN,
        element: <CompanyPageMain/>
      },
      {
        path: 'profile',
        name: ROUTE_NAMES.COMPANY_PAGE_PROFILE,
        element: <CompanyPageProfile/>
      },
      {
        path: 'users',
        name: ROUTE_NAMES.COMPANY_PAGE_USERS,
        element: <CompanyPageUsers/>
      }
    ]
  },
  {
    path: '/logout',
    name: ROUTE_NAMES.LOGOUT,
    element: <Logout/>
  }
]

export const modalRoutes: ExtendedRoute[] = [
  {
    path: '/offer/:offer_id',
    name: ROUTE_NAMES.OFFER_VIEW,
    element: <OfferView/>
  },
  {
    path: '/offer/:offer_id/matches',
    name: ROUTE_NAMES.OFFER_MATCHES,
    element: <OfferMatches/>
  },
  {
    path: '/u/:profile_id',
    name: ROUTE_NAMES.PROFILE_VIEW,
    element: <ProfileView/>
  },
  {
    path: '/logistics-companies/:company_id',
    name: ROUTE_NAMES.COMPANY_VIEW,
    element: <CompanyView/>
  },
  {
    path: '/contacts',
    name: ROUTE_NAMES.CONTACT_LIST,
    element: <ContactsViewAside/>
  },
  // {
  // path: '/profile/:profile_id',
  // name: ROUTE_NAMES.PROFILE_VIEW,
  // element: <ProfileView/>
  // },
]

/**
 * Map, where key is route name, and value is absolute path to this route
 */
export const NAME_PATH_MAP: { [name: string]: string } = {}

/**
 * Index name and paths of routes on start of app
 *
 * @param routes
 * @param parentPath
 */
function recursiveScanRoutesObject(routes: ExtendedRoute[], parentPath = ''): void {
  routes.forEach((route: ExtendedRoute) => {
    // TODO try to delete it and configure this check in linter for routes object
    if(route.path === undefined) {
      throw new Error('Route can`t have no path')
    }

    let currentPath = parentPath + '/' + route.path.replace(/^\//, '')
    currentPath = currentPath.replaceAll('//', '/')

    if(route.name !== undefined) {
      NAME_PATH_MAP[route.name] = currentPath
    }

    if(Array.isArray(route.children)) {
      recursiveScanRoutesObject(route.children, currentPath)
    }
  })
}

recursiveScanRoutesObject(routes)
recursiveScanRoutesObject(modalRoutes)

interface searchParams {
  name: string
}

export function generatePathByName(name: string, params?: any) {
  return generatePath(resolvePathByName({ name }), params)
}

export function resolvePathByName(params: searchParams | string): string {
  if(typeof params === 'string') {
    params = {
      name: params
    }
  }

  if(NAME_PATH_MAP[params.name] === undefined) {
    throw new Error(`Route with name ${params.name} not found`)
  }

  return NAME_PATH_MAP[params.name]
}

// export function resolveNameByPath(path: string): string {
//
// }

export function useNavigateToModal() {
  const navigate = useNavigate()
  const location = useLocation()


  return (pathname: string, params?: {
    replace: boolean
  }) => {
    if(location.pathname === pathname) {
      return
    }


    // TODO CRUNCH, REMOVE THIS ASAP
    if(IS_MOBILE) {
      chatsService.setActiveChat(null)
    }

    return navigate(pathname, {
      ...params,
      state: {
        backgroundLocation: location?.state?.backgroundLocation ?? location
      }
    })
  }
}
