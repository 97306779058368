import { makeAutoObservable } from 'mobx'
import { ReactNode } from 'react'

class CompanyStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _dialog: ReactNode | null = null
  get dialog(): ReactNode | null {
    return <ReactNode | null>this._dialog
  }
  set dialog(value: ReactNode | null) {
    this._dialog = value
  }
}

const companyStore = new CompanyStore()

export {
  companyStore
}
