export function isElementAboveViewport(el: HTMLElement, viewport: HTMLElement, params?: {
  partial: boolean
}) {
  const elTop = getElementOffsetRelativeTo(el, viewport)
  if(params?.partial) {
    return elTop < viewport.scrollTop
  }

  const elBottom = elTop + el.clientHeight

  return elBottom <= viewport.scrollTop
}

export function isElementInViewport(message: HTMLElement, outerContainer: HTMLElement) {
  const viewportTop = outerContainer.scrollTop
  const viewportBottom = outerContainer.scrollTop + outerContainer.clientHeight

  const messageTop = getElementOffsetRelativeTo(message, outerContainer)
  const messageBottom = messageTop + message.clientHeight

  return messageTop >= viewportTop && messageBottom <= viewportBottom
}

export function getElementOffsetRelativeTo(el: HTMLElement, viewport: HTMLElement) {
  let offset = 0

  let currEl: HTMLElement = el
  while(currEl.offsetParent && ![viewport, document].includes(currEl as HTMLElement)) {
    offset += currEl.offsetTop

    currEl = currEl.offsetParent as HTMLElement
  }

  return offset
}
