import { persistAndSyncStore } from '@/utils/mobx'
import { makeAutoObservable } from 'mobx'

class NotificationsStore {
  audioNotifications = true

  constructor() {
    makeAutoObservable(this)
  }
}

const notificationsStore = persistAndSyncStore(new NotificationsStore(), 'notifications')

export {
  NotificationsStore,
  notificationsStore
}
