import { db, DEXIE_STORES } from '@/database'
import { MessageModelFactory } from '@/models/Message.model'
import { getChat, patchChat } from '@/repositories/chats.repository'
import {
  addOrUpdateMessages,
  patchMessageByClientMessageId,
  patchMessageInChatByNumber
} from '@/repositories/messages.repository'
import { getOwnPcp, updateOwnPcp } from '@/repositories/own_pcp.repository'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { debugStore } from '@/store/debug/debug.store'
import { profilesService } from '@/store/profiles/profiles.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { wsStore } from '@/store/ws/ws.store'
import { isSystemMessage } from '@/types/models/chat'
import { ChatType, MessageStatus, PcpChatState } from '@roolz/types/api/chats'
import {
  ChatModelUpdatePackage,
  ChatUpdatePackage,
  ChatUserEventPackage,
  DeleteMessagePackage,
  IncomingPackageType,
  isOnlinePackage,
  MessageStatusChangePackage,
  NewMessagePackage, OutgoingPackageType,
  SendMessageErrorPackage,
  UserEventPackage
} from '@roolz/types/ws/packages'
import { once, uniq } from 'lodash'
import { runInAction } from 'mobx'
// @ts-ignore
import { v4 as uuidv4 } from 'uuid'
import { Message } from "@roolz/types/api/chats"

class WSService {
  init = once(() => {
    // wsStore.connect()

    wsStore.subscribe(IncomingPackageType.SendMessageError, this.handleSendMessageError.bind(this))
    wsStore.subscribe(IncomingPackageType.NewMessage, this.handleNewMessage.bind(this))
    wsStore.subscribe(IncomingPackageType.UpdateMessage, this.handleUpdateMessage.bind(this))
    wsStore.subscribe(IncomingPackageType.DeleteMessage, this.handleDeleteMessage.bind(this))
    wsStore.subscribe(IncomingPackageType.ChatUserEvent, this.handleChatUserEvent.bind(this))
    wsStore.subscribe(IncomingPackageType.UserEvent, this.handleUserEvent.bind(this))
    wsStore.subscribe(IncomingPackageType.MessageStatusChange, this.handleMessageStatusChange.bind(this))
    wsStore.subscribe(IncomingPackageType.UpdateChat, this.handleUpdateChat.bind(this))
    wsStore.subscribe(IncomingPackageType.ChatModelUpdate, this.handleChatModelUpdate.bind(this))
  })

  sendPackage(type: OutgoingPackageType, body: string | object) {
    if(typeof body === 'object') {
      body = JSON.stringify(body)
    }

    try {
      const pkg = {
        id: uuidv4(),
        type,
        body,
        created_at: new Date()
      }

      db[DEXIE_STORES.SOCKET_QUEUE].add(pkg)

      debugStore.logSocketReq('out', { type, body })
    } catch(e) {
      console.error('cant add msg to queue', e)
    }

    wsStore.sentCallback?.()
  }


  handleUpdateChat(data: ChatUpdatePackage) {
    if(!data.chat_id) {
      return
    }

    const existingChat = chatsStore.getChat(data.chat_id)
    if(!existingChat || !existingChat.own_pcp) {
      chatsService.loadNewChatsData()
    } else if(chatsStore.getOwnPcp(data.chat_id)) {
      updateOwnPcp(data.chat_id, data)
    }
  }

  handleChatModelUpdate(data: ChatModelUpdatePackage) {
    // const chatModel = ChatModelFactory(data)
    //
    // const existingChat = chatsStore.getChat(data.id)
    // if(existingChat && existingChat.own_pcp) {
    //   chatsStore.addOrUpdateChats([chatModel])
    // } else {
    //   chatsService.loadNewChatsData()
    //   // chatsService.loadChat(data.id)
    // }
  }

  handleMessageStatusChange(data: MessageStatusChangePackage) {
    // chatsStore.updateMessage(data.chat_id, data.client_message_id, {
    //   number: data.message_number,
    //   status: data.message_status
    // })
    patchMessageByClientMessageId(data.chat_id, data.client_message_id, {
      number: data.message_number,
      status: data.message_status
    })

    const chat = chatsStore.getChat(data.chat_id)
    // const message = chatsStore.getChatMessageByNumber(data.chat_id, data.message_number)

    if(chat && chat?.type === ChatType.DIALOG && chat.companionId
      && data.sender_id === profilesStore.my_profile?.id
      && data.message_status >= MessageStatus.READ
    ) {
      profilesService.topUpProfileLastAction(chat.companionId)
    }
    // if(
    //   data.message_status === MessageStatus.READ && chat) {
    //   chatsStore.updateChatOwnPcp(data.chat_id, {
    //     last_read_message_index: Math.max(chat.own_pcp.last_read_message_index, data.message_number)
    //   })
    // }

    // if(data.message_status === MessageStatus.READ) {
    //   chatsStore.updateChat(data.chat_id, {
    //     last_read_messageindex
    //   })
    // }

  }

  handleSendMessageError(data: SendMessageErrorPackage) {
    if(!data.message) {
      return
    }

    const { chat_id, client_message_id } = data.message

    if(chat_id && client_message_id) {
      patchMessageByClientMessageId(chat_id, client_message_id, {
        status: MessageStatus.ERROR
      })
    }
    // TODO probably check particular error types and do something according to it
  }

  async handleNewMessage(data: NewMessagePackage) {
    const messageModel = MessageModelFactory(data)
    const chat = chatsStore.getChat(data.chat_id)
    const ownPcp = chatsStore.getOwnPcp(data.chat_id)
    if(!chat || !ownPcp) {
      chatsService.loadChat(data.chat_id)
    }

    if(!messageModel.owner) {
      await profilesService.loadProfile(messageModel.sender_id)
    }

    if(chat?.type === ChatType.DIALOG && !chat?.companion) {
      const ids = chat.id.split(':')
      const companionId = ids.find(id => id !== profilesStore.my_profile?.id)

      if(!companionId) {
        return
      }

      await profilesService.loadProfile(companionId)
    }

    if(messageModel.chat && messageModel.isOwnMessage) {
      updateOwnPcp(messageModel.chat_id, {
        last_read_message_index: Math.max(messageModel?.chat?.own_pcp.last_read_message_index, messageModel.number)
      })
    }
    //MOVED TO CHATS STORE
    // if(chat?.type !== ChatType.SELF_CHAT
    //   && messageModel.status === MessageStatus.SENT
    //   && messageModel.sender_id !== profilesStore.my_profile?.id
    // ) {
    //   chatsService.updateMessageStatus({
    //     chat_id: messageModel.chat_id,
    //     client_message_id: messageModel.client_message_id,
    //     message_number: messageModel.number,
    //     sender_id: messageModel.sender_id
    //   }, MessageStatus.DELIVERED)
    // }

    runInAction(() => {
      // TODO Check if it's message type, otherwise remove user Audio message recording, etc...
      chatSessionsStore.removeChatUserTyping(data.chat_id, data.sender_id)

      const chat = messageModel.chat

      const isSelfSystemMessage = isSystemMessage(messageModel)
        && messageModel.isOwnMessage

      const isNewMessage = !chat?.sentMessages.length
        || !!chat?.last_message?.number && messageModel.number > chat?.last_message?.number

      // console.log('BBB', messageModel.chat?.last_message?.number, isSelfSystemMessage, isNewMessage)
      addOrUpdateMessages([data], {
        incrementChatMessagesCountIfNew: !isSelfSystemMessage && isNewMessage
      })
      profilesService.topUpProfileLastAction(data.sender_id)
    })


    if(ownPcp?.chat_state === PcpChatState.READ) {
      chatsService.updateChatState(ownPcp.chat_id, PcpChatState.NORMAL)
    }
  }

  handleChatUserEvent(data: ChatUserEventPackage) {
    runInAction(() => {
      // chatsStore.chatActions = {...chatsStore.chatActions}
      chatSessionsStore.chatActions[data.chat_id] ??= []
      chatSessionsStore.chatActions[data.chat_id].push({
        received_at: Date.now(),
        ...data
      })

      profilesService.topUpProfileLastAction(data.user_id)
    })
  }

  handleUserEvent(data: UserEventPackage) {
    try {
      if(isOnlinePackage(data)) {
        profilesService.topUpProfileLastAction(data.user_id)
      }
    } catch(e) {
      console.log(e)
    }
  }

  async handleDeleteMessage(data: DeleteMessagePackage) {
    let needUpdateChat = false
    const chat = await getChat(data.chat_id)

    Object.entries(data.deleted_messages_state).forEach(([number, state]) => {
      patchMessageInChatByNumber(data.chat_id, Number(number), { state } as any)

      if(chat && !chat?.deleted_message_numbers.includes(Number(number))) {
        needUpdateChat = true
      }
    })

    if(chat && needUpdateChat) {
      patchChat(chat.id, {
        deleted_message_numbers: uniq([
          ...chat.deleted_message_numbers,
          ...Object.keys(data.deleted_messages_state).map(Number)
        ])
      })
    }
  }

  handleUpdateMessage(data: Message) {
    // TODO Check if it's message type, otherwise remove user Audio message recording, etc...
    chatSessionsStore.removeChatUserTyping(data.chat_id, data.sender_id)

    if(data.status === MessageStatus.SENT
      && data.sender_id !== profilesStore.my_profile?.id
    ) {
      chatsService.updateMessageStatus({
        chat_id: data.chat_id,
        client_message_id: data.client_message_id,
        message_number: data.number,
        sender_id: data.sender_id
      }, MessageStatus.DELIVERED)

      data.status = MessageStatus.DELIVERED
    }

    delete data.status

    patchMessageByClientMessageId(data.chat_id, data.client_message_id, data)

    // Object.entries(data.deleted_messages_state).forEach(([number, state]) => {
    //   patchMessageInChatByNumber(data.chat_id, Number(number), { state } as any)
    // })
  }
}

const wsService = new WSService()

export {
  wsService
}
