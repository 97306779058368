import { companiesClient } from '@/api/clients/companies.client'
import { Loadable } from '@/components/ui/Loadable/Loadable'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesService } from '@/store/profiles/profiles.service'
import { getNow } from '@/utils/date'
import { CompanyFormSlug } from '@roolz/types/api/knowledge/companies'
import { InviteMembersAppeal } from '@/components/modals/InviteMembersAppeal/InviteMembersAppeal'
import { companiesStore } from '@/store/companies/companies.store'
import { companyMembersStore } from '@/store/companyMembers/companyMembers.store'
import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { companiesService } from '@/store/companies/companies.service'
import { companyStore } from '@/store/companies/company.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { CheckCircleFill } from '@roolz/icons/CheckCircleFill'
import { Close } from '@roolz/icons/Close'
import { CompanyAndOrganization } from '@roolz/icons/companies/CompanyAndOrganization'
import { CompanyProfile } from '@roolz/icons/companies/CompanyProfile'
import { GroupsAndDepartments } from '@roolz/icons/companies/GroupsAndDepartments'
import { UsersIcon } from '@roolz/icons/companies/Users'
import { HourglassEmpty } from '@roolz/icons/HourglassEmpty'
import { Menu } from '@roolz/icons/Menu'
import { RunningWithErrors } from '@roolz/icons/RunningWithErrors'
import { ArrowRightAlt } from '@roolz/icons/sidebar/ArrowRightAlt'
import { toastError } from '@roolz/sdk/components/snackbars'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { Company, Roles } from '@roolz/types/api/companies'
import cn from 'classnames'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styles from './CompanyLayout.module.scss'

type LogoProps = {
  name?: string
  color?: string
  logoUrl?: string | null
}

const MobileLogo = ({
  name,
  color,
  logoUrl
}: LogoProps) => {
  return (
    <Avatar
      avatarUrl={logoUrl ?? ''}
      first_name={name}
      color_code={color}
      width={32}
      type='company'
    />
  )
}

const Logo = ({
  name,
  color,
  logoUrl
}: LogoProps) => {
  return (
    <div className={styles.sidebar__company}>
      <Avatar
        className={styles.sidebar__companyAvatar}
        avatarUrl={logoUrl ?? ''}
        first_name={name}
        color_code={color}
        width={48}
        type='company'
      />
      <p className={styles.sidebar__logoText}>
        {name}
      </p>
    </div>
  )
}

type HeaderProps = {
  menuClick: () => void
  isShowSidebarMobile: boolean
  company: Company
}

const Header = ({
  menuClick,
  isShowSidebarMobile,
  company
}: HeaderProps) => {
  return (
    <header className={styles.header}>
      <div className={styles.header__menu} onClick={menuClick}>
        {isShowSidebarMobile ? <Close/> : <Menu/>}
      </div>
      <MobileLogo name={company.name} color={company.color} logoUrl={company.logo_path} />
    </header>
  )
}

type SidebarProps = {
  isShowMobile: boolean
  setIsShowSidebar: (value: boolean) => void
  company: Company
}

const Sidebar = observer(function Sidebar({
  isShowMobile,
  setIsShowSidebar,
  company
}: SidebarProps) {
  const { t } = useTranslation('company/admin')

  const companyId = company.id
  const pending = companyMembersStore.statuses.with_status_pending.total_count
  const wait = companyMembersStore.statuses.with_status_await.total_count
  const errors = companyMembersStore.statuses.with_status_email_errors.total_count
  const isShowStatuses = pending + wait + errors > 0

  return (
    <div
      className={cn(styles.sidebar, {
        [styles.sidebar_showMobile]: isShowMobile
      })}
    >
      <Link className={styles.sidebar__back} to={resolvePathByName(ROUTE_NAMES.MY_OFFERS)}>
        <div className={styles.sidebar__backIcon}>
          <ArrowRightAlt />
        </div>
        <span>
          {t('layout.back')}
        </span>
      </Link>
      {!isShowMobile &&
        <Logo name={company.name} color={company.color} logoUrl={company.logo_path} />
      }
      <div className={styles.sidebar__list}>
        <SidebarMenuItem
          label={t('layout.sidebar.company_and_organization')}
          prepend={<CompanyAndOrganization/>}
          to={resolvePathByName(ROUTE_NAMES.COMPANY_PAGE_MAIN).replace(':company_id', companyId)}
          onClick={() => setIsShowSidebar(false)}
        />
        <SidebarMenuItem
          label={t('layout.sidebar.company_profile')}
          prepend={<CompanyProfile/>}
          to={resolvePathByName(ROUTE_NAMES.COMPANY_PAGE_PROFILE).replace(':company_id', companyId)}
          onClick={() => setIsShowSidebar(false)}
        />
        <SidebarMenuItem
          label={t('layout.sidebar.company_users')}
          prepend={<UsersIcon/>}
          to={resolvePathByName(ROUTE_NAMES.COMPANY_PAGE_USERS).replace(':company_id', companyId)}
          onClick={() => setIsShowSidebar(false)}
        >
          {isShowStatuses &&
            <div className={styles.menuItem__statuses}>
              {!!pending &&
                <div className={styles.menuItem__status}>
                  <CheckCircleFill />
                  <span className={styles.menuItem__statusPending}>
                    { pending }
                  </span>
                </div>
              }
              {!!wait &&
                <div className={styles.menuItem__status}>
                  <HourglassEmpty />
                  <span className={styles.menuItem__statusAwait}>
                    { wait }
                  </span>
                </div>
              }
              {!!errors &&
                <div className={styles.menuItem__status}>
                  <RunningWithErrors />
                  <span className={styles.menuItem__statusError}>
                    { errors }
                  </span>
                </div>
              }
            </div>
          }
        </SidebarMenuItem>
        <SidebarMenuItem
          label={t('layout.sidebar.groups_and_departments')}
          prepend={<GroupsAndDepartments color='#c8cbde'/>}
          to={resolvePathByName(ROUTE_NAMES.PUBLIC_EXCHANGE)}
          disabled
        />
      </div>
    </div>
  )
})

interface SidebarMenuItemProps {
  label: string
  prepend?: ReactNode
  to: string,
  disabled?: boolean
  children?: ReactNode

  [key: string]: any
}

export const SidebarMenuItem = ({
  label,
  prepend,
  to,
  disabled = false,
  children,
  ...rest
}: SidebarMenuItemProps) => {
  const { pathname } = useLocation()
  const isActive = useMemo(() =>
    !disabled && new RegExp(`^${to}(/.*)?`).test(pathname)
  , [pathname])

  if (disabled) {
    return (
      <div
        {...rest}
        className={cn(styles.menuItem, {
          [styles.menuItemDisabled]: disabled,
          [styles.menuItemActive]: isActive
        })}
      >
        <div className={styles.menuItem__text}>
          <div className={styles.menuItem__icon}>
            {prepend}
          </div>
          <span className={styles.menuItem__label}>
          {label}
        </span>
        </div>
        { isActive && children }
      </div>
    )
  }

  return (
    <Link
      to={to}
      {...rest}
      className={cn(styles.menuItem, {
        [styles.menuItemDisabled]: disabled,
        [styles.menuItemActive]: isActive
      })}
    >
      <div className={styles.menuItem__text}>
        <div className={styles.menuItem__icon}>
          {prepend}
        </div>

        <span className={styles.menuItem__label}>
          {label}
        </span>
      </div>
      { isActive && children }
    </Link>
  )
}

const COMPANY_OWNERS_COUNT = 1
const INVITE_MODAL_INTERVAL_MINUTES = 30

interface Props {
  children: ReactNode
}

const CompanyLayout = observer(function CompanyLayout({
  children
}: Props) {
  const { pathname } = useLocation()
  const params = useParams()
  const companyId = params?.company_id
  const [isShowSidebarMobile, setIsShowSidebarMobile] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const { t: errorsT } = useTranslation('errors')
  const navigate = useNavigate()
  const dialog = companyStore.dialog
  const company = companiesStore.myCompany

  if (!companyId) {
    return null
  }

  useEffect(() => {
    chatsService.setActiveChat(null)
  }, [])

  const storageItemKey = `invite_modal_${companyId}`

  const handleCloseModal = useCallback(() => {
    localStorage.setItem(storageItemKey, dayjs(getNow()).toString())
    setIsShowModal(false)
  }, [])

  const checkShouldShowInviteModal = async () => {
    const isCompanyPublicForm = !!company?.company_form && CompanyFormSlug.self_employed !== company.company_form

    const prevShowModalDate = localStorage.getItem(storageItemKey)
    const minutes = dayjs(getNow()).diff(prevShowModalDate, 'minutes')

    if (!isCompanyPublicForm || minutes < INVITE_MODAL_INTERVAL_MINUTES) {
      return
    }

    try {
      const { data: { total_count } } = await companiesClient.getCompanyMembers({
        companyId,
        limit: 1
      })
      const hasMembers = total_count > COMPANY_OWNERS_COUNT

      if (!hasMembers) {
        setIsShowModal(true)
      }
    } catch(e: any) {
      toastError(e.response?.data?.detail ?? errorsT('insufficient_request'))
    }
  }

  useEffect(() => {
    if (!company) {
      return
    }

    checkShouldShowInviteModal()
  }, [company])

  const checkAccess = () => {
    const profile = profilesStore.my_profile
    const myRole = (profile?.companies || []).find(({ id }) => id === companyId)?.my_role
    const isAccessAllowed = myRole && [Roles.owner, Roles.admin].includes(myRole)

    if (!isAccessAllowed) {
      navigate('/', { replace: true })
    }

    return isAccessAllowed
  }

  const loadMyCompany = async () => {
    try {
      await companiesService.loadMyCompany({
        id: companyId
      })
    } catch(e: any) {
      toastError(e?.response?.data?.detail ?? errorsT('insufficient_request'))
      if (e?.response?.data?.detail.trim() === 'Company not exists.') {
        await Promise.all([
          companiesService.loadMyCompanies(),
          profilesService.retrieveMyProfile()
        ])
        navigate(resolvePathByName(ROUTE_NAMES.PUBLIC_EXCHANGE))
      }
    }
  }

  useEffect(() => () => {
    companiesStore.myCompany = null
    companiesStore.isMyCompanyLoading = false
  }, [])

  useEffect(() => {
    const access = checkAccess()
    if (!access) {
      return
    }

    if (!company) {
      loadMyCompany()
    }
  }, [companyId, company])

  useEffect(() => {
    companyStore.dialog = null
  }, [pathname])

  if (!company) {
    return <Loadable className={styles.loader} loading={true} />
  }

  return (
    <div className={styles.root}>
      <InviteMembersAppeal open={isShowModal} setOpen={handleCloseModal} />
      <Header
        menuClick={() => setIsShowSidebarMobile(!isShowSidebarMobile)}
        isShowSidebarMobile={isShowSidebarMobile}
        company={company}
      />
      <div className={styles.company}>
        <Sidebar
          isShowMobile={isShowSidebarMobile}
          setIsShowSidebar={setIsShowSidebarMobile}
          company={company}
        />
        {!dialog
          ? <>{children}</>
          : <div className={styles.company__dialog}>
            <main className={styles.company__main}>
              {dialog}
            </main>
          </div>
        }
      </div>
    </div>
  )
})

export default CompanyLayout

