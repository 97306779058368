import { chatsClient } from '@/api'
import { ChatItemView } from '@/components/chats/entities/ChatItemView/ChatItemView'
import { ChatAvatar } from '@/components/chats/shared/chat/ChatAvatar/ChatAvatar'
import { ChatName } from '@/components/chats/shared/chat/ChatName/ChatName'
import { checkIfProfileCanWriteToChat } from '@/components/chats/utils'
import { Dialog } from '@/components/ui/Dialog/Dialog'
import { useNavigateToModal } from '@/config/routes'
import { db, DEXIE_STORES } from '@/database'
import { ChatModelFactory } from '@/models/Chat.model'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { ChatModel } from '@/types/models/chat'
import { ProfileModel } from '@/types/models/profile'
import { matchPhones } from '@/utils/matches'
import { DialogContent, InputAdornment, TextField } from '@mui/material'
import { Close } from '@roolz/icons/Close'
import { PlaceholderSearch } from '@roolz/icons/PlaceholderSearch'
import { Search } from '@roolz/icons/Search'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { Loadable } from '@roolz/sdk/components/ui/Loadable/Loadable'
import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { Chat, ChatType, ContactKind, PcpStatus, SearchChatsResponse, SearchedChatKind } from '@roolz/types/api/chats'
import { debounce, keyBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  forwardRef,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import styles from './SearchChats.module.scss'
import { Backspace } from '@roolz/icons/Backspace'

interface Props {
  open: boolean
  setOpen: (open: boolean) => any

  type?: 'global' | 'local'
  showAllLocalChatsByDefault?: boolean

  title: string,
  onChatSelect: (item: ChatItem) => void
}

type Results = SearchChatsResponse
type LocalResults = {
  chat_list: Chat[]
}


const DEFAULT_RESULTS = (): Results => ({
  chat_list: [],
  contact_list: []
})

export type ChatItem = { type: 'chat', chat: ChatModel }
  | { type: 'profile', profile: ProfileModel }

const TRANSITION_DURATION = 500

export const SearchChats = observer(({
  open,
  setOpen,

  type = 'global',
  showAllLocalChatsByDefault = false,

  title,
  onChatSelect
}: Props) => {
  const { t } = useTranslation('chat/common')

  const inputRef = useRef<any>()

  const [search, setSearch] = useState<string>('')
  const searchRef = useStateRef(search)

  const [results, setResults] = useState<Results>(DEFAULT_RESULTS())
  const [loading, setLoading] = useState<boolean>(false)

  const handleSearchChange = useCallback((value: string) => {
    setSearch(value)
  }, [])

  useLayoutEffect(() => {
    if(!open) {
      return
    }

    if(search?.length >= 1 || showAllLocalChatsByDefault) {
      setLoading(true)

      runSearch(search)
    } else {
      setResults(DEFAULT_RESULTS())
    }
  }, [search, showAllLocalChatsByDefault, open])

  const runSearch = useCallback(debounce((query: string) => {
    type === 'global'
      ? runGlobalSearch(query)
      : runLocalSearch(query)
  }, 500), [type])

  const runGlobalSearch = useCallback((query: string) => {
    return chatsClient.searchChats({ query })
      .then(({ data }) => {
        if(searchRef.current !== query) {
          return
        }
        setResults(data)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        if(searchRef.current !== query) {
          return
        }

        setLoading(false)
      })
  }, [])

  const runLocalSearch = useCallback(async (query: string) => {
    const data = await db.transaction('r', [
      DEXIE_STORES.CHATS,
      DEXIE_STORES.OWN_PCPS,
      DEXIE_STORES.PROFILES
    ], async () => {
      const res = []

      const chats = await db[DEXIE_STORES.CHATS].toArray()
      const ownPcps = await db[DEXIE_STORES.OWN_PCPS].toArray()
      const ownPcpsByChatId = keyBy(ownPcps, 'chat_id')

      async function isChatMatches(chat: Chat): Promise<boolean> {
        const chatModel = ChatModelFactory(chat)
        const pcp = ownPcpsByChatId[chat.id]

        if(!pcp || pcp.status !== PcpStatus.ACTIVE) {
          return false
        }

        if(profilesStore.my_profile
          && !checkIfProfileCanWriteToChat(profilesStore.my_profile, chatModel)) {
          return false
        }

        if(!query?.length) {
          return true
        }

        const nickname = chat.nickname || chatModel.companion?.nickname || ''

        if(/^@.*/.test(query)) {
          const pureQuery = query.replaceAll('@', '')

          return nickname.includes(pureQuery)
        }

        if(nickname.includes(query)) return true
        if(chat.name && chat.name.includes(query)) return true

        switch(chat.type) {
          case ChatType.SELF_CHAT:
            return false // Self chat will be matches in another useMemo
          case ChatType.DIALOG: {
            const companion = await db[DEXIE_STORES.PROFILES]
              .get(chatModel.companionId ?? '')
            if(!companion) return false

            if(companion.phone && matchPhones(query, companion.phone)
              || companion.first_phone && matchPhones(query, companion.first_phone)
              || companion.second_phone && matchPhones(query, companion.second_phone)
            ) {
              return true
            }

            if(companion.email && matchPhones(query, companion.email)
              || companion.first_email && matchPhones(query, companion.first_email)
              || companion.second_email && matchPhones(query, companion.second_email)
            ) {
              return true
            }

            const first_name = (companion.first_name ?? '').toLowerCase()
            const last_name = (companion.last_name ?? '').toLowerCase()

            const parts = query.split(' ')
            for(let part of parts) {
              part = part.toLowerCase()

              if(first_name.includes(part) || last_name.includes(part)) {
                return true
              }
            }

          }
        }

        return false
      }

      for(const chat of chats) {
        if(await isChatMatches(chat)) {
          res.push(chat)
        }
      }

      return { chat_list: res }
    })

    setLocalResults(data)

    setLoading(false)
  }, [])

  function setLocalResults(data: LocalResults) {
    if(data.chat_list) {
      setResults({
        chat_list: data.chat_list.map(item => ({
          company_id: item.company_id || undefined,
          kind: item.company_id
            ? SearchedChatKind.Company
            : SearchedChatKind.Personal,
          chat: ChatModelFactory(item)
        })),
        contact_list: []
      })
    }
  }

  useEffect(() => {
    if(open) {
      inputRef.current && inputRef.current.focus()
      return
    }

    const timeout = setTimeout(() => {
      setSearch('')
    }, TRANSITION_DURATION)

    return () => {
      clearTimeout(timeout)
    }
  }, [open, inputRef])

  const chatsByCompanies = useMemo(() => {
    const res = {}

    results.chat_list
      .filter(item => item.kind === SearchedChatKind.Company
        && myCompaniesStore.companyIds.includes(item.company_id))
      .forEach(({ chat }: any) => {
        // @ts-ignore
        res[chat.company_id] ??= []
        // @ts-ignore
        res[chat.company_id].push({ type: 'chat', chat })
      })

    results.contact_list
      .filter(item => item.kind === ContactKind.Company
        && myCompaniesStore.companyIds.includes(item.company_id))
      .forEach(item => {
        // @ts-ignore
        res[item.company_id] ??= []
        // @ts-ignore
        res[item.company_id].push({ type: 'profile', profile: item.profile })
      })

    return res
  }, [results.chat_list, results.contact_list])

  const personalChats = useMemo(() => {
    const res = [
      ...results.chat_list
        .filter(item => item.kind === SearchedChatKind.Personal
          || (
            item.kind === SearchedChatKind.Company
            && !myCompaniesStore.companyIds.includes(item.company_id)
          )
        )
        .map(({ chat }) => ({
          type: 'chat',
          chat
        })),

      ...results.contact_list
        .filter(item => item.kind === ContactKind.Personal
          || (
            item.kind === ContactKind.Company
            && !myCompaniesStore.companyIds.includes(item.company_id)
          )
        )
        .map(({ profile }) => ({
          type: 'profile',
          profile
        }))
    ]

    if(search.length && t('name.bookmarks').toLowerCase().includes(search.toLowerCase())) {
      const selfChat = Object.values(chatsStore.chats).find(item => item.type === ChatType.SELF_CHAT)

      if(selfChat) {
        res.unshift({
          type: 'chat',
          chat: selfChat
        })
      }
    }

    return res
  }, [results.chat_list, results.contact_list, search])

  const globalChats = useMemo(() => {
    return [
      ...results.chat_list
        .filter(item => item.kind === SearchedChatKind.Global)
        .map(({ chat }) => ({
          type: 'chat',
          chat
        })),

      ...results.contact_list
        .filter(item => item.kind === ContactKind.Global)
        .map(({ profile }) => ({
          type: 'profile',
          profile
        }))
    ]
  }, [results.chat_list, results.contact_list])

  const isNothingFound = !loading && search.length
    && !Object.values(chatsByCompanies).length
    && !personalChats.length
    && !globalChats.length

  useEffect(() => {
    if(!open) {
      setResults(DEFAULT_RESULTS())
    }
  }, [open])

  const isResultEmpty = useMemo(() => {
    return !Object.values(chatsByCompanies).length && !Object.values(personalChats).length && !Object.values(globalChats).length
  }, [chatsByCompanies, personalChats, globalChats])

  const showAppealPlaceholder = useMemo(() => {
    if(showAllLocalChatsByDefault) {
      return loading
        ? false
        : isResultEmpty
    }
    return !search.length
  }, [showAllLocalChatsByDefault, search, isResultEmpty, loading])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth='xs'
      classes={{
        root: styles.root,
        paper: styles.paper
      }}
    >
      <div className={styles.header}>
        <h2 className={styles.title}>
          {title}
        </h2>
        <CloseButton onClick={() => setOpen(false)}/>

        <ChatSearchInput
          ref={inputRef}
          value={search}
          onChange={handleSearchChange}
        />
      </div>

      <DialogContent
        sx={{ padding: 0 }}
        className={styles.body}
      >
        {showAppealPlaceholder ? (
          <Placeholder
            title={t('search.appeal.title')}
            description={t('search.appeal.description')}
          />
        ) : loading ? (
          <Loading/>
        ) : isNothingFound ? (
          <Placeholder
            title={t('search.no_results.title', { text: search })}
            description={t('search.no_results.description')}
          />
        ) : (
          <div className={styles.content}>
            {Object.entries(chatsByCompanies).map(([company_id, chats]) => (
              <Fragment key={company_id}>
                <BlockHeading>
                  {myCompaniesStore.find(company_id)?.name ?? ''}
                  {/*{t('search.sections.private')}*/}
                </BlockHeading>

                <ChatList
                  chats={chats as any}
                  onChatSelect={onChatSelect}
                />
              </Fragment>
            ))}

            {!!personalChats.length && (
              <>
                <BlockHeading>
                  {t('search.sections.private')}
                </BlockHeading>
                <ChatList
                  chats={personalChats as any}
                  onChatSelect={onChatSelect}
                />
              </>
            )}

            {!!globalChats.length && (
              <>
                <BlockHeading>
                  {t('search.sections.global')}
                </BlockHeading>

                <ChatList
                  chats={globalChats as any}
                  onChatSelect={onChatSelect}
                />
              </>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
})


interface InputProps {
  className?: string

  value?: string | null
  onChange?: (value: string) => void
  onClick?: () => void
}

export const ChatSearchInput = forwardRef(({
  className,

  value,
  onChange,
  onClick
}: InputProps, ref: any) => {
  const { t } = useTranslation('chat/common')

  return (
    <TextField
      inputProps={{
        maxLength: 30
      }}
      className={className}
      classes={{ root: className }}
      InputProps={{
        sx: { height: 40 },

        startAdornment: (
          <InputAdornment
            position='start'
            style={{
              paddingRight: '6px'
            }}
          >
            <Search/>
          </InputAdornment>
        ),

        endAdornment: value?.length ? (
          <InputAdornment
            position='end'
            className={styles.cleanSearch}
            onClick={() => onChange?.('')}
          >
            <Backspace/>
          </InputAdornment>
        ) : null
      }}
      autoFocus
      inputRef={ref}
      value={value}
      placeholder={t('search.placeholder')}
      onChange={event => onChange && onChange(event.target.value)}
      onClick={onClick}
    />
  )
})

function BlockHeading({
  children
}: {
  children: ReactNode
}) {
  return (
    <div className={styles.blockHeading}>
      {children}
    </div>
  )
}

interface ChatListProps {
  chats: ChatItem[],
  onChatSelect: (item: ChatItem) => void
}

function ChatList({
  chats,
  onChatSelect
}: ChatListProps) {
  return <>
    {chats.map(item => (
      item.type === 'chat' ? (
        <ChatItem
          chat={item.chat}
          key={item.chat.id}

          onSelect={() => onChatSelect(item)}
        />
      ) : (
        <ProfileItem
          profile={item.profile}
          key={item.profile.id}

          onSelect={() => onChatSelect(item)}
        />
      )
    ))}
  </>
}


const ProfileItem = observer(function ChatItem({
  profile,
  onSelect
}: {
  profile: ProfileModel,
  onSelect: () => void
}) {
  const { t } = useTranslation('chat/common')

  const name = [
    profile.first_name,
    profile.last_name
  ]
    .filter(item => !!item?.length)
    .join(' ')

  const content = `@${profile?.nickname}`

  return (
    <ChatItemView
      style={{ paddingLeft: 16 }}
      nameSlot={name}
      avatarSlot={
        <Avatar
          first_name={profile?.first_name ?? ''}
          last_name={profile?.last_name ?? ''}
          avatarUrl={profile?.avatar ?? ''}
          color_code={profile?.color ?? ''}
          width={40}
          onlineStatus={profile?.onlineStatus}
        />
      }

      contentSlot={
        <div
          className={styles.chat__content}
          title={content}
        >
          {content}
        </div>
      }

      onChatClick={onSelect}
    />
  )
})

const ChatItem = observer(function ChatItem({
  chat,
  onSelect
}: {
  chat: ChatModel,
  onSelect: () => void
}) {
  const { t } = useTranslation('chat/common')

  let content = ''

  switch(chat.type) {
    case ChatType.DIALOG:
      content = `@${chat.companion?.nickname}`
      break
    case ChatType.CHANNEL:
    case ChatType.GROUP_CHAT:
      content = [
        `@${chat.nickname}`,
        t(chat.type === ChatType.CHANNEL ? 'subscribers_count' : 'members_count', { count: chat.count_members })
      ].join(', ')
      break
  }

  return (
    <ChatItemView
      style={{ paddingLeft: 16 }}

      nameSlot={<ChatName chat={chat}/>}
      avatarSlot={<ChatAvatar chat={chat}/>}

      contentSlot={
        <div
          className={styles.chat__content}
          title={content}
        >
          {content}
        </div>
      }

      onChatClick={onSelect}
    />
  )
})

function Loading() {
  return (
    <div className={styles.placeholder}>
      <Loadable loading={true}/>
    </div>
  )
}

function CloseButton(props: any) {
  return (
    <button
      className={styles.close}
      {...props}
    >
      <Close
        className={styles.close__icon}
        color='#8E8E93'
      />
    </button>
  )
}

function Placeholder({
  title,
  description
}: {
  title: string,
  description: string
}) {
  return (
    <div className={styles.placeholder}>
      <PlaceholderSearch/>

      <div className={styles.placeholder__title}>
        {title}
      </div>
      <div className={styles.placeholder__description}>
        {description}
      </div>
    </div>
  )
}
