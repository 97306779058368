import { ChatAvatar } from '@/components/chats/shared/chat/ChatAvatar/ChatAvatar'
import { ChatName } from '@/components/chats/shared/chat/ChatName/ChatName'
import { MessageDeliverStatus } from '@/components/chats/shared/message/MessageDeliverStatus/MessageDeliverStatus'
import { NewMessagesCount } from '@/components/chats/shared/NewMessagesCount/NewMessagesCount'
import { ChatModel } from '@/types/models/chat'
import { Fade } from '@mui/material'
import { Pin } from '@roolz/icons/chats/Pin'
import { Silent } from '@roolz/icons/chats/Silent'
import { WithUniversalContextMenu } from '@roolz/sdk/hooks/useUniversalContextMenu'
import { isNumber } from '@roolz/sdk/utils/types'
import { MessageStatus } from '@roolz/types/api/chats'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { forwardRef, ReactNode } from 'react'
import styles from '@/components/chats/entities/ChatItemView/ChatItemView.module.scss'

interface Props {
  // chat: ChatModel
  lastMessageDeliverStatus?: MessageStatus
  showUnreadMessagesCount?: boolean
  unreadMessagesCount?: number

  avatarSlot?: ReactNode
  nameSlot?: ReactNode
  lastMessageDateSlot?: ReactNode
  contentSlot?: ReactNode

  isActive?: boolean
  isPinned?: boolean
  isMuted?: boolean

  showInfo?: boolean

  onChatClick?: () => void
  onRightClick?: (event: any) => void
  onPointerEnter?: () => void
  onPointerLeave?: () => void

  style?: any
  className?: string
}

export const ChatItemView = observer(forwardRef(({
  lastMessageDeliverStatus,
  showUnreadMessagesCount = true,
  unreadMessagesCount,

  avatarSlot,
  nameSlot,
  lastMessageDateSlot,
  contentSlot,

  isActive = false,
  isPinned = false,
  isMuted = false,

  showInfo = true,

  onChatClick,
  onRightClick,
  onPointerEnter,
  onPointerLeave,

  style,
  className
}: Props, ref: any) => {
  const showUnread = showUnreadMessagesCount && isNumber(unreadMessagesCount)

  return (
    <WithUniversalContextMenu
      onContextMenu={onRightClick}
      ref={ref}
    >
      <button
        style={style}
        className={cn(styles.chat, className, {
          [styles.chatPined]: isPinned,
          [styles.chatActive]: isActive
        })}
        onClick={onChatClick}

        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
      >
        <div className={styles.avatar}>
          {avatarSlot}
        </div>


        <Fade
          in={showInfo}
          appear={false}
          unmountOnExit
        >
          <div
            className={cn(styles.content, {
              // [styles.contentHidden]: !showInfo
            })}
          >
            <div className={styles.content__header}>
              <div className={styles.name__container}>
                <div className={styles.name}>
                  {nameSlot}
                  {/*<ChatName chat={chat}/>*/}
                </div>
              </div>

              {isPinned && (
                <Icon>
                  <Pin/>
                </Icon>
              )}
              {isMuted && (
                <Icon>
                  <Silent color='#8E8E93'/>
                </Icon>
              )}


              <Spacer/>


              {lastMessageDeliverStatus !== undefined && (
                <Icon>
                  <MessageDeliverStatus status={lastMessageDeliverStatus}/>
                </Icon>
              )}

              {lastMessageDateSlot}
            </div>

            {(contentSlot || showUnread) && (
              <div className={styles.content__footer}>
                {contentSlot}

                {showUnread && (
                  <NewMessagesCount
                    count={unreadMessagesCount}
                    isMuted={isMuted}
                  />
                )}
              </div>
            )}
          </div>
        </Fade>
      </button>
    </WithUniversalContextMenu>
  )
}))

function Icon({ children }: {
  children: ReactNode
}) {
  return (
    <div className={styles.icon}>
      {children}
    </div>
  )
}

const Spacer = () => <div className={styles.spacer}></div>
