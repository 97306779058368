import { Dialog, DialogContent } from '@mui/material'
import { TaskAltBlack } from '@roolz/icons/TaskAltBlack'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { Company } from '@roolz/types/api/companies'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { useTranslation } from 'react-i18next'
import styles from './InvitedModalAccept.module.scss'

interface InvitedModalAcceptProps {
  onClose: () => void
  company: Company
}

export const InvitedModalAccept = ({
  onClose,
  company
}: InvitedModalAcceptProps) => {
  const { t } = useTranslation('company/common')

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth='lg'
      sx={{ borderRadius: 0 }}
      className={styles.dialog}
    >
      <DialogContent className={styles.dialog__content}>
        <div className={styles.dialog__icon}>
          <TaskAltBlack />
        </div>
        <p className={styles.dialog__title}>
          {t('invites.join_success.title')}
        </p>
        <p className={styles.dialog__notify}>
          {t('invites.join_success.description')}
        </p>
        <div className={styles.dialog__company}>
          <Avatar
            className={styles.sidebar__companyAvatar}
            avatarUrl={company.logo_path ?? ''}
            first_name={company.name}
            color_code={company.color}
            width={40}
            type='company'
          />
          <p className={styles.dialog__companyText}>
            {company.name}
          </p>
        </div>
      </DialogContent>
      <div className={styles.dialog__action}>
        <Button tabIndex={1} onClick={onClose} className={styles.dialog__actionButton}>
          {t('invites.join_success.accept')}
        </Button>
      </div>
    </Dialog>
  )
}
