import { Chat } from '@roolz/types/api/chats'
import { db } from '@/database'
import { keyBy, merge } from 'lodash'

export async function addOrUpdateChats(chats: Array<Chat>) {
  const newByIds = keyBy(chats, 'id')

  return db.transaction('rw', db.chats, async () => {
    const existing = await db.chats.bulkGet(Object.keys(newByIds))

    existing.forEach(existing => {
      if(existing) merge(existing, newByIds[existing.id])
    })

    await db.chats.bulkPut(Object.values(newByIds))
  })
}
