
interface Props {

}
export const TaskAltBlack = (props: Props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8940_97507)">
        <path d="M28.387 7.8536L15.0536 21.1869C14.5336 21.7069 13.6936 21.7069 13.1736 21.1869L9.4003 17.4136C8.8803 16.8936 8.8803 16.0536 9.4003 15.5336C9.9203 15.0136 10.7603 15.0136 11.2803 15.5336L14.107 18.3603L26.4936 5.9736C27.0136 5.4536 27.8536 5.4536 28.3736 5.9736C28.907 6.4936 28.907 7.3336 28.387 7.8536ZM21.027 3.6536C18.7736 2.7336 16.2136 2.4136 13.547 2.8936C8.12029 3.86694 3.78696 8.24027 2.86696 13.6669C1.34696 22.6669 8.8403 30.3736 17.787 29.2136C23.067 28.5336 27.4936 24.6003 28.8803 19.4669C29.4136 17.5069 29.467 15.6136 29.1603 13.8403C28.987 12.7736 27.667 12.3603 26.8936 13.1203C26.587 13.4269 26.4536 13.8803 26.5336 14.3069C26.827 16.0803 26.6936 17.9736 25.8403 19.9869C24.2936 23.6003 20.9336 26.2536 17.027 26.6136C10.227 27.2403 4.58696 21.4803 5.42696 14.6403C6.00029 9.92027 9.8003 6.08027 14.507 5.42694C16.8136 5.10694 19.0003 5.54694 20.867 6.50694C21.387 6.7736 22.0136 6.68027 22.427 6.26694C23.067 5.62694 22.907 4.54694 22.107 4.1336C21.747 3.9736 21.387 3.80027 21.027 3.6536Z" fill="#4778EE"/>
      </g>
      <defs>
        <clipPath id="clip0_8940_97507">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}