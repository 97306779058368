export interface CompanyAssociation {
  slug: CompanyAssociationSlug
  index: number
  countries: string
  name: string
}

export interface CompanyGoal {
  slug: CompanyGoalSlug
  index: number
}

export interface CompanyCargoType {
  slug: CompanyCargoTypeSlug
  index: number
}

export interface CompanyForm {
  slug: CompanyFormSlug
  index: number
}

export interface CustomsService {
  slug: CustomsServiceSlug
  index: number
}

export interface CompanyEmployeeAmount {
  slug: CompanyEmployeeAmountSlug
  index: number
}

export interface ExchangeRole {
  slug: ExchangeRoleSlug
  index: number
  fleet_amount_active: boolean,
  international_direction_active: boolean,
  national_direction_active: boolean,
  transportation_type_active: boolean,
  cargo_type_active: boolean,
  customs_service_active: boolean
}

export interface FleetAmount {
  slug: FleetAmountSlug
  index: number
}

export interface CompanyGoal {
  slug: CompanyGoalSlug
  index: number
}

export interface LogisticsService {
  slug: LogisticsServiceSlug
  index: number
}

export interface OrderAmount {
  slug: OrderAmountSlug
  index: number
}

export interface TransportationType {
  slug: TransportationTypeSlug
  index: number
}

export enum CompanyGoalSlug {
  find_cargo = 'find_cargo',
  find_transport = 'find_transport',
  private_exchange = 'private_exchange',
  api_integration = 'api_integration',
  visibility = 'visibility',
  communication = 'communication',
  crm = 'crm',
  tms = 'tms',
  fms = 'fms',
  offer_service = 'offer_service',
  other = 'other'
}

export enum CompanyCargoTypeSlug {
  general = 'general',
  groupage = 'groupage',
  cep = 'cep',
  last_mile = 'last_mile',
  adr = 'adr',
  oversize = 'oversize',
  project = 'project',
  temperature = 'temperature',
  perishable = 'perishable',
  bulk = 'bulk',
  liquid = 'liquid',
  container = 'container',
  passenger = 'passenger',
  animals = 'animals',
  other_special = 'other_special'
}

export enum CompanyAssociationSlug {
  us_tia = 'us_tia',
  us_ooida = 'us_ooida',
  us_ata = 'us_ata',
  tw_iofflat = 'tw_iofflat',
  cn_crta = 'cn_crta',
  cn_cifa = 'cn_cifa',
  tw_tafla = 'tw_tafla',
  tw_tfla = 'tw_tfla',
  jp_jiffa = 'jp_jiffa',
  jp_jafa = 'jp_jafa',
  jp_jfff = 'jp_jfff',
  mx_amacarga = 'mx_amacarga',
  id_ilfa = 'id_ilfa',
  th_tiffa = 'th_tiffa',
  br_actc = 'br_actc',
  ru_asmap = 'ru_asmap',
  ru_far = 'ru_far',
  fr_aftri = 'fr_aftri',
  fr_tlf = 'fr_tlf',
  in_ficci = 'in_ficci',
  in_acaai = 'in_acaai',
  in_fffai = 'in_fffai',
  es_astic = 'es_astic',
  es_feteia = 'es_feteia',
  it_uicciaa = 'it_uicciaa',
  it_fedespedi = 'it_fedespedi',
  tr_tobb = 'tr_tobb',
  tr_und = 'tr_und',
  tr_utikad = 'tr_utikad',
  gb_logistics_uk = 'gb_logistics_uk',
  gb_bifa = 'gb_bifa',
  gb_rha = 'gb_rha',
  kr_kiffa = 'kr_kiffa',
  au_afif = 'au_afif',
  au_cbfca = 'au_cbfca',
  pl_zmpd = 'pl_zmpd',
  pl_piffa = 'pl_piffa',
  de_bgl = 'de_bgl',
  de_dslv = 'de_dslv',
  de_aist = 'de_aist',
  ar_aaaci = 'ar_aaaci',
  za_saaff = 'za_saaff',
  sy_snc_icc = 'sy_snc_icc',
  sy_siffa = 'sy_siffa',
  co_fitac = 'co_fitac',
  sa_sata = 'sa_sata',
  sa_nlc = 'sa_nlc',
  ua_asmap_ua = 'ua_asmap_ua',
  ua_aiffu = 'ua_aiffu',
  ua_ukrzovnishtrans = 'ua_ukrzovnishtrans',
  ir_iccima = 'ir_iccima',
  ir_itca = 'ir_itca',
  eg_eiffa = 'eg_eiffa',
  cl_alog_chile = 'cl_alog_chile',
  ec_aseaci = 'ec_aseaci',
  pt_antram = 'pt_antram',
  pt_apat = 'pt_apat',
  my_fmff = 'my_fmff',
  gt_agac = 'gt_agac',
  ca_ciffa = 'ca_ciffa',
  gr_ofae = 'gr_ofae',
  gr_iffag_l = 'gr_iffag_l',
  nl_fenex = 'nl_fenex',
  ma_amtri = 'ma_amtri',
  ma_affm = 'ma_affm',
  ro_untrr = 'ro_untrr',
  ro_user = 'ro_user',
  be_febetra = 'be_febetra',
  be_forward_belgium = 'be_forward_belgium',
  do_adacam = 'do_adacam',
  lk_slffa = 'lk_slffa',
  cz_cesmad_bohemia = 'cz_cesmad_bohemia',
  cz_ssl = 'cz_ssl',
  nz_cbaff = 'nz_cbaff',
  se_sa = 'se_sa',
  se_sifa = 'se_sifa',
  no_nlf = 'no_nlf',
  no_nho_lt = 'no_nho_lt',
  kz_kazato = 'kz_kazato',
  kz_kffa = 'kz_kffa',
  ke_kifwa = 'ke_kifwa',
  hu_mkfe = 'hu_mkfe',
  hu_ahf = 'hu_ahf',
  pk_pnc_icc = 'pk_pnc_icc',
  pk_piffa = 'pk_piffa',
  bg_aebtri = 'bg_aebtri',
  bg_nsbs = 'bg_nsbs',
  by_bamap = 'by_bamap',
  by_baif = 'by_baif',
  ph_pmtlai = 'ph_pmtlai',
  tn_ccit = 'tn_ccit',
  at_zv = 'at_zv',
  at_aiso = 'at_aiso',
  kg_airto_kr = 'kg_airto_kr',
  dk_dsf = 'dk_dsf',
  dk_dtl = 'dk_dtl',
  ch_spedlogswiss = 'ch_spedlogswiss',
  ch_astag = 'ch_astag',
  af_acci = 'af_acci',
  ni_anac = 'ni_anac',
  il_irtb = 'il_irtb',
  il_iffcca = 'il_iffcca',
  sk_cesmad_slovakia = 'sk_cesmad_slovakia',
  sk_zlz_sr = 'sk_zlz_sr',
  ug_uffa = 'ug_uffa',
  ie_irha = 'ie_irha',
  ie_iifa = 'ie_iifa',
  gh_giff = 'gh_giff',
  qa_qcci = 'qa_qcci',
  kw_kiac = 'kw_kiac',
  py_atolpar = 'py_atolpar',
  bd_baffa = 'bd_baffa',
  cr_acacia = 'cr_acacia',
  om_sinyar = 'om_sinyar',
  rs_ccis_at = 'rs_ccis_at',
  md_aem_trans = 'md_aem_trans',
  md_aita = 'md_aita',
  sg_saaa__singapore = 'sg_saaa__singapore',
  sg_sla = 'sg_sla',
  az_abada = 'az_abada',
  vn_vla = 'vn_vla',
  hr_cce = 'hr_cce',
  hr_hgk = 'hr_hgk',
  sv_asac = 'sv_asac',
  ge_girca = 'ge_girca',
  ge_afg = 'ge_afg',
  pa_apac = 'pa_apac',
  ae_atcuae = 'ae_atcuae',
  tz_taffa = 'tz_taffa',
  tz_zfb = 'tz_zfb',
  ae_nafl = 'ae_nafl',
  hk_haffa = 'hk_haffa',
  jo_racj = 'jo_racj',
  jo_jla = 'jo_jla',
  hn_ahaci = 'hn_ahaci',
  uy_audaca = 'uy_audaca',
  zm_zcfaa = 'zm_zcfaa',
  lb_cciabml = 'lb_cciabml',
  lb_lfs = 'lb_lfs',
  zw_sfaaz = 'zw_sfaaz',
  lt_linava = 'lt_linava',
  lt_lineka = 'lt_lineka',
  ee_eraa = 'ee_eraa',
  ee_elfa = 'ee_elfa',
  cy_tda = 'cy_tda',
  cy_ccfaa = 'cy_ccfaa',
  si_giz_itt = 'si_giz_itt',
  si_ccis = 'si_ccis',
  fi_skal = 'fi_skal',
  fi_shll = 'fi_shll',
  lv_latvijas_auto = 'lv_latvijas_auto',
  lv_laff = 'lv_laff',
  cm_synautratra = 'cm_synautratra',
  ba_airh = 'ba_airh',
  ba_vtk_stk_bih = 'ba_vtk_stk_bih',
  al_analtir = 'al_analtir',
  et_effsaa = 'et_effsaa',
  mt_atto = 'mt_atto',
  mt_malta_chamber = 'mt_malta_chamber',
  mu_apt = 'mu_apt',
  mk_amerit = 'mk_amerit',
  bn_brufa = 'bn_brufa',
  uz_aircuz = 'uz_aircuz',
  kh_camffa = 'kh_camffa',
  am_airca = 'am_airca',
  am_aaff = 'am_aaff',
  mn_nartam = 'mn_nartam',
  dj_atd = 'dj_atd',
  tj_abbat = 'tj_abbat',
  mm_miffa = 'mm_miffa',
  tm_thada = 'tm_thada',
  uz_uifa = 'uz_uifa',
  me_pkcg = 'me_pkcg',
  mn_fmff = 'mn_fmff'
}

export enum CompanyFormSlug {
  private_company = 'private_company',
  self_employed = 'self_employed',
  partnership = 'partnership',
  public_company = 'public_company',
  government_agency = 'government_agency',
  non_profit = 'non_profit'
}

export enum CustomsServiceSlug {
  declaration = 'declaration',
  statistical_declaration = 'statistical_declaration',
  document_handling = 'document_handling',
  representation = 'representation',
  consulting = 'consulting',
  customs_guarantee = 'customs_guarantee',
  temporary_storage = 'temporary_storage'
}

export enum CompanyEmployeeAmountSlug {
  '1 - 15' = '1_15',
  '16 - 50' = '16_50',
  '51 - 150' = '51_150',
  '151 - 500' = '151_500',
  '501 - 1,500' = '501_1500',
  '1,501 - 5,000' = '1,501_5000',
  '5,000+' = '5000_more'
}

export enum ExchangeRoleSlug {
  shipper = 'shipper',
  carrier = 'carrier',
  forwarder = 'forwarder',
  forwarder_carrier = 'forwarder_carrier',
  dispatcher = 'dispatcher',
  freight_broker = 'freight_broker',
  customs_agent = 'customs_agent',
  storage_provider = 'storage_provider',
  other = 'other'
}

export enum FleetAmountSlug {
  '1 - 5' = '1_5',
  '6 - 15' = '6_15',
  '16 - 50' = '16_50',
  '51 - 100' = '51_100',
  '101 - 500' = '101_500',
  '501 - 1,000' = '501_1000',
  '1,000+' = '1000_more'
}

export enum LogisticsServiceSlug {
  transportation = 'transportation',
  forwarding = 'forwarding',
  dispatching = 'dispatching',
  freight_brokerage = 'freight_brokerage',
  inventory_mngmt = 'inventory_mngmt',
  order_mngmt = 'order_mngmt',
  responsible_storage = 'responsible_storage',
  storage_rent = 'storage_rent',
  transport_rent = 'transport_rent',
  cargo_consolidation = 'cargo_consolidation',
  cross_docking = 'cross_docking',
  loader_work = 'loader_work',
  cargo_insurance = 'cargo_insurance',
  transport_insurance = 'transport_insurance',
  return_mngmt = 'return_mngmt'
}

export enum OrderAmountSlug {
  'zero' = '0',
  '1 - 30' = '1_30',
  '31 - 100' = '31_100',
  '101 - 500' = '101_500',
  '501 - 2,000' = '501_2000',
  '2,001 - 10,000' = '2001_10000',
  '10,001 - 50,000' = '10001_50000',
  '50,000+' = '50000_more'
}

export enum TransportationTypeSlug {
  road = 'road',
  ocean = 'ocean',
  air = 'air',
  railway = 'railway',
  multimodal = 'multimodal'
}
