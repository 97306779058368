import { Expand } from '@roolz/icons/Expand'
import { DONT_CLOSE_MENU_FLAG } from '../const'
import { Collapse } from '@mui/material'
import cn from 'classnames'
import { MouseEvent, ReactNode, useState } from 'react'
import styles from './MenuListItemGroup.module.scss'

interface Props {
  prepend?: ReactNode,
  label?: ReactNode,
  children?: ReactNode

  [key: string]: any
}

export const MenuListItemGroup = ({
  prepend,
  label,
  children,
  ...rest
}: Props) => {
  const [expand, setExpand] = useState<boolean>(false)

  function handleClick(event: MouseEvent) {
    // event.stopPropagation()
    // @ts-ignore
    event.target.flags ??= []
    // @ts-ignore
    event.target.flags.push(DONT_CLOSE_MENU_FLAG)

    setExpand(expand => !expand)
  }

  return (<>
    <button
      className={styles.root}
      onClick={handleClick}
      {...rest}
    >
      {prepend && (
        <span className={styles.icon}>
          {prepend}
        </span>
      )}
      <div className={styles.label}>
        {label}
      </div>

      <div
        className={cn(styles.arrow, {
          [styles.arrowExpanded]: expand
        })}
      >
        <Expand/>
      </div>
    </button>

    <Collapse in={expand} timeout={200}>
      <div className={styles.children}>
        {children}
      </div>
    </Collapse>
  </>)
}
