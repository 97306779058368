import { ChatsPageTitleController } from '@/components/chats/ChatsPageTitleController'
import { MessageForwardProvider } from '@/components/chats/MessageForwardProvider'

export function MessagingControlsProvider() {
  return (<>
    <ChatsPageTitleController/>

    <MessageForwardProvider/>
  </>)
}
