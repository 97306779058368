import { isClient } from '@roolz/sdk/utils/ssr'
import { useCallback, useLayoutEffect } from 'react'

let listener: undefined | EventListener

const subs: CallableFunction[] = []

function handleResize() {
  subs.forEach(sub => {
    sub()
  })
}

interface Props {
  onWidthChange: (width: number) => void
  onHeightChange: (height: number) => void
}

export function useWindowResizeLight({ onWidthChange, onHeightChange }: Props): void {
  useLayoutEffect(() => {
    ResizeHandler()
  }, [onWidthChange, onHeightChange])

  const ResizeHandler = useCallback(() => {
    onWidthChange(isClient() ? window?.innerWidth : 0)
    onHeightChange(isClient() ? window?.innerHeight : 0)
  }, [])

  useLayoutEffect(() => {
    if(!subs.find(sub => sub === ResizeHandler)) {
      subs.push(ResizeHandler)
    }

    if(!listener) {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      const subIndex = subs.findIndex(sub => sub === ResizeHandler)
      if(subIndex !== -1) {
        subs.splice(subIndex, 1)
      }
    }
  }, [])

  useLayoutEffect(() => {
    handleResize()
  })
}
