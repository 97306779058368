import { isClient } from '@roolz/sdk/utils/ssr'
import { useCallback, useLayoutEffect, useState } from 'react'

let listener: undefined | EventListener

const subs: CallableFunction[] = []

function handleResize() {
  subs.forEach(sub => {
    sub()
  })
}

export function useWindowSize(): {
  vw: number,
  vh: number
} {
  const [vw, setVw] = useState<number>(isClient() ? window?.innerWidth : 0)
  const [vh, setVh] = useState<number>(isClient() ? window?.innerHeight : 0)

  const ResizeHandler = useCallback(() => {
    setVw(window?.innerWidth ?? 0)
    setVh(window?.innerHeight ?? 0)
  }, [])

  useLayoutEffect(() => {
    if(!subs.find(sub => sub === ResizeHandler)) {
      subs.push(ResizeHandler)
    }

    if(!listener) {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      const subIndex = subs.findIndex(sub => sub === ResizeHandler)
      if(subIndex !== -1) {
        subs.splice(subIndex, 1)
      }
    }
  }, [])

  useLayoutEffect(() => {
    handleResize()
  })

  return { vw, vh }
}
