interface Props {

}

export const PublicExchange = (props: Props) => {
  return (

    <svg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.43 17.2501L18.33 15.7101C18.84 15.0601 19.14 14.2401 19.14 13.3501C19.14 11.8101 18.23 10.4701 16.92 9.86006V3.92006C16.92 3.92006 16.92 3.90006 16.92 3.88006C16.93 3.61006 16.78 3.35006 16.51 3.24006L8.74 0.0500586C8.58 -0.0199414 8.4 -0.0199414 8.24 0.0500586L0.44 3.28006C0.35 3.32006 0.28 3.37006 0.21 3.44006C0.08 3.56006 0 3.74006 0 3.92006V13.2601C0 13.5301 0.16 13.7701 0.41 13.8701L8.19 17.0701C8.27 17.1001 8.36 17.1201 8.44 17.1201C8.44 17.1201 8.45 17.1201 8.46 17.1201C8.46 17.1201 8.47 17.1201 8.48 17.1201C8.56 17.1201 8.64 17.1101 8.72 17.0801L12.24 15.7101C12.95 16.6201 14.05 17.2101 15.29 17.2101C16.04 17.2101 16.75 16.9901 17.34 16.6201L19.66 18.3201C19.78 18.4101 19.91 18.4501 20.05 18.4501C20.25 18.4501 20.45 18.3601 20.58 18.1801C20.79 17.8901 20.73 17.4801 20.44 17.2601L20.43 17.2501ZM7.78 7.56006V15.4801L1.31 12.8201V4.90006L7.78 7.56006ZM15.6 9.50006C15.49 9.50006 15.39 9.49006 15.28 9.49006C13.15 9.49006 11.42 11.2201 11.42 13.3501C11.42 13.7601 11.49 14.1601 11.61 14.5401L9.09 15.5201V7.58006L15.6 4.90006V9.50006ZM2.35 3.91006L8.49 1.37006L14.62 3.89006L8.46 6.42006L2.35 3.91006ZM15.28 10.8001C16.68 10.8001 17.82 11.9401 17.82 13.3401C17.82 14.7401 16.68 15.8801 15.28 15.8801C13.88 15.8801 12.74 14.7401 12.74 13.3401C12.74 11.9401 13.88 10.8001 15.28 10.8001Z'
        fill='#272A36'
      />
    </svg>

  )
}
