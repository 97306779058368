interface Props {
  size?: number
  color?: string
  className?: string
}

export function Expand({
  size = 8,
  color = '#8E8E93',
  className
}: Props) {
  return (
    <svg
      className={className}
      width={size} height={size * 0.75} viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.933399 5.03332L0.233398 4.31666L4.00007 0.549988L7.76673 4.31666L7.06673 5.03332L4.00007 1.96665L0.933399 5.03332Z'
        fill={color}
      />
    </svg>
  )
}
