export function Logout() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask
        id='mask0_4069_18679'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='16'
      >
        <rect width='16' height='16' fill='#D9D9D9'/>
      </mask>
      <g mask='url(#mask0_4069_18679)'>
        <path
          d='M3.41662 13.6667C3.07217 13.6667 2.78328 13.55 2.54995 13.3167C2.31662 13.0833 2.19995 12.8 2.19995 12.4667V10.0833H3.19995V12.4667C3.19995 12.5111 3.22217 12.5556 3.26662 12.6C3.31106 12.6445 3.36106 12.6667 3.41662 12.6667H12.5833C12.6388 12.6667 12.6888 12.6445 12.7333 12.6C12.7777 12.5556 12.8 12.5111 12.8 12.4667V3.53334C12.8 3.4889 12.7777 3.44445 12.7333 3.40001C12.6888 3.35557 12.6388 3.33334 12.5833 3.33334H3.41662C3.36106 3.33334 3.31106 3.35557 3.26662 3.40001C3.22217 3.44445 3.19995 3.4889 3.19995 3.53334V5.91668H2.19995V3.53334C2.19995 3.20001 2.31662 2.91668 2.54995 2.68334C2.78328 2.45001 3.07217 2.33334 3.41662 2.33334H12.5833C12.9277 2.33334 13.2166 2.45001 13.45 2.68334C13.6833 2.91668 13.8 3.20001 13.8 3.53334V12.4667C13.8 12.8 13.6833 13.0833 13.45 13.3167C13.2166 13.55 12.9277 13.6667 12.5833 13.6667H3.41662ZM6.99995 10.9667L6.29995 10.25L8.04995 8.50001H2.19995V7.50001H8.04995L6.29995 5.75001L6.99995 5.03334L9.96662 8.00001L6.99995 10.9667Z'
          fill='#E23834'
        />
      </g>
    </svg>
  )
}
