/**
 * Used for retrieving errors for specified form field from http response
 * Needs because server could return errors for field in [fieldName] key, or [body.fieldName], etc
 *
 * @param fieldName
 * @param errors
 */
export function getFieldError<TValue = string>(fieldName: string, errors: {
  [key: string]: TValue
}): TValue | null {
  if(typeof errors !== 'object') {
    return null
  }

  if(errors[fieldName]) {
    return errors[fieldName]
  }

  const patterns = [
    new RegExp(`.*.${fieldName}`)
  ]

  for(const key of Object.keys(errors)) {
    for(const pattern of patterns) {
      if(pattern.test(key)) {
        return errors[key]
      }
    }
  }

  return null
}
