let host = ''

try {
  host = document?.location?.host ?? ''
} catch(e) {
//
}

export const IS_DEV = host
  && (host.includes('fg13.net') || host.includes('localhost'))

console.log('IS_DEV', IS_DEV)
