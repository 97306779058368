import { Loadable } from '@/components/ui/Loadable/Loadable'
import styles from './PageLoading.module.scss'

export function PageLoading() {
  return (
    <div className={styles.container}>
      <Loadable loading={true}/>
    </div>
  )
}
