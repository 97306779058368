interface Props {
  size?: number
}

export function SmallScreen({
  size = 24
}: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <mask id="mask0_14555_148958" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_14555_148958)">
        <path d="M8.5 19V15.5H5V14H10V19H8.5ZM14 19V14H19V15.5H15.5V19H14ZM5 10V8.5H8.5V5H10V10H5ZM14 10V5H15.5V8.5H19V10H14Z" fill="#8E8E93"/>
      </g>
    </svg>
  )
}
