import { useLayoutEffect } from 'react'
import gsap from '@/plugins/gsap'

interface Params {
  enable?: boolean
}

const defaultParams: Params = {
  enable: true
}

export function useGsap(rootEl: any, animation: (gsap: any) => void, {
  enable
}: Params = {}) {
  const on = enable ?? defaultParams.enable

  useLayoutEffect(() => {
    if(!on) {
      return
    }

    const ctx = gsap.context(() => animation(gsap))

    return () => ctx.revert()
  }, [on])
}
