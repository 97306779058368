import { Offer } from './api/exchange'
import { LoadingTypeSlug, PointTypeSlug } from './api/knowledge/exchange'
import { ID, Url } from './scalars'

/**
 * Types of internal frontend entities that not exist or have any belongings with backend
 */

export interface Country {
  id?: ID
  name: string,
  phone_code: string | number,
  alpha2: string,
  flag: Url
}

export interface UserCountry extends Country {
  latitude: number
  longitude: number
}

export enum Mimetypes {
  PNG = 'image/png'
}

export type Coordinates = {
  x: number
  y: number
}

export type Dimensions = {
  width: number,
  height: number
}

export interface RoutePoint {
  location: RoutePointLocation | null
  radius: number | null

  datetime: {
    date_from: number | null
    date_to: number | null
    time_from: number | null
    time_to: number | null
    timezone: string | null
  },
  type?: PointTypeSlug | null
  loading_type?: LoadingTypeSlug[] | null
}

export interface RoutePointLocation {
  osm_id: string
  osm_type: string
  position_type: string
  is_radius?: boolean
  lat: number
  lon: number
  address: Offer['route'][number]['location']['address']
}

export interface BaseChangeEvent {
  target: {
    name: string
    value: string
  }
}

export enum Direction {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum OnlineStatus {
  Online = 'online',
  Recently = 'recently',
  Offline = 'offline'
}
