import { Fade } from '@mui/material'
import styles from './GlobalModalBackdrop.module.scss'
import cn from 'classnames'

interface Props {
  show: boolean
  isInteractive?: boolean
  // onClick?: () => void
  [key: string]: any
}

export const GlobalModalBackdrop = ({
  show,
  isInteractive = true,
  ...rest
}: Props) => {
  return (
    <Fade
      in={show}
      unmountOnExit={true}
      timeout={{
        appear: 300,
        enter: 300,
        exit: 200
      }}
    >
      <div
        className={cn(styles.backdrop, {
          [styles.backdropNonInteractive]: !show
        })}
        {...rest}
      />
    </Fade>
  )
}
