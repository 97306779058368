import { BaseApiClient } from '@/api/clients/_base.client'
import { messagingHttp } from '@/api/transport/http/messaging.http'
import { Chat, GetChatMessagesRequest, GetChatMessagesResponse } from '@roolz/types/api/chats'

class MessagesClient extends BaseApiClient {
  async loadChatMessages(id: Chat['id'], params: GetChatMessagesRequest) {
    return this.get<GetChatMessagesResponse>(`/v1/message/${id}/list`, { params })
  }
}

export const messagesClient = new MessagesClient(messagingHttp, '')
