import { ModalType } from '@/global-modals/const'
import { Location } from '@remix-run/router'

type pathname = string

type GlobalModalsHistory = {
  [key in ModalType]: pathname[]
}

export const globalModalsHistory: GlobalModalsHistory = {
  [ModalType.OVERLAY]: [],
  [ModalType.POPUP]: []
}
