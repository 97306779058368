import { refreshTokensInterceptor } from '@/api/refreshTokensInterceptor'
import i18n from '@/plugins/i18n'
import { authStore } from '@/store/auth/auth.store'
import axios from 'axios'
import Qs from 'qs'

/**
 * Http client for request to Roolz File Server
 */
export const hereProxyHttp = axios.create({
  baseURL: process.env.REACT_APP_OWN_SERVER_BASE_URL + '/here-proxy',
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' })})

hereProxyHttp.interceptors.request.use(refreshTokensInterceptor)

hereProxyHttp.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      'X-Roolz-Auth': `Bearer ${authStore.access_token}`,
      'Accept-Language': i18n.language
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
