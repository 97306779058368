interface Props {

  className?: string
}

export const Backspace = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width='18'
      height='14'
      viewBox='0 0 18 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.22578 10.65L10.8258 8.05L13.4258 10.65L14.4758 9.6L11.8758 7L14.4758 4.4L13.4258 3.35L10.8258 5.95L8.22578 3.35L7.17578 4.4L9.77578 7L7.17578 9.6L8.22578 10.65ZM0.550781 7L4.52578 1.4C4.72578 1.11667 4.97578 0.895667 5.27578 0.737C5.57578 0.579 5.90078 0.5 6.25078 0.5H15.6258C16.1258 0.5 16.5551 0.675 16.9138 1.025C17.2718 1.375 17.4508 1.8 17.4508 2.3V11.7C17.4508 12.2 17.2718 12.625 16.9138 12.975C16.5551 13.325 16.1258 13.5 15.6258 13.5H6.25078C5.90078 13.5 5.57578 13.4167 5.27578 13.25C4.97578 13.0833 4.72578 12.8667 4.52578 12.6L0.550781 7ZM2.40078 7L5.77578 11.75C5.82578 11.8167 5.89245 11.875 5.97578 11.925C6.05911 11.975 6.15078 12 6.25078 12H15.6258C15.7091 12 15.7841 11.9667 15.8508 11.9C15.9174 11.8333 15.9508 11.7667 15.9508 11.7V2.3C15.9508 2.23333 15.9174 2.16667 15.8508 2.1C15.7841 2.03333 15.7091 2 15.6258 2H6.25078C6.15078 2 6.05911 2.025 5.97578 2.075C5.89245 2.125 5.82578 2.18333 5.77578 2.25L2.40078 7Z'
        fill='#8E8E93'
      />
    </svg>
  )
}
