import { profilesStore } from '@/store/profiles/profiles.store'
import { Offer, RouteWay, Template } from '@roolz/types/api/exchange'
import { clone, isEqual, merge, mergeWith } from 'lodash'
import { makeAutoObservable } from 'mobx'

type OfferLikeObjToUpdate = Partial<Offer> & Pick<Offer, '_id'>

class ExchangeStore {
  offers: Offer[] = []

  cargoTemplates: Template[] = []
  isCargoTemplatesLoaded = false

  transportTemplates: Template[] = []
  isTransportTemplatesLoaded = false

  constructor() {
    makeAutoObservable(this)

    // this.findOffer = memoize(this.findOffer)
  }

  removeCargoTemplate(id: string): void {
    this.cargoTemplates = this.cargoTemplates.filter(template => template.id !== id)
  }

  removeTransportTemplate(id: string): void {
    this.transportTemplates = this.transportTemplates.filter(template => template.id !== id)
  }

  removeOffer({ _id }: Pick<Offer, '_id'>) {
    this.offers = this.offers.filter(offer => offer._id !== _id)
  }

  addOrUpdateOffer(offer: Offer): void {
    const existing = this.offers.find(({ _id }) => _id === offer._id)

    if(existing && new Date(existing.updated_at) <= new Date(offer.updated_at)) {
      mergeWith(existing, offer, (a: unknown, b: unknown) => {
        if(Array.isArray(b)) {
          return isEqual(a, b) ? a : b
        }
        if(Array.isArray(a) && (b == null)) {
          return b
        }
      })
      return
    }

    this.offers.push(offer)
  }

  updateOfferPartial(partial: OfferLikeObjToUpdate) {
    const existing = this.offers.find(({ _id }) => _id === partial._id)

    if(existing && (!partial.updated_at || new Date(existing.updated_at) <= new Date(partial.updated_at))) {
      merge(existing, partial)
      return
    }
  }

  updateOfferRoute(offer: Pick<Offer, '_id'>, route: RouteWay) {
    const existing = this.offers.find(({ _id }) => _id === offer._id)

    if(existing) {
      existing.route_way = route
    }
  }

  findOffer(_id: Offer['_id']): Offer | undefined {
    return this.offers.find(offer => offer._id === _id)
  }

  isOwnOffer(offer: Offer) {
    const myProfile = profilesStore.my_profile
    const activeSpaceId = profilesStore.activeCompanyId

    if(activeSpaceId === null) {
      return myProfile?.id === offer.creation_profile_id
    }

    return activeSpaceId === offer.company_id
  }
}

const exchangeStore = new ExchangeStore()

export {
  ExchangeStore,
  exchangeStore
}
