import { Tab, Tabs } from '@mui/material'
import { forwardRef } from 'react'
import styles from 'src/components/chats/features/ChatSpaces/ChatSpaces.module.scss'

// TODO replace by generic
type Value = string

interface Props {
  value: Value

  groups: Array<{
    name: string
    value: Value
  }>

  onChange: (value: Value) => void

  style?: any
}

export const ChatSpaces = forwardRef(({
  value,
  groups,
  onChange,

  style
}: Props, ref: any) => {
  return (
    <Tabs
      style={style}
      ref={ref}
      className={styles.tabs}
      classes={{
        indicator: styles.indicator
      }}
      value={value}
      variant="scrollable"
      scrollButtons="auto"

      onChange={(event, newValue) => onChange(newValue)}
    >
      {groups.map(group => (
        <Tab
          className={styles.item}
          key={group.value}
          label={group.name}
          value={group.value}
        />
      ))}
    </Tabs>
  )
})
