// @ts-ignore
import * as Sentry from "@sentry/react"
import { createBrowserRouter, useLocation, useNavigationType } from "react-router-dom"
import { createRoutesFromChildren, matchRoutes } from 'react-router'
import * as React from 'react'

if(process.env.NODE_ENV === 'production' && window.location.hostname !== 'localhost') {
  Sentry.init({
   dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay({
        maskAllText: false, maskAllInputs: false,
        blockAllMedia: false
      }),

    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
