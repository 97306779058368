import { Fade, Zoom } from '@mui/material'
import { Clock } from '@roolz/icons/chats/Clock'
import { Delivered } from '@roolz/icons/chats/Delivered'
import { SendingError } from '@roolz/icons/chats/SendingError'
import { Sent } from '@roolz/icons/chats/Sent'
import { MessageStatus } from '@roolz/types/api/chats'
import * as React from 'react'

export function MessageDeliverStatus({
  status
}: {
  status: MessageStatus | undefined
}) {
  if(status === MessageStatus.SENT) {
    return (
      // <Zoom
      //   in={true}
      //   appear={true}
      // >
        <Sent color='#8E8E93'/>
      // </Zoom>
    )
  }
  if(status === MessageStatus.DELIVERED) {
    return <Delivered color='#8E8E93'/>
  }

  if(status === MessageStatus.READ) {
    return <Delivered color='#4778EE'/>
  }

  if(status === MessageStatus.SENDING) {
    return (
      <Fade
        in={true}
        appear={true}
        timeout={200}
        style={{ transitionDelay: '300ms' }}
      >
        <Clock color='#8E8E93'/>
      </Fade>
    )
  }

  if(status === MessageStatus.ERROR) {
    return <SendingError/>
  }
  return null
}
