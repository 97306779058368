import data from './data/trashed.json'
import Lottie, { LottieComponentProps } from 'lottie-react'

type Props = Omit<LottieComponentProps, 'animationData'>

export const Trashed = (props: Props) => {
  const size = 24

  return (
    <Lottie
      width={size}
      height={size}
      style={{ width: size, height: size }}
      animationData={data}
      {...props}
    />
  )
}
