import { useGsap } from '@/hooks/useGsap'
import { ChevronDown } from '@roolz/icons/ChevronDown'
import { useMount } from 'ahooks'
import { forwardRef, useRef } from 'react'
import styles from '@/components/chats/shared/chat/ScrollToEndButton/ScrollToEndButton.module.scss'
import cn from 'classnames'

interface Props {
  isVisible?: boolean
  className?: string
  newMessagesCount?: number

  onClick?: () => void
}


const anim = {
  duration: 0.3,
  ease: 'back',

  opacity: 0,
  translateY: '100%',
  scale: 0.5
}

export const ScrollToEndButton = forwardRef(({
  isVisible = true,

  className,
  newMessagesCount = 0,

  onClick,
  ...rest
}: Props, ref: any) => {
  const mountRef = useRef(false)
  useMount(() => mountRef.current = true)

  const duration = mountRef.current ? anim.duration : 0

  useGsap(document, gsap => {
    gsap.from(`.${styles.scrollToBottom}`, {
      ...anim,
      duration
    })
  }, { enable: isVisible })

  useGsap(document, gsap => {
    gsap.to(`.${styles.scrollToBottom}`, {
      ...anim,
      duration
    })
  }, { enable: !isVisible })

 return (
   <div
     {...rest}
     className={cn(styles.scrollToBottom, className)}
     onClick={onClick}
     ref={ref}
   >
     <ChevronDown/>

     {newMessagesCount > 0 && (
       <div className={styles.newMessages}>
         {newMessagesCount < 1000 ? newMessagesCount : '999+'}
       </div>
     )}
   </div>
 )
})
