import { Dialog as MuiDialog, DialogProps as MuiDialogProps } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useLockScrolling } from '@roolz/sdk/hooks'

export const Dialog = (props: MuiDialogProps) => {
  const { lock, unlock } = useLockScrolling()

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    const keyCode = event.keyCode || event.which

    if(keyCode === 27) {
      if(props.onClose) {
        props.onClose(event, 'escapeKeyDown')
      }
      event.stopPropagation()
      event.preventDefault()
    }
  }, [props.onClose])

  const cleanUp = useCallback(() => {
    document.removeEventListener('keydown', handleKeyDown, { capture: true })
  }, [handleKeyDown])

  useEffect(() => {
    props.open ? lock() : unlock()

    if(props.open) {
      document.addEventListener('keydown', handleKeyDown, { capture: true })
    } else {
      cleanUp()
    }

    return cleanUp
  }, [props.open])

  return (
    <MuiDialog
      {...props}
    />
  )
}
