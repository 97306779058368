interface Props {
  className?: string
}
export const CompanyPlaceholder = ({
  className
}: Props) => {
 return (
   <svg
     className={className}
     width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M60 53.3333V6.66667C60 3 57 0 53.3333 0H6.66667C3 0 0 3 0 6.66667V53.3333C0 57 3 60 6.66667 60H53.3333C57 60 60 57 60 53.3333ZM19.6667 36.6L26.6667 45.0333L37 31.7333C37.6667 30.8667 39 30.8667 39.6667 31.7667L51.3667 47.3667C52.2 48.4667 51.4 50.0333 50.0333 50.0333H10.0667C8.66667 50.0333 7.9 48.4333 8.76667 47.3333L17.0667 36.6667C17.7 35.8 18.9667 35.7667 19.6667 36.6V36.6Z"
           fill="#C8CBDE"/>
   </svg>
 )
}
