import { InvitedModalAccept } from '@/components/modals/InvitedModalAccept/InvitedModalAccept'
import { getStorageItemNameByProfileId, JUST_SIGNED_UP_STORAGE_VALUE } from '@/components/GlobalRequestModalsContainer'
import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { companiesStore } from '@/store/companies/companies.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Header from './Header/Header'
import styles from './Home.module.scss'
import Sidebar from './Sidebar/Sidebar'
import { getAfterAuthURL, removeAfterAuthURL } from '@roolz/sdk/utils/afterAuthRedirect'
import { getCompanyAfterInvite, removeCompanyAfterInvite } from '@roolz/sdk/utils/afterEmailInviteRedirect'
import { Company } from '@roolz/types/api/companies'

interface Props {
  showSidebar?: boolean,
  children: ReactNode
}

export default function HomeLayout({
  showSidebar = true,
  children
}: Props) {
  const globalModalsManager = useGlobalModals()
  const navigate = useNavigate()
  const [invitedCompany, setInvitedCompany] = useState<Company | null>(null)

  const handleCloseInviteModal = () => {
    removeCompanyAfterInvite()
    setInvitedCompany(null)
  }

  useEffect(() => {
    const invitedCompanyId = getCompanyAfterInvite()
    const invitedCompany = companiesStore.findCompany(invitedCompanyId ?? '')
    setInvitedCompany(invitedCompany ?? null)
  }, [])

  useEffect(() => {
    const justSignedIn =
      localStorage.getItem(getStorageItemNameByProfileId(profilesStore.my_profile?.id)) === JUST_SIGNED_UP_STORAGE_VALUE

    const shouldShowCreateCompanyModal = profilesStore.isMyProfileFilled
      && profilesStore.my_profile
      && !profilesStore.my_profile.companies?.length
      && justSignedIn

    const afterAuthCallback = () => {
      const redirectURL = getAfterAuthURL()

      if(redirectURL) {
        removeAfterAuthURL()
        navigate(redirectURL)
      }
    }

    if(shouldShowCreateCompanyModal) {
      globalModalsManager.open(GLOBAL_MODALS_NAMES.CREATE_COMPANY_NEW, {
        props: {
          afterAuthCallback
        }
      })
    } else {
      profilesStore.isMyProfileFilled && afterAuthCallback()
    }
  }, [profilesStore.isMyProfileFilled, myCompaniesStore.companies, profilesStore.my_profile])

  return (
    <main className={styles.company__main}>
      <div className={styles.root}>
        <Header/>

        <div className={styles.main}>
          {showSidebar && <Sidebar/>}

          <div className={styles.content}>
            {children}

            {invitedCompany && (
              <InvitedModalAccept company={invitedCompany} onClose={handleCloseInviteModal} />
            )}
          </div>
        </div>
      </div>
    </main>
  )
}
