import { BROADCAST_MESSAGES } from '@/config/workers'
import { db, DEXIE_STORES } from '@/database'
import { isSameDates } from '@/utils/date'
import { broadcastTabsMessage } from '@/utils/tabs'
import { liveQuery } from 'dexie'
import { isEqual, merge } from 'lodash'
import { autorun, makeAutoObservable } from 'mobx'

const defaultSettings: ClientSettings = {
  id: 1,

  lastChatsLoadTime: null
}

export const settings: ClientSettings = makeAutoObservable(defaultSettings)

export interface ClientSettings {
  id: 1,

  lastChatsLoadTime: string | null // ISO string
}

export function setSettings(data: ClientSettings) {
  if(!isEqual(data, settings)) {
    merge(settings, data || defaultSettings)
  }
}

export async function initClientSettings() {
  let data

  try {
    data = await db[DEXIE_STORES.CLIENT_SETTINGS].get(1)
  } catch(e) {
    console.error('CLIENT SETTINGS FAILED', e)
  }
  setSettings(data)
  console.log('CLIENT SETTINGS INITED', data)

  autorun(() => {
    // just to make autorun deep
    JSON.stringify(settings)

    db[DEXIE_STORES.CLIENT_SETTINGS].put(settings, 1)
  })

  liveQuery(() => db[DEXIE_STORES.CLIENT_SETTINGS].get(1))
    .subscribe(setSettings)

}
