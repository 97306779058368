import { ChatItemView } from '@/components/chats/entities/ChatItemView/ChatItemView'
import { uiStore } from '@/store/ui/ui.store'
import { Notifications } from '@roolz/icons/Notifications'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './NotificationsRequest.module.scss'
import { Collapse } from '@mui/material'

export const NotificationsRequest = observer(() => {
  if(!('Notification' in window)) {
    return null
  }

  const [canShowNotifications, setCanShowNotifications] = useState(Notification.permission === 'granted')

  const { t } = useTranslation('chat/list')

  function handleClick() {
    Notification.requestPermission()
      .finally(() => {
        setCanShowNotifications(Notification.permission === 'granted')
      })
  }

  return (
    <Collapse in={!canShowNotifications} enter={false} unmountOnExit>
      <ChatItemView
        className={styles.root}

        showInfo={!uiStore.isSidebarCollapsed}
        avatarSlot={<div className={styles.avatar}>
          <Notifications/>
        </div>}
        nameSlot={
          <div className={styles.title} title={t('notifications.title')}>
            {t('notifications.title')}
          </div>
        }
        contentSlot={
          <div className={styles.content} title={t('notifications.allow')}>
            {t('notifications.allow')}
          </div>
        }
        onChatClick={handleClick}
      />
    </Collapse>
  )
})
