import * as React from 'react'

interface Props {

}

export const CheckCircleFill = (props: Props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_10196_85173" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
        <rect width="14" height="14" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_10196_85173)">
        <path d="M6.1694 9.47787L10.0923 5.55495L9.47982 4.94245L6.1694 8.25286L4.5069 6.59036L3.8944 7.20287L6.1694 9.47787ZM7.00065 12.5404C6.2326 12.5404 5.51082 12.3945 4.83532 12.1029C4.15943 11.8112 3.57357 11.4174 3.07773 10.9216C2.5819 10.4258 2.18815 9.83992 1.89648 9.16403C1.60482 8.48853 1.45898 7.76675 1.45898 6.9987C1.45898 6.23064 1.60482 5.50867 1.89648 4.83278C2.18815 4.15728 2.5819 3.57161 3.07773 3.07578C3.57357 2.57995 4.15943 2.1862 4.83532 1.89453C5.51082 1.60286 6.2326 1.45703 7.00065 1.45703C7.76871 1.45703 8.49068 1.60286 9.16657 1.89453C9.84207 2.1862 10.4277 2.57995 10.9236 3.07578C11.4194 3.57161 11.8132 4.15728 12.1048 4.83278C12.3965 5.50867 12.5423 6.23064 12.5423 6.9987C12.5423 7.76675 12.3965 8.48853 12.1048 9.16403C11.8132 9.83992 11.4194 10.4258 10.9236 10.9216C10.4277 11.4174 9.84207 11.8112 9.16657 12.1029C8.49068 12.3945 7.76871 12.5404 7.00065 12.5404Z" fill="#3EBF71"/>
      </g>
    </svg>
  )
}