import {
  useCopyMessage,
  useDeleteMessage,
  useEditMessage,
  useForwardMessage,
  useReplyMessage
} from '@/hooks/chats/message.utils'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { MessageModel } from '@/types/models/chat'
import { Fade } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { Close } from '@roolz/icons/Close'
import * as React from 'react'
import { ReactNode, useCallback } from 'react'
import styles from '@/components/chats/features/ChatBottomPanels/MessageActions/MessageActions.module.scss'
import { Forward } from '@roolz/icons/chats/Forward'
import { Reply } from '@roolz/icons/chats/Reply'
import { Copy } from '@roolz/icons/Copy'
import { Delete2 } from '@roolz/icons/Delete2'
import { Edit2 } from '@roolz/icons/Edit2'
import cn from 'classnames'

interface Props {
  message: MessageModel
}

export const MessageActions = ({
  message
}: Props) => {
  const handleCopy = useCopyMessage(message)
  const handleForward = useForwardMessage(message)
  const [canEdit, handleEdit] = useEditMessage(message)
  const [canDelete, handleDelete] = useDeleteMessage(message)
  const [canReply, handleReply] = useReplyMessage(message)

  const handleClose = useCallback(() => {
    chatSessionsStore.selectedMessage = null
  }, [])

  const clickWrapper = (cb: () => any) => () => {
    handleClose()
    cb()
  }

  return (
    <Collapse in appear collapsedSize={30}>
      <div className={styles.root}>
        <div>
          {canDelete ? (
            <Button onClick={clickWrapper(handleDelete)}>
              <Delete2 size={16}/>
            </Button>
          ) : (
            <Button style={{ visibility: 'hidden' }}>
              &#8205;
            </Button>
          )}
        </div>
        <div className={styles.group}>
          {canReply && (
            <Button onClick={clickWrapper(handleReply)}>
              <Reply size={22}/>
            </Button>
          )}
          <Button onClick={clickWrapper(handleForward)}>
            <Forward size={18}/>
          </Button>
          <Button onClick={clickWrapper(handleCopy)}>
            <Copy size={16} color="#8E8E93"/>
          </Button>
          {canEdit && (
            <Button onClick={clickWrapper(handleEdit)}>
              <Edit2 size={16}/>
            </Button>
          )}
        </div>
        <div>
          <Button
            onClick={handleClose}
            className={styles.closeButton}
          >
            {/*<div className={styles.closeButton__icon}>*/}
            <Close color='#8E8E93'/>
            {/*</div>*/}
          </Button>
        </div>
      </div>
    </Collapse>
  )
}

const Button = ({
  children,
  onClick,
  style,
  className
}: {
  children: ReactNode
  onClick?: () => any
  style?: any
  className?: any
}) => {
  return (
    <button
      className={cn(styles.icon, className)}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  )
}
