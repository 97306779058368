import { useId } from 'react'

interface Props {
  color?: string
  size?: number
}

export const ChatBubble = ({
  color = '#1C1B1F',
  size = 24
}: Props) => {
  const id = useId()

  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <mask id={id} maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
          <rect width='24' height='24' fill='#D9D9D9'/>
        </mask>
        <g mask={`url(#${id})`}>
          <path
            d='M2 19.575V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H6L3.7 20.3C3.38333 20.6167 3.02083 20.6875 2.6125 20.5125C2.20417 20.3375 2 20.025 2 19.575Z'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
}
