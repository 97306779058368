import { tFunc, tFuncParams } from '@roolz/sdk/types'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { isServer } from '@roolz/sdk/utils/ssr'
import { createContext, ReactNode, useContext, useEffect, useLayoutEffect, useState } from 'react'


interface SdkContextInterface {
  t: tFunc // function for localization
  lang: string
  getServerDatetime: () => Date
}

const SdkContext = createContext<SdkContextInterface>({} as SdkContextInterface)

interface SdkProviderProps {
  children: ReactNode
  value: SdkContextInterface
}

const SdkProvider = ({ children, value }: SdkProviderProps) => {
  useEffect(() => {
    const lang = value.lang.replace(/-.*$/, '')

    dayjs.locale(lang)
  }, [value.lang])

  return (
    <SdkContext.Provider value={value}>
      {children}
    </SdkContext.Provider>
  )
}

const useSdkContext = () => {
  return useContext(SdkContext)
}

const useSdkTranslation = (ns?: string) => {
  const ctx = useSdkContext()

  const t = typeof ns === 'string'
    ? (key: string, params?: tFuncParams) => {
      if(key.indexOf(':') !== -1) {
        return ctx.t(key, params)
      }

      return ctx.t(`${ns}:${key}`, params)
    }
    : ctx.t

  return {
    t,
    lang: ctx.lang
  }
}

export {
  SdkContext,
  SdkProvider,
  useSdkContext,

  useSdkTranslation
}
