import { Chat } from "@roolz/types/api/chats"

export function getChatAvatarLetters(chat: Chat) {
  return chat.name
    ? chat.name?.trim()?.[0]
    : ''
}

export function padNumber(number: string | number, width: number, symbol: string | number = '0') {
  if(typeof number === 'number') number = String(number)
  if(typeof symbol === 'number') symbol = String(symbol)

  return number.length >= width
    ? number
    : new Array(width - number.length + 1).join(symbol) + number;
}

export function recoursiveArraySome(a: Array<unknown>, comparator: (item: any) => boolean): boolean {
  return Object.values(a).some(function check(item) {
    try {
      if(comparator(item)) return true
    } catch(e) {
      // console.log(e)
    }

    if(Array.isArray(item)) {
      if(recoursiveArraySome(item, comparator)) return true
    }
    else if(typeof item === 'object' && recoursiveArraySome(Object.values(item), comparator)) {
      return true
    }
    return false
  })
}
