import { get, set, remove } from './cookie'

const AFTER_EMAIL_INVITE_KEY = 'after_email_invite_key'

const { hostname } = new URL(process.env.NEXT_PUBLIC_ROOLZ_STATIC_URL ?? process.env.REACT_APP_ROOLZ_STATIC_URL)
const domain = hostname.split('.').slice(-2).join('.')

export const setCompanyAfterInvite = (companyId: string) => set(AFTER_EMAIL_INVITE_KEY, companyId, { expires: '0', domain })

export const getCompanyAfterInvite = () => get(AFTER_EMAIL_INVITE_KEY) || null

export const removeCompanyAfterInvite = () => remove(AFTER_EMAIL_INVITE_KEY, { domain })
