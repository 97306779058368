import { MessageGroup } from '@/components/chats/entities/MessageGroup/MessageGroup'
import { SystemMessageGroup } from '@/components/chats/entities/SystemMessageGroup/SystemMessageGroup'
import { EmptyChatPlaceholder } from '@/components/chats/shared/chat/EmptyChatPlaceholder/EmptyChatPlaceholder'
import { FloatingMessagesDate } from '@/components/chats/shared/message/FloatingMessagesDate/FloatingMessagesDate'
import { MessageContextMenu } from '@/components/chats/features/MessageContextMenu/MessageContextMenu'
import { ScrollToEndButton } from '@/components/chats/shared/chat/ScrollToEndButton/ScrollToEndButton'
import { SelfChatPlaceholder } from '@/components/chats/shared/chat/SelfChatPlaceholder/SelfChatPlaceholder'
import { UnreadMessagesPanel } from '@/components/chats/shared/UnreadMessagesPanel/UnreadMessagesPanel'
import { divideMessagesToGroups } from '@/components/chats/utils'
import { CHAT_MESSAGES_PER_BUNCH } from '@/config/const'
import { useElementScrollPosition } from '@/hooks/useElementScrollPosition'
import gsap from '@/plugins/gsap'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore, messagesDescComparator } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { systemStore } from '@/store/system/system.store'
import { ChatModel, isSystemMessage, MessageModel } from '@/types/models/chat'
import { SmthWentWrong } from '@roolz/icons/lottie/SmthWentWrong'
import { ErrorFallback } from '@roolz/sdk/components/ErrorFallback'
import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { useContextMenuState } from '@roolz/sdk/hooks/useContextMenuState'
import { useIsElementScrolling } from '@roolz/sdk/hooks/useIsElementScrolling'
import { useResizeObserver } from '@roolz/sdk/hooks/useResizeObserver'
import { useTasksQueue } from '@roolz/sdk/hooks/useTasksQueue'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { isNumber } from '@roolz/sdk/utils/types'
import { getElementOffsetRelativeTo, isElementAboveViewport, isElementInViewport } from '@roolz/sdk/utils/viewport'
import {
  ChatType,
  Message,
  MessageState,
  MessageStatus,
  PcpStatus,
  PcpType,
  SystemMessageEvent
} from '@roolz/types/api/chats'
import { Profile } from '@roolz/types/api/profiles'
import { groupBy, max, min, throttle } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import styles from '@/components/chats/features/MessagesWindow/MessagesWindow.module.scss'
import cn from 'classnames'
import Scrollbar from 'smooth-scrollbar'

interface Props {
  chat: ChatModel
}

const SHOW_SCROLL_TO_END_DISTANCE_PX = 50

export const MessagesWindow = observer(forwardRef(({
  chat
}: Props, refControls: any) => {
  const ref = useRef<any>()

  const { t } = useTranslation('chat/common')

  const [isReady, setIsReady] = useState<boolean>(false)

  // TODO probably replace by prop
  const isChatReady = chatsStore.isChatReady(chat.id)

  useLayoutEffect(() => { setIsReady(false) }, [chat])
  useLayoutEffect(() => { isChatReady && setIsReady(true) }, [isChatReady, chat])

  const unreadMessagesPanelRef = useRef<any>()

  const handleScroll = useCallback(throttle(() => {
    // checkScrollToEndVisibility()

    if(ref.current) {
      // TODO bind chat id here some way
      // chatsStore.chatScrollPositions[chat.id] = ref.current.scrollTop
    }
  }, 50, { leading: true, trailing: true }), [])


  // useEffect(() => {
  //   checkScrollToEndVisibility()
  // }, [ref, chat.messages, chat.messages?.length])

  // useLayoutEffect(() => {
    // if(isChatReady) {
    //   setIsReady(true)


      // if(ref.current) {
      //   if(unreadMessagesPanelRef.current) {
      //     ref.current.scrollTop = unreadMessagesPanelRef.current.offsetTop
      //       + unreadMessagesPanelRef.current.offsetParent.offsetTop
      //       - 40
      //   } else if(chatsStore.chatScrollPositions?.[chat.id] !== undefined) {
      //     ref.current.scrollTop = chatsStore.chatScrollPositions?.[chat.id]
      //   } else {
      //     goToEnd()
      //   }
      //
      //   checkScrollToEndVisibility()
      // }
    // }
  // }, [isChatReady, chat])

  // useLayoutEffect(() => {
  //   if(isInEnd && isInited.current) {
  //     goToEnd()
  //     checkScrollToEndVisibility()
  //   }
  // }, [chat.messages, chat.messages?.length])

  const placeholder = useMemo(() => {
    // if(!isChatReady) {
    //   return <ChatLoading/>
    // }

    let messagesCount = chat.totalMessagesCount

    if(chat.type === ChatType.SELF_CHAT) {
      // sometimes Messaging Service adds system message to the end of self chat,
      // sometimes not
      if(messagesCount === 1
        && isSystemMessage(chat.messages?.[0])
        && chat.messages?.[0]?.decodedContent?.event === SystemMessageEvent.SELF_CHAT_FIRST_MESSAGE) {
        messagesCount -= 1
      }

      if(messagesCount === 0) {
        return (
          <SelfChatPlaceholder profile={profilesStore.my_profile as Profile}/>
        )
      }
    }

    if(chat.visibleMessages.length < 1) {
      return (
        <EmptyChatPlaceholder/>
      )
    }

    return null
  }, [chat, chat.messages, chat.messages?.length
    // isChatReady
  ])

  return (
    <ErrorFallback fallback={<ErrorPlaceholder/>}>
      <div
        className={styles.content}
        onScrollCapture={handleScroll}
      >
        {placeholder ? (
          <div className={styles.placeholder}>
            {placeholder}
          </div>
        ) : (
          <Messages
            ref={refControls}
            isChatReady={isReady}
            unreadMessagesPanelRef={unreadMessagesPanelRef}
            chat={chat}
          />
        )}
      </div>
    </ErrorFallback>
  )
}))


interface DateRenderInfo {
  type: 'date',
  date: string | undefined
}

interface MessageGroupRenderInfo {
  type: 'message-group',
  messages: MessageModel[]
}

const Messages = observer(forwardRef(({
  isChatReady,
  chat,
  unreadMessagesPanelRef
  // parentRef
}: {
  isChatReady: boolean
  chat: ChatModel,
  unreadMessagesPanelRef: any
}, refControls: any) => {
  const outerRef = useRef<any>()

  const isScrolling = useIsElementScrolling(outerRef)
  const { isInEnd, getDistanceToEnd } = useElementScrollPosition({ element: outerRef })
  const isInEndRef = useStateRef(isInEnd)

  const isLoading = useRef<boolean>(false)

  const previousScrollHeight = useRef<number>(0)
  const previousScrollPos = useRef<number>(0)

  const lastScroll = useRef<number>(0)


  // @ts-ignore
  const messages: MessageModel[] = chat.messagesInViewport

  const messageRefs = useRef<Record<Message['number'], any>>({})

  const minMessagesNumber = useMemo(() => min(messages.map(item => item.number)) || 0, [messages])
  const maxMessagesNumber = useMemo(() => max(messages.map(item => item.number)) || 0, [minMessagesNumber, messages])

  // Flag to stop loading more on scroll, for example if scroll animation is in progress
  const shouldLoadMore = useRef(true)

  const prevLastMessageNumber = useRef<number | undefined>()
  useEffect(() => {
    prevLastMessageNumber.current = maxMessagesNumber
  }, [maxMessagesNumber])

  const shouldRememberLastReadMessageIndex = (() => {
    return (
      chat.own_pcp.last_read_message_index < chat.totalMessagesCount - chat.nonSentMessages.length
      // || chat.own_pcp.last_read_message_index === 0
    ) && chat.type !== ChatType.SELF_CHAT
      && chat.own_pcp.status === PcpStatus.ACTIVE

  })()

  const [lastReadMessageIndex] = useState(
    shouldRememberLastReadMessageIndex ? chat.own_pcp.last_read_message_index : null
  )

  const [addMessagesLayoutTask, flushMessagesLayoutTasks] = useTasksQueue()

  const prevHeight = useRef<number>(0)

  useResizeObserver({
    element: outerRef.current,
    onResize() {
      if(!outerRef.current) {
        return
      }

      if(prevHeight.current) {
        const diff = outerRef.current.clientHeight - prevHeight.current

        if(isInEndRef.current === true) {
          goToEnd()
        } else {
          outerRef.current.scrollTop -= diff
        }
      }
      lastScroll.current = outerRef.current.scrollTop
      prevHeight.current = outerRef.current.clientHeight
    }
  })


  function queueScrollRestoration() {
    if(!outerRef.current) {
      console.warn('CAN`T queueScrollRestoration because of empty outerRef.current')
      return
    }

    previousScrollPos.current = outerRef.current.scrollTop

    addMessagesLayoutTask(() => {
      outerRef.current.scrollTop = previousScrollPos.current + outerRef.current.scrollHeight - previousScrollHeight.current
    })
  }

  const visibleMessages = useMemo(() => {
    let list: MessageModel[] = [...messages]

    const firstMessage = list?.[0]

    // TODO filter deleted messages
    if(chat.type === ChatType.SELF_CHAT
      && firstMessage
      && isSystemMessage(firstMessage)
      && firstMessage?.decodedContent?.event === SystemMessageEvent.SELF_CHAT_FIRST_MESSAGE
    ) {
      list.splice(0, 1)
    }

    list = list.filter(item => item.state !== MessageState.NOT_DISPLAYED)

    return list.sort(messagesDescComparator)
  }, [messages])

  const visibleMessagesByDates = useMemo(() => {
    return groupBy(visibleMessages, (message) => {
      return dayjs(message.created_at).format('MM/DD/YYYY')
    })
  }, [visibleMessages])

  useEffect(() => {
    if(messageRefs.current) {
      const visibleNumbers = visibleMessages.map(item => item.number)

      Object.entries(messageRefs.current).forEach(([number]) => {
        if(!visibleNumbers.includes(Number(number))) {
          delete messageRefs.current[number as any]
        }
      })
    }
  }, [visibleMessages])

  const showNewMessagesLabelBeforeMsg = (message: MessageModel) =>
    chat.type !== ChatType.SELF_CHAT
    // && lastReadMessageIndex !== 0
    && Math.floor(lastReadMessageIndex ?? -1) === message?.number - 1
    // && chat.unreadMessagesCount > 0

  const isOwnMessage = (message: MessageModel): boolean => message.isOwnMessage
  const isOwnGroup = (messages: MessageModel[]) => {
    if(chat.type === ChatType.CHANNEL) {
      const isAdmin = [PcpType.ADMIN, PcpType.OWNER].includes(chat.own_pcp.type)

      return isAdmin && chat.own_pcp.status === PcpStatus.ACTIVE
    }

    return isOwnMessage(messages[0])
  }

  const getScreensOnTopCount = () => outerRef.current.scrollTop / outerRef.current.clientHeight
  const getScreensOnBottomCount = () => ((outerRef.current.scrollHeight - outerRef.current.scrollTop) / outerRef.current.clientHeight) - 1


  const shouldLoadPrevious = useCallback(() => {
    if(!outerRef.current) return false

    return getMessagesCountAboveViewport() <= CHAT_MESSAGES_PER_BUNCH
      && minMessagesNumber > chat.own_pcp.min_message_index
  }, [minMessagesNumber, chat.own_pcp.min_message_index])

  const shouldLoadFollowing = useCallback(() => {
    if(!outerRef.current) return false

    return getScreensOnBottomCount() <= 3 && maxMessagesNumber < (chat.own_pcp.max_message_index || chat.totalMessagesCount)
  }, [maxMessagesNumber, chat.own_pcp.max_message_index, chat.totalMessagesCount])

  const loadMoreIfNeeded = useCallback(() => {
    if(!shouldLoadMore.current) {
      return
    }

    if(shouldLoadPrevious()) {
      if(!isLoading.current) {
        isLoading.current = true

        chatsService.loadPreviousMessagesBunch(chat, minMessagesNumber - 1)
          .finally(() => {
            queueScrollRestoration()
            chatSessionsStore.setChatViewInfo(chat.id, {
              minViewportMessageIndex: Math.max(0, chat.own_pcp?.min_message_index, minMessagesNumber - CHAT_MESSAGES_PER_BUNCH + 1)
            })
            isLoading.current = false
          })
      }
    }

    if(
      getMessagesCountAboveViewport() > CHAT_MESSAGES_PER_BUNCH * 2
      // getScreensOnTopCount() >= 10
      // && getMessagesCountAboveViewport() > CHAT_MESSAGES_PER_BUNCH + 2
    ) {
      // TODO check that new value wont be greater than maxViewportMessageIndex
      chatSessionsStore.setChatViewInfo(chat.id, {
        minViewportMessageIndex: minMessagesNumber + CHAT_MESSAGES_PER_BUNCH
      })

      queueScrollRestoration()
    }

    // if(shouldLoadFollowing()) {
    //   loadMore(false, maxMessagesNumber, () => {
    //     return shouldLoadFollowing()
    //   })
    // }
  }, [shouldLoadPrevious, shouldLoadFollowing, minMessagesNumber, maxMessagesNumber])

  const [showScrollToEnd, setShowScrollToEnd] = useState<boolean>(false)

  const checkScrollToEndVisibility = useCallback(() => {
    setShowScrollToEnd(getDistanceToEnd() > SHOW_SCROLL_TO_END_DISTANCE_PX)
  }, [chat, showScrollToEnd, outerRef.current])

  const lastUnreadMsgInViewport = useRef(-1)

  const sendReadStatus = useCallback(throttle(() => {
    if(lastUnreadMsgInViewport.current > chat.own_pcp.last_read_message_index) {
      const messageModel = chatsStore.getChatMessageByNumber(chat.id, lastUnreadMsgInViewport.current)

      if(!messageModel) {
        return
      }

      chatsService.updateMessageStatus({
        chat_id: messageModel.chat_id,
        client_message_id: messageModel.client_message_id,
        message_number: messageModel.number,
        sender_id: messageModel.sender_id
      }, MessageStatus.READ)
    }
  }, 100), [chat, isChatReady])

  const getMessagesCountAboveViewport = () => {
    return Object.values(messageRefs.current).reduce((cnt, el) => {
      try {
        cnt += isElementAboveViewport(el, outerRef.current)
      } catch(e) {
        console.log(e)
      }

      return cnt
    }, 0)
  }

  const checkUnreadMessages = useCallback(() => {
    if(chat.own_pcp.status !== PcpStatus.ACTIVE) return

    if(!outerRef.current) return
    const outerEl = outerRef.current

    if(systemStore.tabVisibility !== 'visible') {
      return
    }

    let updated = false

    const viewportBottom = outerEl.scrollTop + outerEl.clientHeight

    for(let i = chat.count_messages; i > chat.own_pcp.last_read_message_index; i--) {
      const msg = messageRefs.current[i]

      if(!msg) continue

      const msgTop = msg.offsetTop + msg.offsetParent.offsetTop
      const msgBottom = msgTop + msg.clientHeight - 10 // 10 for paddings, etc

      if(msgBottom <= viewportBottom && i > lastUnreadMsgInViewport.current) {
        lastUnreadMsgInViewport.current = i
        updated = true
        break
      }
    }

    if(updated) sendReadStatus()
  }, [chat.own_pcp.status, chat.own_pcp.last_read_message_index, chat.totalMessagesCount, systemStore.tabVisibility])

  useEffect(() => {
    if(!isChatReady) return

    if(systemStore.tabVisibility === 'visible') {
      checkUnreadMessages()
    }
  }, [isChatReady, systemStore.tabVisibility, visibleMessagesByDates])

  // TODO add the same handler for resize of minichat event
  const handleScroll = useCallback(throttle(() => {
    lastScroll.current = outerRef.current.scrollTop

    chatSessionsStore.setChatViewInfo(chat.id, {
      scrollTop: outerRef.current.scrollTop
    })

    loadMoreIfNeeded()
    checkScrollToEndVisibility()
    checkUnreadMessages()
  }, 40, { leading: true }), [loadMoreIfNeeded])


  const goToEnd = useCallback(() => {
    // TODO if opened messages in middle of conversation, need to load messages at the end (starting from unread if exist) first
    if(outerRef.current) {
      const el = outerRef.current
      el.scrollTop = el.scrollHeight + el.scrollTop
    }
  }, [])

  const goToUnreadPanelOrEnd = useCallback(() => {
    if(unreadMessagesPanelRef.current) {
      outerRef.current.scrollTop = unreadMessagesPanelRef.current.offsetTop
        + unreadMessagesPanelRef.current.offsetParent.offsetTop
        - 30

      return
    }

    goToEnd()
  }, [])

  useLayoutEffect(() => {
    if(isChatReady && visibleMessages.length) {
      const prevScrollTop = chatSessionsStore.chatViewInfos[chat.id]?.scrollTop

      if([PcpStatus.GONE, PcpStatus.DELETED].includes(chat.own_pcp.status)) {
        goToUnreadPanelOrEnd()
      } else if(chat.own_pcp.last_read_message_index !== maxMessagesNumber) {
        const firstUnreadMessage = messageRefs.current[Math.floor(chat.own_pcp.last_read_message_index) + 1]

        if(firstUnreadMessage) {
          outerRef.current.scrollTop = firstUnreadMessage.offsetTop + firstUnreadMessage.offsetParent.offsetTop - 100
          return
        } else {
          goToUnreadPanelOrEnd()
        }
      } else if(isNumber(prevScrollTop)) {
        outerRef.current.scrollTop = prevScrollTop
      } else {
        goToUnreadPanelOrEnd()
      }

    }
  }, [isChatReady])

  useLayoutEffect(() => {
    if(outerRef.current) {
      if(isChatReady && isInEnd) {
        goToEnd()
        // goToUnreadPanelOrEnd()

        // checkScrollToEndVisibility()
      }
    }

    flushMessagesLayoutTasks()

    if(isChatReady) {
      setTimeout(() => {
        checkUnreadMessages()
      }, 300)
    }

    previousScrollHeight.current = outerRef.current.scrollHeight


    // const prevLastMessageNum = prevLastMessageNumber.current
    // const prevLastMessageNum = prevLastMessageNumber.current
    // if(isInEnd && prevLastMessageNum && prevLastMessageNum < maxMessagesNumber) {
    //   chatSessionsStore.setChatViewInfo(chat.id, {
    //     minViewportMessageIndex: (chatSessionsStore.chatViewInfos?.[chat.id]?.minViewportMessageIndex ?? 0) + (maxMessagesNumber - prevLastMessageNum)
    //   })
    // }

    // if(previousScrollHeight.current) {
    //   console.log('LOL: WRITE NEW', outerRef.current.scrollHeight)
    //   outerRef.current.scrollTop = previousScrollPos.current + outerRef.current.scrollHeight - previousScrollHeight.current
    // }

    // if(isChatReady && isInEnd) {
    //   goToEnd()
    // }
  }, [visibleMessagesByDates])

  useEffect(checkScrollToEndVisibility, [visibleMessages])

  function handleJumpDown() {
    const outer = outerRef.current

    if(unreadMessagesPanelRef.current && outer) {
      const unreadPanelOffsetTop = getElementOffsetRelativeTo(unreadMessagesPanelRef.current, outer)
      const scrollBottomPosition = outer.scrollTop + outer.clientHeight - 16 // 16 is padding, seems like crunch

      const isUnreadLabelUnderViewport = unreadPanelOffsetTop > scrollBottomPosition

      if(isUnreadLabelUnderViewport) {
        return goToUnreadPanelOrEnd()
      }
    }

    goToEnd()
  }

  const {
    showContextMenu,
    hideContextMenu,
    ctx
  } = useContextMenuState<MessageModel>()

  const handleContextMenu = useCallback((message: MessageModel, event: React.MouseEvent<HTMLDivElement>) => {
    if(IS_MOBILE) {
      return
    }

    if(message.state !== MessageState.ACTIVE
      || isSystemMessage(message)) {
      return
    }

    showContextMenu(message, {
      top: event.clientY,
      left: event.clientX
    })
  }, [])


  // const isMessageInViewport = (number: Message["number"]) => {
  //   const messageRef = messageRefs.current[number]
  //
  //   if(!messageRef) {
  //     return
  //   }
  //
  //   const top = messageRef?.offsetTop + messageRef.offsetParent?.offsetTop
  //
  //   return messageRef
  //     // && messageRef.
  // }

  function goToMessage(msg: Message, highlight = true) {
    return true
    if(msg.chat_id !== chat.id) {
      return
    }

    const messageEl = messageRefs.current[msg.number]

    if(messageEl && outerRef.current) {
      const tl = gsap.timeline()

      if(!isElementInViewport(messageEl, outerRef.current)) {
        shouldLoadMore.current = false

        tl.to(outerRef.current, {
          duration: .4,
          ease: 'power2',

          scrollTo: {
            y: messageEl,
            offsetY: 40,
            autoKill: true
          },
          onComplete: () => {
            shouldLoadMore.current = true
            loadMoreIfNeeded()
          }
        })
      }

      tl.to(messageEl, {
        background: 'rgba(238,244,250,0.7)',
        duration: 0.5
      }, '<')
      .to(messageEl, {
        background: '#FFFFFF',
        duration: 0.7
      }, '>')
    }
  }

  const handleGoToMessage = useCallback((message: MessageModel) => {
    return true
    if(message.chat_id === chat.id) {
      const messageRef = messageRefs.current[message.number]

      // TODO also need to check by message number boundaries
      if(messageRef) {
        goToMessage(message)
      } else {
        // TODO
      }
    }

  }, [chat])

  useImperativeHandle(refControls, () => {
    return {
      goToEnd,
      queueScrollRestoration,
      goToMessage
    }
  }, [])

  useEffect(() => {
    Scrollbar.init(outerRef.current, {
      continuousScrolling: false,
      damping: 0.8,

    })
  }, [])

  return (<>
      <div
        ref={outerRef as any}

        className={cn(styles.messages, {
          [styles.messagesMobile]: IS_MOBILE
        })}
        onScroll={handleScroll}
      >
        <div className={styles.messages__content}>
          {Object.entries(visibleMessagesByDates).map(([date, messages]) => (
            <div
              key={date}
              className={styles.messagesByDay}
              style={{ position: 'relative' }}
            >
              <FloatingMessagesDate
                date={messages[0].created_at}
                isScrolling={isScrolling}
              />
              {divideMessagesToGroups({ messages, isOwnMessage, chat, lastReadMessageIndex })
                .map((messages) => (
                  <Fragment key={messages[0].id}>
                    {showNewMessagesLabelBeforeMsg(messages[0]) && (
                      <UnreadMessagesPanel
                        ref={unreadMessagesPanelRef}
                      />
                    )}
                    {messages.every(isSystemMessage) ? (
                      <SystemMessageGroup
                        key={messages[0].id + messages.at(-1)?.id}
                        messages={messages}
                        refsContainer={messageRefs}
                      />
                    ) : (
                      <MessageGroup
                        messages={messages}
                        isOwn={isOwnGroup(messages)}
                        refsContainer={messageRefs}
                        onContextMenu={handleContextMenu}
                        onGoToMessage={handleGoToMessage}

                        highlightedMessageId={ctx.currentModel?.id ?? chatSessionsStore.selectedMessage?.id ?? null}
                      />
                    )}
                  </Fragment>
                ))}
            </div>
          ))}
        </div>

        <MessageContextMenu
          show={ctx.show}
          position={ctx.position}
          message={ctx.currentModel}

          onClose={hideContextMenu}
        />

      </div>

      <ScrollToEndButton
        className={styles.scrollToEnd}
        isVisible={showScrollToEnd}
        newMessagesCount={chat.unreadMessagesCount}

        onClick={handleJumpDown}
      />
    </>
  )
}))

export const ErrorPlaceholder = () => {
  return (
    <div className={styles.errorPlaceholder}>
      <SmthWentWrong className={styles.errorPlaceholder__image}/>
    </div>
  )
}
