import { setLocale } from 'yup'
import i18n from '@/plugins/i18n'

const LOCALE_NAMESPACE = 'validation'

const t = (key: string, ...params: any[]) => i18n.t(`${LOCALE_NAMESPACE}:` + key, ...params)

setLocale({
  mixed: {
    required: () => t('required'),
  },

  string: {
    length: ({ length }) => t('string_length', { length }),
    min: ({ min }) => t('string_min_length', { min }),
    max: ({ max }) => t('string_max_length', { max }),
    email: () => t('email'),
    url: () => t('url'),
    matches: () => t('matches')
  },

  number: {
    min: ({ min }) => t('min_number', { min }),
    max: ({ max }) => t('max_number', { max }),
    integer: () => t('integer'),
    lessThan: () => t('lessThan'),
    moreThan: () => t('moreThan'),
    negative: () => t('negative'),
    positive: () => t('positive'),
  },

  array: {
    min: ({ min }) => t('array_min', { min }),
    max: ({ max }) => t('array_max', { max }),
  }
})
