import { IS_DEV } from '@roolz/sdk/utils/env'
import { makeAutoObservable } from 'mobx'

export interface SocketPackage {
  id: string | number
  type: 'in' | 'out'
  body: any
  date: Date | string
}

class DebugStore {
  private socketPackages: SocketPackage[] = []

  getSocketPackages() {
    return this.socketPackages
  }

  cleanSocketPackages() {
    this.socketPackages = []
  }

  constructor() {
    makeAutoObservable(this)
  }
  logSocketReq(type: SocketPackage['type'], body: SocketPackage['body']) {
    if(!IS_DEV) return

    console.log('ADD LOG')

    this.socketPackages.unshift({
      type,
      body,
      date: new Date(),
      id: String(Math.random())
    })
  }
}

export const debugStore = new DebugStore()
