import { Url } from '@roolz/types/scalars'
import { Axios, AxiosResponse } from 'axios'

interface GetParams {
  // uri: string,
  params?: { [key: string]: any }, // get params
  headers?: { [key: string]: any },
}

interface PostParams extends GetParams {
  body?: unknown
}

export class BaseApiClient {
  constructor(
    readonly transport: Axios,
    readonly baseUrl: Url
  ) {
  }

  protected get<TResponse = any>(uri: string, params: GetParams = {}): Promise<AxiosResponse<TResponse>> {
    return this.transport.get<TResponse>(this.getFinalUrl(uri), {
      params: params.params,
      headers: params.headers
    })
  }

  protected post<TResponse = any>(uri: string, params: PostParams = {}): Promise<AxiosResponse<TResponse>> {
    return this.transport.post<TResponse>(this.getFinalUrl(uri), JSON.stringify(params.body), {
      params: params.params,
      headers: params.headers
    })
  }

  protected put<TResponse = any>(uri: string, params: PostParams = {}): Promise<AxiosResponse<TResponse>> {
    return this.transport.put<TResponse>(this.getFinalUrl(uri), JSON.stringify(params.body), {
      params: params.params,
      headers: params.headers
    })
  }

  protected patch<TResponse>(uri: string, params: PostParams = {}): Promise<AxiosResponse<TResponse>> {
    return this.transport.patch<TResponse>(this.getFinalUrl(uri), JSON.stringify(params.body), {
      params: params.params,
      headers: params.headers
    })
  }

  protected delete<TResponse>(uri: string, params: PostParams = {}): Promise<AxiosResponse<TResponse>> {
    return this.transport.delete(this.getFinalUrl(uri), {
      data: JSON.stringify(params.body),
      params: params.params,
      headers: params.headers
    })
  }

  protected getFinalUrl(uri: string) {
    return this.baseUrl + uri
  }
}
