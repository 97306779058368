import { BaseApiClient } from '@/api/clients/_base.client'
import { identityHttp } from '@/api/transport/http/identity.http'
import { messagingHttp } from '@/api/transport/http/messaging.http'
import {
  changePasswordRequest,
  changePasswordResponse,
  checkIfEmailBusyRequest,
  checkIfEmailBusyResponse,
  checkNicknameBusynessRequest,
  checkNicknameBusynessResponse,
  checkOwnPasswordRequest,
  checkOwnPasswordResponse,
  createVerifiedPasswordTokenRequest,
  createVerifiedPasswordTokenResponse,
  deleteOwnEmailRequest,
  deleteOwnEmailResponse,
  deleteOwnPasswordRequest,
  deleteOwnPasswordResponse,
  deleteOwnPhoneResponse,
  deleteOwnProfileRequest,
  Profile,
  registerProfileRequest,
  updateMyProfileRequest,
  updateOwnEmailRequest,
  updateOwnEmailResponse,
  updateOwnPhoneRequest,
  updateOwnPhoneResponse,
  untieSocialNetworkRequest,
  TiedSocialNetworks,
  LoadProfilesByIdsRequest,
  UserCompany,
  Device
} from '@roolz/types/api/profiles'


class ProfilesClient extends BaseApiClient {
  getById(id: Profile['id']) {
    return this.get<Profile>('/v1/profile/' + id)
  }

  getByIds(params: LoadProfilesByIdsRequest) {
    // // @ts-ignore
    // params.profile_ids = params.profile_ids.join(',')

    return this.get<Profile[]>('/v1/profile/list/by_ids', { params })
  }

  retrieveMe() {
    return this.get<Profile & { companies?: UserCompany[] }>('/v1/me')
  }

  retrieveMyDevice() {
    return this.get<Device>('/v1/me/device')
  }

  getMySocialNetworks() {
    return this.get<TiedSocialNetworks>('/v1/profile/own/social_networks')
  }

  untieSocialNetwork(params: untieSocialNetworkRequest) {
    return this.delete<TiedSocialNetworks>('/v1/profile/own/untie_social_network_auth', { params })
  }

  checkIfEmailBusy(params: checkIfEmailBusyRequest) {
    return this.get<checkIfEmailBusyResponse>('/v1/profile/check_email_is_busy', { params })
  }

  registerProfile(body: registerProfileRequest) {
    return this.post('/v1/profile/registration', { body })
  }

  updateMyProfile(body: Partial<updateMyProfileRequest>) {
    return this.patch<Profile>('/v1/me', { body })
  }

  deleteOwnProfile(body: deleteOwnProfileRequest) {
    return this.post<Profile>('/v1/profile/own/delete', { body })
  }

  checkNicknameBusyness(params: checkNicknameBusynessRequest) {
    return messagingHttp.get<checkNicknameBusynessResponse>('/v1/nickname/check_nickname_is_busy', { params })
  }

  checkOwnPassword(params: checkOwnPasswordRequest) {
    return this.get<checkOwnPasswordResponse>('/v1/profile/own/check_password', { params })
  }

  createVerifiedPasswordToken(body: createVerifiedPasswordTokenRequest) {
    return this.post<createVerifiedPasswordTokenResponse>('/v1/profile/own/create_verified_password_token', { body })
  }

  changePassword(body: changePasswordRequest) {
    return this.post<changePasswordResponse>('/v1/update_password_profile', { body })
  }

  deleteOwnEmail(body: deleteOwnEmailRequest) {
    return this.post<deleteOwnEmailResponse>('/v1/delete_email_profile', { body })
  }

  updateOwnEmail(body: updateOwnEmailRequest) {
    return this.post<updateOwnEmailResponse>('/v1/update_email_profile', { body })
  }

  updateOwnPhone(body: updateOwnPhoneRequest) {
    return this.post<updateOwnPhoneResponse>('/v1/profile/own/change_phone', { body })
  }

  deleteOwnPhone() {
    return this.post<deleteOwnPhoneResponse>('/v1/profile/own/delete_phone')
  }
  deleteOwnPassword(body: deleteOwnPasswordRequest) {
    return this.post<deleteOwnPasswordResponse>('/v1/delete_password_profile', { body })
  }
}

export const profilesClient = new ProfilesClient(identityHttp, '')
