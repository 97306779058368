import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { Message } from '@roolz/types/api/chats'
import { MessageModel } from '@/types/models/chat'
import { clone } from 'lodash'
import { sha256Encode } from "@roolz/sdk/utils/hashing"

export function MessageModelFactory(message: Message): MessageModel {
  return ({
    ...clone(message),

    get owner() {
      return profilesStore.findProfile(this.sender_id)
    },

    get chat() {
      return chatsStore.chats[this.chat_id]
    },

    get decodedContent(): null | object {
      try {
        return JSON.parse(this.content) || null
      } catch(e) {
        console.log(e)
      }
      return null
    },

    get isOwnMessage(): boolean {
      return this.sender_id === profilesStore.my_profile?.id
        || this.sender_hash === sha256Encode((profilesStore.my_profile?.personal_key ?? '') + '_' + this.number)
    },

    // get totalMessagesCount() {
    //   // TODO add
    //   return (this.chat?.count_messages ?? 0) + (this.chat?.nonSendMessagesCount ?? 0)
    // }
  })
}

