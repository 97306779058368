// @ts-ignore
import { v4 as uuidv4 } from 'uuid'

export class SmartQueue<Payload> {
  private lastDistributedTaskId: string | undefined
  private queue: Array<Task<Payload>> = []
  private workers : Array<Worker<Task<Payload>>> = []

  constructor(initialTasks: Array<Payload> = []) {
    this.queue = [...initialTasks.map(payload => ({
      id: uuidv4(),
      dedupe: true,
      payload
    }))].reverse()

    this.distributeTasks()

    setInterval(() => {

    }, 3000)
  }

  public addWorker(handler: Worker<Task<Payload>>) {
    this.workers.push(handler)

    this.distributeTasks()
  }

  public removeWorker(handler: Worker<Task<Payload>>) {
    this.workers = this.workers.filter(w => w !== handler)
  }

  public addTask(payload: Payload, dedupe = false, id?: string) {
    this.queue.unshift({
      id: id || uuidv4(),
      dedupe,
      payload
    })

    this.distributeTasks()
  }

  public removeTask(id: string) {
    this.queue = this.queue.filter(item => item.id !== id)

    this.distributeTasks()
  }

  private distributeTasks() {
    const currentTask = this.queue.at(-1)

    if(currentTask === undefined
      || (currentTask.dedupe && currentTask.id === this.lastDistributedTaskId)) {
      return
    }

    this.workers.forEach(worker => {
      this.lastDistributedTaskId = currentTask.id
      worker(currentTask)
    })
  }
}

export type Worker<Task> = (task: Task) => unknown

export type Task<Payload> = {
  id: string
  dedupe: boolean
  payload: Payload
}
