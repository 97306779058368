import { PageLoading } from '@/components/layouts/PageLoading/PageLoading'
import { ComponentType, ReactNode, Suspense } from 'react'

export function withSuspense<TProps extends object>(
  WrappedComponent: ComponentType<TProps>,
  FallbackComponent: ReactNode | null | undefined = undefined
) {
  return (props: TProps) => {
    if(FallbackComponent === null) FallbackComponent = <PageLoading/>

    return (
      <Suspense fallback={FallbackComponent}>
        <WrappedComponent {...props as TProps} />
      </Suspense>
    )
  }
}
