export enum FaviconType {
  default = 'default',
  withNews = 'withNews'
}

const links: Record<FaviconType, string> = {
  [FaviconType.default]: process.env.REACT_APP_CDN_URL + '/web/favicon-business.png',
  [FaviconType.withNews]: process.env.REACT_APP_CDN_URL + '/web/favicon-business-badge.png',
}

export function changePageFavicon(type: FaviconType): void {
  const iconTagSelectors = [
    `link[rel="apple-touch-icon"]`,
    `link[rel="icon"]`,
    `link[rel="mask-icon"]`,
  ]

  iconTagSelectors.forEach(selector => {
    const tag = document.querySelector(selector)

    if(tag) {
      tag.setAttribute('href', links[type])
    }
  })
}
