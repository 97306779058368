import { wsProxyHttp } from '@/api/transport/http/ws-proxy.http'
import { TypingRequest, BindToPresenceRequest } from '@roolz/types/api/ws-proxy'

export class WsProxyClient {
  bindToTyping(data: TypingRequest) {
    return wsProxyHttp.post('/v1/bind/typing', data)
  }

  bindToPresence(data: BindToPresenceRequest) {
    return wsProxyHttp.post('/v1/bind/presence', data)
  }
}

export const wsProxyClient = new WsProxyClient()
