import { GroupContacts } from '@/components/contactList/ContactList'
import { Profile, ContactInfo } from '@roolz/types/api/profiles'
import { makeAutoObservable } from 'mobx'
import { ReactNode } from 'react'

type ContactListTypes = {
  getIcon: (...params: any[]) => ReactNode,
  onClickIcon?: (user: Profile) => void | Promise<any>
  openProfile: (user: Profile) => void
  inviteUser?: (user: Profile) => void
  invited: Record<string, Profile>
}

class ContactListStore {
  contacts: ContactInfo[] = []
  groups: GroupContacts = {}

  getIcon: ContactListTypes['getIcon']
  onClickIcon: ContactListTypes['onClickIcon']
  openProfile: ContactListTypes['openProfile']
  inviteUser: ContactListTypes['inviteUser']
  invited: Record<string, Profile> = {}

  private _search = ''

  constructor() {
    makeAutoObservable(this)
  }

  get search(): string {
    return <string>this._search
  }

  set search(value: string) {
    this._search = value
  }

  resetContacts() {
    this.contacts = []
    this.groups = {}
  }

  addInvited(user: Profile) {
    this.invited[user.id] = user
  }

  create({
    getIcon,
    onClickIcon,
    openProfile,
    inviteUser
  }: Omit<ContactListTypes, 'invited'>) {
    this.getIcon = getIcon
    this.onClickIcon = onClickIcon
    this.openProfile = openProfile
    this.inviteUser = inviteUser
  }
}

const contactListStore = new ContactListStore()

export {
  contactListStore
}
