import React, { ErrorInfo, ReactNode } from 'react'

interface State {
  errorInfo: ErrorInfo | null
  error: Error | null
}

export class ErrorFallback extends React.Component<{
  children: ReactNode,
  fallback: ReactNode
}, State> {
  constructor(props: {
    children: ReactNode,
    fallback: ReactNode
  }) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log('CATCHED', error, errorInfo)
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  // This will render this component wherever called
  render() {
    if(this.state.errorInfo) {
      return this.props.fallback
    }

    return this.props.children
  }
}
