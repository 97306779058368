import { useWindowSize } from '@roolz/sdk/hooks/useWindowSize'
import { ReactNode } from 'react'

interface Props {
  minWidth?: number;
  maxWidth?: number;

  // if false the component will always render children
  forceView?: boolean
  forceHide?: boolean

  children: ReactNode
}

export const Breakpoint = ({ children, forceView = false, forceHide = false, minWidth, maxWidth }: Props) => {
  const { vw } = useWindowSize()

  if(forceView) {
    return <>{children}</>
  }

  if(forceHide) {
    return null
  }

  if(
    (minWidth === undefined || minWidth <= vw)
    && (maxWidth === undefined || maxWidth >= vw)
  ) {
    return <>{children}</>
  }

  return null
}
