import { BaseApiClient } from '@/api/clients/_base.client'
import { exchangeHttp } from '@/api/transport/http/exchange.http'
import {
  CreateDuplicatesRequest,
  CreateOfferRequest,
  CreateOfferResponse,
  CreateTemplateRequest,
  GetMyOffersRequest,
  GetOfferResponse,
  GetOffersResponse,
  GetPublicOffersCompanyRequest,
  GetPublicOffersRequest,
  GetTemplatesRequest,
  GetTemplatesResponse,
  GetOfferRequest,
  Offer,
  Template,
  UpdateOfferRequest,
  UpdateOfferResponse,
  GetOfferRouteResponse,
  GetOfferGeoStateResponse,
  GeoOfferMatchesResponse,
  GetOfferMatchesRequest
} from '@roolz/types/api/exchange'

class ExchangeClient extends BaseApiClient {
  async getPublicOffers({ page, records, sort, ...body }: GetPublicOffersRequest) {
    return this.post<GetOffersResponse>('/v1/offers-filter', {
      body,
      params: { page, records, sort }
    })
  }

  async getCompanyOffers({ companyId, ...params }: GetPublicOffersCompanyRequest) {
    return this.get(`/v1/public/companies/${companyId}/offers`, { params })
  }

  async createOffer(body: CreateOfferRequest) {
    return this.post<CreateOfferResponse>('/v1/offers', { body })
  }

  async editOffer({ id, ...body }: UpdateOfferRequest) {
    return this.patch<UpdateOfferResponse>('/v1/offers/' + id, { body })
  }

  async getMyOffers(params: GetMyOffersRequest) {
    return this.get<GetOffersResponse>('/v1/my/offers', { params })
  }

  async getTemplates(params: GetTemplatesRequest) {
    return this.get<GetTemplatesResponse>('/v1/my/templates', { params })
  }

  async createTemplate(body: CreateTemplateRequest) {
    return this.post<Template>('/v1/templates', { body })
  }

  async deleteTemplate(id: string) {
    return this.delete('/v1/templates/' + id)
  }

  async createDuplicates({ offer: { _id }, ...body }: CreateDuplicatesRequest) {
    return this.post<Offer[]>(`/v1/offers/${_id}/duplicate`, { body })
  }

  async getOffer({ id, ...params }: GetOfferRequest) {
    return this.get<GetOfferResponse>('/v1/offers/' + id, { params })
  }

  async getOfferRoute({ _id }: Pick<Offer, '_id'>) {
    return this.get<GetOfferRouteResponse>(`/v1/offers/${_id}/routeway`)
  }

  async getOfferGeoState({ _id }: Pick<Offer, '_id'>) {
    return this.get<GetOfferGeoStateResponse>(`/v1/offers/${_id}/geostate`)
  }

  async getOfferMatches({ id, ...params }: GetOfferMatchesRequest) {
    return this.get<GeoOfferMatchesResponse>(`/v1/offers/${id}/matches`, { params })
  }
}

export const exchangeClient = new ExchangeClient(exchangeHttp, '')
