// @flow
import * as React from 'react'

interface Props {

}
export const RunningWithErrors = (props: Props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_10196_85177" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
        <rect width="14" height="14" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_10196_85177)">
        <path d="M6.91276 12.5404C6.14471 12.5404 5.42273 12.3945 4.74684 12.1029C4.07134 11.8112 3.48568 11.4174 2.98984 10.9216C2.49401 10.4258 2.10026 9.83992 1.80859 9.16403C1.51693 8.48853 1.37109 7.76675 1.37109 6.9987C1.37109 6.23064 1.51693 5.50867 1.80859 4.83278C2.10026 4.15728 2.49401 3.57161 2.98984 3.07578C3.48568 2.57995 4.07134 2.1862 4.74684 1.89453C5.42273 1.60286 6.14471 1.45703 6.91276 1.45703C7.70998 1.45703 8.46346 1.61745 9.17318 1.93828C9.8829 2.25911 10.5051 2.7112 11.0398 3.29453L6.91276 7.40703V2.33203C5.60998 2.33203 4.50651 2.78411 3.60234 3.68828C2.69818 4.59245 2.24609 5.69592 2.24609 6.9987C2.24609 8.30148 2.69818 9.40495 3.60234 10.3091C4.50651 11.2133 5.60998 11.6654 6.91276 11.6654C7.62248 11.6654 8.29565 11.5075 8.93226 11.1917C9.56926 10.8755 10.1114 10.4404 10.5586 9.8862V11.1258C10.053 11.573 9.48682 11.9207 8.85993 12.1688C8.23265 12.4165 7.58359 12.5404 6.91276 12.5404ZM11.7253 10.4987V5.97786H12.6003V10.4987H11.7253ZM12.1628 12.4383C12.0267 12.4383 11.9148 12.392 11.8273 12.2994C11.7398 12.2073 11.6961 12.098 11.6961 11.9716C11.6961 11.8355 11.7398 11.7214 11.8273 11.6292C11.9148 11.5366 12.0267 11.4904 12.1628 11.4904C12.2989 11.4904 12.4107 11.5366 12.4982 11.6292C12.5857 11.7214 12.6294 11.8355 12.6294 11.9716C12.6294 12.098 12.5857 12.2073 12.4982 12.2994C12.4107 12.392 12.2989 12.4383 12.1628 12.4383Z" fill="#E23834"/>
      </g>
    </svg>

  )
}