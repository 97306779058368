import { authStore } from '@/store/auth/auth.store'
import { debugStore } from '@/store/debug/debug.store'
import { wsService } from '@/store/ws/ws.service'
// @ts-ignore
import { v4 as uuidv4 } from 'uuid'
import { autorun, makeAutoObservable } from 'mobx'
import { OutgoingPackageType } from '@roolz/types/ws/packages'

type MessageHandler = (msg: any) => void
type Subscribers = {
  [msgType: string]: MessageHandler[]
}

type SendingFunction = (() => void)

class WSStore {
  private subscribers: Subscribers = {}

  private _sentCallback: null | SendingFunction = null

  set sentCallback(f: null | SendingFunction) {
    this._sentCallback = f
  }

  get sentCallback(): null | SendingFunction {
    return this._sentCallback
  }

  constructor() {
    makeAutoObservable(this)
  }

  handleNewMessage(message: string) {
    try {
      const decoded = JSON.parse(message)

      debugStore.logSocketReq('in', decoded)

      if(!!decoded.id && decoded.type === 'ack') {
        return
      }

      if(!!decoded?.type && this.subscribers?.[decoded.type]) {
        this.subscribers?.[decoded.type].forEach(handler => {
          let body
          try {
            body = JSON.parse(decoded.body)
          } catch(e) {
            console.error('Can`t decode socket package body')
          }

          try {
            handler(body)
          } catch(e) {
            console.error('Package handler error: ', e)
          }
        })
      }
    } catch(e) {
      console.log('can`t decode message', message)
    }
  }

  subscribe(event: string, handler: MessageHandler) {
    this.subscribers[event] ??= []
    this.subscribers[event].push(handler)
  }

  unsubscribe(event: string, handler: MessageHandler) {
    if(this.subscribers[event]) {
      this.subscribers[event] = this.subscribers[event].filter(h => h !== handler)
    }
  }
}

const wsStore = new WSStore()

export { wsStore }
