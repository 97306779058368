import './HollowDots.scss'

export function HollowDots() {
  return (
    <div className='hollow-dots-spinner'>
      <div className='dot'></div>
      <div className='dot'></div>
      <div className='dot'></div>
    </div>
  )
}
