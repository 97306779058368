import { db, DEXIE_STORES } from '@/database/index'
import { MessageModelFactory } from '@/models/Message.model'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { Chat } from '@roolz/types/api/chats'
import { liveQuery, Subscription } from 'dexie'
import { Profile } from '@roolz/types/api/profiles'
import { memo, ReactNode, useEffect, useLayoutEffect } from 'react'

const observingChatIds: Set<Chat['id']> = new Set()

interface Props {
  children?: ReactNode
}

export const IdbObservationsProvider = memo(({ children }: Props) => {
  useLayoutEffect(() => {
    // TODO probably need to do more granular
    liveQuery(() => db[DEXIE_STORES.CHATS].toArray())
      .subscribe(chats => {
        chatsStore.addOrUpdateChats(chats)

        chats.forEach(chat => {
          const { id } = chat

          if(!observingChatIds.has(chat.id)) {
            observeChatMessagesUpdates(chat.id)
            // observeChatMemberProfiles(chat)

            observingChatIds.add(chat.id)
          }
        })
      })

    liveQuery(() => db[DEXIE_STORES.OWN_PCPS]
      .toArray())
      .subscribe(ownPcps => {
        chatsStore.addOrUpdateOwnPcps(ownPcps)
      })

    let pcpProfilesLiveQuery: Subscription | undefined

    liveQuery(() => db[DEXIE_STORES.PCPS].toArray())
      .subscribe(async pcps => {
        chatsStore.addOrUpdatePcps(pcps)

        const profileIds = pcps.map(pcp => pcp.profile_id)

        try {
          const profiles = (await db[DEXIE_STORES.PROFILES].bulkGet(profileIds))
            .filter(profile => !!profile) as Profile[]

          profiles.map(profilesStore.addOrUpdateProfile)
        } catch(e) {
          console.error(e)
        }
      })

    liveQuery(() => db[DEXIE_STORES.PROFILES].toArray())
      .subscribe(profiles => {
        profiles.map(profilesStore.updateProfileIfExists)
      })

    function observeChatMessagesUpdates(id: Chat['id']) {
      return liveQuery(() => db[DEXIE_STORES.MESSAGES]
        .where('chat_id')
        .equals(id)
        .toArray()
      )
        .subscribe(msgs => {
          chatsStore.addOrUpdateMessages(msgs.map(MessageModelFactory))
        })
    }

// LOAD INITIAL DATA
// TODO Temporary crunch. Remove when profiles store would be refactored
    db[DEXIE_STORES.PROFILES].toArray()
      .then(profiles => {
        profiles.forEach(profilesStore.addOrUpdateProfile)
      })
  }, [])

  return null
})
