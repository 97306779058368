interface Props {
  size?: number
  color?: string
  className?: string
}
export const ArrowCircleUp = ({
  size = 20,
  color = '#4778EE',
  className
}: Props) => {
 return (
   <svg
     className={className}
     width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
   >
     <path d="M10 13.7C10.2167 13.7 10.396 13.625 10.538 13.475C10.6793 13.325 10.75 13.15 10.75 12.95V9.125L12.075 10.475C12.2083 10.6083 12.3833 10.675 12.6 10.675C12.8167 10.675 12.9917 10.6083 13.125 10.475C13.2583 10.325 13.325 10.146 13.325 9.938C13.325 9.72933 13.2583 9.55833 13.125 9.425L10.65 6.95C10.55 6.85 10.446 6.779 10.338 6.737C10.2293 6.69567 10.1167 6.675 10 6.675C9.88333 6.675 9.771 6.69567 9.663 6.737C9.55433 6.779 9.45833 6.85 9.375 6.95L6.875 9.425C6.74167 9.55833 6.675 9.72933 6.675 9.938C6.675 10.146 6.74167 10.325 6.875 10.475C7.00833 10.6083 7.18333 10.675 7.4 10.675C7.61667 10.675 7.79167 10.6083 7.925 10.475L9.25 9.125V12.95C9.25 13.15 9.321 13.325 9.463 13.475C9.60433 13.625 9.78333 13.7 10 13.7ZM10 19.5C8.68333 19.5 7.446 19.25 6.288 18.75C5.12933 18.25 4.125 17.575 3.275 16.725C2.425 15.875 1.75 14.8707 1.25 13.712C0.75 12.554 0.5 11.3167 0.5 10C0.5 8.68333 0.75 7.44567 1.25 6.287C1.75 5.129 2.425 4.125 3.275 3.275C4.125 2.425 5.12933 1.75 6.288 1.25C7.446 0.75 8.68333 0.5 10 0.5C11.3167 0.5 12.5543 0.75 13.713 1.25C14.871 1.75 15.875 2.425 16.725 3.275C17.575 4.125 18.25 5.129 18.75 6.287C19.25 7.44567 19.5 8.68333 19.5 10C19.5 11.3167 19.25 12.554 18.75 13.712C18.25 14.8707 17.575 15.875 16.725 16.725C15.875 17.575 14.871 18.25 13.713 18.75C12.5543 19.25 11.3167 19.5 10 19.5Z"
           fill={color}
     />
   </svg>
 )
}
