import { BaseApiClient } from '@/api/clients/_base.client'
import { messagingHttp } from '@/api/transport/http/messaging.http'
import {
  PatchOwnPcpRequest,
  GetChatsDataRequest,
  SendMessageRequest,
  SendMessageResponse,
  GetChatsDataResponse,
  DeleteMessagesByNumbersRequest,
  DeleteMessagesByNumbersResponse,
  PublicChat,
  Chat, OwnPcp,
  GetChatResponse,
  PinChatRequest,
  UnpinChatRequest,
  CleanChatResponse,
  SearchChatsResponse,
  SearchChatsRequest,
  GetOrCreateDialogResponse,
  GetPcpOrCreateObserverResponse,
  EditMessageRequest,
  Message
} from '@roolz/types/api/chats'
import { chatByNickname } from '@roolz/types/api/profiles'
import { Profile } from '@roolz/types/api/profiles'

class ChatsClient extends BaseApiClient {
  async getByNickname({ nickname }: chatByNickname) {
    return this.get<PublicChat>('/v1/chat/by_nickname/' + nickname)
  }

  async loadChats(params: GetChatsDataRequest) {
    return this.get<GetChatsDataResponse>('/v1/chats_data', { params })
  }

  async getChat(chatId: Chat['id']) {
    return this.get<GetChatResponse>('/v1/chat/' + chatId)
  }

  async sendMessage(body: SendMessageRequest) {
    return this.post<SendMessageResponse>('/v1/message/send', { body })
  }

  async patchOwnPcp(chatId: Chat["id"], body: PatchOwnPcpRequest) {
    return this.patch<OwnPcp>('/v1/participant/own/' + chatId, { body })
  }

  async deleteChat(chatId: Chat["id"]) {
    return this.delete('/v1/chat/' + chatId)
  }
  async deleteMessagesByNumbers(chatId: Chat["id"], body: DeleteMessagesByNumbersRequest) {
    return this.post<DeleteMessagesByNumbersResponse>(`/v1/message/${chatId}/delete_by_message_numbers`, { body })
  }

  async cleanSelfChat() {
    return this.post<CleanChatResponse>('/v1/chat/self_chat/clean')
  }

  async leaveChat(chatId: Chat["id"]) {
    return this.post(`/v1/chat/${chatId}/leave`)
  }

  async joinChat(chatId: Chat["id"]) {
    return this.post(`/v1/chat/${chatId}/join`)
  }

  async pinChat(chatId: Chat["id"], body: PinChatRequest) {
    return this.post<OwnPcp>(`/v1/chat/${chatId}/pin`, { body })
  }

  async unpinChat(chatId: Chat["id"], body: UnpinChatRequest) {
    return this.post<OwnPcp>(`/v1/chat/${chatId}/unpin`, { body })
  }

  async searchChats(params: SearchChatsRequest) {
    return this.get<SearchChatsResponse>('/v1/chat/search', { params })
  }

  async getOrCreateDialog(profile_id: Profile["id"]) {
    return this.post<GetOrCreateDialogResponse>('/v1/chat/dialog/get_or_create', { body: { profile_id } })
  }

  async getPcpOrCreateObserver(chat_id: Chat["id"]) {
    return this.post<GetPcpOrCreateObserverResponse>(`/v1/chat/${chat_id}/get_participant_or_create_observer`)
  }

  async bindToChat(chat_id: Chat["id"]) {
    return this.post(`/v1/chat/${chat_id}/bind`)
  }

  async editMessage(chatId: Chat["id"], number: Message["number"], body: EditMessageRequest) {
    return this.patch<Message>(`/v1/message/${chatId}/${number}`, { body })
  }
}

export const chatsClient = new ChatsClient(messagingHttp, '')
