
interface Props {
  size?: number
  color?: string
  style?: any
}
export function Mail({
  size = 20,
  color = '#4778EE',
  style
}: Props) {

  return (
    <svg
      style={style}
      width={size} height={size * 0.8} viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.3 15.5C1.8 15.5 1.375 15.325 1.025 14.975C0.675 14.625 0.5 14.2 0.5 13.7V2.3C0.5 1.8 0.675 1.375 1.025 1.025C1.375 0.675 1.8 0.5 2.3 0.5H17.7C18.2 0.5 18.625 0.675 18.975 1.025C19.325 1.375 19.5 1.8 19.5 2.3V13.7C19.5 14.2 19.325 14.625 18.975 14.975C18.625 15.325 18.2 15.5 17.7 15.5H2.3ZM18 3.45L10.475 8.25C10.4083 8.3 10.3333 8.33733 10.25 8.362C10.1667 8.38733 10.0833 8.4 10 8.4C9.91667 8.4 9.83333 8.38733 9.75 8.362C9.66667 8.33733 9.59167 8.3 9.525 8.25L2 3.45V13.7C2 13.7833 2.02933 13.8543 2.088 13.913C2.146 13.971 2.21667 14 2.3 14H17.7C17.7833 14 17.8543 13.971 17.913 13.913C17.971 13.8543 18 13.7833 18 13.7V3.45ZM10 7L17.85 2H2.15L10 7ZM2 3.45V14V13.7V3.45Z'
        fill={color}
      />
    </svg>
  )
}
