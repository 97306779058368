import { persistAndSyncStore } from '@/utils/mobx'
import { Company } from '@roolz/types/api/companies'
import { mergeWith } from 'lodash'
import { makeAutoObservable } from 'mobx'

class CompaniesStore {
  companies: Company[] = []
  myCompany: Company | null = null
  isMyCompanyLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  addOrUpdateCompany(company: Company) {
    const existing = this.companies.find(({ id }) => id === company?.id)

    if(existing) {
      return mergeWith(existing, company, (a: unknown, b: unknown) => {
        if(Array.isArray(b)) return b
      })
    }

    this.companies.push(company)
  }

  findCompany = (id: Company['id']): Company | undefined => {
    return this.companies.find(company => company.id === id)
  }
}

const companiesStore = new CompaniesStore()

export {
  CompaniesStore,
  companiesStore
}
