interface Props {
  size?: number
}
export const Notifications = ({
  size = 24
}: Props) => {
 return (
   <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g id="notifications">
       <mask id="mask0_17302_160203" maskUnits="userSpaceOnUse" x="0" y="0" width={size} height="24">
         <rect id="Bounding box" width="24" height="24" fill="#D9D9D9"/>
       </mask>
       <g mask="url(#mask0_17302_160203)">
         <path id="notifications_2" d="M4.25 18.875V17.375H6.25V10.125C6.25 8.75834 6.671 7.554 7.513 6.512C8.35433 5.47067 9.43333 4.8 10.75 4.5V3.8C10.75 3.45 10.871 3.154 11.113 2.912C11.3543 2.67067 11.65 2.55 12 2.55C12.35 2.55 12.6457 2.67067 12.887 2.912C13.129 3.154 13.25 3.45 13.25 3.8V4.5C14.5667 4.8 15.646 5.47067 16.488 6.512C17.3293 7.554 17.75 8.75834 17.75 10.125V17.375H19.75V18.875H4.25ZM12 21.8C11.5 21.8 11.075 21.625 10.725 21.275C10.375 20.925 10.2 20.5 10.2 20H13.8C13.8 20.5 13.625 20.925 13.275 21.275C12.925 21.625 12.5 21.8 12 21.8ZM7.75 17.375H16.25V10.125C16.25 8.94167 15.8373 7.93734 15.012 7.112C14.1873 6.28734 13.1833 5.875 12 5.875C10.8167 5.875 9.81267 6.28734 8.988 7.112C8.16267 7.93734 7.75 8.94167 7.75 10.125V17.375Z" fill="#4778EE"/>
       </g>
     </g>
   </svg>
 )
}
