import { BoundedMessagePreview } from '@/components/chats/shared/message/BoundedMessagePreview/BoundedMessagePreview'
import { isTextMessage, MessageModel } from '@/types/models/chat'
import Collapse from '@mui/material/Collapse'
import { Reply } from '@roolz/icons/chats/Reply'
import { Close } from '@roolz/icons/Close'
import { Edit2 } from '@roolz/icons/Edit2'
import { useTranslation } from 'react-i18next'
import styles from '@/components/chats/features/ChatBottomPanels/NewMessagePanel/ActingMessage/ActingMessage.module.scss'
import { MouseEvent } from 'react'

interface Props {
  mode: 'edit' | 'reply'
  message: MessageModel

  onMessageClick: (e: MouseEvent<HTMLElement>) => void
  onAbortAction: () => void
}

export const ActingMessage = ({
  mode,
  message,

  onAbortAction,
  onMessageClick
}: Props) => {
  const { t } = useTranslation('chat/common')

  function getIcon() {
    if(mode === 'edit') {
      return <Edit2 size={18} color='#4778EE'/>
    }
    if(mode === 'reply') {
      return <Reply size={24} color='#4778EE'/>
    }
  }

  function getTitle() {
    if(mode === 'edit') {
      return t('message_panel_modes.edit')
    }

    if(mode === 'reply') {
      return [message.owner?.first_name, message.owner?.last_name].join(' ')
    }
  }

  return (
    <Collapse appear in timeout={250}>
      <div className={styles.root}>
        <div className={styles.icon}>
          {getIcon()}
        </div>

        <BoundedMessagePreview
          title={getTitle()}
          message={message}
          className={styles.content}

          onClick={onMessageClick}
        />

        <div
          className={styles.close}
          onClick={onAbortAction}
        >
          <Close color='#8E8E93' size={12}/>
        </div>
      </div>
    </Collapse>
  )
}
