interface Props {
  size?: number
  color?: string
}

export const Clarify = ({ size = 16, color = '#8E8E93' }: Props) => {
  return (
    <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.16699 11.1663H8.50033V10.1663H4.16699V11.1663ZM10.8337 11.1663H11.8337V4.83301H10.8337V11.1663ZM4.16699 8.49967H8.50033V7.49967H4.16699V8.49967ZM4.16699 5.83301H8.50033V4.83301H4.16699V5.83301ZM2.86699 13.6663C2.53366 13.6663 2.25033 13.5497 2.01699 13.3163C1.78366 13.083 1.66699 12.7997 1.66699 12.4663V3.53301C1.66699 3.19967 1.78366 2.91634 2.01699 2.68301C2.25033 2.44967 2.53366 2.33301 2.86699 2.33301H13.1337C13.467 2.33301 13.7503 2.44967 13.9837 2.68301C14.217 2.91634 14.3337 3.19967 14.3337 3.53301V12.4663C14.3337 12.7997 14.217 13.083 13.9837 13.3163C13.7503 13.5497 13.467 13.6663 13.1337 13.6663H2.86699ZM2.86699 12.6663H13.1337C13.1781 12.6663 13.2225 12.6441 13.267 12.5997C13.3114 12.5552 13.3337 12.5108 13.3337 12.4663V3.53301C13.3337 3.48856 13.3114 3.44412 13.267 3.39967C13.2225 3.35523 13.1781 3.33301 13.1337 3.33301H2.86699C2.82255 3.33301 2.7781 3.35523 2.73366 3.39967C2.68921 3.44412 2.66699 3.48856 2.66699 3.53301V12.4663C2.66699 12.5108 2.68921 12.5552 2.73366 12.5997C2.7781 12.6441 2.82255 12.6663 2.86699 12.6663Z'
        fill={color}
      />
    </svg>
  )
}
