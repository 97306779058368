import { ChatItem, SearchChats } from '@/components/modals/SearchChats/SearchChats'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { cloneDeep } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { MessageType, SendMessageRequest, Message } from '@roolz/types/api/chats'
// @ts-ignore
import { v4 as uuidv4 } from 'uuid'
import { toastSuccess } from '@roolz/sdk/components/snackbars/index'

export const MessageForwardProvider = observer(() => {
  const { t } = useTranslation('chat/common')

  function setOpen(v: boolean) {
    if(!v) {
      chatsStore.forwardingMessage = null
    }
  }


  function handleForwardChatSelected(chat: ChatItem) {
    if(chat.type !== 'chat') {
      return
    }

    let forwardMessage = cloneDeep(chatsStore.forwardingMessage)

    const sender_id = forwardMessage?.forward_from?.sender_id || forwardMessage?.sender_id
    if(sender_id === profilesStore.my_profile?.id) {
      forwardMessage = null
    }

    chatsService.sendNewMessage({
      chat_id: chat.chat.id,
      client_message_id: uuidv4(),
      type: MessageType.TEXT,

      content: chatsStore.forwardingMessage?.content ?? '{}',
      forward_from: forwardMessage?.forward_from || forwardMessage || undefined,
      forward_from_id: forwardMessage?.forward_from_id || forwardMessage?.id
    })

    // chat.chat?.id && chatsService.setActiveChatAsGroup(chat.chat?.id)
    chatSessionsStore.resetChatViewInfo(chat.chat?.id)

    toastSuccess(t('chat/message:action_toasts.messages_forwarded'))

    chatsStore.forwardingMessage = null
  }

  return (
    <SearchChats
      title={t('search.forward_title')}
      type='local'
      showAllLocalChatsByDefault

      open={!!chatsStore.forwardingMessage}
      setOpen={setOpen}

      onChatSelect={handleForwardChatSelected}
    />
  )
})
