import { ArrowCircleUp } from '@roolz/icons/chats/ArrowCircleUp'
import { memo } from 'react'
import styles from '@/components/chats/features/ChatBottomPanels/NewMessagePanel/SendMessageButton/SendMessageButton.module.scss'
import cn from 'classnames'

interface Props {
  onClick: () => void
  disabled?: boolean
}

export const SendMessageButton = memo(({
  onClick,
  disabled = false
}: Props) => {
  return (
    <button
      onClick={onClick}
      className={cn(styles.button, {
        [styles.buttonDisabled]: disabled
      })}
    >
      <ArrowCircleUp
        className={styles.icon}
      />
    </button>
  )
})
