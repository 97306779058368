import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { Chat, Pcp, PcpStatus, PcpType } from '@roolz/types/api/chats'
import { Profile } from '@roolz/types/api/profiles'
import { PcpModel } from '@/types/models/chat'

class PP implements PcpModel {
  chat_id: Chat["id"]
  profile_id: Profile["id"]

  is_banned: boolean
  type: PcpType
  status: PcpStatus
  is_wt_on: boolean

  constructor(pp: Pcp) {
    this.chat_id = pp.chat_id
    this.profile_id = pp.profile_id

    this.is_banned = pp.is_banned
    this.type = pp.type
    this.status = pp.status
    this.is_wt_on = pp.is_wt_on

    // makeAutoObservable(this)
  }

  get chat() {
    return chatsStore.chats[this.chat_id]
  }

  get profile() {
    // setTimeout(() => {
    //   console.log('PCP profile', profilesStore.findProfile(pcp.profile_id))
    // }, 200)
    return profilesStore.findProfile(this.profile_id)
  }
}

export function PcpModelFactory(pcp: Pcp): PcpModel {
  return new PP(pcp)
}
