import Cookies from "cookies-ts"
import dayjs from '@roolz/sdk/plugins/dayjs'

export const STORAGE_KEY = 'credentials'

export interface Credentials {
  access_token: string,
  refresh_token: string | null,
  expiration_at: number | null,
  token_type: 'Bearer' | null
  lang_code?: string
}

/**
 * Get credentials from cookies in browser
 */
export const retrieveClientside = async (): Promise<Credentials | null> => {
  const cookies = new Cookies()

  try {
    const credentialsString = cookies.get(STORAGE_KEY)

    if(typeof credentialsString === 'string') {
      return JSON.parse(credentialsString) as Credentials
    } else {
      return (credentialsString as Credentials) ?? null
    }
  } catch(e) {
    return null
  }
}

export const getCookiesDomain = (): string => {
  const domain = new URL(process.env.NEXT_PUBLIC_ROOLZ_STATIC_URL ?? process.env.REACT_APP_ROOLZ_STATIC_URL).hostname
  let segments = domain.split('.')

  if(segments.length > 2) {
    segments = segments.slice(1)
  }

  return segments.join('.')
}

/**
 * Get credentials from raw cookie string, needed for retrieve in Next.js in getServerSideProps
 *
 * @param cookieStr
 */
// export const retrieveFromCookieString = (cookieStr: string): Credentials => {
//   const cookieParsed = new CookiesStringParse(cookieStr)
//
//   const credentialsString = cookieParsed.get(STORAGE_KEY)
//
//   try {
//     return JSON.parse(credentialsString) as Credentials
//   } catch(e) {
//     return null
//   }
// }

/**
 * Save auth credentials to cookie
 *
 * @param credentials
 * @param domain
 */
export const persist = async (credentials: Credentials): Promise<void> => {
  const cookies = new Cookies()


  cookies.set(STORAGE_KEY, JSON.stringify(credentials), {
    domain: getCookiesDomain(),
    expires: dayjs().add(2, 'months').toString()
  })
}

/**
 * Remove all auth credentials from cookies (logout)
 */
export const clean = async (): Promise<void> => {
  const cookies = new Cookies()

  cookies.remove(STORAGE_KEY, {
    domain: getCookiesDomain()
  })
}
