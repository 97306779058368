import { companiesClient } from '@/api/clients/companies.client'
import stylesConfig from '@/assets/scss/config.module.scss'
import { InvitedModalAccept } from '@/components/modals/InvitedModalAccept/InvitedModalAccept'
import { MAX_COMPANIES_COUNT } from '@/config/const'
import { companiesService } from '@/store/companies/companies.service'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesService } from '@/store/profiles/profiles.service'
import { uiStore } from '@/store/ui/ui.store'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { useBreakpoint } from '@roolz/sdk/hooks/useBreakpoint'
import { toastError, toastWarning } from '@roolz/sdk/components/snackbars'
import { getDeviceInfo } from '@roolz/sdk/utils/device'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { Company } from '@roolz/types/api/companies'
import { profilesStore } from '@/store/profiles/profiles.store'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Invitation.module.scss'

export const Invitations = observer(function Invitations() {
  const { t: companyT } = useTranslation('company/common')
  const { t: errorsT } = useTranslation('errors')
  const profile = profilesStore.my_profile
  const [invitingCompany, setInvitingCompany] = useState<Company | null>(null)
  const [loading, setLoading] = useState(false)

  if(!profile) {
    return null
  }

  const isDesktopVersion = useBreakpoint({
    minWidth: Number.parseFloat(stylesConfig.mobileSidebarBreakpointWidth)
  })

  const decline = async (
    companyId: string,
    invitationId: string
  ) => {
    setLoading(true)
    try {
      await companiesClient.declineInvitation({
        companyId,
        invitationId
      })
      await profilesService.retrieveMyProfile()
    } catch(e: any) {
      const message = e?.response?.data?.detail
      if(message) {
        toastError(message)
      }
    } finally {
      setLoading(false)
    }
  }

  const accept = async (
    companyId: string,
    invitationId: string,
    magic_hash: string
  ) => {
    setLoading(true)
    try {
      const response = await companiesClient.acceptInvitation({
        companyId,
        invitationId,
        body: {
          device: getDeviceInfo(),
          magic_hash
        }
      })

      const { company } = response.data

      setInvitingCompany(company)
    } catch(e: any) {
      const message = e?.response?.data?.detail
      if(message) {
        toastError(message)
      }
      await updateProfile()
    } finally {
      setLoading(false)
    }
  }

  const updateProfile = async () => {
    setInvitingCompany(null)
    await companiesService.loadMyCompanies()
    await profilesService.retrieveMyProfile()
  }

  const countCompanies = myCompaniesStore?.companies?.length || 0

  if(countCompanies >= MAX_COMPANIES_COUNT) {
    return null
  }

  //FIXME Validate backend send foreign invites, refactor with new identity
  const invitations = profile.company_invitations
    ?.filter((invitation) => {
      if(profile.companies?.length) {
        const alreadyInCompany = !!profile.companies.find(({ id }) => id === invitation.company_id)

        if(alreadyInCompany) {
          return false
        }
      }
      return invitation.invited_user_id === profile.id
        || (invitation.email && invitation.email === profile.email)
    })

  return (
    <>
      {invitingCompany && <InvitedModalAccept company={invitingCompany} onClose={updateProfile}/>}

      <div className={styles.invites}>
        {invitations?.map((invite) =>
          <div
            className={cn(styles.invite, {
              [styles.inviteCollapsed]: isDesktopVersion && uiStore.isSidebarCollapsed
            })}
            key={invite.id}
          >
            <div className={styles.invite__company}>
              <div className={styles.invite__companyWarning}>
                <span>!</span>
              </div>
              <div className={styles.invite__companyAvatar}>
                <Avatar
                  avatarUrl={invite.company.logo_path ?? ''}
                  first_name={invite.company.name}
                  color_code={invite.company.color}
                  width={40}
                  type='company'
                />
              </div>
              <div className={styles.invite__companyRight}>
                <p className={styles.invite__companyTitle}>
                  {companyT('invites.preview.title')}
                </p>
                <p className={styles.invite__companyName}>
                  {invite.company.name}
                </p>
              </div>
            </div>
            <div className={styles.invite__buttons}>
              <Button
                disabled={loading}
                size='small'
                variant='outlined'
                onClick={() => decline(invite.company?.id, invite.id)}
                className={cn(styles.invite__buttonsItem, styles.invite__buttons, styles.Decline)}
              >
                {companyT('invites.preview.refuse')}
              </Button>
              <Button
                disabled={loading}
                size='small'
                variant='outlined'
                onClick={() => accept(invite.company?.id, invite.id, invite.magic_hash)}
                className={cn(styles.invite__buttonsItem, styles.invite__buttons, styles.Accept)}
              >
                {companyT('invites.preview.accept')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  )
})

