import { loadAudio } from '@/utils/audio'
import { useEventListener } from 'ahooks'
import { useCallback, useState } from 'react'

export function useIsTabInteracted() {
  const [isInteracted, setIsInteracted] = useState(window.isInteracted)

  const sendTabIsInteracted = useCallback(() => {
    loadAudio()

    setIsInteracted(true)
  }, [])


  useEventListener('pointerdown', sendTabIsInteracted, { target: document, once: true })
  useEventListener('keydown', sendTabIsInteracted, { target: document, once: true })

  return isInteracted
}
