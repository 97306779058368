interface Props {
  color?: string
  className?: string
  size?: number

  [key: string]: any
}

export const Close = ({ size = 14, color = '#1C1B1F', className, ...rest }: Props) => {
  return (
    <svg
      className={className}
      width={size} height={size} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M7.00002 8.05002L1.92502 13.125C1.79169 13.2584 1.62102 13.329 1.41302 13.337C1.20436 13.3457 1.02502 13.275 0.875024 13.125C0.725024 12.975 0.650024 12.8 0.650024 12.6C0.650024 12.4 0.725024 12.225 0.875024 12.075L5.95002 7.00002L0.875024 1.92502C0.741691 1.79169 0.671024 1.62069 0.663024 1.41202C0.654358 1.20402 0.725024 1.02502 0.875024 0.875024C1.02502 0.725024 1.20002 0.650024 1.40002 0.650024C1.60002 0.650024 1.77502 0.725024 1.92502 0.875024L7.00002 5.95002L12.075 0.875024C12.2084 0.741691 12.3794 0.670691 12.588 0.662024C12.796 0.654024 12.975 0.725024 13.125 0.875024C13.275 1.02502 13.35 1.20002 13.35 1.40002C13.35 1.60002 13.275 1.77502 13.125 1.92502L8.05002 7.00002L13.125 12.075C13.2584 12.2084 13.329 12.379 13.337 12.587C13.3457 12.7957 13.275 12.975 13.125 13.125C12.975 13.275 12.8 13.35 12.6 13.35C12.4 13.35 12.225 13.275 12.075 13.125L7.00002 8.05002Z'
        fill={color}
      />
    </svg>
  )
}

