import { systemStore } from '@/store/system/system.store'
import { getNow } from '@/utils/date'
import { Profile } from '@roolz/types/api/profiles'
import { ProfileModel } from '@/types/models/profile'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { clone } from 'lodash'
import { OnlineStatus } from '@roolz/types/custom'

export function ProfileModelFactory(profile: Profile): ProfileModel {
  return ({
    ...clone(profile),

    get onlineStatus() {
      const diffMins = dayjs(systemStore.serverTime10secUpdate)
        .diff(this.last_action_time, 'minutes')

      if(diffMins < 5) {
        return OnlineStatus.Online
      } else if(diffMins < 30) {
        return OnlineStatus.Recently
      }

      return OnlineStatus.Offline
    },

    get isActive() {
      return !!this.is_active
    }
  })
}

