import cn from 'classnames'
import { ReactNode } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styles from './SidebarMenuItem.module.scss'

interface Props {
  label: string
  prepend?: ReactNode
  to: string,
  disabled?: boolean

  [key: string]: any
}

export const SidebarMenuItem = ({
  label,
  prepend,
  to,
  disabled = false,
  ...rest
}: Props) => {
  const { pathname } = useLocation()

  function isActive() {
    return !disabled && new RegExp('^' + to + '(/.*)?').test(pathname)
  }

  return (
    <Link
      to={to}
      className={cn(styles.menuItem, {
        [styles.menuItemDisabled]: disabled,
        [styles.menuItemActive]: isActive()
      })}
      tabIndex={disabled ? -1 : undefined}
      {...rest}
    >
      <div className={styles.menuItem__icon}>
        {prepend}
      </div>

      <div className={styles.menuItem__label}>
        {label}
      </div>
    </Link>
  )
}
