import { refreshTokensInterceptor } from '@/api/refreshTokensInterceptor'
import i18n from '@/plugins/i18n'
import { authStore } from '@/store/auth/auth.store'
import axios from 'axios'

// const cache = setupCache({
//   maxAge: 15 * 60 * 1000,
//   store: FILESERVER_CACHE_NAME
// })

/**
 * Http client for request to Roolz File Server
 */
export const fileServerHttp = axios.create({
  baseURL: process.env.REACT_APP_OWN_SERVER_BASE_URL + '/fileserver',
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
fileServerHttp.interceptors.request.use(refreshTokensInterceptor)

// Change request data/error here
fileServerHttp.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      'X-Roolz-Auth': `Bearer ${authStore.access_token}`,
      'Accept-Language': i18n.language
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)


export const fileServerFetchClient = (url: string, options: any = {}) => {
  url = process.env.REACT_APP_OWN_SERVER_BASE_URL + '/fileserver' + '/' + url.replace(/^\//, '')

  options.headers = {
    ...options?.headers,
    'X-Roolz-Auth': `Bearer ${authStore.access_token}`,
    'Accept-Language': i18n.language
  }
  return fetch(url, options)
}
