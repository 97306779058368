interface Props {
  className?: string
}
export const UserPlaceholder = ({
  className
}: Props) => {
 return (
   <svg
     className={className}
     width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
     <mask id="mask0_3006_1823" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="160" height="160">
       <circle cx="80" cy="80" r="80" fill="#C4C4C4"/>
     </mask>
     <g mask="url(#mask0_3006_1823)">
       <circle cx="80" cy="80" r="80" fill="#EBECF2"/>
       <path
         d="M80.5 100C97.075 100 110.5 86.575 110.5 70C110.5 53.425 97.075 40 80.5 40C63.925 40 50.5 53.425 50.5 70C50.5 86.575 63.925 100 80.5 100ZM80.5 115C60.475 115 20.5 125.05 20.5 145V152.5C20.5 156.625 23.875 160 28 160H133C137.125 160 140.5 156.625 140.5 152.5V145C140.5 125.05 100.525 115 80.5 115Z"
         fill="#C8CBDE"
       />
     </g>
   </svg>
 )
}
