export function getFlagUrl(alpha2: string ) {
  if(!alpha2?.length) {
    alpha2 = 'plsh'
  }

  return getStaticCdnFilePath(`/flags/${alpha2.toLowerCase()}.png`)
}

export function getStaticCdnFilePath(path: string) {
  return (process.env.NEXT_PUBLIC_CDN_URL || process.env.REACT_APP_CDN_URL) + '/' + path.replace(/^\//, '')
}

export function getFileserverAvatarLinkByPath(fileserverPath: string, params?: {
  width?: number
  height?: number
}) {
  const query = new URLSearchParams()
  for(const [key, value] of Object.entries(params ?? {})) {
    query.set(key, String(value))
  }

  return (process.env.NEXT_PUBLIC_OWN_SERVER_BASE_URL || process.env.REACT_APP_OWN_SERVER_BASE_URL)
    + fileserverPath
    + '?' + query.toString()
}
