import {
  BROADCAST_MESSAGES,
  CLIENT_MESSAGES,
  SEND_ONLINE_INTERVAL,
  SW_MESSAGES,
  WORKER_MESSAGES
} from '@/config/workers'
import { useWindowFocus } from '@/hooks/events/useWindowFocus'
import { useWindowVisibilityChange } from '@/hooks/events/useWindowVisibilityChange'
import { useIsTabInteracted } from '@/hooks/useIsTabInteracted'
import { authStore } from '@/store/auth/auth.store'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsService } from '@/store/chats/chats.service'
import { setSettings, settings } from '@/store/client-settings/client-settings'
import { profilesStore } from '@/store/profiles/profiles.store'
import { systemStore } from '@/store/system/system.store'
import { workersStore } from '@/store/workers/workers.store'
import { wsStore } from '@/store/ws/ws.store'
import { Task } from '@/utils/SmartQueue'
import { OnlinePackage } from '@roolz/types/ws/packages'
import { useAppWorker } from '@/workers/app-worker/useAppWorker'
import { useServiceWorker } from '@/workers/service-worker/useServiceWorker'
import { useInterval } from 'ahooks'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useLayoutEffect } from 'react'
import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'

export function WorkersProvider() {
  const isInteracted = useIsTabInteracted()

  const { activeWorker: serviceWorker } = useServiceWorker()
  const serviceWorkerRef = useStateRef(serviceWorker)

  const { appWorker, subscribeOnMessage: subscribeOnAppWorkerMessage } = useAppWorker()


  const handleBroadcastMessage = useCallback(async (message: any) => {
    const { type, body } = message

    switch(type) {
      case BROADCAST_MESSAGES.NEW_NOTIFICATION:
        console.log('HIGHLIGHT')
        chatSessionsStore.highlightTitle = true

        break
      case BROADCAST_MESSAGES.CLIENT_SETTINGS_CHANGED:
        console.log('client settings changed', body)
        setSettings(body)

        break
    }
  }, [])

  /**
   * App worker message handlers
   */
  const handleWorkerMessage = useCallback(async (message: any) => {
    console.log('handle worker message', message)

    try {
      switch(message.type) {
        case WORKER_MESSAGES.NEW_WS_MESSAGE: {
          wsStore.handleNewMessage(message.body)

          chatsService.queueNotificationIfNecessary({ body: message.body })
          break
        }
        case WORKER_MESSAGES.NOTIFY_SW_ABOUT_NEW_WORKER:
          console.log('WORKER REQUESTED SW NOTIFICATION', message)
          break
        case CLIENT_MESSAGES.BROADCAST:
          handleBroadcastMessage(message.body)
          break
        case WORKER_MESSAGES.WS_ESTABLISHED:
          chatsService.loadNewChatsData()
          break
      }
    } catch(e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    if(appWorker && subscribeOnAppWorkerMessage) {
      subscribeOnAppWorkerMessage(handleWorkerMessage)
    }
  }, [appWorker, subscribeOnAppWorkerMessage])

  /**
   * Service worker message handlers
   */

  function handleServiceWorkerMessage(message: any) {
    try {
      switch(message.type) {
        case CLIENT_MESSAGES.BROADCAST:
          handleBroadcastMessage(message.body)
          break

        case SW_MESSAGES.SHOW_NOTIFICATIONS:
          chatsService.showQueuedNotifications({
            haveFocusedTabs: message?.haveFocusedTabs ?? true
          })
          break
        case SW_MESSAGES.ROOLZ_TAB_FOCUSED:
          chatSessionsStore.highlightTitle = false
          break
      }
    } catch(e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const msgConsumer = (msg: Task<any>) => {
      serviceWorkerRef.current && serviceWorkerRef.current.postMessage({
        id: msg.id,
        ...msg.payload
      })
    }

    workersStore.swQueue.addWorker(msgConsumer)

    return () => {
      workersStore.swQueue.removeWorker(msgConsumer)
    }
  }, [serviceWorker])

  useLayoutEffect(() => {
    navigator.serviceWorker.addEventListener('message', (message: any) => {
      if(message.data.type === SW_MESSAGES.ACK && message?.data?.id !== undefined) {
        workersStore.swQueue.removeTask(message.data.id)
      }

      if(message.type === 'message') {
        handleServiceWorkerMessage(message.data)
      }
    })
  }, [])


  useEffect(() => {
    if(isInteracted) {
      workersStore.swQueue.addTask({
        type: CLIENT_MESSAGES.ADD_TO_INTERACTED
      }, false)
    }
    //
    // if(isInteracted && serviceWorker) {
    //   serviceWorker.postMessage({
    //     type: CLIENT_MESSAGES.ADD_TO_INTERACTED
    //   })
    // }
  }, [isInteracted, serviceWorker])


  useEffect(() => {
    if(appWorker) {
      wsStore.sentCallback = () => appWorker.postMessage({
        type: CLIENT_MESSAGES.SEND_WS_MESSAGE,
        // body: { type, message }
      })
    } else {
      wsStore.sentCallback = null
    }

    return () => {
      wsStore.sentCallback = null
    }
  }, [appWorker, authStore.access_token, authStore.refresh_token, authStore.expiration_at])

  useWindowFocus(() => {
    chatSessionsStore.highlightTitle = false

    workersStore.swQueue.addTask({
      type: CLIENT_MESSAGES.TAB_FOCUSED
    })
  })

  const sendOnline = useCallback(() => {
    if(document.visibilityState === 'visible' && appWorker && profilesStore.my_device
      && systemStore.online) {
      const body: OnlinePackage = {
        'roolz-is-background': 0,
        state_radio_map: false,
        event: 1,
        os_type: profilesStore.my_device?.os_type || 'windows',
        device_id: profilesStore.my_device?.id || '0',
        state_radio_chats: false,
        user_id: profilesStore.my_profile?.id || '0'
      }

      appWorker.postMessage({
        type: CLIENT_MESSAGES.SEND_ONLINE,
        body
      })
    }
  }, [appWorker])

  useWindowVisibilityChange(sendOnline)
  useInterval(sendOnline, SEND_ONLINE_INTERVAL)
  useEffect(sendOnline, [])

  return null
}
