import { exchangeClient } from '@/api/clients/exchange.client'
import { addOrUpdateProfiles } from '@/repositories/profiles.repository'
import { companiesStore } from '@/store/companies/companies.store'
import { exchangeStore } from '@/store/exchange/exchange.store'
import { myOffersStore } from '@/store/exchange/my_offers.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import {
  CreateDuplicatesRequest,
  CreateOfferRequest,
  CreateTemplateRequest,
  GetMyOffersRequest, GetOfferMatchesRequest, GetOfferRequest, GetOfferRouteResponse,
  GetPublicOffersRequest,
  GetTemplatesRequest,
  Offer,
  OfferStatus,
  OfferType,
  UpdateOfferRequest
} from '@roolz/types/api/exchange'

export class ExchangeService {
  private showConfetti() {
    const count = 200,
      defaults = {
        origin: { y: 0.7 },
      }

    function fire(particleRatio: any, opts: any) {
      // @ts-ignore
      confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio),
        })
      )
    }

    function run() {
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      })

      fire(0.2, {
        spread: 60,
      })

      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      })

      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      })

      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      })
    }

    setTimeout(() => {
      run()
      setTimeout(run, 150)
    }, 500)
  }

  createOffer(body: CreateOfferRequest) {
    return exchangeClient.createOffer(body)
      .then(response => {
        const { data, headers } = response

        if(headers?.['total-offers'] === '1') {
          this.showConfetti()
        }

        exchangeStore.addOrUpdateOffer(data)
        myOffersStore.handleOfferUpdated(data)

        myOffersStore.handleStatusChange(data.status)

        return data
      })
  }

  updateOffer(params: UpdateOfferRequest) {
    const oldStatus = exchangeStore.findOffer(params.id)?.status
    // const oldStatus = exchangeStore.findOffer(params.id)?.status

    return exchangeClient.editOffer(params)
      .then(({ data }) => {
        // exchangeStore.removeOffer(data)
        exchangeStore.removeOffer(data)
        exchangeStore.addOrUpdateOffer(data)
        myOffersStore.handleOfferUpdated(data)

        if(oldStatus && oldStatus !== data.status) {
          myOffersStore.handleStatusChange(data.status, oldStatus)
        }

        return data
      })
  }

  publishOffer(offer: UpdateOfferRequest) {
    return this.updateOffer({
      ...offer,
      status: OfferStatus.PUBLISHED
    })
  }

  unpublishOffer(offer: UpdateOfferRequest) {
    return this.updateOffer({
      ...offer,
      status: OfferStatus.NOT_PUBLISHED
    })
  }

  closeOffer(offer: UpdateOfferRequest) {
    return this.updateOffer({
      ...offer,
      status: OfferStatus.CLOSED
    })
  }

  loadCargoTemplates(params?: GetTemplatesRequest) {
    exchangeClient.getTemplates({
      ...params,
      type: OfferType.CARGO,
      records: 100,
      active_space_company_id: profilesStore.activeCompanyId ?? null
    })
      .then(({ data }) => {
        exchangeStore.cargoTemplates = data.result ?? []
        exchangeStore.isCargoTemplatesLoaded = true
      })
      .catch(console.log)
  }


  loadTransportTemplates(params?: GetTemplatesRequest) {
    exchangeClient.getTemplates({
      ...params,
      type: OfferType.TRANSPORT,
      records: 100,
      active_space_company_id: profilesStore.activeCompanyId ?? null
    })
      .then(({ data }) => {
        exchangeStore.transportTemplates = data.result ?? []
        exchangeStore.isTransportTemplatesLoaded = true
      })
      .catch(console.log)
  }

  createTemplate(params: CreateTemplateRequest) {
    return exchangeClient.createTemplate(params)
      .then(({ data }) => {
        if(data.body.offer_type === OfferType.CARGO) {
          exchangeStore.cargoTemplates.unshift(data)
        }
        if(data.body.offer_type === OfferType.TRANSPORT) {
          exchangeStore.transportTemplates.unshift(data)
        }

        return data
      })
  }

  deleteCargoTemplate(id: string) {
    return exchangeClient.deleteTemplate(id)
      .then(() => {
        exchangeStore.removeCargoTemplate(id)
      })
  }

  deleteTransportTemplate(id: string) {
    return exchangeClient.deleteTemplate(id)
      .then(() => {
        exchangeStore.removeTransportTemplate(id)
      })
  }

  loadMyOffers(params: GetMyOffersRequest) {
    return exchangeClient.getMyOffers(params)
      .then(({ data }) => {
        if(data.result) {
          data.result.forEach(offer => {
            exchangeStore.addOrUpdateOffer(offer)
          })
        }

        if(data.profiles?.length) {
          addOrUpdateProfiles(data.profiles)
        }

        if(data.companies?.length) {
          data.companies.forEach(company => {
            companiesStore.addOrUpdateCompany(company)
          })
        }

        return data
      })
  }

  loadPublicOffers(params: GetPublicOffersRequest) {
    return exchangeClient.getPublicOffers(params)
      .then(({ data }) => {
        if(data.result) {
          data.result.forEach(offer => {
            exchangeStore.addOrUpdateOffer(offer)
          })
        }

        if(data.profiles?.length) {
          addOrUpdateProfiles(data.profiles)
        }

        if(data.companies?.length) {
          data.companies.forEach(company => {
            companiesStore.addOrUpdateCompany(company)
          })
        }

        return data
      })
  }

  duplicateOffer(params: CreateDuplicatesRequest) {
    return exchangeClient.createDuplicates(params)
      .then(({ data }) => {
        data.forEach(offer => {
          exchangeStore.addOrUpdateOffer(offer)
          myOffersStore.handleOfferUpdated(offer)

          myOffersStore.handleStatusChange(offer.status)
        })

        return data
      })
  }

  loadOffer(params: GetOfferRequest): Promise<Offer> {
    return exchangeClient.getOffer(params)
      .then(({ data }) => {
        exchangeStore.addOrUpdateOffer(data.result)
        companiesStore.addOrUpdateCompany(data.companies)

        if(data.profiles?.length) {
          addOrUpdateProfiles(data.profiles)
        }

        return data.result
      })
  }

  loadOfferRoute({ _id }: Pick<Offer, '_id'>) {
    return exchangeClient.getOfferRoute({ _id })
      .then(({ data }) => {
        if(!data?.way) {
          throw new Error(`Internal: Offer Route is incorrect`)
        }

        exchangeStore.updateOfferRoute({ _id }, data)

        return data
      })
  }

  loadOfferMatches(params: GetOfferMatchesRequest) {
    return exchangeClient.getOfferMatches(params)
      .then(({ data }) => {
        if(params.exact) {
          exchangeStore.updateOfferPartial({
            _id: params.id,
            matches_exact_count: data.total
          })
        } else {
          exchangeStore.updateOfferPartial({
            _id: params.id,
            matches_partial_count: data.total
          })
        }

        data.matches.forEach(offer => exchangeStore.addOrUpdateOffer(offer))

        if(data.profiles?.length) {
          addOrUpdateProfiles(data.profiles)
        }

        if(data.companies?.length) {
          data.companies.forEach(company => {
            companiesStore.addOrUpdateCompany(company)
          })
        }

        return data
      })
  }
}

export const exchangeService = new ExchangeService()
