import { refreshTokensInterceptor } from '@/api/refreshTokensInterceptor'
import i18n from '@/plugins/i18n'
import { authStore } from '@/store/auth/auth.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import axios from 'axios'
import qs from 'qs'

/**
 * Http client for requests to Roolz Exchange Server
 */
export const exchangeHttp = axios.create({
  paramsSerializer: (params: any) => qs.stringify(params, { encode: false, arrayFormat: 'comma' }),
  baseURL: process.env.REACT_APP_OWN_SERVER_BASE_URL + '/exchange',
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
})
exchangeHttp.interceptors.request.use(refreshTokensInterceptor)

// Change request data/error here
exchangeHttp.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authStore.access_token}`,
      'Accept-Language': i18n.language
    }

    if(config.params?.active_space_company_id === undefined) {
      if(profilesStore.activeCompanyId) {
        config.params = {
          ...config?.params,
          active_space_company_id: profilesStore.activeCompanyId
        }
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
