import { forwardRef } from 'react'

interface Props {
  color?: string
  style?: any
}

export const Sent = forwardRef(({
  color = '#8E8E93',
  style
}: Props, ref: any) => {
  return (
    <svg
      ref={ref}
      style={style}
      width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.36584 7.51667C3.28806 7.51667 3.21318 7.50267 3.14118 7.47467C3.06873 7.44711 3.00473 7.40556 2.94918 7.35L0.165842 4.56667C0.0658424 4.46667 0.0185092 4.34711 0.0238426 4.208C0.0296203 4.06933 0.0769536 3.95 0.165842 3.85C0.265842 3.75 0.385398 3.7 0.524509 3.7C0.663176 3.7 0.776954 3.75 0.865843 3.85L3.36584 6.35L9.13251 0.583334C9.2214 0.483334 9.33807 0.433334 9.48251 0.433334C9.62695 0.433334 9.74362 0.483334 9.83251 0.583334C9.93251 0.683334 9.98251 0.802668 9.98251 0.941334C9.98251 1.08045 9.93251 1.2 9.83251 1.3L3.78251 7.35C3.72695 7.40556 3.66318 7.44711 3.59118 7.47467C3.51873 7.50267 3.44362 7.51667 3.36584 7.51667Z'
        fill={color}
      />
    </svg>
  )
})
