import * as React from 'react'

interface Props {
  size?: number
  color?: string
  className?: string
}

export const Add = ({
  size = 12,
  color = '#8E8E93',
  className
}: Props) => {
  return (
    <svg
      className={className}
      width={size} height={size} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.375 11.625V6.625H0.375V5.375H5.375V0.375H6.625V5.375H11.625V6.625H6.625V11.625H5.375Z'
        fill={color}
      />
    </svg>
  )
}
