import { ProfileLink } from '@/components/globalModalLinks/ProfileLink'
import { useProfileLink } from '@/global-modals/hooks/useProfileLink'
import { MessageModelFactory } from '@/models/Message.model'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { Profile } from '@roolz/types/api/profiles'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'src/components/chats/shared/message/ForwardedFrom/ForwardedFrom.module.scss'
import { Message } from '@roolz/types/api/chats'

interface Props {
  forwardedMessage: Message
}

export const ForwardFrom = observer(({
  forwardedMessage
}: Props) => {
  const { t } = useTranslation('chat/message')

  const { openProfile } = useProfileLink()

  const messageModel = useMemo(() => {
    return MessageModelFactory(forwardedMessage)
  }, [forwardedMessage])

  // TODO probably on receive message need to check if profiles is loaded and load if not

  function handleClick() {
    // CRUNCH TO DETECT IF PROFILE BELONGS TO CHANNEL
    if(messageModel.owner?.is_system_user
      && messageModel?.sender_id) {
      chatsService.setActiveChat(messageModel.chat_id)
    } else {
      if(chatsStore.getDialogWithUser(messageModel?.sender_id)) {
        chatsService.setActiveChatAsDialog(messageModel.sender_id)
      } else {
        openProfile(messageModel.sender_id)
      }
    }
  }

  // TODO add logic for channel forwarding
  return (
    <div
      className={styles.root}
      onClick={handleClick}
    >
      {/*{profile.id}*/}

      <span className={styles.label}>
        {t('forward_from')}:
      </span>
      <div className={styles.profile}>
        <div className={styles.profile}></div>
        {messageModel.owner?.first_name} {messageModel.owner?.last_name}
      </div>
    </div>
  )
})
