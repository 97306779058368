interface Props {
 size?: number
}
export const NoMessages = ({
  size = 200
}: Props) => {
 return (
   <svg width={size} height={size} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M200 200H0C65.2306 180.431 134.769 180.431 200 200Z" fill="url(#paint0_linear_10737_114117)"/>
     <path d="M54.3593 106.357C54.3593 98.6043 60.6184 92.3149 68.3439 92.3149C76.0693 92.3149 82.3285 98.6043 82.3285 106.357C82.3285 114.111 76.0651 120.4 68.3439 120.4C60.6227 120.4 54.3593 114.115 54.3593 106.357ZM109.026 51.8894C109.026 44.3702 115.099 38.2723 122.587 38.2723C130.075 38.2723 136.148 44.3702 136.148 51.8894C136.148 59.4085 130.075 65.5064 122.587 65.5064C115.099 65.5064 109.026 59.4085 109.026 51.8894ZM116.654 136.996C116.654 126.187 125.38 117.421 136.148 117.421C146.916 117.421 155.642 126.187 155.642 136.996C155.642 147.804 146.912 156.57 136.148 156.57C125.384 156.57 116.654 147.804 116.654 136.996ZM39.0524 116.119C34.6113 122.072 31.9796 129.468 31.9796 137.481C31.9796 157.174 47.8797 173.145 67.4963 173.145C78.9129 173.145 89.0666 167.728 95.5631 159.319C103.623 170.055 116.426 177 130.847 177C155.235 177 175 157.149 175 132.664C175 120.366 170.012 109.234 161.956 101.204C165.999 93.2979 168.279 84.3319 168.279 74.834C168.279 42.8936 142.492 17 110.688 17C88.2699 17 68.8397 29.8638 59.3259 48.6426C40.0695 50.4553 25 66.7277 25 86.5404C25 98.4851 30.4794 109.14 39.0524 116.119Z" fill="url(#paint1_linear_10737_114117)"/>
     <path d="M69.2637 103.149V53.9021C69.2637 47.7234 74.2685 42.6979 80.4217 42.6979H150.714C156.867 42.6979 161.872 47.7234 161.872 53.9021V103.153C161.872 109.332 156.867 114.357 150.714 114.357H145.467C144.531 114.357 143.772 115.119 143.772 116.06V135.004L118.909 114.74C118.608 114.494 118.231 114.362 117.841 114.362H80.4217C74.2685 114.362 69.2637 109.336 69.2637 103.157V103.149Z" fill="white"/>
     <path d="M38.1285 89.7447C38.1285 85.366 41.6755 81.8043 46.0362 81.8043H65.8774V103.149C65.8774 111.204 72.4036 117.757 80.4257 117.757H106.458V126.54C106.458 130.919 102.911 134.481 98.5506 134.481H70.5898C70.1999 134.481 69.8228 134.617 69.5219 134.86L51.647 149.43V136.183C51.647 135.243 50.8884 134.481 49.9519 134.481H46.0319C41.6713 134.481 38.1243 130.919 38.1243 126.54V89.7447H38.1285ZM108.149 114.353H80.4214C74.2682 114.353 69.2634 109.328 69.2634 103.149V53.9021C69.2634 47.7234 74.2682 42.6979 80.4214 42.6979H150.713C156.866 42.6979 161.871 47.7234 161.871 53.9021V103.153C161.871 109.332 156.866 114.357 150.713 114.357H145.467C144.53 114.357 143.772 115.119 143.772 116.06V135.004L118.909 114.74C118.608 114.494 118.231 114.362 117.841 114.362H108.149V114.353ZM34.7383 89.7447V126.54C34.7383 132.796 39.8067 137.885 46.0362 137.885H48.261V153.004C48.261 153.66 48.6382 154.26 49.2272 154.54C49.4603 154.651 49.7061 154.706 49.9561 154.706C50.3375 154.706 50.7147 154.579 51.024 154.328L71.1958 137.885H98.5506C104.78 137.885 109.848 132.796 109.848 126.54V117.757H117.243L144.403 139.894C144.713 140.145 145.09 140.272 145.471 140.272C145.721 140.272 145.967 140.217 146.2 140.106C146.789 139.826 147.166 139.226 147.166 138.57V117.753H150.717C158.74 117.753 165.266 111.2 165.266 103.145V53.9021C165.266 45.8468 158.74 39.2936 150.717 39.2936H80.4214C72.3993 39.2936 65.8732 45.8468 65.8732 53.9021V78.4043H46.0362C39.8067 78.4043 34.7383 83.4936 34.7383 89.7489V89.7447Z" fill="url(#paint2_linear_10737_114117)"/>
     <path d="M46.0369 81.8085H65.8781V103.153C65.8781 111.209 72.4043 117.762 80.4264 117.762H106.459V126.545C106.459 130.923 102.912 134.485 98.5513 134.485H70.5905C70.2007 134.485 69.8235 134.621 69.5226 134.864L51.6477 149.434V136.187C51.6477 135.247 50.8892 134.485 49.9526 134.485H46.0327C41.672 134.485 38.125 130.923 38.125 126.545V89.7489C38.125 85.3702 41.672 81.8085 46.0327 81.8085H46.0369Z" fill="#F2F2F2"/>
     <path d="M38.9993 171.417C38.9993 169.385 37.5546 167.693 35.6392 167.315C35.6567 167.16 35.6662 167.004 35.6662 166.845C35.6662 164.536 33.7998 162.664 31.4977 162.664C31.2292 162.664 30.9665 162.691 30.7119 162.74C29.9552 161.686 28.7221 161 27.3292 161C26.8082 161 26.3099 161.097 25.8502 161.272C25.3905 161.097 24.8922 161 24.3712 161C23.3293 161 22.3778 161.384 21.6474 162.018C20.917 161.384 19.9656 161 18.9236 161C16.6216 161 14.7551 162.872 14.7551 165.181C14.7551 165.412 14.7748 165.638 14.8106 165.859C12.5917 165.953 10.8216 167.787 10.8216 170.036C10.8216 170.571 10.923 171.083 11.1061 171.554C9.31848 172.026 8 173.657 8 175.598C8 177.907 9.86645 179.779 12.1685 179.779C12.1984 179.779 12.2276 179.778 12.2575 179.777C12.1991 180.055 12.1685 180.343 12.1685 180.638C12.1685 182.835 13.8576 184.635 16.005 184.805C16.5391 186.496 18.1166 187.722 19.9794 187.722C20.7996 187.722 21.5642 187.484 22.2092 187.073C22.9506 188.232 24.245 189 25.7196 189C28.0217 189 29.8881 187.128 29.8881 184.819C29.8881 184.698 29.8815 184.579 29.8721 184.462C29.8772 184.462 29.883 184.462 29.8881 184.462C32.1902 184.462 34.0566 182.59 34.0566 180.28C34.0566 179.909 34.0077 179.551 33.918 179.208C35.6312 178.689 36.8782 177.094 36.8782 175.206C36.8782 175.157 36.876 175.108 36.8745 175.059C38.1434 174.342 39 172.979 39 171.415L38.9993 171.417Z" fill="url(#paint3_linear_10737_114117)"/>
     <ellipse cx="10" cy="15.5" rx="10" ry="15.5" transform="matrix(-1 0 0 1 192 161)" fill="url(#paint4_linear_10737_114117)"/>
     <defs>
       <linearGradient id="paint0_linear_10737_114117" x1="100" y1="170" x2="100" y2="200" gradientUnits="userSpaceOnUse">
         <stop stop-color="#E8EAEE"/>
         <stop offset="1" stop-color="#E3E4EB" stop-opacity="0"/>
       </linearGradient>
       <linearGradient id="paint1_linear_10737_114117" x1="100" y1="17" x2="100" y2="177" gradientUnits="userSpaceOnUse">
         <stop stop-color="#E7EAED"/>
         <stop offset="1" stop-color="#DDDEE5" stop-opacity="0"/>
       </linearGradient>
       <linearGradient id="paint2_linear_10737_114117" x1="100.002" y1="39.2936" x2="100.002" y2="154.706" gradientUnits="userSpaceOnUse">
         <stop stop-color="#AFB2BB"/>
         <stop offset="1" stop-color="#C6C8CF"/>
       </linearGradient>
       <linearGradient id="paint3_linear_10737_114117" x1="23.5" y1="161" x2="23.5" y2="189" gradientUnits="userSpaceOnUse">
         <stop stop-color="#E7EAED"/>
         <stop offset="1" stop-color="#DDDEE5" stop-opacity="0"/>
       </linearGradient>
       <linearGradient id="paint4_linear_10737_114117" x1="10" y1="0" x2="10" y2="31" gradientUnits="userSpaceOnUse">
         <stop stop-color="#E7EAED"/>
         <stop offset="1" stop-color="#DDDEE5" stop-opacity="0"/>
       </linearGradient>
     </defs>
   </svg>
 )
}
