interface Props {
  color?: string
}

export const Pin = ({
  color = '#8E8E93'
}: Props) => {
  return (
    <svg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 7.84127C0 8.07312 0.0724534 8.25969 0.21736 8.40097C0.362267 8.53863 0.55608 8.60746 0.798799 8.60746H3.7386V11.1451C3.7386 11.3516 3.75671 11.5473 3.79294 11.732C3.82916 11.9168 3.87445 12.078 3.92879 12.2156C3.98313 12.3569 4.03566 12.4674 4.08637 12.5471C4.14071 12.6268 4.186 12.6667 4.22222 12.6667C4.25483 12.6667 4.29649 12.6268 4.3472 12.5471C4.40154 12.4674 4.45588 12.3569 4.51022 12.2156C4.56456 12.078 4.60985 11.9168 4.64607 11.732C4.6823 11.5473 4.70041 11.3516 4.70041 11.1451V8.60746H7.64021C7.88655 8.60746 8.08037 8.53863 8.22165 8.40097C8.36656 8.25969 8.43901 8.07312 8.43901 7.84127C8.43901 7.45727 8.3412 7.0787 8.14557 6.70556C7.94995 6.32881 7.66919 5.98465 7.3033 5.6731C6.93741 5.36155 6.50088 5.1134 5.99371 4.92864L5.81439 2.43443C6.07884 2.28228 6.32337 2.12832 6.54798 1.97254C6.77621 1.81677 6.97545 1.66462 7.14572 1.51609C7.31598 1.36756 7.44821 1.23171 7.5424 1.10854C7.68368 0.927404 7.75433 0.749893 7.75433 0.576005C7.75433 0.409362 7.69455 0.2717 7.575 0.16302C7.45908 0.0543401 7.30693 0 7.11855 0H1.3259C1.1339 0 0.979932 0.0543401 0.864007 0.16302C0.748081 0.2717 0.690119 0.409362 0.690119 0.576005C0.690119 0.749893 0.758949 0.927404 0.896611 1.10854C0.9908 1.23171 1.12303 1.36756 1.29329 1.51609C1.46356 1.66462 1.66099 1.81858 1.8856 1.97798C2.11383 2.13375 2.36017 2.28591 2.62462 2.43443L2.4453 4.92864C1.93813 5.1134 1.5016 5.36155 1.13571 5.6731C0.769817 5.98465 0.489061 6.32881 0.293436 6.70556C0.0978121 7.0787 0 7.45727 0 7.84127Z'
        fill={color}
      />
    </svg>
  )
}
