import { useLayoutEffect, useState } from 'react'
import { lockPageScroll, unlockPageScroll } from '../utils/scroll'

interface Options {
  lock: () => void
  unlock: () => void
}

let locks: number = 0

export function useLockScrolling(): Options {
  const [isLocked, setIsLocked] = useState<boolean>(false)

  const lock = () => {
    setIsLocked(true)
  }

  const unlock = () => {
    setIsLocked(false)
  }

  useLayoutEffect(() => {
    if(isLocked) {
      locks++
      lockPageScroll()
    } else {
      locks = Math.max(locks, 0)

      if(locks <= 0) {
        unlockPageScroll()
      }
    }

    return () => {
      if(isLocked === true) {
        locks = Math.max(locks - 1, 0)

        if(locks <= 0) {
          unlockPageScroll()
        }
      }
    }
  }, [isLocked])

  // useEffect(() => {
  //   console.log(isLocked)
  // }, [isLocked])

  return {
    lock,
    unlock
  }
}
