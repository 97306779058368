import { get, set, remove } from './cookie'

const AFTER_AUTH_URL_KEY = 'after_auth_redirect_key'

const { hostname } = new URL(process.env.NEXT_PUBLIC_ROOLZ_STATIC_URL ?? process.env.REACT_APP_ROOLZ_STATIC_URL)
const domain = hostname.split('.').slice(-2).join('.')

export const setAfterAuthURL = (url: string) => set(AFTER_AUTH_URL_KEY, url, { expires: '0', domain })

export const getAfterAuthURL = () => get(AFTER_AUTH_URL_KEY) || null

export const removeAfterAuthURL = () => remove(AFTER_AUTH_URL_KEY, { domain })
