import { BaseApiClient } from '@/api/clients/_base.client'
import { identityHttp } from '@/api/transport/http/identity.http'
import {
  authToken,
  checkTokenExpiration,
  getMagicLink,
  getResetPasswordLink,
  loginByEmail,
  loginByMagicLink,
  loginByMagicLinkToken,
  loginBySms,
  loginBySocialNetwork,
  testToken
} from '@roolz/types/api/profiles'
import { Credentials } from '@roolz/sdk/utils/auth'

class AuthClient extends BaseApiClient {
  authToken(body: authToken) {
    return this.post('/v1/auth/token', { body })
  }

  testToken(body: testToken) {
    return this.post('/v1/auth/token', { body })
  }

  checkTokenExpiration(body: checkTokenExpiration) {
    return this.post('/v1/auth/check_token_expiration', { body })
  }

  loginByEmail(body: loginByEmail) {
    return this.post('/v1/auth/login/email_password', { body })
  }

  loginByMagicLinkToken(body: loginByMagicLinkToken) {
    return this.post('/v1/auth/login/magic_link_token', { body })
  }

  loginBySms(body: loginBySms) {
    return this.post('/v1/auth/login/sms', { body })
  }

  getResetPasswordLink(body: getResetPasswordLink) {
    return this.post('/v1/auth/get_reset_password_link', { body })
  }

  getMagicLink(body: getMagicLink) {
    return this.post('/v1/auth/get_magic_link', { body })
  }

  loginViaMagicLink(body: loginByMagicLink) {
    return this.post('/v1/auth/login/magic_link_token', { body })
  }

  loginViaSocialNetwork(body: loginBySocialNetwork) {
    return this.post('/v1/auth/oauth2/social_networks', { body })
  }

  logout() {
    return this.post('/v1/me/device/logout')
  }
}

export const authClient = new AuthClient(identityHttp, '')
