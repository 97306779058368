import { withSuspense } from '@/components/utils/WithSuspense'
import { ComponentType, lazy, ReactNode } from 'react'

export function lazyWithSuspense<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  Fallback?: ReactNode | null
): any {
  return withSuspense(lazy<T>(factory), Fallback)
}

