import { useWindowResizeLight } from '@roolz/sdk/hooks/useWindowResizeLight'
import { useRef, useState } from 'react'
import { isClient } from '@roolz/sdk/utils/ssr'

interface Props {
  minWidth?: number;
  maxWidth?: number;

  minHeight?: number;
  maxHeight?: number;
}

// return if specified parameters of breakpoint are satisfied
export function useBreakpoint({
  minWidth,
  maxWidth,
  minHeight,
  maxHeight
}: Props): boolean {
  const vw = useRef<number>(isClient() ? window?.innerWidth : 0)
  const vh = useRef<number>(isClient() ? window?.innerWidth : 0)

  const [fits, setFits] = useState<boolean>(false)

  function checkActiveness() {
    const width = vw.current
    const height = vh.current

    setFits(
      (minWidth === undefined || minWidth <= width)
      && (maxWidth === undefined || maxWidth >= width)
      && (minHeight === undefined || minHeight <= height)
      && (maxHeight === undefined || maxHeight >= height)
    )
  }

  const onWidthChange = (width: number) => {
    vw.current = width
    checkActiveness()
  }
  const onHeightChange = (height: number) => {
    vh.current = height
    checkActiveness()
  }

  useWindowResizeLight({ onWidthChange, onHeightChange })

  return fits
}
