let audioNotification: HTMLAudioElement | null = null

export function loadAudio() {
  audioNotification = new Audio('/sound/notification.wav')

  audioNotification.load()
}

export function playNotification() {
  if(audioNotification) {
    audioNotification.play()
  }
}

