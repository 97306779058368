export const DoubleArrowLeft = () => {
  return (
    <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.98439 15.2668C8.13575 15.4136 8.33161 15.5 8.5631 15.5C9.02606 15.5 9.37328 15.1632 9.37328 14.7142C9.37328 14.4983 9.28425 14.2997 9.1418 14.1528L2.66032 8.00432L9.1418 1.85579C9.28425 1.70898 9.37328 1.50173 9.37328 1.29447C9.37328 0.845423 9.02606 0.5 8.5631 0.5C8.33161 0.5 8.13575 0.586356 7.98439 0.733161L0.93311 7.42573C0.76395 7.5639 0.666016 7.77979 0.666016 8.00432C0.666016 8.22021 0.755047 8.41883 0.924206 8.5829L7.98439 15.2668ZM15.9349 15.2668C16.0862 15.4136 16.291 15.5 16.5136 15.5C16.9766 15.5 17.3327 15.1632 17.3327 14.7142C17.3327 14.4983 17.2347 14.2997 17.0923 14.1528L10.6197 8.00432L17.0923 1.85579C17.2347 1.70898 17.3327 1.50173 17.3327 1.29447C17.3327 0.845423 16.9766 0.5 16.5136 0.5C16.291 0.5 16.0951 0.586356 15.9349 0.733161L8.88361 7.42573C8.71445 7.5639 8.61651 7.77979 8.61651 8.00432C8.61651 8.22021 8.71445 8.41883 8.88361 8.5829L15.9349 15.2668Z'
        fill='#8E8E93'
      />
    </svg>
  )
}
