interface Props {
  className?: string,
  color?: string,
  size?: number
}

export const Delete = ({
  className,
  color = '#1C1B1F',
  size = 16
}: Props) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.3 17.5C2.8 17.5 2.375 17.325 2.025 16.975C1.675 16.625 1.5 16.2 1.5 15.7V3H1.25C1.03333 3 0.854333 2.929 0.713 2.787C0.571 2.64567 0.5 2.46667 0.5 2.25C0.5 2.03333 0.571 1.85433 0.713 1.713C0.854333 1.571 1.03333 1.5 1.25 1.5H5C5 1.25 5.08733 1.04167 5.262 0.875C5.43733 0.708333 5.65 0.625 5.9 0.625H10.1C10.35 0.625 10.5627 0.708333 10.738 0.875C10.9127 1.04167 11 1.25 11 1.5H14.75C14.9667 1.5 15.146 1.571 15.288 1.713C15.4293 1.85433 15.5 2.03333 15.5 2.25C15.5 2.46667 15.4293 2.64567 15.288 2.787C15.146 2.929 14.9667 3 14.75 3H14.5V15.7C14.5 16.2 14.325 16.625 13.975 16.975C13.625 17.325 13.2 17.5 12.7 17.5H3.3ZM3 3V15.7C3 15.7833 3.02933 15.8543 3.088 15.913C3.146 15.971 3.21667 16 3.3 16H12.7C12.7833 16 12.8543 15.971 12.913 15.913C12.971 15.8543 13 15.7833 13 15.7V3H3ZM5.4 13.25C5.4 13.4667 5.471 13.646 5.613 13.788C5.75433 13.9293 5.93333 14 6.15 14C6.36667 14 6.546 13.9293 6.688 13.788C6.82933 13.646 6.9 13.4667 6.9 13.25V5.75C6.9 5.53333 6.82933 5.354 6.688 5.212C6.546 5.07067 6.36667 5 6.15 5C5.93333 5 5.75433 5.07067 5.613 5.212C5.471 5.354 5.4 5.53333 5.4 5.75V13.25ZM9.1 13.25C9.1 13.4667 9.17067 13.646 9.312 13.788C9.454 13.9293 9.63333 14 9.85 14C10.0667 14 10.246 13.9293 10.388 13.788C10.5293 13.646 10.6 13.4667 10.6 13.25V5.75C10.6 5.53333 10.5293 5.354 10.388 5.212C10.246 5.07067 10.0667 5 9.85 5C9.63333 5 9.454 5.07067 9.312 5.212C9.17067 5.354 9.1 5.53333 9.1 5.75V13.25ZM3 3V15.7C3 15.7833 3.02933 15.8543 3.088 15.913C3.146 15.971 3.21667 16 3.3 16H3V3Z'
      fill={color}
    />
  </svg>
)
