import { WSPackages } from '@/components/_debug/WSPackages/WSPackages'

export const DebugProvider = () => {
 return (
  <>
    <WSPackages/>
    <div/>
  </>
 )
}
