interface Props {
  color?: string,
  className?: string
}

export const Search = ({
  className,
  color = '#8E8E93'
}: Props) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.0254 17.05L10.2504 11.3C9.75039 11.7167 9.17539 12.0417 8.52539 12.275C7.87539 12.5083 7.20872 12.625 6.52539 12.625C4.80872 12.625 3.35872 12.0333 2.17539 10.85C0.992057 9.66667 0.400391 8.21667 0.400391 6.5C0.400391 4.8 0.992057 3.354 2.17539 2.162C3.35872 0.970667 4.80872 0.375 6.52539 0.375C8.22539 0.375 9.66706 0.966667 10.8504 2.15C12.0337 3.33333 12.6254 4.78333 12.6254 6.5C12.6254 7.21667 12.5087 7.9 12.2754 8.55C12.0421 9.2 11.7254 9.76667 11.3254 10.25L17.1004 16.025C17.2337 16.1583 17.3004 16.325 17.3004 16.525C17.3004 16.725 17.2254 16.9 17.0754 17.05C16.9254 17.2 16.7464 17.275 16.5384 17.275C16.3297 17.275 16.1587 17.2 16.0254 17.05ZM6.52539 11.125C7.80872 11.125 8.89639 10.675 9.78839 9.775C10.6797 8.875 11.1254 7.78333 11.1254 6.5C11.1254 5.21667 10.6797 4.125 9.78839 3.225C8.89639 2.325 7.80872 1.875 6.52539 1.875C5.22539 1.875 4.12972 2.325 3.23839 3.225C2.34639 4.125 1.90039 5.21667 1.90039 6.5C1.90039 7.78333 2.34639 8.875 3.23839 9.775C4.12972 10.675 5.22539 11.125 6.52539 11.125Z'
        fill={color}
      />
    </svg>
  )
}
