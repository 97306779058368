import { BaseApiClient } from '@/api/clients/_base.client'
import { identityHttp } from '@/api/transport/http/identity.http'
import { retryRequest } from '../retryRequest'

class CountriesClient extends BaseApiClient {
  detectMine() {
    return retryRequest(() => this.get('/v1/system/country/auto'), {
      attempts: 5
    })
  }

  getList() {
    return retryRequest(() => this.get('/v1/system/country/list'), {
      attempts: Infinity,
      delay: 1500
    })
  }
}

export const countriesClient = new CountriesClient(identityHttp, '')
