import { Location } from '@remix-run/router'

export function useLastLocation() {
  function saveLastLocation(location: Location): void {
    localStorage.setItem('last_location', JSON.stringify(location))
  }

  function getLastLocation(): Location | null {
    return JSON.parse(localStorage.getItem('last_location') as string)
  }

  return {
    saveLastLocation,
    getLastLocation
  }
}
