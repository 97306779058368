import { resolvePathByName, ROUTE_NAMES, useNavigateToModal } from '@/config/routes'
import { Profile } from '@roolz/types/api/profiles'
import { matchPath } from 'react-router'
import { generatePath } from 'react-router-dom'

export function useProfileLink() {
  const navigate = useNavigateToModal()
  const profileViewPath = resolvePathByName(ROUTE_NAMES.PROFILE_VIEW)
  const currentPath = window.location.pathname

  // navigate to another profile with replace prop to close all profiles at once
  // with correct history
  const isAnotherProfileOpened = !!matchPath(profileViewPath, currentPath)
  const getLink = (id: Profile["id"]) => generatePath(resolvePathByName(ROUTE_NAMES.PROFILE_VIEW), {
    profile_id: id ?? ''
  })

  const openProfile = (id: Profile["id"]) => {
    navigate(getLink(id), { replace: isAnotherProfileOpened })
  }

  return {
    isAnotherProfileOpened,
    getLink,
    openProfile
  }
}
