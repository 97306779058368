import React, { ReactNode } from 'react'
import { toast as toastifyToast, ToastOptions } from 'react-toastify'
import { Breakpoint } from '../utils/Breakpoint'
import { Archived } from './icons/Archived'
import { Closed } from './icons/Closed'
import { DeleteTimer } from './icons/DeleteTimer'
import { EmailSuccess } from './icons/EmailSuccess'
import { Emodji } from './icons/Emodji'
import { Error } from './icons/Error'
import { Instruments } from './icons/Instruments'
import { Offline } from './icons/Offline'
import { Success } from './icons/Success'
import { Trashed } from './icons/Trashed'
import { Update } from './icons/Update'
import styles from './snackbars.module.scss'


interface Action {
  title: string
  onClick?: (params: {
    closeToast: () => void,
    toastProps: any
  }) => void
}

type IconType =
  'success'
  | 'error'
  | 'instsruments'
  | 'warning'
  | 'update'
  | 'archived'
  | 'closed'
  | 'deleteTimer'
  | 'emailSuccess'
  | 'offline'
  | 'trashed'

export type ToastParams = ToastOptions & {
  title?: ReactNode
  message?: ReactNode

  icon?: IconType | ReactNode
  actions?: Action[]
}

export const IconTypeComponents: { [key in IconType]: ReactNode } = {
  success: <Success/>,
  error: <Error/>,
  instsruments: <Instruments/>,
  warning: <Emodji/>,
  update: <Update/>,
  archived: <Archived/>,
  closed: <Closed/>,
  deleteTimer: <DeleteTimer/>,
  emailSuccess: <EmailSuccess/>,
  offline: <Offline/>,
  trashed: <Trashed/>
}

export function toast({
  title,
  message,
  actions,
  icon,
  ...props
}: ToastParams) {
  let Icon = icon

  if(typeof icon === 'string') {
    Icon = IconTypeComponents[icon]
  }

  const Content = ({
    closeToast,
    toastProps
  }) => {
    function Actions() {
      if(!actions || !actions?.length) {
        return null
      }

      return (
        <div className={styles.actions}>
          {actions.map(action => (
            <button
              className={styles.actions__button}
              onClick={(e: any) => {
                e.preventDefault()
                e.stopPropagation()
                action.onClick({ closeToast, toastProps })
              }}
            >
              {action.title}
            </button>
          ))}
        </div>
      )
    }

    return <>
      <div className={styles.wrapper}>
        {Icon && (
          <div className={styles.icon}>
            {Icon}
          </div>
        )}

        <div className={styles.content}>
          {title && (
            <h2 className={styles.title}>{title}</h2>
          )}

          <div className={styles.message}>
            {message}
          </div>
        </div>

        <Breakpoint minWidth={801}>
          <Actions/>
        </Breakpoint>
      </div>

      <Breakpoint maxWidth={800}>
        <Actions/>
      </Breakpoint>
    </>
  }


  return toastifyToast(Content, {
    className: styles.toast,
    bodyClassName: styles.body,
    closeButton: false,
    ...props
  })
}

export function toastError(message: ReactNode, params?: ToastParams) {
  return toast({
    ...params,
    message,
    icon: 'error'
  })
}

export function toastSuccess(message: ReactNode, params?: ToastParams) {
  return toast({
    ...params,
    message,
    icon: 'success'
  })
}

export function toastWarning(message: ReactNode, params?: ToastParams) {
  return toast({
    ...params,
    message,
    icon: 'warning'
  })
}

export const useSnackbar = () => {
  return {
    toast
  }
}
