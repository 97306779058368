import cn from 'classnames'
import { ReactNode } from 'react'
import styles from './MenuListContent.module.scss'

interface Props {
  className?: string
  children: ReactNode
}

export const MenuListContent = ({
  className,
  children
}: Props) => {
  return (
    <div className={cn(styles.root, className)}>
      {children}
    </div>
  )
}
