import { profilesClient, systemClient } from '@/api'
import { wsProxyClient } from '@/api/clients/ws-proxy.client'
import i18n from '@/plugins/i18n'
import { addOrUpdateProfiles, getProfileById, patchProfile } from '@/repositories/profiles.repository'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import {
  changePasswordRequest,
  deleteOwnEmailRequest,
  deleteOwnPasswordRequest,
  deleteOwnProfileRequest, Profile,
  updateMyProfileRequest,
  updateOwnEmailRequest,
  updateOwnPhoneRequest
} from '@roolz/types/api/profiles'
import { getDeviceInfo } from '@roolz/sdk/utils/device'
import sha256 from 'sha256'
import { profilesStore } from './profiles.store'
import { throttle } from 'lodash'
import dayjs from '@roolz/sdk/plugins/dayjs'

class ProfilesService {
  bindToProfilesOnlineChanges = throttle(() => {
    const ids = [...profilesStore.presenceSubscriptionQueue]
    profilesStore.presenceSubscriptionQueue = []

    return wsProxyClient.bindToPresence({ user_ids: ids })
  }, 3000)

  loadProfile(id: Profile['id']) {
    return profilesClient.getById(id)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  private loadingProfileIds: Profile["id"][] = []

  loadProfiles(ids: Array<Profile["id"]>) {
    ids = ids.filter(id => !this.loadingProfileIds.includes(id))
    this.loadingProfileIds.push(...ids)

    if(!ids.length) {
      return
    }

    return Promise.all(ids.map(id => {
      return profilesClient.getById(id)
        .then(({ data }) => addOrUpdateProfiles([data]))
    }))

    // const requests: Array<Promise<any>> = []

    // const LIMIT = 50
    // let offset = 0
    //
    // do {
    //   const request = profilesClient.getByIds({
    //     profile_ids: ids.slice(offset, offset + LIMIT),
    //     limit: LIMIT,
    //     offset
    //   }).then(({ data }) => {
    //     return addOrUpdateProfiles(data)
    //   })
    //
    //   requests.push(request)
    //   offset += LIMIT
    // } while(offset + LIMIT < ids.length)

    // return Promise.all(requests)
  }

  retrieveMyProfile() {
    return profilesClient.retrieveMe()
      .then(({ data }) => {
        profilesStore.setMyProfile(data)
        myCompaniesStore.companies = data?.companies || []

        if(data.lang_code) {
          i18n.changeLanguage(data.lang_code)
        }

        return data
      })
  }

  retrieveMyDevice() {
    return profilesClient.retrieveMyDevice()
      .then(({ data }) => {
        profilesStore.setMyDevice(data)

        return data
      })
  }


  updateMyProfile(data: updateMyProfileRequest) {
    return profilesClient.updateMyProfile(data)
      .then(({ data }) => {
        profilesStore.setMyProfile(data)

        return data
      })
  }

  deleteOwnProfile(data: deleteOwnProfileRequest) {
    return profilesClient.deleteOwnProfile(data)
      .then(({ data }) => {
        profilesStore.setMyProfile(data)

        return data
      })
  }

  changeOwnPassword(data: changePasswordRequest) {
    return profilesClient.changePassword(data)
      .then(({ data }) => {
        profilesStore.setMyProfile(data)

        return data
      })
  }

  deleteOwnEmail(data: deleteOwnEmailRequest) {
    return profilesClient.deleteOwnEmail(data)
      .then(({ data }) => {
        profilesStore.setMyProfile(data)

        return data
      })
  }

  deleteOwnPassword(data: deleteOwnPasswordRequest) {
    return profilesClient.deleteOwnPassword(data)
      .then(({ data }) => {
        profilesStore.setMyProfile(data)

        return data
      })
  }

  updateOwnEmail(data: updateOwnEmailRequest) {
    return profilesClient.updateOwnEmail(data)
      .then(({ data }) => {
        profilesStore.setMyProfile(data)

        return data
      })
  }

  sendSmsToChangePhone({ phone }: { phone: string }) {
    phone = '+' + phone.replaceAll(/\D/g, '')
    const hash = sha256(`${phone}_${process.env.REACT_APP_SECRET_SMS_KEY}`)

    return systemClient.sendSmsToChangePhone({
      phone,
      imei: getDeviceInfo().installation_id,
      hash
    }).then(({ data }) => data)
  }

  updateOwnPhone(data: updateOwnPhoneRequest) {
    return profilesClient.updateOwnPhone(data)
      .then(({ data }) => {
        profilesStore.setMyProfile(data)

        return data
      })
  }

  deleteOwnPhone() {
    return profilesClient.deleteOwnPhone()
      .then(({ data }) => {
        profilesStore.setMyProfile(data)

        return data
      })
  }

  async topUpProfileLastAction(profile_id: Profile["id"], date?: string): Promise<void> {
    const profile = await getProfileById(profile_id)
    const newDate = date || (new Date()).toString()

    if(profile &&
      (!profile.last_action_time || dayjs(profile.last_action_time).isBefore(newDate))
    ) {
      patchProfile(profile.id, {
        last_action_time: newDate
      })
    }
  }

  // updateProfile(data: updateProfileRequest) {
  //   return profiles.updateProfile(data)
  //     .then(({ data }) => {
  //       console.log(data)
  //     })
  // }
}

export const profilesService = new ProfilesService
