import { HollowDots } from '@/components/ui/Spinners/HollowDots/HollowDots'
import { Fade } from '@mui/material'
import { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress'
import cn from 'classnames'
import { ReactNode, useLayoutEffect, useState } from 'react'
import styles from './Loadable.module.scss'

interface Props extends CircularProgressProps {
  Tag?: any
  loading: boolean
  delay?: number
  className?: string
  loadingContentClassname?: string
  children?: ReactNode

  dontHideContent?: boolean

  [key: string]: any
}

export function Loadable({
  Tag = 'div',
  loading,
  children,
  delay = 0,
  color = 'primary',
  className,
  loadingContentClassname,
  dontHideContent,
  ...rest
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { style, ...etc } = rest

  useLayoutEffect(() => {
    if(delay > 0) {
      if(loading) {
        setTimeout(() => {
          setIsLoading(true)
        }, delay)
      } else {
        setIsLoading(false)
      }
    } else {
      setIsLoading(loading)
    }
  }, [loading])

  return (
    <Tag
      className={cn(styles.wrapper, className)}
      style={loading ? style : {}}
      {...etc}
    >
      <Fade
        in={!loading || dontHideContent}
        appear={!dontHideContent}
        enter={!dontHideContent}
        exit={!dontHideContent}
      >
        <div
          className={cn(styles.content, {
            [styles.contentHidden]: !dontHideContent && loading,
            [loadingContentClassname ?? '']: loading
          })}
        >
          {children}
        </div>
      </Fade>

      {loading && (
        <Fade in={loading}>
          <div className={styles.spinner}>
            {/*<Rhombuses/>*/}
            <HollowDots/>
          </div>
        </Fade>
      )}
    </Tag>
  )
}
