import { Update } from '@roolz/icons/lottie/Update'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './UpdateAppButton.module.scss'

interface Props {
  onUpdate: () => void
  style?: any
}
export const UpdateAppButton = forwardRef(({
  onUpdate,
  style
}: Props, ref: any) => {
  const { t } = useTranslation('ui')

  return (
    <div
      className={styles.root}
      style={style}
      ref={ref}
    >
      <Update className={styles.icon}/>

      <div className={styles.content}>
        {t('update_button.content')}
      </div>

      <button
        className={styles.button}
        onClick={onUpdate}
      >
        {t('update_button.update')}
      </button>
    </div>
 )
})
