interface Props {
  color?: string
}

export const ChevronDown = ({
  color = '#8E8E93'
}: Props) => {
  return (
    <svg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M15 1L8 8L1 1' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>
  )
}
