import { BaseApiClient } from '@/api/clients/_base.client'
import { knowledgeHttp } from '@/api/transport/http/knowledge.http'
import {
  CompanyAssociation,
  CompanyCargoType,
  CompanyEmployeeAmount,
  CompanyForm,
  CompanyGoal,
  CustomsService,
  ExchangeRole,
  FleetAmount,
  LogisticsService,
  OrderAmount,
  TransportationType
} from '@roolz/types/api/knowledge/companies'
import {
  AdrClass,
  CargoType,
  Currency,
  ExtraEquipment,
  LoadingType,
  PaymentCondition,
  PaymentMethod,
  PointType,
  PublicationAutocancelReason,
  ShippingMode,
  TransportType,
  TruckBody
} from '@roolz/types/api/knowledge/exchange'
import { KnowledgeChecksums } from '@roolz/types/api/knowledge'

class KnowledgeClient extends BaseApiClient {
  // Exchange
  getAdrClasses() {
    return this.getDictionaryByPath<AdrClass[]>('/exchange/adr_classes')
  }

  getCargoTypes() {
    return this.getDictionaryByPath<CargoType[]>('/exchange/cargo_types')
  }

  getExtraEquipment() {
    return this.getDictionaryByPath<ExtraEquipment[]>('/exchange/extra_equipment')
  }

  getLoadingTypes() {
    return this.getDictionaryByPath<LoadingType[]>('/exchange/loading_types')
  }

  getPaymentConditions() {
    return this.getDictionaryByPath<PaymentCondition[]>('/exchange/payment_cond')
  }

  getPaymentMethods() {
    return this.getDictionaryByPath<PaymentMethod[]>('/exchange/payment_methods')
  }

  getPointTypes() {
    return this.getDictionaryByPath<PointType[]>('/exchange/point_types')
  }

  getPublicationAutocancelReasons() {
    return this.getDictionaryByPath<PublicationAutocancelReason[]>('/exchange/publ_autocancel_reasons')
  }

  getShippingModes() {
    return this.getDictionaryByPath<ShippingMode[]>('/exchange/shipping_modes')
  }

  getTransportTypes() {
    return this.getDictionaryByPath<TransportType[]>('/exchange/transport_types')
  }

  getTruckBodies() {
    return this.getDictionaryByPath<TruckBody[]>('/exchange/truck_bodies')
  }

  getCurrencies() {
    return this.getDictionaryByPath<Currency[]>('/global/curr')
  }

  // Company

  getCompanyGoals() {
    return this.getDictionaryByPath<CompanyGoal[]>('/company/goal')
  }

  getCompanyAssociations() {
    return this.getDictionaryByPath<CompanyAssociation[]>('/company/associations')
  }

  getCompanyCargoTypes() {
    return this.getDictionaryByPath<CompanyCargoType[]>('/company/cargo_type')
  }

  getCompanyForm() {
    return this.getDictionaryByPath<CompanyForm[]>('/company/company_form')
  }

  getCustomsServices() {
    return this.getDictionaryByPath<CustomsService[]>('/company/customs_service')
  }

  getEmployeeAmounts() {
    return this.getDictionaryByPath<CompanyEmployeeAmount[]>('/company/employee_amount')
  }

  getExchangeRoles() {
    return this.getDictionaryByPath<ExchangeRole[]>('/company/exchange_role')
  }

  getFleetAmounts() {
    return this.getDictionaryByPath<FleetAmount[]>('/company/fleet_amount')
  }

  getLogisticServices() {
    return this.getDictionaryByPath<LogisticsService[]>('/company/logistics_service')
  }

  getOrderAmounts() {
    return this.getDictionaryByPath<OrderAmount[]>('/company/order_amount')
  }

  getTransportationTypes() {
    return this.getDictionaryByPath<TransportationType[]>('/company/transportation_type')
  }

  getAllChecksums() {
    return this.get<KnowledgeChecksums>('/checksums')
  }

  // ETC
  async getDictionaryByPath<DictionaryResponse = unknown>(path: string) {
    return this.get<DictionaryResponse>(path)
  }

  async getDictionariesList(list: string[]) {
    return this.post('lists', { body: {
      "required_lists": list
    }})
  }
}

export const knowledgeClient = new KnowledgeClient(knowledgeHttp, '')
