import { systemClient } from '@/api'
import { getFingerprintClientside } from '@roolz/sdk/utils/device'

const fireEvent = async (event_name: string, event_data?: any) => {
  event_data ??= {}

  event_data.product = 'business'

  systemClient.sendEvent({
    event_name,
    installation_id: await getFingerprintClientside(),
    event_timestamp: Date.now(),
    event_data
  })
}

/**
 * Company events
 */
export const fireCompanyCreateButton = () => fireEvent('click_button_create_company')
export const fireSrcGoalsCreateCompany = () => fireEvent('scr_goals_create_company')
export const fireSrcProfileCreateCompany = () => fireEvent('scr_profile_create_company')
export const fireSrcOrgCreateCompany = () => fireEvent('scr_org_create_company')
export const fireClickCancelCreateCompany = () => fireEvent('click_cancel_create_company')
export const fireEnterCompanyAdmin = () => fireEvent('enter_company_admin')
export const fireClickDeleteCompany = () => fireEvent('click_button_delete_company')
export const fireClickInviteCompany = () => fireEvent('click_invite_to_company')
export const fireSrcCompaniesDirectory = (params: SrcCompaniesDirectoryParams) => fireEvent('scr_companies_directory', params)
export const fireApplyCompaniesFilter = (params: ApplyCompaniesFilterParams) => fireEvent('apply_filter_companies', params)
export const fireViewCompanyProfile = (params: ViewCompanyProfileParams) => fireEvent('view_company_profile', params)
export const fireCopyCompanyProfileLink = (params: CopyCompanyProfileLinkParams) => fireEvent('copy_company_profile_link', params)
export const fireSearchCompanies = (params: SearchCompaniesParams) => fireEvent('view_another_user_profile', params)

/**
 * Offer events
 */
export const fireClickButtonCreateOffer = () => fireEvent('click_button_create_offer')
export const fireCreateOfferRequiredFieldError = () => fireEvent('create_offer_error_required_field')
export const fireApplyOfferTemplate = (params: ApplyOfferTemplateParams) => fireEvent('apply_offer_template', params)
export const fireCopyOfferLink = (params: CopyOfferLinkParams) => fireEvent('copy_offer_link', params)
export const fireViewOfferStrange = (params: ViewOfferStrangeParams) => fireEvent('view_offer_strange', params)
export const fireSrcSpaPublicExchange = () => fireEvent('scr_spa_publ_exchange')
export const fireApplyOffersFilterFreight = (params: ApplyOffersFilterParams) => fireEvent('apply_filter_freight', params)
export const fireApplyOffersFilterVehicle = (params: ApplyOffersFilterParams) => fireEvent('apply_filter_vehicle', params)
export const fireCreateOffersAppealShown = () => fireEvent('shown_popup_create_offer')
/**
 * Common
 */
export const fireClearFilter = (params: ClearFilterParams) => fireEvent('clear_filter', params)
export const fireViewOwnOffer = (params: ViewOwnOfferParams) => fireEvent('view_offer_own_company', params)
export const fireSrcFillProfile = (params: SrcFillProfileParams) => fireEvent('scr_create_profile', params)
export const fireLogout = (params: LogoutParams) => fireEvent('logout', params)
export const fireSearchResultClick = (params: SearchResultClickParams) => fireEvent('search_result_click', params)
export const fireViewAnotherUserProfile = (params: ViewAnotherUserParams) => fireEvent('view_another_user_profile', params)
// export const fireSiteLoading = (params: { is_spa_app: boolean }) => fireEvent('site_loading', params)


interface ApplyOffersFilterParams {
  filled_fields: object
  chosen_values: object
  exact_result_count: number
  partial_result_count? : number
}

interface ViewOfferStrangeParams {
  is_spa_app: boolean
  offer_type: 'cargo' | 'transport'
  offer_id: string
}

interface ClearFilterParams {
  is_spa_app: boolean
  place: 'exchange' | 'companies'
}

interface ViewOwnOfferParams {
  offer_type: 'cargo' | 'transport'
}

interface CopyOfferLinkParams {
  offer_type: 'cargo' | 'transport'
  is_spa_app: boolean
  is_own_offer: boolean
}

interface ApplyOfferTemplateParams {
  offer_type: 'cargo' | 'transport'
}

interface CopyCompanyProfileLinkParams {
  copy_company_id: string
}

interface ViewCompanyProfileParams {
  is_spa_app: boolean
  company_id: string
}

interface ApplyCompaniesFilterParams {
  is_spa_app: boolean
  filled_fields: object
  chosen_values: object
  filter_results_count: number
}

interface SrcCompaniesDirectoryParams {
  is_spa_app: boolean
}

interface SrcFillProfileParams {
  view_duration?: number
  latitude?: number
  longitude?: number
}

interface LogoutParams {
  latitude?: number
  longitude?: number
}

interface SearchResultClickParams {
  type: 'user' | 'chat' | 'address' | 'company'
  latitude?: number
  longitude?: number
}

interface ViewAnotherUserParams {
  is_contact: boolean
  view_duration?: number
  latitude?: number
  longitude?: number
}

interface SearchCompaniesParams {
  is_spa_app: boolean
  search_results_count: number
}
