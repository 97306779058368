import { companiesClient } from '@/api/clients/companies.client'
import { toastError } from '@roolz/sdk/components/snackbars'
import { EmailItem, EmailMultiSelect } from '@/components/ui/fields/EmailMultiSelect/EmailMultiSelect'
import { Select } from '@/components/ui/fields/Select/Select'
import { Loadable } from '@/components/ui/Loadable/Loadable'
import { Label } from '@/components/ui/typography/Label/Label'
import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { Button } from '@mui/material'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styles from './InviteMembersForm.module.scss'

export const STATUSES = {
  LIMIT: 'limit',
  INCORRECT: 'incorrect',
  DUPLICATE: 'duplicate',
  ACCEPT: 'accept',
  DELETE: 'delete',
}

const EMAIL_IS_VALID = 'ok'

const defaultValues = {
  role: 'member',
  emails: [],
  profile_ids: []
}

type InviteMembersFormProps = {
  cancelText?: string
  acceptText?: string
  hideModal?: () => void
  reloadMembers?: () => void | Promise<void>
  companyId: string
}

const getEmailItem = (email: string, status: string): EmailItem  => {
  const item: EmailItem = { value: email, error: null, variant: 'error' }

  if ([STATUSES.DELETE, STATUSES.LIMIT, STATUSES.INCORRECT].includes(status)) {
    return { ...item, error: status }
  }

  if (status === STATUSES.DUPLICATE) {
    return {
      value: email,
      error: status,
      variant: 'warning'
    }
  }

  return {
    value: email,
    error: null,
  }
}

export const InviteMembersForm = ({
  hideModal,
  reloadMembers,
  cancelText,
  acceptText,
  companyId
}: InviteMembersFormProps) => {
  const { t } = useTranslation('company/users')
  const { t: errorsT } = useTranslation('errors')
  const navigate = useNavigate()
  const ref = useRef<{ parseAndAddEmails: () => Promise<boolean> }>(null)

  const accept = acceptText || t('invite')
  const cancel = cancelText || t('cancel')

  const [loading, setLoading] = useState<boolean>(false)

  const validate = async (email: string) => {
    try {
      const response = await companiesClient.checkEmailInCompany({ companyId, email })

      if (response.data.status === EMAIL_IS_VALID) {
        return getEmailItem(email, STATUSES.ACCEPT)
      }
    } catch(e) {
      const detail = e?.response?.data?.detail

      if (!detail) {
        return getEmailItem(email, STATUSES.INCORRECT)
      }

      switch (detail.trim()) {
        case 'User has reached the limit of spaces.':
          return getEmailItem(email, STATUSES.LIMIT)
        case 'Company not exists.':
          navigate(resolvePathByName(ROUTE_NAMES.PUBLIC_EXCHANGE))
          return null
        default:
          return getEmailItem(email, STATUSES.DUPLICATE)
      }
    }
  }

  const form = useForm<{
    role: string
    profile_ids: string[]
    emails: string[]
  }>({
    mode: 'onChange',
    defaultValues
  })

  const onEmailChange = useCallback((value: string[]) => {
    form.setValue('emails', value)
  }, [])

  const roles = useMemo(() => [{
    value: 'member',
    label: t('roles.member'),
  }, {
    value: 'admin',
    label: t('roles.admin')
  }], [])

  const onSubmit = async () => {
    if (!companyId || loading) return

    setLoading(true)
    if (!form.getValues().emails.length) {
      const isValid = await ref.current?.parseAndAddEmails()
      if (!isValid) {
        return setLoading(false)
      }
    }

    const {
      role,
      profile_ids,
      emails
    } = form.getValues()

    try {
      await companiesClient.sendInvitation({
        companyId,
        body: {
          role,
          profile_ids,
          emails
        },
      })

      hideModal?.()
      await reloadMembers?.()

    } catch(e: any) {
      toastError(e?.response?.data?.detail ?? errorsT('insufficient_request'))
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <div className={styles.formBlock}>
        <div className={styles.formGroup}>
          <Label
            htmlFor='role'
            required
          >
            {t('role')}
          </Label>
          <Controller
            control={form.control}
            name='role'
            render={({ field }) => (
              <Select
                id='role'
                fullWidth
                items={roles}
                allowEmpty={false}
                placeholder={t('member')}
                {...field}
              />
            )}
          />
        </div>
      </div>

      <EmailMultiSelect
        onChange={onEmailChange}
        validate={validate}
        ref={ref}
      />

      <div className={styles.actions}>
        <Button
          tabIndex={1}
          onClick={hideModal}
        >
          {cancel}
        </Button>
        <Loadable
          loading={loading}
          delay={500}
        >
          <Button
            tabIndex={2}
            onClick={onSubmit}
            disabled={loading}
          >
            {accept}
          </Button>
        </Loadable>
      </div>
    </>
  )
}
