import { CHAT_MESSAGES_PER_BUNCH } from '@/config/const'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { ChatModel, MessageModel } from '@/types/models/chat'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import dayjs from 'dayjs'
import { merge } from 'lodash'
import { makeAutoObservable, observable, runInAction } from 'mobx'
import { Chat } from '@roolz/types/api/chats'
import { ChatUserEventPackage, ChatUserEventTypes } from '@roolz/types/ws/packages'
import { Profile } from '@roolz/types/api/profiles'

type UnixTime = number

type ChatViewInfo = {
  minViewportMessageIndex: number | null
  maxViewportMessageIndex: number | null

  scrollTop: number | null
}

const DEFAULT_POSITION = () => ({
  bottom: 0,
  left: 359
})

const DEFAULT_SIZE = () => ({
  width: 536,
  height: 525
})

interface ChatActions {
  [chat_id: Chat["id"]]: Array<ChatUserEventPackage & {
    received_at: UnixTime
  }>
}

const MINI_CHAT_POSITION_STORAGE_KEY = 'mini-chat-position'
const MINI_CHAT_SIZE_STORAGE_KEY = 'mini-chat-size'

class ChatSessionsStore {
  // used to show in title of page
  highlightTitle = false
  newHighlightedMessagesCount = 0

  // Typings, audio recordings, etc...
  chatActions: ChatActions = {}
  chatViewInfos: Record<Chat["id"], ChatViewInfo> = {}
  // TODO need to replace it to list of intervals
  chatsMinLoadedMessageIndexes: Record<Chat["id"], number> = {}

  miniChatPosition = DEFAULT_POSITION()
  miniChatSize = DEFAULT_SIZE()
  isChatExpanded = false

  selectedMessage = null

  setMiniChatPosition(data: ReturnType<typeof DEFAULT_POSITION>) {
    this.miniChatPosition = data
    localStorage.setItem(MINI_CHAT_POSITION_STORAGE_KEY, JSON.stringify(data))
  }

  setMiniChatSize(data: ReturnType<typeof DEFAULT_SIZE>) {
    this.miniChatSize = data
    localStorage.setItem(MINI_CHAT_SIZE_STORAGE_KEY, JSON.stringify(data))
  }

  constructor() {
    makeAutoObservable(this, {
      chatViewInfos: observable.deep
    })

    let prevSize
    let prevPos
    if(!IS_MOBILE) {
      try {
        prevSize = JSON.parse(localStorage.getItem(MINI_CHAT_SIZE_STORAGE_KEY) || '')
      } catch(e) {
        console.log(e)
      }
      try {
        prevPos = JSON.parse(localStorage.getItem(MINI_CHAT_POSITION_STORAGE_KEY) || '')
      } catch(e) {
        console.log(e)
      }
    }

    this.miniChatSize = prevSize || DEFAULT_SIZE()

    if(prevPos) {
      this.miniChatPosition = prevPos
    } else {
      this.miniChatPosition.left = Math.max(
        0,
        DEFAULT_POSITION().left - Math.max(0, (DEFAULT_POSITION().left + this.miniChatSize.width - window.innerWidth))
      )
    }
  }

  getChatTypingUsers(chat: Chat): Array<Profile["id"]> {
    if(!this.chatActions[chat.id]?.length) {
      return []
    }

    return this.chatActions[chat.id].filter(event => {
      return event.event === ChatUserEventTypes.Typing
        && event.user_id !== profilesStore.my_profile?.id
    })
      .map(event => event.user_id)
  }

  removeChatUserTyping(chat_id: Chat["id"], profile_id: Profile["id"]) {
    if(this.chatActions?.[chat_id]?.length) {
      const index = this.chatActions[chat_id].findIndex(event => {
        return event.event === ChatUserEventTypes.Typing
          && event.user_id === profile_id
      })

      if(index !== -1) {
        this.chatActions[chat_id].splice(index, 1)
      }
    }
  }

  setChatViewInfo(chatId: Chat["id"], info: Partial<ChatViewInfo>) {
    this.chatViewInfos[chatId] ??= {
      minViewportMessageIndex: null,
      maxViewportMessageIndex: null,
      scrollTop: null
    }

    this.chatViewInfos[chatId] = merge(this.chatViewInfos[chatId], info)
  }

  resetChatViewInfo(chatId: Chat["id"]) {
    delete this.chatViewInfos[chatId]
  }

  getChatViewportMessages(chatId: Chat["id"]): MessageModel[] {
    const info = this.chatViewInfos[chatId]
    if(!info?.minViewportMessageIndex) {
      return []
    }
    const chat = chatsStore.getChat(chatId)
    if(!chat) {
      return []
    }

    return chat.messages.filter(item => {
      // @ts-ignore
      return item.number >= info.minViewportMessageIndex
       && (!info.maxViewportMessageIndex || item.number <= info.maxViewportMessageIndex)
    })
  }
}

export const chatSessionsStore = new ChatSessionsStore()

setInterval(() => {
  runInAction(() => {
    Object.keys(chatSessionsStore.chatActions).forEach(chatId => {
      const newList = chatSessionsStore.chatActions[chatId].filter((event, i) => {
        return dayjs().diff(event.received_at, 'seconds') < 5
      })

      if(chatSessionsStore.chatActions[chatId].length !== newList.length) {
        chatSessionsStore.chatActions[chatId] = newList
      }
    })
  })
}, 1000)
