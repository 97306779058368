import cn from 'classnames'
import { ReactNode, useEffect, useRef, useState } from 'react'
import styles from './Label.module.scss'

type Props = {
  children?: ReactNode
  className?: string
  required?: boolean
  [key: string]: any
};
export const Label = ({
  children,
  className,
  required = false,
  ...rest
}: Props) => {
  const textRef = useRef<any>()
  const [title, setTitle] = useState<string>('')

  useEffect(() => {
    setTitle(textRef.current.innerText)
  }, [textRef.current])

  return (
    <label
      className={cn(styles.label, className)}
      title={title}
      {...rest}
    >
      <span
        className={cn(
          styles.text, {
            [styles.textWithStar]: required
          }
        )}
        ref={textRef}
      >
        {children}
      </span>

      {required && <span className={styles.star}> *</span>}
    </label>
  )
}
