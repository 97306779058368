import { observer } from 'mobx-react-lite'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '@/components/chats/features/ChatBottomPanels/MutePanel/MutePanel.module.scss'
import { ChatType, Chat } from '@roolz/types/api/chats'

interface Props {
  isMuted: boolean
  onChange: (isMuted: boolean) => void
}

export const MutePanel = observer(({
  isMuted,
  onChange
}: Props) => {
  const { t } = useTranslation('chat/common')

  return (
    <button
      className={styles.main}
      onClick={() => onChange(!isMuted)}
    >
      {isMuted ? t('chat_actions.unmute') : t('chat_actions.mute')}
    </button>
  )
})
