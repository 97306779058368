import { useRef } from 'react'

export type Task = () => void

export function useTasksQueue(): [(task: Task) => void, () => void] {
  const tasks = useRef<Array<Task>>([])

  const addTask = (task: Task) => tasks.current.push(task)

  const flushTasks = () => {
    while(tasks.current.length) {
      tasks.current.pop()?.()
    }
  }

  return [addTask, flushTasks]
}
