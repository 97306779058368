import ClientJS from 'clientjs/dist/client.base.min.js'
import sha256 from 'crypto-js/sha256'
import { detect } from 'detect-browser'
import { memoize } from 'lodash'
import { Device, DeviceType, OS } from '@roolz/types/api'

const { ClientJS: ClientJSClass } = ClientJS as any
let is_mobile
try {
  is_mobile = (new ClientJSClass()).isMobile()
} catch(e) {
  is_mobile = false
}

export const IS_MOBILE = is_mobile

export const getDeviceInfo = memoize(function (): Device {
  const client = new ClientJSClass()
  let os: OS

  if (client.isMobile()) {
    os = client.isMobileIOS() ? OS.Ios : OS.Android
  } else {
    if (client.isMac()) {
      os = OS.MacOS
    } else if (client.isWindows()) {
      os = OS.Windows
    } else {
      os = OS.Linux
    }
  }
  return {
    installation_id: String(client.getFingerprint()) ?? '',
    phone_model: client.getBrowser() ?? '',
    system_version: client.getBrowserVersion() ?? '',
    app_version: process.env.NEXT_PUBLIC_APP_VERSION || process.env.REACT_APP_APP_VERSION || '',
    os_type: os
  }
})

export const getFingerprintClientside = (() => {
  function hashString(str) {
    let encoder = new TextEncoder()
    let data = encoder.encode(str)
    return crypto.subtle.digest('SHA-256', data).then(function(hash) {
      return Array.from(new Uint8Array(hash)).map(function(byte) {
        return byte.toString(16).padStart(2, '0')
      }).join('')
    })
  }

  let fingerprint = ''

  // User Agent
  fingerprint += navigator.userAgent

  // Screen Resolution
  fingerprint += window.screen.width + 'x' + window.screen.height

  // let fonts = ''
  // if (typeof window.FontFace !== 'undefined') {
  //   let fontFaces = document.fonts.values() as any
  //   for (let fontFace of fontFaces) {
  //     fonts += fontFace.family + ','
  //   }
  // }
  // fingerprint += fonts

  // Timezone Offset
  fingerprint += new Date().getTimezoneOffset()

  // Random Number
  // fingerprint += Math.random()


  return hashString(fingerprint)


  // const client = new ClientJSClass()
  //
  // return client.getFingerprint()
})

/**
 * Required only for invite links, because fingerprints there are should be same on IOS and web.
 *
 * Used detect-browser lib instead of clientjs because just copied from old fully tested project
 */
export const getSimpleFingerprintClientside = memoize(() => {
  const browser = detect()

  return sha256(window.screen.width + ':' + window.screen.height + ':' + browser.os).toString()
})

export const getDeviceType = memoize(function(): any {
  const client = new ClientJSClass()

  const userAgent = navigator.userAgent.toLowerCase()
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)

  if(isTablet) {
    return DeviceType.TABLET
  }
  if(client.isMobile()) {
    return DeviceType.MOBILE
  }

  return DeviceType.DESKTOP
})
