import { BaseApiClient } from '@/api'
import { identityHttp } from '@/api/transport/http/identity.http'
import {
  Company,
  CreateCompanyRequest,
  DeleteCompanyRequest,
  PublicCompanies,
  UpdateCompanyRequest
} from '@roolz/types/api/companies'
import { GetCompanyMembersRequest, CompanyMembersResponse } from '@roolz/types/api/companyMembers'
import { OS } from '@roolz/types/api'

class CompaniesClient extends BaseApiClient {
  getCompanies({
    offset = 0,
    limit = 50,
    ...rest
  }) {
    return this.get<Company[]>('/v1/api/company/list', {
      params: {
        offset,
        limit,
        ...rest
      }
    })
  }

  sendInvitation({
    companyId,
    body
  }: {
    companyId: string
    body: {
      emails: string[]
      profile_ids: string[]
      role: string
    }
  }) {
    return this.post(`/v1/company/${companyId}/invitation/send`, {
      body
    })
  }

  cancelInvitation({
    companyId,
    invitationId
  }: {
    companyId: string
    invitationId: string
  }) {
    return this.post(`/v1/company/${companyId}/invitation/${invitationId}/cancel`, )
  }

  resendInvitation({
    companyId,
    invitationId
  }: {
    companyId: string
    invitationId: string
  }) {
    return this.post(`/v1/company/${companyId}/invitation/${invitationId}/resend`, )
  }

  deleteMember({
    companyId,
    profileId
  }: {
    companyId: string
    profileId: string
  }) {
    return this.delete(`/v1/company/${companyId}/profile/${profileId}`, )
  }

  getInvitationByHash({
    magic_hash
  }: {
    magic_hash: string
  }) {
    return this.get(`/v1/company/invitation/${magic_hash}`, {
      params: {
        magic_hash
      }
    })
  }

  getCompanyMembers({
    companyId,
    filter,
    search,
    offset = 0,
    limit = 12,
  }: GetCompanyMembersRequest) {
    return this.get<CompanyMembersResponse>(`/v1/company/${companyId}/members/list`, {
      params: {
        filter,
        search,
        offset,
        limit,
      }
    })
  }

  checkEmailInCompany({
    companyId,
    email
  }: {
    companyId: string
    email: string
  }) {
    return this.get(`/v1/company/${companyId}/check_email_in_company`, {
      params: {
        email
      }
    })
  }

  getCompany ({
    companyId
  }: {
    companyId: string
  }) {
    return this.get<Company>(`/v1/company/${companyId}`, {
      params: {}
    })
  }

  acceptInvitation ({
    companyId,
    invitationId,
    body
  }: {
    companyId: string
    invitationId: string
    body: {
      device: {
        installation_id: string,
        phone_model: string,
        system_version: string,
        app_version: string,
        os_type: OS
      },
      magic_hash: string
    }
  }) {
    return this.post(`/v1/company/${companyId}/invitation/${invitationId}/accept`, {
      body
    })
  }

  declineInvitation ({
    companyId,
    invitationId
  }: {
    companyId: string
    invitationId: string
  }) {
    return this.post<Company>(`/v1/company/${companyId}/invitation/${invitationId}/decline`, {})
  }

  getAllPublicCompanies({
    ...rest
  }) {
    for(const key in rest) {
      rest[key] = Array.isArray(rest[key]) ? rest[key].join(',') : rest[key]
    }
    return this.get<PublicCompanies>('/v1/company/public/list', {
      params: {
        ...rest
      }
    })
  }

  getAllOwnCompanies() {
    return this.get<Company[]>('/v1/company/own_profile/list')
  }

  getMyCompany(companyId: string) {
    return this.get<Company>(`/v1/company/${companyId}`)
  }

  getPublicCompany(companyId: string) {
    return this.get<Company>(`/v1/company/public/${companyId}`)
  }

  createCompany(body: CreateCompanyRequest) {
    return this.post<Company>('/v1/company', {
      body
    })
  }

  updateCompany(company_id: string, body: UpdateCompanyRequest) {
    return this.patch<Company>(`/v1/company/${company_id}`, {
      body
    })
  }

  deleteCompany(company_id: string, body: DeleteCompanyRequest) {
    return this.delete(`/v1/company/${company_id}`, {
      body
    })
  }

  getCompanyNickname(companyNickname: string) {
    return this.get<Company>(`/v1/company/public_by_nickname/${companyNickname}`)
  }
}

export const companiesClient = new CompaniesClient(identityHttp, '')
