interface Props {
  className?: string
  size?: number
  color?: string
}

export const TikTokIcon = ({ className, size = 15, color = '#4778EE' }: Props) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox='0 0 13 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.9998 3.67024C12.0453 3.67022 11.1299 3.28352 10.455 2.59522C9.78005 1.90691 9.4009 0.973387 9.40091 0H6.82966V10.2679C6.82918 10.736 6.68424 11.1921 6.41537 11.5715C6.1465 11.951 5.76735 12.2345 5.33165 12.3819C4.89596 12.5293 4.42582 12.5331 3.98787 12.3928C3.54992 12.2525 3.16639 11.9752 2.89161 11.6002C2.61684 11.2252 2.46478 10.7715 2.45698 10.3034C2.44917 9.83537 2.58603 9.37666 2.84814 8.99234C3.11025 8.60802 3.48432 8.31758 3.91734 8.16218C4.35036 8.00678 4.82035 7.9943 5.26071 8.12652V5.57756C4.32487 5.44887 3.3728 5.6144 2.53164 6.05205C1.69048 6.48969 1.00026 7.17861 0.553145 8.02683C0.106031 8.87505 -0.0766945 9.84218 0.0293678 10.7991C0.13543 11.756 0.525232 12.6571 1.14672 13.3822C1.76821 14.1072 2.59181 14.6216 3.50754 14.8568C4.42327 15.0919 5.38755 15.0365 6.27161 14.6981C7.15567 14.3596 7.91744 13.7541 8.45519 12.9625C8.99294 12.1709 9.28109 11.2309 9.28115 10.2679L9.35615 5.07778C10.4126 5.86868 11.6894 6.29423 13 6.29224L12.9998 3.67024Z'
        fill={color}
      />
    </svg>
  )
}
