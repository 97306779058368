interface Props {
  color?: string
  size?: number
}
export const Unpin = ({
  color = '#272A36',
  size = 24
}: Props) => {
 return (
   <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g>
       <path d="M5.98885 14.7619C5.98885 15.1097 6.09753 15.3895 6.31489 15.6015C6.53225 15.808 6.82297 15.9112 7.18704 15.9112H11.5967V19.7177C11.5967 20.0275 11.6239 20.3209 11.6782 20.598C11.7326 20.8752 11.8005 21.117 11.882 21.3235C11.9635 21.5354 12.0423 21.7011 12.1184 21.8207C12.1999 21.9402 12.2678 22 12.3222 22C12.3711 22 12.4336 21.9402 12.5097 21.8207C12.5912 21.7011 12.6727 21.5354 12.7542 21.3235C12.8357 21.117 12.9036 20.8752 12.958 20.598C13.0123 20.3209 13.0395 20.0275 13.0395 19.7177V16.0253H13.5122L8.49121 10.988C7.96411 11.2977 7.51308 11.6564 7.13814 12.0639C6.76863 12.4715 6.48334 12.9062 6.28228 13.3681C6.08666 13.83 5.98885 14.2946 5.98885 14.7619ZM7.97769 3C7.68969 3 7.45874 3.08151 7.28486 3.24453C7.11097 3.40755 7.02402 3.61404 7.02402 3.86401C7.02402 4.11941 7.12455 4.38024 7.32561 4.6465C7.46146 4.83126 7.65709 5.03775 7.91248 5.26598C8.17332 5.48877 8.4749 5.71972 8.81725 5.95882C9.15959 6.19248 9.5291 6.42342 9.92578 6.65165L9.85242 7.71943L17.9627 15.8215C18.4191 15.6422 18.6474 15.289 18.6474 14.7619C18.6474 14.1859 18.5006 13.618 18.2072 13.0583C17.9138 12.4932 17.4926 11.977 16.9438 11.5097C16.395 11.0423 15.7402 10.6701 14.9794 10.393L14.7104 6.65165C15.3082 6.31474 15.8353 5.96968 16.2917 5.61647C16.7482 5.25783 17.0824 4.94266 17.2943 4.67096C17.5117 4.39382 17.6203 4.12484 17.6203 3.86401C17.6203 3.61404 17.5307 3.40755 17.3514 3.24453C17.1775 3.08151 16.9492 3 16.6667 3H7.97769ZM4.17932 5.24968L18.9163 19.9704C19.0413 20.0954 19.1908 20.1579 19.3647 20.1579C19.5385 20.1579 19.6853 20.0954 19.8048 19.9704C19.9244 19.8454 19.9841 19.696 19.9841 19.5221C19.9896 19.3536 19.9298 19.2069 19.8048 19.0819L5.07593 4.36122C4.95095 4.23624 4.80152 4.17375 4.62763 4.17375C4.45374 4.17375 4.3043 4.23624 4.17932 4.36122C4.05977 4.48077 4 4.6302 4 4.80952C4 4.98341 4.05977 5.13013 4.17932 5.24968Z"
             fill={color}/>
     </g>
   </svg>
 )
}
