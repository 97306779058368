import { createTheme } from '@mui/material'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    icon?: Palette['primary'];
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    icon?: {
      light?: string,
      main?: string,
      dark?: string
    };
    neutral: PaletteOptions['primary'];
  }
}


const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#4778EE'
    },
    error: {
      main: '#E23834'
    },
    success: {
      main: '#3EBF71'
    },
    warning: {
      main: '#E59F42'
    },
    // TODO using by text. Move it to text section (extend text interface etc.)
    common: {
      black: '#272A36'
    },
    text: {
      primary: '#272A36',
      secondary: '#8E8E93'
      // disabled: ''
    },
    background: {
      paper: '#FFFFFF',
      default: '#F4F4F4'
    },
    divider: '#E2E2E2',
    icon: {
      main: '#272A36',
      light: '#8E8E93'
    },
    grey: {
      500: '#8E8E93'
    },
    neutral: {
      main: '#F7F7F7',
      contrastText: '#272A36'
    }
  },
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true // No more ripple!,
      }
    },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: 'none'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: '40px'
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          marginBottom: 15,
          marginLeft: 15,
          marginRight: 15
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          paddingTop: 20,
          paddingBottom: 12
        }
      }
    },

    MuiTextField: {
      styleOverrides: {
        root: {

          '& .MuiInputBase-root': {
            background: 'white'
          },

          '& .MuiOutlinedInput-root': {
            input: {
              fontSize: '14px'
            },
            textarea: {
              fontSize: '14px'
            },
            'fieldset': {
              // background: 'white',
              transition: '0.2s border',
              borderWidth: '1px !important'
            },
            '&:not(.Mui-disabled):hover fieldset': {
              borderColor: '#4778EE !important'
            },
            '&.Mui-disabled': {
              background: '#F7F7F7'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#4778EE'
            },
            '&.MuiInputBase-colorSuccess fieldset': {
              borderColor: '#3EBF71 !important'
            },
            '&.MuiInputBase-colorSuccess.Mui-focused fieldset': {
              borderColor: '#3EBF71'
            },
            '&.Mui-error:hover fieldset': {
              borderColor: '#E23834 !important'
            },
            '&.Mui-error.Mui-focused fieldset': {
              borderColor: '#E23834'
            },
            '& .MuiInputBase-input:not(.MuiInputBase-inputMultiline)': {
              height: 'auto !important',

              ':not(.MuiInputBase-inputMultiline)': {
                paddingTop: '8px !important',
                paddingBottom: '8px !important'
              },

              '&.MuiAutocomplete-input': {
                paddingTop: '0 !important',
                paddingBottom: '0 !important'
              },

              '&.MuiOutlinedInput-root': {
                height: 'auto !important'
              }
            },

            '& fieldset': {
              borderRadius: `4px`,
              borderColor: '#E5E6EE'
            },
            '&.MuiAutocomplete-input': {
              borderTop: 0,
              borderBottom: 0
            }
          },
          '& .Mui-disabled.MuiOutlinedInput-root': {
            minHeight: 'auto !important',

            '& fieldset': {
              borderColor: '#eaebef'
            }
          },
          '& label.Mui-disabled': {
            color: '#C8CBDE'
          },
          '& .MuiFormHelperText-root': {
            marginLeft: 0,
            marginRight: 0
          },
          '& .MuiInputBase-multiline': {
            paddingTop: '9px !important',
            paddingBottom: '0px !important'
          }
        }
      },
      defaultProps: {
        InputLabelProps: {
          shrink: true
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderRadius: `8px`
            // borderColor: '#E5E6EE !important'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    }
  },
  typography: {
    fontSize: 16,

    caption: {
      fontSize: '0.75rem',
      lineHeight: '0.875rem'
    },

    h1: {
      fontSize: '1.5rem',
      lineHeight: '1.75rem'
    },
    h2: {
      fontSize: '1.25rem',
      lineHeight: '1.45rem'
    },

    body1: {
      fontSize: '1rem'
    },
    body2: {
      fontSize: '0.875rem'
    }
  }
})

export { defaultTheme }
