import { SmartQueue } from '@/utils/SmartQueue'
import { makeAutoObservable } from 'mobx'

class WorkersStore {
  swQueue = new SmartQueue<any>()
  constructor() {
    makeAutoObservable(this)
  }
}

export const workersStore = new WorkersStore()
