import { companiesClient } from '@/api/clients/companies.client'
import { companiesStore } from '@/store/companies/companies.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { Company, GetCompanyRequest, UpdateCompanyRequest } from '@roolz/types/api/companies'
import { AxiosResponse } from 'axios'

const myCompanyRequestWrapper = (promise: Promise<AxiosResponse<Company, any>>) => {
  companiesStore.isMyCompanyLoading = true

  return promise
    .then(({ data }) => {
      companiesStore.addOrUpdateCompany(data)

      const myCompany = companiesStore.findCompany(data.id)
      companiesStore.myCompany = myCompany ?? null

      return data
    })
    .catch(e => {
      throw e
    })
    .finally(() => (companiesStore.isMyCompanyLoading = false))
}

class CompaniesService {
  loadMyCompany({ id }: GetCompanyRequest) {
    return myCompanyRequestWrapper(companiesClient.getMyCompany(id))
  }

  updateCompany(company_id: string, body: UpdateCompanyRequest) {
    return myCompanyRequestWrapper(companiesClient.updateCompany(company_id, body))
  }

  getCompanyNickname({ nickname }: { nickname: string }) {
    return companiesClient.getCompanyNickname(nickname)
      .then(({ data }) => {
        companiesStore.addOrUpdateCompany(data)
        return data
      })
  }

  loadPublicCompany({ id }: GetCompanyRequest) {
    return companiesClient.getPublicCompany(id)
      .then(({ data }) => {
        companiesStore.addOrUpdateCompany(data)

        return data
      })
  }

  loadMyCompanies() {
    return companiesClient.getAllOwnCompanies()
      .then(({ data }) => {
        data.forEach((company: Company) => {
          companiesStore.addOrUpdateCompany(company)
        })
        myCompaniesStore.companies = data

        return data
      })
  }
}

export const companiesService = new CompaniesService()
