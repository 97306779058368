import * as React from 'react'

interface Props {
  size?: number
}

export const EmptyAvaRec = ({ size = 24 }: Props) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='24' height='24' rx='4' fill='#EBECF2'/>
      <g clipPath='url(#clip0_6140_103375)'>
        <path
          d='M12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C14.76 17 17 14.76 17 12C17 9.24 14.76 7 12 7ZM12 8.5C12.83 8.5 13.5 9.17 13.5 10C13.5 10.83 12.83 11.5 12 11.5C11.17 11.5 10.5 10.83 10.5 10C10.5 9.17 11.17 8.5 12 8.5ZM12 15.6C10.75 15.6 9.645 14.96 9 13.99C9.015 12.995 11 12.45 12 12.45C12.995 12.45 14.985 12.995 15 13.99C14.355 14.96 13.25 15.6 12 15.6Z'
          fill='#C8CBDE'
        />
      </g>
      <defs>
        <clipPath id='clip0_6140_103375'>
          <rect width='12' height='12' fill='white' transform='translate(6 6)'/>
        </clipPath>
      </defs>
    </svg>

  )
}