import { DONT_CLOSE_MENU_FLAG } from './const'
import { Fade, Popper } from '@mui/material'
import * as React from 'react'
import { MouseEvent, ReactNode, useEffect, useState } from 'react'
import styles from './MenuList.module.scss'

interface Props {
  anchorRef: any //MutableRefObject<HTMLElement>
  children: ReactNode
  triggerEvent?: string

  offset?: [x: number, y: number]

  onOpenChange?: (open: boolean) => void

  [key: string]: any
}

export const MENU_LIST_OPEN_FLAG = 'menu_list-open'

export const MenuList = ({
  anchorRef,
  children,
  triggerEvent = 'click',
  offset = [0, 0],
  modifiers = [],
  disablePortal = false,

  onOpenChange,
  ...rest
}: Props) => {
  const [show, setShow] = useState<boolean>(false)
  const [el, setEl] = useState<null | HTMLElement>()

  function handleAnchorEvent(event: MouseEvent<HTMLElement>) {
    // event.stopPropagation()
    // @ts-ignore
    // event.target.flags ??= []
    // @ts-ignore
    // event.target.flags.push(DONT_CLOSE_MENU_FLAG)

    event.target.flags ??= []
    // @ts-ignore
    event.target.flags = [
      // @ts-ignore
      ...event.target.flags,
      MENU_LIST_OPEN_FLAG
    ]

    setEl(event.currentTarget)
    setShow(show => {
      onOpenChange && onOpenChange(!show)

      return !show
    })
  }

  useEffect(() => {
    if(anchorRef?.current) {
      anchorRef.current.addEventListener(triggerEvent, handleAnchorEvent)
    }

    return () => {
      if(anchorRef?.current) {
        anchorRef.current.removeEventListener(triggerEvent, handleAnchorEvent)
      }
    }
  }, [anchorRef])


  function handleDocumentClick(event: any) {
    setShow(show => {
      if(event.target?.flags?.length && event.target.flags.includes(DONT_CLOSE_MENU_FLAG) && show) {
        return show
      }

      onOpenChange && onOpenChange(!show)
      return !show
    })
  }

  function handleContentClick(event: any) {
    // if(event.flags.contains(DONT_CLOSE_MENU_FLAG)) {
    //   console.log('event contains')
    //   return
    // }
    //
    // // event.stopPropagation()
    // setShow(show => !show)
  }

  useEffect(() => {
    setTimeout(() => {
      if(show) {
        document.addEventListener(triggerEvent, handleDocumentClick)
      } else {
        document.removeEventListener(triggerEvent, handleDocumentClick)
      }
    })

    return () => {
      document.removeEventListener(triggerEvent, handleDocumentClick)
    }
  }, [show])

  return (
    <Popper
      disablePortal={disablePortal}
      open={show}
      anchorEl={el}
      className={styles.root}
      placement='top-end'
      transition
      modifiers={[
        { name: 'offset', options: { offset } },
        ...modifiers
      ]}
      {...rest}
    >
      {({ TransitionProps }) => (
        <Fade
          {...TransitionProps}
          timeout={200}
        >
          <div onClick={handleContentClick}>
            {children}
          </div>
        </Fade>
      )}
    </Popper>
  )
}
