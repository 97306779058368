import {
  useCopyMessage,
  useDeleteMessage,
  useEditMessage,
  useForwardMessage,
  useReplyMessage
} from '@/hooks/chats/message.utils'
import { MessageModel } from '@/types/models/chat'
import { Popover, PopoverProps } from '@mui/material'
import { Forward } from '@roolz/icons/chats/Forward'
import { Reply } from '@roolz/icons/chats/Reply'
import { Copy } from '@roolz/icons/Copy'
import { Delete2 } from '@roolz/icons/Delete2'
import { Edit2 } from '@roolz/icons/Edit2'
import { MenuListContent } from '@roolz/sdk/components/ui/MenuList/MenuListContent/MenuListContent'
import { MenuListItem } from '@roolz/sdk/components/ui/MenuList/MenuListItem/MenuListItem'
import { useEventListener } from 'ahooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styles from 'src/components/chats/features/MessageContextMenu/MessageContextMenu.module.scss'

interface Props {
  message: MessageModel | null

  show: boolean
  position: {
    left: number
    top: number
  }

  onClose: () => void

  popoverProps?: PopoverProps,
}

export const MessageContextMenu = observer(({
  message,

  show,
  position,

  onClose,

  popoverProps
}: Props) => {
  useEventListener('click', () => {
    if(show) onClose()
  }, { target: window })

  if(!message) return null

  return (
    <Popover
      disablePortal={true}
      open={show}
      className={styles.root}
      classes={{
        paper: styles.paper
      }}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      anchorPosition={position}
      anchorReference='anchorPosition'
      transitionDuration={{
        enter: 200,
        exit: 0
      }}
      // TransitionProps={{
      //   exit: false
      // }}

      onClose={onClose}
      {...popoverProps}
    >
      <MenuListContent className={styles.content}>
        <ContextMenuContent
          message={message}
        />
      </MenuListContent>
    </Popover>
  )
})

function ContextMenuContent({
  message
}: Pick<Props, 'message'>) {
  const { t } = useTranslation('chat/message')

  const handleCopy = useCopyMessage(message)
  const handleForward = useForwardMessage(message)
  const [canEdit, handleEdit] = useEditMessage(message)
  const [canDelete, handleDelete] = useDeleteMessage(message)
  const [canReply, handleReply] = useReplyMessage(message)

  return (<>
    {canReply && (
      <MenuListItem
        prepend={<Reply size={16}/>}
        label={t('actions.reply')}
        onClick={handleReply}
      />
    )}
    <MenuListItem
      prepend={<Copy size={12} color='#8E8E93'/>}
      label={t('actions.copy')}
      onClick={handleCopy}
    />
    {canEdit && (
      <MenuListItem
        prepend={<Edit2 size={14}/>}
        label={t('actions.edit')}
        onClick={handleEdit}
      />
    )}
    <MenuListItem
      prepend={<Forward size={14}/>}
      label={t('actions.forward')}
      onClick={handleForward}
    />
    {canDelete && (
      <MenuListItem
        prepend={<Delete2/>}
        label={t('actions.delete')}
        onClick={handleDelete}
      />
    )}
  </>)
}
