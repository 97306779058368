import { SmthWentWrong } from '@roolz/icons/lottie/SmthWentWrong'
import { useTranslation } from 'react-i18next'
import styles from './AppIsUnderConstruction.module.scss'

interface Props {

}

export const AppIsUnderConstruction = (props: Props) => {
  const { t } = useTranslation('ui')

  return (
    <div className={styles.root}>
      <div className={styles.logo__container}>
        <img src="https://staticcdn.roolz.net/web/logo/logo-business.svg" alt=''/>
      </div>

      <div className={styles.content}>
        <div className={styles.title}>
          {t('app_is_under_construction.title')}
        </div>
        <div className={styles.image__container}>
          <SmthWentWrong
            className={styles.image}
          />
        </div>

        <div className={styles.subtitle}>
          {t('app_is_under_construction.subtitle')}
        </div>
        <div className={styles.description}>
          {t('app_is_under_construction.description')}
        </div>
      </div>

      <div/>
    </div>
  )
}
