interface Config {
  /**
   * Number of retries. Specify Infinity for retries for requests until success
   */
  attempts: number,
  delay?: number
}

/**
 * Retries some request to the server many times till it success or till number of attempts reached maximum
 *
 * @param request
 * @param attempts
 * @param delay
 */
export function retryRequest<TResponse = any>(
  request: () => Promise<TResponse>,
  { attempts, delay = 1000 }: Config
): Promise<TResponse> {
  return new Promise((resolve, reject) => {
    // count of attempts made
    let i = 0

    function makeRequest() {
      request()
        .then((result: TResponse) => resolve(result))
        .catch((e: any) => {
          i++
          if(i < attempts) {
            return setTimeout(makeRequest, delay)
          }
          reject(e)
        })
    }

    makeRequest()
  })
}
