import { GlobalModalLink } from '@/global-modals/components/ModalLink'
import { useProfileLink } from '@/global-modals/hooks/useProfileLink'
import { ReactNode } from 'react'
import { Profile } from '@roolz/types/api/profiles'

interface Props {
  profile: Profile
  onOpen?: (profile: Profile) => void
  replace?: boolean
  className?: string
  children?: ReactNode
}
export const ProfileLink = ({
  profile,
  onOpen,
  replace,
  className,
  children,
}: Props) => {
  const { getLink, isAnotherProfileOpened } = useProfileLink()

 return (
   <GlobalModalLink
     onOpen={() => onOpen?.(profile)}
     to={getLink(profile.id)}
     replace={replace ? replace : isAnotherProfileOpened}
     className={className}
   >
     {children}
   </GlobalModalLink>
 )
}
