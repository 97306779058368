import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import * as React from 'react'
import { ReactNode } from 'react'

export interface ConfirmationProps {
  open: boolean
  setOpen: (open: boolean) => any

  title?: string | ReactNode
  content?: ReactNode

  actions: ReactNode
  maxWidth?: number
}

export const Confirmation = ({
  open,
  setOpen,
  title,
  content,
  actions,
  maxWidth = 312
}: ConfirmationProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth='xs'
      sx={{ borderRadius: 0 }}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent style={{ maxWidth }}>
        {content}
      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </Dialog>
  )
}
