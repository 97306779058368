interface Props {
  className?: string
  color?: string
}

export const Check = ({ color = '#1C1B1F', className }: Props) => {
  return (
    <svg
      className={className}
      width='20'
      height='14'
      viewBox='0 0 18 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.00012 10.7003L2.50012 7.20031C2.11012 6.81031 1.49012 6.81031 1.10012 7.20031C0.710117 7.59031 0.710117 8.21031 1.10012 8.60031L5.29012 12.7903C5.68012 13.1803 6.31012 13.1803 6.70012 12.7903L17.3001 2.20031C17.6901 1.81031 17.6901 1.19031 17.3001 0.800312C16.9101 0.410313 16.2901 0.410313 15.9001 0.800312L6.00012 10.7003Z'
        fill={color}
      />
    </svg>

  )
}
