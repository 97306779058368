import { Confirmation } from '@/components/modals/Confirmation/Confirmation'
import { confirmationContext } from '@/confirmation/ConfirmationContext'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'

export const ConfirmationContainer = observer(() => {
  const ctx = confirmationContext
  const { open, params } = ctx

  function setOpen(val: boolean) {
    if(!val && params?.onClose) {
      params?.onClose?.()
    }

    ctx.open = val
  }

  useEffect(() => {
    console.log('ctx', ctx)
  }, [ctx])

  return (
    // @ts-ignore
    <Confirmation
      open={open}
      setOpen={setOpen}

      {...ctx.params}
    />
  )
})
