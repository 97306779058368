import { CompanyMember, Statuses } from '@roolz/types/api/companyMembers'
import { makeAutoObservable } from 'mobx'

const getStatusesDefault = () => ({
  with_status_pending: {
    emails:  [],
    total_count: 0
  },
  with_status_await: {
    emails: [],
    total_count: 0
  },
  with_status_email_errors: {
    emails: [],
    total_count: 0
  }
})

class CompanyMembersStore {
  members: CompanyMember[] = []
  membersTotalCount = 0
  statuses: Statuses = getStatusesDefault()

  constructor() {
    makeAutoObservable(this, undefined, {deep: true})
  }

  setMembers(members: CompanyMember[]) {
    this.members = members
  }

  setMembersTotalCount(totalCount = 0) {
    this.membersTotalCount = totalCount
  }

  addMembers(members: CompanyMember[]) {
    this.members = [...this.members, ...members]
  }

  setMemberStatuses(statuses: Statuses) {
    this.statuses = { ...statuses }
  }

  resetMembers() {
    this.members = []
    this.membersTotalCount = 0
  }
}

const companyMembersStore = new CompanyMembersStore()

export {
  CompanyMembersStore,
  companyMembersStore
}
