import { Fade } from '@mui/material'
import { ReactNode } from 'react'
import styles from './HeaderButton.module.scss'
import cn from 'classnames'

interface HeaderButtonProps {
  className?: string
  icon: ReactNode
  title: string
  appeal: string
  onClick?: () => void
}

export const HeaderButton = (props: HeaderButtonProps) => {
  const { className, title, appeal, icon, onClick } = props

  return (
    <Fade in appear>
      <div className={styles.root}>
        <button className={cn(styles.headerButton, className)} onClick={onClick}>
          {icon}
          <div className={styles.title}>{title}</div>
          <div className={styles.appeal}>{appeal}</div>
        </button>
      </div>
    </Fade>
  )
}
