export function isNotUndefined<T>(item: T | undefined): item is T {
  return item !== undefined
}

export function isNullOrUndefined(val: unknown): val is null | undefined {
  return val === null || val === undefined
}

export function isNumber(val: unknown): val is number {
  return typeof val === 'number' && !Number.isNaN(val)
}

export const hasLatLng = (object: any): object is { lat: number, lng: number } => {
  return typeof object === 'object'
    && typeof object?.lat === 'number' && !isNaN(object?.lat)
    && typeof object?.lng === 'number' && !isNaN(object?.lng)
}

export const numberOrNull = (x: unknown): number | null => {
  x = Number.parseFloat(x as any)

  return typeof x === 'number' && !Number.isNaN(x)
    ? x
    : null
}

export const numberOrUndefined = (x: unknown): number | undefined => {
  return typeof x === 'number' && !Number.isNaN(x)
    ? x
    : undefined
}

export const isPromise = <Return>(val: any): val is Promise<Return> => {
  return !!val?.then
}

export const isNotNull = (val: unknown): boolean => val !== null

export const notFalsy = (val: unknown) => !!val

export function isTruthy<T>(value?: T | undefined | null | false): value is T {
  return !!value
}