import { forwardRef } from 'react'

interface Props {
  color?: string
  style?: any
}

export const Clock = forwardRef(({
  color = '#8E8E93',
  style
}: Props, ref: any) => {
  return (
    <svg
      ref={ref}
      style={style}
      width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.98268 9.68334C9.07157 9.77223 9.18268 9.81667 9.31602 9.81667C9.44935 9.81667 9.56602 9.76667 9.66601 9.66667C9.76602 9.56667 9.81602 9.45 9.81602 9.31667C9.81602 9.18334 9.76602 9.06667 9.66601 8.96667L7.49935 6.8V4.15C7.49935 4.01667 7.45224 3.90289 7.35802 3.80867C7.26335 3.714 7.14379 3.66667 6.99935 3.66667C6.8549 3.66667 6.73557 3.714 6.64135 3.80867C6.54668 3.90289 6.49935 4.02223 6.49935 4.16667V6.95001C6.49935 7.02778 6.51313 7.10267 6.54068 7.17467C6.56868 7.24712 6.61601 7.31667 6.68268 7.38334L8.98268 9.68334ZM6.99935 13.3333C6.12157 13.3333 5.29668 13.1667 4.52468 12.8333C3.75224 12.5 3.08268 12.05 2.51602 11.4833C1.94935 10.9167 1.49935 10.2471 1.16602 9.47467C0.832682 8.70267 0.666016 7.87778 0.666016 7C0.666016 6.12223 0.832682 5.29712 1.16602 4.52467C1.49935 3.75267 1.94935 3.08334 2.51602 2.51667C3.08268 1.95001 3.75224 1.50001 4.52468 1.16667C5.29668 0.833338 6.12157 0.666672 6.99935 0.666672C7.87713 0.666672 8.70224 0.833338 9.47468 1.16667C10.2467 1.50001 10.916 1.95001 11.4827 2.51667C12.0493 3.08334 12.4993 3.75267 12.8327 4.52467C13.166 5.29712 13.3327 6.12223 13.3327 7C13.3327 7.87778 13.166 8.70267 12.8327 9.47467C12.4993 10.2471 12.0493 10.9167 11.4827 11.4833C10.916 12.05 10.2467 12.5 9.47468 12.8333C8.70224 13.1667 7.87713 13.3333 6.99935 13.3333ZM6.99935 12.3333C8.47713 12.3333 9.73557 11.814 10.7747 10.7753C11.8133 9.73623 12.3327 8.47778 12.3327 7C12.3327 5.52223 11.8133 4.26378 10.7747 3.22467C9.73557 2.186 8.47713 1.66667 6.99935 1.66667C5.52157 1.66667 4.26335 2.186 3.22468 3.22467C2.18557 4.26378 1.66602 5.52223 1.66602 7C1.66602 8.47778 2.18557 9.73623 3.22468 10.7753C4.26335 11.814 5.52157 12.3333 6.99935 12.3333Z'
        fill={color}
      />
    </svg>
  )
})
