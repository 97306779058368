import { db } from '@/database'
import { getNow } from '@/utils/date'
import { now } from 'lodash'
import { autorun, makeAutoObservable } from 'mobx'
import { Country } from '@roolz/types/custom'

class SystemStore {
  private _clientServerDateDiffMs: number

  online = navigator.onLine

  get clientServerDateDiffMs() {
    return this._clientServerDateDiffMs
  }
  set clientServerDateDiffMs(value: number) {
    this._clientServerDateDiffMs = value
    this.serverTime10secUpdate = getNow().getTime()
  }

  tabVisibility = document.visibilityState

  countries: Country[]
  isJivositeLoaded = false

  serverTime10secUpdate: number

  constructor() {
    makeAutoObservable(this)
  }

  setCountries(countries: Country[]): void {
    this.countries = countries
  }
}

const systemStore = new SystemStore()

setInterval(() => {
  systemStore.serverTime10secUpdate = getNow().getTime()
}, 10 * 1000)

// window.addEventListener('online', () => systemStore.online = navigator.onLine)
// window.addEventListener('offline', () => systemStore.online = navigator.onLine)

export {
  SystemStore,
  systemStore
}
