import data from '@roolz/icons/lottie/data/smth_went_wrong_.json'
import Lottie, { LottieComponentProps } from 'lottie-react'

type Props = Omit<LottieComponentProps, 'animationData'>

export const SmthWentWrong = (props: Props) => {
  return (
    <Lottie
      animationData={data}
      {...props}
    />
  )
}
