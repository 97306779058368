import { makeAutoObservable } from 'mobx'

export enum Mode {
  Email = 'Email',
  Phone = 'Phone'
}

export enum Stage {
  MethodSelection,
  SmsOrPasswordSpecifying,
  MagicLinkSent
}

class AuthFormStore {
  loading = false

  mode: Mode = Mode.Phone
  stage: Stage = Stage.MethodSelection

  isAccountExists = false
  // TODO set default value
  country = ''
  phone = ''

  email = ''
  sms_id = ''

  // password: string = ''

  constructor() {
    makeAutoObservable(this)
  }

  setMode(mode: Mode): void {
    this.mode = mode
  }

  backStage() {
    this.stage -= 1
  }

  goToBeginning() {
    this.stage = Stage.MethodSelection
  }

  setSmsId(id: string) {
    this.sms_id = id
  }

  /**
   * Actions
   */

  setEmail(email: string): void {
    this.email = email
  }

  setPhone(phone: string): void {
    this.phone = phone
  }

  // setPassword(password: string): void {
  //   this.password = password
  // }

  setLoading(status: boolean): void {
    this.loading = status
  }

  setIsAccountExists(value: boolean): void {
    this.isAccountExists = value
  }

  setStage(stage: Stage): void {
    this.stage = stage
  }

}

const authFormStore = new AuthFormStore()


export {
  AuthFormStore,
  authFormStore
}
