import { countriesClient, systemClient } from '@/api'
import { systemStore } from '@/store/system/system.store'
import { Country } from '@roolz/types/custom'

class SystemService {
  syncClientConfig() {
    const requestStart = performance.now()

    return systemClient.getClientConfig()
      .then(({ data }) => {
        const diff = new Date().getTime() - (new Date(data.server_time).getTime() + (performance.now() - requestStart))

        systemStore.clientServerDateDiffMs = diff
        systemStore.serverTime10secUpdate
      })
  }

  loadCountries(force = false) {
    // PROD probably replace hand preventing of loading to react-query
    if(force || (systemStore.countries.length === 0)) {
      return countriesClient.getList()
        .then(response => {
          if(response?.data) {
            systemStore.setCountries(response?.data as Country[])
          }

          return response
        })
        .catch(e => {
          console.log(e)
        })
    } else {
      return Promise.resolve(systemStore.countries)
    }
  }
}

export const systemService = new SystemService()
