import { contactsClient } from '@/api/clients/contacts.client'
import { addOrUpdateProfiles, getProfileById } from '@/repositories/profiles.repository'
import { contactListStore } from '@/store/contactList/contactList.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { notFalsy } from '@/utils/types'
import { Profile, GetList, GetSearch, ContactInfo, ContactKind } from '@roolz/types/api/profiles'
import { GetCompanyContacts } from 'static/src/types/api/companies'

const resolveContacts = async ({ data }: { data: ContactInfo[]}) => {
  const contactsWithProfiles = data.filter(
    item => !!item.profile
  )

  const contacts = await Promise.all(contactsWithProfiles.map(async item => {
    await addOrUpdateProfiles([item.profile])

    return {
      ...item,
      profile: await getProfileById(item.profile.id)
    }
  })) as ContactInfo[]

  contactListStore.contacts = contacts

  return contacts
}

class ContactsService {
  private _lastAddedProfile: Profile | null = null

  banProfile(id: Profile['id']) {
    return contactsClient.banContact(id)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  unbanProfile(id: Profile['id']) {
    return contactsClient.unbanContact(id)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  getContacts(params?: GetList) {
    return contactsClient.getList(params ?? {})
      .then(resolveContacts)
  }

  getPersonalContacts() {
    contactsClient.getList({})
      .then((response) => {
        const personalContacts = response.data.filter(contact => contact?.profile?.relationships?.is_personal_contact)

        return {
          ...response,
          data: personalContacts
        }
      })
      .then(resolveContacts)
  }

  getContactsBySearch(params: GetSearch) {
    return contactsClient.getContactsBySearch(params)
      .then(resolveContacts)
  }

  getCompanyContacts(company_id: string, params: GetCompanyContacts) {
    return contactsClient.getCompanyContacts(company_id, params)
      .then(resolveContacts)
  }

  addContact(id: Profile['id']) {
    return contactsClient.addPersonalContact(id)
      .then(async ({ data }) => {
        addOrUpdateProfiles([data])

        const profile = await getProfileById(data.id)

        if(!profile) {

          contactListStore.contacts.push({
            profile,
            is_ban: false
          })
          this._lastAddedProfile = profile

          return data
        }
      })
      .catch(e => {
        if (this._lastAddedProfile) {
          contactListStore.contacts
            .filter(({ profile: { id } }) => id !== this._lastAddedProfile?.id)
        }
        throw e
      })
      .finally(() => this._lastAddedProfile = null)
  }

  unbanAndAddContact(id: Profile['id']) {
    return contactsClient.unbanAndAddPersonalContact(id)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }

  deleteContact(id: Profile['id']) {
    return contactsClient.deletePersonalContact(id)
      .then(({ data }) => {
        addOrUpdateProfiles([data])

        return data
      })
  }
}

export const contactsService = new ContactsService()
