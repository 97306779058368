import { BaseApiClient } from '@/api/clients/_base.client'
import { fileServerFetchClient, fileServerHttp } from '@/api/transport/http/file-server.http'
import {
  getAvatarRequest,
  getAvatarRequestWithWidth,
  uploadFileMultipartRequest,
  uploadFileMultipartResponse
} from '@roolz/types/api/files'

class FilesClient extends BaseApiClient {
  uploadFileMultipart(data: uploadFileMultipartRequest) {
    const formData = new FormData()

    formData.append('profile_id', data.profile_id)
    formData.append('file_size', String(data.file_size))
    formData.append('origin_name', String(data.origin_name))
    formData.append('file_mimetype', String(data.file_mimetype))
    formData.append('file_type', String(data.file_type))
    formData.append('is_avatar', String(data.is_avatar))
    formData.append('file', data.file)

    return fileServerHttp.post<uploadFileMultipartResponse>('file-single', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  getAvatar({ file_id }: getAvatarRequest) {
    return fileServerHttp.get<Blob>(`avatar/${file_id}`)
  }

  getAvatarWithWidth({ file_id, width }: getAvatarRequestWithWidth) {
    return fileServerHttp.get<Blob>(`avatar/${file_id}/${width}`)
  }

  getFile({ url }: { url: string }, clientParams?: any) {
    return fileServerHttp.get<Blob>(url.replace(/^\/(fileserver\/)?/, ''), {
      responseType: 'arraybuffer',
      ...clientParams
    })
  }

  getFileUsingFetch({ url }: { url: string }, clientParams?: any) {
    return fileServerFetchClient(
      url.replace(/^\/(fileserver\/)?/, '')
    )
  }
}

export const filesClient = new FilesClient(fileServerHttp, '')
