import { debugStore, SocketPackage } from '@/store/debug/debug.store'
import Collapse from '@mui/material/Collapse'
import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { useEventListener } from 'ahooks'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import styles from './WSPackages.module.scss'
import cn from 'classnames'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { CleaningServices, Close } from '@mui/icons-material'

export const WSPackages = observer(() => {
  const [open, setOpen] = useState(false)
  const openRef = useStateRef(open)

  useEventListener('keydown', (event: KeyboardEvent) => {
    if(event.ctrlKey && event.shiftKey && event.keyCode === 49) {
      setOpen(!openRef.current)
    }
  }, { target: document })

  if(!open) {
    return null
  }

  return (
    <div className={styles.root}>
      {debugStore.getSocketPackages().map(item => (
        <Item
          item={item}
          key={item.id}
        />
      ))}

      <button
        className={styles.close}
        title='Clean logs'
        onClick={() => setOpen(false)}
      >
        <Close/>
      </button>

      <button
        className={styles.clear}
        title='Clean logs'
        onClick={() => debugStore.cleanSocketPackages()}
      >
        <CleaningServices/>
      </button>
    </div>
  )
})

interface ItemProps {
  item: SocketPackage
}
const Item = observer(({
  item
}: ItemProps) => {
  const [isPretty, setIsPretty] = useState(false)

  let body = item.body
  let type = ''

  try {
    if(typeof body === 'string') {
      body = JSON.stringify(JSON.parse(item.body.body), null, isPretty ? 2 : undefined)
      type = JSON.parse(item.body).type
    } else {
      body = JSON.stringify(JSON.parse(item.body.body), null, isPretty ? 2 : undefined)
      type = item.body.type
    }
  } catch(e) {
    //
  }

  const date = dayjs(item.date).format('HH:mm:ss')

  const BodyTag = isPretty ? 'pre' : 'div'

  return (
    // <Collapse
    //   in={Date.now() - new Date(item.date).getTime() < 2000}
    //   exit={false}
    // >
      <div
        className={cn(styles.item, {
          [styles.itemIn]: item.type === 'in',
          [styles.itemOut]: item.type === 'out',
        })}
        onClick={() => setIsPretty(v => !v)}
      >
        <div className={styles.item__date}>
          {item.type === 'in' ? 'IN' : 'OUT'} --- {date}
          <span className={styles.item__type}>
            {type}
          </span>
        </div>
        <div className={styles.item__body}>
          <BodyTag>{body}</BodyTag>
        </div>
      </div>
    // </Collapse>
  )
})
