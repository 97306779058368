interface Props {
  size?: number
}

export function Delete2({ size = 10 } : Props) {
  return (
    <svg width={size} height={size * 1.2} viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.86667 11.6665C1.53333 11.6665 1.25 11.5498 1.01667 11.3165C0.783333 11.0832 0.666667 10.7998 0.666667 10.4665V1.99984H0.5C0.355556 1.99984 0.236222 1.9525 0.142 1.85784C0.0473332 1.76362 0 1.64428 0 1.49984C0 1.35539 0.0473332 1.23606 0.142 1.14184C0.236222 1.04717 0.355556 0.999837 0.5 0.999837H3C3 0.833171 3.05822 0.694282 3.17467 0.583171C3.29156 0.472059 3.43333 0.416504 3.6 0.416504H6.4C6.56667 0.416504 6.70844 0.472059 6.82533 0.583171C6.94178 0.694282 7 0.833171 7 0.999837H9.5C9.64444 0.999837 9.764 1.04717 9.85867 1.14184C9.95289 1.23606 10 1.35539 10 1.49984C10 1.64428 9.95289 1.76362 9.85867 1.85784C9.764 1.9525 9.64444 1.99984 9.5 1.99984H9.33333V10.4665C9.33333 10.7998 9.21667 11.0832 8.98333 11.3165C8.75 11.5498 8.46667 11.6665 8.13333 11.6665H1.86667ZM1.66667 1.99984V10.4665C1.66667 10.5221 1.68622 10.5694 1.72533 10.6085C1.764 10.6472 1.81111 10.6665 1.86667 10.6665H8.13333C8.18889 10.6665 8.23622 10.6472 8.27533 10.6085C8.314 10.5694 8.33333 10.5221 8.33333 10.4665V1.99984H1.66667ZM3.26667 8.83317C3.26667 8.97762 3.314 9.09717 3.40867 9.19184C3.50289 9.28606 3.62222 9.33317 3.76667 9.33317C3.91111 9.33317 4.03067 9.28606 4.12533 9.19184C4.21956 9.09717 4.26667 8.97762 4.26667 8.83317V3.83317C4.26667 3.68873 4.21956 3.56917 4.12533 3.4745C4.03067 3.38028 3.91111 3.33317 3.76667 3.33317C3.62222 3.33317 3.50289 3.38028 3.40867 3.4745C3.314 3.56917 3.26667 3.68873 3.26667 3.83317V8.83317ZM5.73333 8.83317C5.73333 8.97762 5.78044 9.09717 5.87467 9.19184C5.96933 9.28606 6.08889 9.33317 6.23333 9.33317C6.37778 9.33317 6.49733 9.28606 6.592 9.19184C6.68622 9.09717 6.73333 8.97762 6.73333 8.83317V3.83317C6.73333 3.68873 6.68622 3.56917 6.592 3.4745C6.49733 3.38028 6.37778 3.33317 6.23333 3.33317C6.08889 3.33317 5.96933 3.38028 5.87467 3.4745C5.78044 3.56917 5.73333 3.68873 5.73333 3.83317V8.83317ZM1.66667 1.99984V10.4665C1.66667 10.5221 1.68622 10.5694 1.72533 10.6085C1.764 10.6472 1.81111 10.6665 1.86667 10.6665H1.66667V1.99984Z'
        fill='#E23834'
      />
    </svg>
  )
}
