interface Props {
  size?: number
  color?: string
}

export function LocalShipping({ size = 16, color = '#8E8E93' }: Props) {
  return (
    <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.8999 13.0333C3.37768 13.0333 2.9359 12.8529 2.57457 12.492C2.21368 12.1307 2.03324 11.6889 2.03324 11.1667H0.899902V4.2C0.899902 3.86667 1.01657 3.58333 1.2499 3.35C1.48324 3.11667 1.76657 3 2.0999 3H11.1832V5.53333H12.9499L15.0999 8.41667V11.1667H13.8999C13.8999 11.6889 13.7166 12.1307 13.3499 12.492C12.9832 12.8529 12.5388 13.0333 12.0166 13.0333C11.5055 13.0333 11.0666 12.8529 10.6999 12.492C10.3332 12.1307 10.1499 11.6889 10.1499 11.1667H5.76657C5.76657 11.6889 5.58612 12.1307 5.22524 12.492C4.8639 12.8529 4.42212 13.0333 3.8999 13.0333ZM3.8999 12.0333C4.14435 12.0333 4.3499 11.95 4.51657 11.7833C4.68324 11.6167 4.76657 11.4111 4.76657 11.1667C4.76657 10.9222 4.68324 10.7167 4.51657 10.55C4.3499 10.3833 4.14435 10.3 3.8999 10.3C3.65546 10.3 3.4499 10.3833 3.28324 10.55C3.11657 10.7167 3.03324 10.9222 3.03324 11.1667C3.03324 11.4111 3.11657 11.6167 3.28324 11.7833C3.4499 11.95 3.65546 12.0333 3.8999 12.0333ZM1.8999 10.1667H2.38324C2.52768 9.92222 2.73324 9.71667 2.9999 9.55C3.26657 9.38333 3.56657 9.3 3.8999 9.3C4.22212 9.3 4.51946 9.38045 4.7919 9.54133C5.0639 9.70267 5.27212 9.91111 5.41657 10.1667H10.1832V4H2.0999C2.05546 4 2.01101 4.02222 1.96657 4.06667C1.92212 4.11111 1.8999 4.15556 1.8999 4.2V10.1667ZM12.0332 12.0333C12.2777 12.0333 12.4832 11.95 12.6499 11.7833C12.8166 11.6167 12.8999 11.4111 12.8999 11.1667C12.8999 10.9222 12.8166 10.7167 12.6499 10.55C12.4832 10.3833 12.2777 10.3 12.0332 10.3C11.7888 10.3 11.5806 10.3833 11.4086 10.55C11.2361 10.7167 11.1499 10.9222 11.1499 11.1667C11.1499 11.4111 11.2361 11.6167 11.4086 11.7833C11.5806 11.95 11.7888 12.0333 12.0332 12.0333ZM11.1832 8.83333H14.1666L12.4332 6.53333H11.1832V8.83333Z'
        fill={color}
      />
    </svg>
  )
}
