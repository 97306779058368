const WORKER_ID = Math.random()

export const log = (...args) => {
  console.log(`[${WORKER_ID}] %cAW: %c`, 'color: aqua', 'color: inherit', ...args)
}
export const error = (...args) => {
  console.error(`[${WORKER_ID}] %cAW: %c`, 'color: aqua', 'color: inherit', ...args)
}
export const logGroupCollapsed = (...args) => {
  console.groupCollapsed(`[${WORKER_ID}] %cAW: %c`, 'color: aqua', 'color: inherit', ...args)
}

export const logGroupEnd = () => {
  console.groupEnd()
}
