import { RecursivePartial } from '../index'
import { Company } from './companies'
import {
  AdrClassSlug,
  CargoTypeSlug,
  CurrencySlug,
  ExtraEquipmentSlug,
  LoadingTypeSlug,
  PaymentConditionSlug,
  PaymentMethodSlug,
  PointTypeSlug, RouteStartDateOffsetDays,
  ShippingModeSlug,
  TransportTypeSlug,
  TruckBodySlug
} from './knowledge/exchange'
import { Profile } from './profiles'
import { GeoJson, ID, Lat, Lon, PointGeoJson } from '../scalars'

export type CreateOfferResponse = Offer
export type UpdateOfferResponse = Offer
export type FlexiblePolyline = string

export enum CreationMethod {
  MANUAL = 'manual'
}

export enum MileageFormat {
  KM = 'km',
  MILES = 'miles'
}

export enum OfferStatus {
  PUBLISHED = 'published',
  NOT_PUBLISHED = 'not_published',
  CLOSED = 'closed'
}

export enum AssigneeContactMethod {
  PHONE = 'phone',
  EMAIL = 'email',
  CHAT = 'chat'
}

export enum OfferType {
  CARGO = 'cargo',
  TRANSPORT = 'transport'
}

export interface GetOfferRequest {
  id: Offer['_id'],
  active_space_company_id: Company['id'] | null
  polygon?: 1
  way_polyline?: 1
}

export interface ExchangePagination {
  sort?: 'asc' | 'desc'
  page?: number
  records?: number
}

export interface CreateOfferRequestBase {
  creation_method: CreationMethod.MANUAL
  organization: { id: ID, name: string } | null
  assignee_users: Array<{
    id: Profile["id"]
    contact_methods: { method: AssigneeContactMethod }[]
  }>
  is_public: boolean
  private_partners: ID[]
  offer_type: OfferType
  description: string | null
  attachments: URL[]
  route: Array<Omit<RoutePoint, '_id'>>,

  cargo_units: Array<Omit<CargoUnit, '_id'>> | null
  transportation_requirement: TransportationRequirement | null,
  transport?: TransportInfo
  payment_cargo?: PaymentCargo
  payment_transport?: PaymentTransport
}

export interface CreateOfferRequest extends CreateOfferRequestBase {
  status: OfferStatus.PUBLISHED | OfferStatus.NOT_PUBLISHED
  // company_id: Company['id'] | null
}

export interface UpdateOfferRequest extends RecursivePartial<Omit<CreateOfferRequest, 'status'>> {
  id: string
  status?: OfferStatus
  deleted_at?: string | null
}

export interface CreateDuplicatesRequest {
  offer: Pick<Offer, '_id'>,
  count: number
}

export interface RoutePointDatetime {
  date: string | null // YYYY-MM-DD
  time: string | null // HH:MM:SS
  datetime?: string // usually exist on offer output
}

export interface RoutePoint {
  // _id: string
  order: number
  point_type: PointTypeSlug
  start_datetime: RoutePointDatetime
  end_datetime: RoutePointDatetime | null
  time_zone: string
  point_timezone?: string // after creation
  loading_type: LoadingTypeSlug[] | null
  start_time_offset_days?: RouteStartDateOffsetDays
  location: {
    osm_id: string
    osm_type: string

    address: {
      title: string | null
      lang: string | null
      country_code: string | null
      country_name: string | null
      county: string | null
      state_name: string | null
      state_code: string | null
      is_ru_us_in: boolean | null
      city: string | null
      district: string | null
      sub_district: string | null
      street: string | null
      postal_code: string | null
      house_number: string | null
      time_zone: string | null
    },
    is_radius: boolean
    radius: number | null
    position?: GeoJson | null
    position_type: string | null
    access_point: PointGeoJson | null
    map_view: {
      west: number
      south: number
      east: number
      north: number
    } | null
  }
}

export interface PaymentCargo {
  is_not_quote: boolean
  currency: CurrencySlug | null
  quote: number | null
  is_prepay: boolean
  prepay_percent: number | null
  payment_condition: PaymentConditionSlug | null
  payment_period: number | null
  payment_method: PaymentMethodSlug | null
}

export interface PaymentTransport {
  is_not_quote: boolean
  currency: CurrencySlug | null
  quote_mileage: number | null
  mileage_format: MileageFormat
}

export interface CargoUnit {
  _id: ID
  name: string | null
  type: CargoTypeSlug | null
  quantity: number | null
  weight: number
  volume: number
  adr_class: AdrClassSlug | null
  length: number | null
  width: number | null
  height: number | null
  temperature_min_c: number | null
  temperature_max_c: number | null
}

export interface TransportationRequirement {
  shipping_mode: ShippingModeSlug | null
  type: TransportTypeSlug
  quantity: number | null
  body: TruckBodySlug[]
  equipment: ExtraEquipmentSlug[] | null
  is_trailer_interchange: boolean
}

export interface RouteWay {
  total_dur: number | null
  total_length: number | null
  way?: FlexiblePolyline | null
}

export type Offer = CargoOffer | TransportOffer

// interface OfferCommonFields {
//
// }

export interface AssigneeContact {
  id: Profile["id"]
  contact_methods: {
    method: AssigneeContactMethod
    value: string
  }[]
}

interface GeoProcessState {
  status: GeoStateStatus
}

export interface CargoOffer {
  _id: string
  views_count: number
  status_changed_at: string
  published_at: string
  company_id: Company['id'] | null
  creation_profile_id: Profile['id']
  offer_name: string
  creation_method: CreationMethod
  assignee_users: AssigneeContact[] | null
  organization: Organization | null
  // assignee_contact_method: AssigneeContactMethod[] | null
  status: OfferStatus
  is_public: boolean
  private_partners?: string[] | null
  offer_type: OfferType.CARGO
  description: string | null
  attachments: string[] | null
  cargo_units: CargoUnit[] | null
  transportation_requirement: TransportationRequirement
  route: RoutePoint[]
  payment_cargo: PaymentCargo
  geo_process_state?: GeoProcessState
  watching_now_count?: number

  publication_autocancel_at: string
  publication_autocancel_type: 'time_expiration' | 'assignee_removed' | 'moderator'
  is_publication_autocanceled: boolean
  is_autoclosed: boolean
  autoclose_at?: string
  closed_at: string
  deleted_at: string | null
  route_way?: RouteWay

  matches_exact?: number | null
  matches_partial?: number | null
  matches_exact_count?: number | null
  matches_partial_count?: number | null

  created_at: string
  updated_at: string
}

interface Organization {
  id: string
  name: string
}

export interface TransportOffer {
  _id: string
  views_count: number
  status_changed_at: string
  published_at: string
  company_id: Company['id'] | null
  creation_profile_id: Profile['id']
  offer_name?: string
  creation_method: CreationMethod
  assignee_users: AssigneeContact[] | null
  organization: Organization | null
  // assignee_contact_method: AssigneeContactMethod[] | null
  status: OfferStatus
  is_public: boolean
  private_partners: string[] | null
  offer_type: OfferType.TRANSPORT
  description: string | null
  attachments: string[] | null
  transport: TransportInfo
  route: RoutePoint[]
  payment_transport: PaymentTransport
  watching_now_count?: number

  publication_autocancel_at: string
  publication_autocancel_type: 'time_expiration' | 'assignee_removed' | 'moderator'
  is_publication_autocanceled: boolean
  is_autoclosed: boolean
  autoclose_at?: string
  closed_at: string
  deleted_at: string | null
  route_way?: RouteWay

  matches_exact?: Array<Offer['_id']> | null
  matches_partial?: Array<Offer['_id']> | null
  matches_exact_count?: number | null
  matches_partial_count?: number | null

  created_at: string
  updated_at: string
}

export interface TransportInfo {
  type: TransportTypeSlug | null
  body: TruckBodySlug
  equipment: ExtraEquipmentSlug[]
  loading_type: LoadingTypeSlug[]
  shipping_mode: ShippingModeSlug[] | null
  is_trailer_interchange: boolean
  temperature_min_c: number | null
  temperature_max_c: number | null
  adr_class: AdrClassSlug[] | null
  available_weight: number | null
  available_volume: number | null
  length: number | null
  width: number | null
  height: number | null
}

export interface GetMyOffersRequest extends ExchangePagination {
  type?: OfferType
  status?: OfferStatus[]
  active_space_company_id?: Company['id'] | null
}

export interface GetTemplatesRequest {
  page?: number
  records?: number
  type?: OfferType
  active_space_company_id?: Company['id'] | null
}

export interface GetOffersResponse {
  result: Offer[]
  total: number
  count: number
  page: number
  count_by_status: OfferInStatusCount[]

  profiles?: Profile[]
  companies?: Company[]
}

export interface OfferInStatusCount {
  count: number
  status: OfferStatus
}

export interface GetPublicOffersRequest {
  offer_type: OfferType

  page?: number
  records?: number
  sort?: 'asc' | 'desc'

  start_point?: {
    osm_id: string
    osm_type: string
    radius: number | null
  }
  end_point?: {
    osm_id: string
    osm_type: string
    radius: number | null
  }

  shipping_mode?: string | null
  transport_body?: TruckBodySlug[] | null
  transport_type?: TransportTypeSlug[] | null
  weight_min?: number | null
  weight_max?: number | null
  volume_min?: number | null
  volume_max?: number | null
  adr_class?: AdrClassSlug[] | null
  type_exact?: boolean | null

  date_start?: string | null // '2016-01-02'
  date_end?: string | null // 2016-01-02
}

export interface GetPublicOfferRequest {
  id: Offer["_id"]
  user_hash: string
}

export interface GetPublicOffersCompanyRequest extends ExchangePagination {
  offer_type?: OfferType
  companyId: string
}

export type GetTemplatesResponse = {
  count: number
  page: number
  total: number
  result: Array<Template>
}

export type CreateTemplateRequest = {
  name: string
  body: CreateTemplateBody
}

interface CreateTemplateBody extends CreateOfferRequestBase {
  company_id?: Company['id'] | null
}

export type Template = {
  id: ID
  name: string
  body: RecursivePartial<CreateOfferResponse>
}

export interface GetOfferResponse {
  result: Offer
  profiles: Profile[]
  companies: Company
}

export type GetOfferRouteResponse = RouteWay

export type GeoStateStatus = 'ok' | 'failed' | 'in_progress'

export interface GetOfferGeoStateResponse {
  status: GeoStateStatus
  error_msg?: string
}

export interface GetOfferMatchesRequest extends ExchangePagination {
  id: Offer['_id']

  exact: boolean
  polygon?: boolean
  way_polyline?: boolean
}

export interface GeoOfferMatchesResponse {
  count: number
  last_matched_at: string | null
  matches: Offer[]
  page: number
  total: number

  profiles?: Profile[]
  companies?: Company[]
}


/*
 * Type guards
 */
export const isCargoOffer = (offer: Pick<Offer, 'offer_type'>): offer is CargoOffer => {
  return offer.offer_type === OfferType.CARGO
}

export const isTransportOffer = (offer: Pick<Offer, 'offer_type'>): offer is TransportOffer => {
  return offer.offer_type === OfferType.TRANSPORT
}
/*
 * Type guards end
 */
