import * as React from 'react'

interface Props {
  color?: string
}

export function CompanyProfile({ color = '#272A36' }: Props) {
  return (
    <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.98558 14.7643H11.0143V9.56429C11.0143 9.40238 10.9655 9.27143 10.8677 9.17143C10.77 9.07143 10.642 9.02143 10.4837 9.02143H7.52321C7.36029 9.02143 7.22996 9.07143 7.1322 9.17143C7.03445 9.27143 6.98558 9.40238 6.98558 9.56429V14.7643ZM4.25551 15.5H13.7025C14.2006 15.5 14.5869 15.3619 14.8616 15.0857C15.1362 14.8095 15.2735 14.4214 15.2735 13.9214V6.02857L14.1494 5.24286V13.6286C14.1494 13.8619 14.0912 14.0405 13.9748 14.1643C13.8585 14.2881 13.6886 14.35 13.4651 14.35H4.49291C4.26947 14.35 4.09725 14.2881 3.97622 14.1643C3.85985 14.0405 3.80166 13.8619 3.80166 13.6286V5.25L2.67752 6.02857V13.9214C2.67752 14.4214 2.81484 14.8095 3.08947 15.0857C3.36876 15.3619 3.75744 15.5 4.25551 15.5ZM0.666626 7.55C0.666626 7.69762 0.720157 7.83095 0.827218 7.95C0.93428 8.06429 1.08091 8.12143 1.2671 8.12143C1.36485 8.12143 1.45329 8.09762 1.53243 8.05C1.61621 8.00238 1.69535 7.95 1.76982 7.89286L8.75907 1.89286C8.8382 1.82143 8.91966 1.78571 9.00345 1.78571C9.09189 1.78571 9.17568 1.82143 9.25481 1.89286L16.2441 7.89286C16.3139 7.95 16.3884 8.00238 16.4675 8.05C16.5513 8.09762 16.642 8.12143 16.7398 8.12143C16.9027 8.12143 17.0424 8.07381 17.1587 7.97857C17.2751 7.87857 17.3333 7.74286 17.3333 7.57143C17.3333 7.37143 17.2611 7.20952 17.1168 7.08571L9.84831 0.835714C9.58763 0.611905 9.30602 0.5 9.00345 0.5C8.70554 0.5 8.42625 0.611905 8.16558 0.835714L0.890058 7.08571C0.741103 7.20952 0.666626 7.36429 0.666626 7.55ZM13.521 4.32857L15.2735 5.84286V2.54286C15.2735 2.38571 15.227 2.2619 15.1339 2.17143C15.0454 2.07619 14.9244 2.02857 14.7708 2.02857H14.0237C13.8747 2.02857 13.7537 2.07619 13.6606 2.17143C13.5675 2.2619 13.521 2.38571 13.521 2.54286V4.32857Z'
        fill={color}
      />
    </svg>
  )
}
