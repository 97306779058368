import cn from 'classnames'
import { ReactNode } from 'react'
import styles from './MenuListItem.module.scss'

interface Props {
  Component?: any
  prepend?: ReactNode
  label?: ReactNode
  className?: string
  labelClassName?: string
  disabled?: boolean

  [key: string]: any
}

export const MenuListItem = ({
  Component = 'button',
  prepend,
  label,
  className = '',
  labelClassName = '',
  disabled = false,
  onClick,
  ...rest
}: Props) => {
  return (
    <Component
      className={cn(styles.root, className, {
        [styles.disabled]: disabled
      })}
      onClick={disabled ? () => {} : onClick}
      {...rest}
    >
      {prepend && (
        <span className={styles.icon}>
          {prepend}
        </span>
      )}

      <div className={cn(styles.label, labelClassName)}>
        {label}
      </div>
    </Component>
  )
}
