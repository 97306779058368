import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { useLongPress } from 'ahooks'
import { cloneElement, forwardRef, MouseEvent, ReactElement, ReactNode, useRef } from 'react'

// Version working on ios safari as well
interface Props {
  children: ReactElement<any>

  onContextMenu: (event: any) => void
}

export const WithUniversalContextMenu = forwardRef(({
  children,
  onContextMenu
}: Props, outerRef: any) => {
  const internalRef = useRef(null)

  const ref = outerRef || internalRef

  // if(IS_MOBILE) {
    useLongPress((event: TouchEvent) => {
      event.preventDefault()

      onContextMenu(event.changedTouches[0])
    },
      () => ref.current, {
      moveThreshold: { x: 10, y: 10 },
    })
  // }

  return cloneElement(children, {
    ref,
    onContextMenu: (event) => {
      event.preventDefault()
      onContextMenu(event)
    }
  })
})
