import { useState } from 'react'

interface State<TModel> {
  currentModel: TModel | null,
  show: boolean,
  position: {
    left: number,
    top: number
  }
}
export function useContextMenuState<TModel>() {
  type CurrentStateType = State<TModel>

  const initialState: CurrentStateType = {
    currentModel: null,
    show: false,
    position: { left: 0, top: 0 }
  }

  const [state, setState] = useState(initialState)

  const showContextMenu = (model: TModel, position: State<TModel>["position"]) => {
    setState({
      currentModel: model,
      show: true,
      position
    })
  }

  const hideContextMenu = () => {
    setState(initialState)
  }

  return {
    showContextMenu,
    hideContextMenu,
    ctx: state
  }
}
