import { authClient, profilesClient, systemClient } from '@/api'
import { cleanDatabase, db, DEXIE_STORES } from '@/database'
import { addOrUpdateProfiles } from '@/repositories/profiles.repository'
import { authStore } from '@/store/auth/auth.store'
import { settings } from '@/store/client-settings/client-settings'
import { authFormStore } from '@/store/forms/auth-form.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { systemStore } from '@/store/system/system.store'
import { appWorker } from '@/workers/app-worker/useAppWorker'
import { AuthenticableSocialNetwork, SUCCESS_RESPONSE } from '@roolz/types/api/profiles'
import { getNow, MINUTE_MS, SECOND_MS } from '@/utils/date'
import { clean, retrieveClientside } from '@roolz/sdk/utils/auth'
import { getDeviceInfo } from '@roolz/sdk/utils/device'
import sha256 from 'sha256'
import { persist } from '@roolz/sdk/utils/auth'

class AuthService {
  loginByEmail(params: { email: string, password: string }) {
    return authClient.loginByEmail({
      ...params,
      device: getDeviceInfo()
    })
      .then(({ data }) => {
        authStore.setCredentials(data.credentials)

        return data
      })
  }

  registerAccountByEmailPassword(params: { email: string, password: string }) {
    return profilesClient.registerProfile({
      ...params,
      device: getDeviceInfo()
    })
      .then(({ data }) => {
        authStore.setCredentials(data.credentials)

        return data
      })
  }

  sendMagicLink({ email }: { email: string }) {
    return authClient.getMagicLink({ email })
      .then(resp => {
        const { data } = resp
        if(data !== SUCCESS_RESPONSE) {
          throw new Error('Unknown server response')
        }

        return resp
      })
  }

  checkIfEmailBusy({ email }: { email: string }) {
    return profilesClient.checkIfEmailBusy({ email })
      .then(resp => {
        authFormStore.isAccountExists = !!resp?.data?.is_busy
      })
      .catch(e => {
        authFormStore.isAccountExists = false

        return Promise.reject(e)
      })
  }

  sendSms({ phone }: { phone: string }) {
    phone = '+' + phone.replaceAll(/\D/g, '')
    const hash = sha256(`${phone}_${process.env.REACT_APP_SECRET_SMS_KEY}`)

    return systemClient.sendSms({
      phone,
      imei: getDeviceInfo().installation_id,
      hash
    })
      .then(({ data }) => {
        authFormStore.setIsAccountExists(data.profile_exist)
        authFormStore.setSmsId(data.sms_id)

        return data
      })
  }

  submitSms({ sms_id, code }: { sms_id: string, code: string }) {
    return authClient.loginBySms({
      sms_id,
      code,
      device: getDeviceInfo()
    })
      .then(({ data }) => {
        const { credentials, profile } = data

        authStore.setCredentials(credentials)
        addOrUpdateProfiles([profile])
      })
  }

  loginViaSocial(params: {
    user_token: string,
    social_network: AuthenticableSocialNetwork,
    invite_id?: string,
    email?: string
  }) {
    return authClient.loginViaSocialNetwork({
      ...params,
      device: getDeviceInfo()
    })
      .then(({ data }) => {
        const { credentials, profile } = data

        authStore.setCredentials(credentials)
        addOrUpdateProfiles([profile])
      })
  }

  sendRestorePasswordEmail({ email }: { email: string }) {
    return authClient.getResetPasswordLink({
      email
    })
  }

  /**
   * Take auth credentials from storage (p.e. cookie) and load it to application
   */
  restoreAuth() {
    return retrieveClientside()
      .then(credentials => {
        if(credentials === null) {
          throw new Error('Auth credentials restoring failed')
        }

        authStore.setCredentials({
          access_token: credentials.access_token,
          refresh_token: credentials.refresh_token ?? '',
          expiration_at: (credentials.expiration_at ?? 0) * SECOND_MS
        })

        return true
      })
  }

  private isRefreshing = false
  async refreshTokenIfNecessary() {
    if(this.isRefreshing) return

    if(authStore.expiration_at) {
      const expTime = new Date(authStore.expiration_at).getTime()
      const now = getNow().getTime()
      const timeRemains = expTime - now

      if(timeRemains < 5 * MINUTE_MS) {
        this.isRefreshing = true
        return this.refreshTokens().finally(() => {
          this.isRefreshing = false
        })
      }
    }
    return false
  }

  async fullLogout() {
    return authClient.logout()
      .finally(() => this.clientsideLogout())
  }

  async clientsideLogout() {
    return clean()
      .then(async () => {
        localStorage.clear()
        sessionStorage.clear()

        try {
          await cleanDatabase()
        } catch(e) {
          console.log(e)
        }
        appWorker?.close()
        authStore.clean()
      })
  }

  async syncCredentialsWithIdb() {
    try {
      await this.cleanIdbDataFromAnotherUserIfNecessary()

      await db[DEXIE_STORES.AUTH].put({
        id: profilesStore.my_profile?.id ?? 1,
        access_token: authStore.access_token ?? '',
        expiration_at: authStore.expiration_at ?? 0,
        refresh_token: authStore.refresh_token ?? ''
      })
    } catch(e) {
      console.error('CANT SAVE CREDS TO IDB', e)
    }
  }

  async cleanIdbDataFromAnotherUserIfNecessary() {
    try {
      const creds = (await db[DEXIE_STORES.AUTH].toArray())?.[0]

      // Logged to another profile, need to clean idb\n'
      if(!creds || creds?.id !== profilesStore.my_profile?.id) {
        console.log('ANOTHER USER: FOUND', creds, profilesStore.my_profile?.id)

        await cleanDatabase()
        await db.open()
        appWorker?.close()
      } else {
        console.log('ANOTHER USER: NOT FOUND')
      }
    } catch(e) {
      console.log('CLEAN OLD IDB: ', e)
    }
  }

  protected async refreshTokens() {
     if(authStore.refresh_token) {
        authClient.authToken({
          grant_type: 'refresh_token',
          refresh_token: authStore.refresh_token
        })
          .then(({ data }) => {
            console.log('refresh res', data)
            persist({
              access_token: data?.access_token ?? '',
              refresh_token: data?.refresh_token ?? '',
              expiration_at: data?.expiration_at ?? '',
              token_type: 'Bearer'
            })

            authStore.setCredentials({
              access_token: data.access_token,
              refresh_token: data.refresh_token ?? '',
              expiration_at: (data.expiration_at ?? 0) * SECOND_MS
            })

            return this.syncCredentialsWithIdb()
          })
     }

     return false
  }
}

export const authService = new AuthService
