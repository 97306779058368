import { InputAdornment, TextField } from '@mui/material'
import { useRef } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Search } from '@roolz/icons/Search'

interface Props {
  className?: string

  collapsed?: boolean
  value?: string | null
  onChange?: (value: string) => void
  onClick?: () => void
}

export const ChatSearchInput = ({
  className,

  collapsed,
  value,
  onChange,
  onClick
}: Props) => {
  const { t } = useTranslation('chat/common')

  const ref = useRef<any>()

  function handleFocus() {
    if(ref.current) {
      ref.current.blur()
    }
  }

  return (
    <TextField
      ref={ref}
      inputProps={{
        maxLength: 30
      }}
      className={className}
      classes={{ root: className }}
      InputProps={{
        sx: { height: 32 },

        startAdornment: (
          <InputAdornment
            position='start'
            style={{
              paddingRight: '6px',
              paddingLeft: collapsed ? '10px' : 0,
              transition: '0.3s padding-left'
            }}
          >
            <Search/>
          </InputAdornment>
        ),
        readOnly: true
      }}
      tabIndex={-1}
      onFocus={handleFocus}
      value={value}
      placeholder={collapsed ? '' : t('search.placeholder')}
      onChange={event => onChange && onChange(event.target.value)}
      onClick={onClick}
    />
  )
}
